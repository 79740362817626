import React from "react";
import EmailCardHover from "./EmailCardHover";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "./emailcardhover.css";
const styles = {
  card: {
    margin: "16px 16px 0 0",
    width: 230,
    height: "auto",
    fontSize: 6,
    position: "relative",
    backgroundColor: "white",
    overflow: "hidden",
    border: "none",
    borderRadius: "4px",
  },
  media: {
    height: 240,
  },
};
const classes = styles;
function EmailPreviewCard(props) {
  return (
    <div className="card" style={classes.card}>
      <CardHeader title={props.name} className="title-name"></CardHeader>
      <CardMedia
        className={classes.media}
        image={props.imgUrl}
        style={styles.media}
      ></CardMedia>
      <EmailCardHover
        flashFn={props.flashFn}
        emailTemplate={props.emailTemplate}
      />
    </div>
  );
}
export default EmailPreviewCard;
