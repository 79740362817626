import React from "react";

const cBlockCustom = {
  background:
    "url(/images/certificate-assets/background.jpg) top center no-repeat",
  "border-radius": "5px",
  border: "none",
  height: "710px",
  width: "1140px",
};

const colorBlack = {
  color: "#000",
};

class PrintCertificate extends React.Component {
  render() {
    const { authUser } = this.props;
    return (
      <div className="site-wrapper mb-3">
        <div className="site-wrapper-inner">
          <div className="container">
            <div className="panel c-blok" style={cBlockCustom}>
              <div className="panel-body">
                <h1>TRANSFORMERS 2023</h1>
                <h2>Global Youth Social Innovation Challenge</h2>

                <div
                  className="mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src="/images/certificate-assets/Tr2022.png" />
                  </div>
                  <div>
                    <h3 style={colorBlack}>Certificate of Participation</h3>
                    <h4 className="pb-2 mb-2 candidateName">
                      {authUser && authUser.name && authUser.name.first_name}{" "}
                      {authUser && authUser.name && authUser.name.last_name}
                    </h4>
                  </div>
                  <div>
                    <img src=" /images/certificate-assets/sdg.png" />
                  </div>
                </div>
                <p>
                  We appreciate your participation in
                  <br />
                  <strong>TALTransformers 2023 -</strong>{" "}
                  {/* <strong>TALScouts Global</strong> */}
                  {/* <br />
                  on <strong>24th JANUARY</strong> */}
                  <br />
                  Global Youth Social Innovation Challenge
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="mb-3"
                >
                  <div>
                    <img src="/images/certificate-assets/talscouts-logo.png" />
                  </div>

                  <div>
                    <img src="/images/certificate-assets/TAL-logo-black.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintCertificate;
