import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  TextareaAutosize,
} from "@material-ui/core";
import validator from "validator";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import useFormValidation from "../../hooks/useFormValidation";
import { bulkInviteMentor, deleteMentor } from "../../utils/api";
import toastr from "toastr";
import "./Admin.scss";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const MentorAllocation = (props) => {
  const classes = useStyles();
  const [mentors, setMentors] = useState([]);
  const initialState = {
    email: "",
    ideaId: null,
  };
  const { ideaIds, setIdeaIds, mentorsList } = props;
  const handleBulkInviteMentors = () => {
    const finalValues = {};
    const errors = {};
    const mentorsEmail = values.mentors && values.mentors.trim();

    if (!mentorsEmail || mentorsEmail.length == 0) {
      toastr.error("Please enter Mentor E-mails");
    } else if (mentorsEmail) {
      const emailList =
        mentorsEmail &&
        mentorsEmail.length > 0 &&
        mentorsEmail.replace(/\s/g, "").split(",");
      finalValues.email = emailList;
      finalValues.project = "Transformers 2023";

      let hasInvalidEmails =
        emailList &&
        emailList.length > 0 &&
        emailList.find((item) => !validator.isEmail(item.trim()));

      if (hasInvalidEmails) {
        toastr.error("Please enter valid email address: " + hasInvalidEmails);
        setValues((currentValues) => {
          return {
            ...currentValues,
            mentors: " ",
          };
        });
      } else {
        bulkInviteMentor({ ...finalValues, ideaId: ideaIds })
          .then((response) => {
            if (response.statusCode === 200) {
              if (mentorsList) {
                setMentors((previousValues) => [
                  {
                    _id: response.data._id,
                    email: emailList,
                    status: "pending",
                    role: "mentor",
                  },
                  ...previousValues,
                ]);
              }
              props.setDonationRequests([]);
              toastr.success("Invitation sent successfully");
              props.onCloseModal();
              setErrors({});
            }
            if (!mentorsList) {
              props.onAllocate();
            }
            setIdeaIds([]);
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              setErrors((currentValues) => {
                return {
                  ...currentValues,
                  title: "This Mentor already exists ",
                };
              });
              toastr.error("This Mentor already exists ");
            } else {
              console.log(error);
              toastr.error(
                error.response &&
                  error.response.data &&
                  error.response.data.message
              );
            }
          });
        setValues((currentValues) => {
          return {
            ...currentValues,
            mentors: " ",
          };
        });
      }
    }
  };

  useEffect(() => {
    if (mentorsList) {
      mentorsList && setMentors(mentorsList);
    } else {
      setMentors([]);
    }
  }, [mentorsList]);

  const handleDeleteMentor = (inputData) => {
    if (!inputData || !inputData._id) {
      toastr.error("Invalid Mentor details");
    } else if (
      window.confirm(
        "Are you sure you want to delete Mentor - " + inputData.email
      )
    ) {
      deleteMentor(inputData._id)
        .then(() => {
          setMentors(mentors.filter((item) => item._id != inputData._id));
          toastr.success("The Mentor is deleted successfully");
          props.refreshPage();
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleBulkInviteMentors);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px ",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.orgTitle}>Mentor Allocation</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon
                  onClick={(e) => {
                    setIdeaIds([]);
                    setMentors([]);
                    props.setDonationRequests([]);
                    props.onCloseModal();
                  }}
                />
              </span>
            </div>
            <Grid style={{ padding: "20px" }}>
              <Grid item xs={12} className="subtitle">
                Add Mentor <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid lg={6} xs={12} className="crt-form">
                <TextareaAutosize
                  variant="outlined"
                  className="f100"
                  placeholder="Please enter multiple emails by separating them with commas(,)"
                  name="mentors"
                  type="email"
                  value={values && values.mentors}
                  onChange={changeHandler}
                  aria-label="minimum height"
                  rowsMin={6}
                  inputProps={{ maxLength: 1000 }}
                />

                {errors && errors.mentors && (
                  <Typography className="custom-error">
                    {errors.mentors}
                  </Typography>
                )}
              </Grid>

              <Grid lg={6} xs={12} className="crt-forms">
                <Button
                  class="btn btn-danger w-25"
                  type="button"
                  id="validate_team"
                  onClick={handleBulkInviteMentors}
                  style={{ marginTop: "10px" }}
                >
                  Allocate
                </Button>
              </Grid>
            
            <Grid item lg={6} xs={12} className="subtitle">
              {mentors && mentors.length > 0 && (
                <p
                  style={{ 
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Your Mentors
                </p>
              )}
              <div className="tal-team-members" >
                {mentors &&
                  mentors.length > 0 &&
                  mentors.map((mentor, index) => {
                    return (
                      <>
                        <InputLabel
                          name="mentor"
                          value={mentor && mentor.email}
                        >
                          <div> {mentor && mentor.email}</div>
                          <div>
                            {mentor && mentor.status === "accepted" ? (
                              <IconButton
                                data-toggle="tooltip"
                                title="Accepted"
                                data-placement="top"
                                className="del-btn"
                              >
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="del-btn"
                                data-toggle="tooltip"
                                title="Waiting for Acceptance"
                                data-placement="top"
                              >
                                <ErrorOutlineIcon style={{ color: "red" }} />
                              </IconButton>
                            )}

                            <IconButton
                              data-toggle="tooltip"
                              title="Click to Delete Mentor"
                              data-placement="top"
                              className="del-btn"
                              onClick={() => handleDeleteMentor(mentor)}
                            >
                              <CancelIcon style={{ color: "red" }} />
                            </IconButton>
                          </div>
                        </InputLabel>
                      </>
                    );
                  })}
              </div>
            </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(MentorAllocation);
