import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import toastr from "toastr";
import Container from "@material-ui/core/Container";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@material-ui/core/Avatar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Box from "@material-ui/core/Box";

import { appContext } from "../../App";

import "./style.scss";
import "./newUserProfile.scss";
import styles from "./newUserProfile.module.scss";

import {
  updateProfile,
  getUserData,
  changeCurrentPassword,
  accountInformation,
  getAccountInfo,
  updateAccountInformation,
  getUserSummary,
  manageAccountInfo,
  getChannels,
} from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateProfile from "./ValidateProfile";
import ValidatePassword from "./ValidatePassword";
import ValidateBank from "./ValidateBank";
import NewAddress from "../common/NewAddress";
import Organization from "../Organization/Organization";
import Tooltip from "@material-ui/core/Tooltip";
import {
  isValidPassword,
  formatScore,
  numberWithCommas,
} from "../../utils/utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { LOGIN_PAGE } from "../../utils/utils";

import Radio from "@material-ui/core/Radio";
import Contacts from "../Contacts/Contacts";
import ContactGroups from "../ContactGroups/ContactGroups";
import AddPhotos from "../common/AddPhotos";
import PhoneNumber from "../Phone/PhoneNumber";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "start",
    color: theme.palette.text.secondary,
  },
}));

const NewUserProfile = (props) => {
  const {
    authUser,
    setAuthUser,
    isAdmin,
    isVolunteer,
    isSuperAdmin,
    region,
    userSummary,
    orgList,
    channels,
    setChannels,
    userLocaleInfo,
  } = useContext(appContext);
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/Ce2a424ef4ceda0e290183969f80b8bf3/projects/M5a82fbbd9de4261e887571c42aefeae91571702605323/images/M2f21aa2c1aae080d85ba324736f6e4d91586935513274"
  );
  const initialState = {
    userName: "",
    firstName: "",
    lastName: "",
    summary: "",
    institutionName: "",
    institutionUrl: "",
    institutionalRole: "",
    studentId: "",
  };

  const intialPasswordDetails = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const intialBankDetails = {
    label: "",
    accountNumber: null,
    routingNumber: null,
    isDefault: true,
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  //list of languages
  const languagesList = ["English", "Hindi", "Telugu"];

  const [showPassword, setShowPassword] = useState(true);
  const [userDetails, setUserDetails] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [mySummary, setMySummary] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [volunteerApplicationStatus, setVolunteerApplicationStatus] =
    useState(10);

  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);

  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [otherErrors, setOtherErrors] = useState({});
  const [, setChangePasswordError] = useState("");
  const initialAccountDetails = {
    label: "",
    accountNumber: null,
    routingNumber: null,
    isDefault: true,
  };
  const [phone, setPhone] = useState(initialState.phone || null);
  const [accountValues, setAccountValues] = useState(initialAccountDetails);
  const [accountDetails, setAccountDetails] = useState({});
  const [type, setType] = React.useState("");
  const [passwordForAccMan, setPasswordForAccMan] = useState("");
  const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
  const [defaultChannel, setDefaultChannel] = useState("");

  const handleAccMangementChange = (event) => {
    setType(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleChangeDefaultChannel = (event) => {
    setDefaultChannel(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeLanguage = (event) => {
    setLanguages(event.target.value);
  };

  const handleOrganization = () => {
    setOpenOrgModal(true);
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    props.history.push(`/organization/${orgId}`);
  };

  const handleDateofBirthChange = (date) => {
    setDob(date);
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const onConfirm = () => {
    updateUserProfile();
  };

  const onPasswordConfirm = () => {
    submitHandlerChangePassword();
  };

  const onBankConfirm = () => {
    submitHandlerAccountDetails();
  };
  const phoneRef = useRef();
  const updateUserProfile = () => {
    const finalValues = {};
    finalValues.email = values.email;
    finalValues.username = values.userName;
    finalValues.institutionName = values.institutionName;
    finalValues.studentId = values.studentId;
    finalValues.institutionUrl = values.institutionUrl;
    finalValues.institutionalRole = values.institutionalRole;

    finalValues.name = {
      first_name: values.firstName,
      last_name: values.lastName,
    };
    // validate the phone input
    if (!phoneRef.current.isValid()) {
      return;
    }
    //validate the phone input
    // if (!phone || !isPossiblePhoneNumber(phone)) {
    //   setOtherErrors((currentValues) => {
    //     return {
    //       ...currentValues,
    //       phone: "Please enter valid phone number",
    //     };
    //   });
    //   return;
    // } else {
    //   finalValues.phone = phone;
    //   setOtherErrors((currentValues) => {
    //     return {
    //       ...currentValues,
    //       phone: "",
    //     };
    //   });
    // }
    finalValues.summary = values.summary;
    finalValues.title = values.title;
    finalValues.aboutMe = values.aboutMe;
    finalValues.facebookProfileUrl = values.facebookProfileUrl;
    finalValues.instagramProfileUrl = values.instagramProfileUrl;
    finalValues.twitterProfileUrl = values.twitterProfileUrl;
    finalValues.linkedInProfileUrl = values.linkedInProfileUrl;
    finalValues.languages = languages;
    finalValues.defaultChannel = defaultChannel;
    finalValues.dob = dob ? dob && new Date(dob).getTime() : 0;
    if (gender === "m" || gender === "f" || gender === "o") {
      finalValues.gender = gender;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          gender: "",
        };
      });
    } else {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          gender: "Please Select gender.",
        };
      });
      return;
    }

    if (
      tempAddress ||
      tempAddress.line1 ||
      tempAddress.line2 ||
      tempAddress.city ||
      tempAddress.state ||
      tempAddress.country ||
      tempAddress.zip_code
    ) {
      const newAddress = {};
      newAddress.line1 = tempAddress.line1;
      newAddress.line2 = tempAddress.line2;
      newAddress.city = tempAddress.city;
      newAddress.state = tempAddress.state;
      newAddress.country = tempAddress.country;
      newAddress.zip_code = tempAddress.zip_code;
      finalValues.address = newAddress;
    }

    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        setAuthUser(response.data.data);
        if (authUser.unique_id === userId) {
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
        }
        toastr.success("User Profile Updated Successfully");
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            toastr.error(error.response.data.errors[0].msg);
          } else {
            toastr.error(error.response.message);
          }
        } else {
          toastr.error(error.message);
        }
      });
  };

  const handleOrgDetails = (orgData) => {
    if (orgData.orgId) {
      props.history.push(`/organization/${orgData.orgId._id}`);
    }
  };

  const updateDefaultImageUrl = (url) => {
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProfileImageUrl(url);
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
          return true;
        } else {
          toastr.error(response.data.message);
          return false;
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        return false;
      });
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateProfile, onConfirm);

  const {
    values: passwordValues,
    errors: passwordErrors,
    setErrors: setPasswordErrors,
    changeHandler: passwordChangeHandler,
    submitHandler: passwordSubmitHandler,
  } = useFormValidation(
    intialPasswordDetails,
    ValidatePassword,
    onPasswordConfirm
  );

  const {
    values: bankValues,
    setValues: setBankValues,
    errors: bankErrors,
    changeHandler: bankChangeHandler,
    submitHandler: bankSubmitHandler,
  } = useFormValidation(intialBankDetails, ValidateBank, onBankConfirm);

  const changeProfileImageClickHandler = () => {
    setShowingAddPhotoModal(true);
  };

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setUserDetails(userData);
        setValues((currentValues) => {
          return {
            ...currentValues,
            userName: userData.username,
            firstName: userData.name.first_name,
            lastName: userData.name.last_name,
            summary: userData.summary,
            email: userData.email,
            title: userData.title,
            institutionName: userData.institutionName,
            studentId: userData.studentId,
            institutionUrl: userData.institutionUrl,
            institutionalRole: userData.institutionalRole,
            aboutMe: userData.aboutMe,
            gender: userData.gender,
            facebookProfileUrl: userData.facebookProfileUrl,
            instagramProfileUrl: userData.instagramProfileUrl,
            twitterProfileUrl: userData.twitterProfileUrl,
            linkedInProfileUrl: userData.linkedInProfileUrl,
            dob: userData.dob ? moment(userData.dob).format("DD-MM-YYYY") : "",
            experienceSummary: userData.volunteerInfo
              ? userData.volunteerInfo.experienceSummary
              : "",
            hoursAvailablePerWeek: userData.volunteerInfo
              ? userData.volunteerInfo.hoursAvailablePerWeek
              : 0,
            whyWorkWithUs: userData.volunteerInfo
              ? userData.volunteerInfo.whyWorkWithUs
              : "",
            languages: userData.languages || [],
            defaultChannel: userData.defaultChannel,
          };
        });
        userData && userData.phone && setPhone(userData.phone);
        if (userData && userData.address) {
          setAddress({
            line1: userData.address.hasOwnProperty("line1")
              ? userData.address.line1
              : "",
            line2: userData.address.hasOwnProperty("line2")
              ? userData.address.line2
              : "",
            city: userData.address.hasOwnProperty("city")
              ? userData.address.city
              : "",
            state: userData.address.hasOwnProperty("state")
              ? userData.address.state
              : "",
            zip_code: userData.address.hasOwnProperty("zip_code")
              ? userData.address.zip_code
              : "",
            country: userData.address.hasOwnProperty("country")
              ? userData.address.country
              : "",
          });
        }
        if (userData && userData.dob) {
          setDob(moment(userData.dob));
        }
        if (userData && userData.profile_image_url) {
          setProfileImageUrl(userData.profile_image_url);
        }
        setGender(userData && userData.gender);
        setVolunteerApplicationStatus(
          userData && userData.volunteerApplicationStatus
        );
        setLanguages(userData && userData.languages);
        if (userData && userData.defaultChannel) {
          setDefaultChannel(userData.defaultChannel);
        } else {
          if (channels && channels.length > 0) {
            channels.forEach((channel) => {
              if (channel.isDefault) {
                setDefaultChannel(channel.name);
              }
            });
          }
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const loadChannels = () => {
    getChannels()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setChannels(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Channels:" + error.message);
      });
  };
  useEffect(() => {
    loadChannels();
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      setUserId(props.match.params.id);
      if (authUser && authUser.unique_id) {
        setEditMode(
          props.match.params.id === authUser.unique_id ||
            isSuperAdmin ||
            isAdmin ||
            isVolunteer
        );
      } else {
        setEditMode(false);
      }
      loadUserData(props.match.params.id);
    }
  }, [props.match.params.id, authUser, channels]);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setUniqueId(authUser.unique_id);
      getAccountInfo(authUser.unique_id)
        .then((response) => {
          setAccountValues(response.data.data[0]);
          setAccountDetails(response.data.data[0]);

          setBankValues((currentValues) => {
            return {
              ...currentValues,
              accountNumber: response.data.data[0]
                ? response.data.data[0].accountNumber
                : "",
              isDefault: response.data.data[0]
                ? response.data.data[0].isDefault
                : true,
              label: response.data.data[0] ? response.data.data[0].label : "",
              routingNumber: response.data.data[0]
                ? response.data.data[0].routingNumber
                : "",
              // _id: response.data.data[0] ? response.data.data[0]._id : "",
            };
          });
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
    handleUserSummary();
  }, [authUser, userSummary]);

  const handleUserSummary = () => {
    if (authUser) {
      getUserSummary(authUser.unique_id).then((response) => {
        setMySummary(response);
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const submitHandlerChangePassword = () => {
    const obj = {};
    const validPasswordMessage = isValidPassword(passwordValues.newPassword);

    if (validPasswordMessage) {
      setPasswordErrors((currentValues) => {
        return {
          ...currentValues,
          newPassword: validPasswordMessage,
        };
      });
    } else if (authUser && authUser.unique_id) {
      obj.currentPassword = passwordValues.currentPassword;
      obj.newPassword = passwordValues.newPassword;
      obj.accessToken = authUser.token_detail.token;
      obj.userId = authUser.unique_id;
      changeCurrentPassword(obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode === 401) {
            toastr.error(error.response.data.message);
          }
        });
    } else {
      setChangePasswordError("Password updated failed. User auth error.");
    }
  };

  const submitHandlerAccountDetails = () => {
    const id = uniqueId;
    const obj = {};
    obj.label = bankValues.label;
    obj.accountNumber = bankValues.accountNumber;
    obj.routingNumber = bankValues.routingNumber;
    obj.isDefault = bankValues.isDefault;

    if (accountDetails && accountDetails["_id"]) {
      updateAccountInformation(accountValues["_id"], id, obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    } else {
      accountInformation(bankValues, id)
        .then((response) => {
          if (response.data.statusCode === 200) {
            setAccountValues(response.data.data.label);
            setAccountValues(response.data.data.accountNumber);
            setAccountValues(response.data.data.routingNumber);
            toastr.success(response.data.message);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const submitAccountManagement = () => {
    const obj = {};
    let isValid = false;
    if (passwordForAccMan === "") {
      setErrorPasswordMessage("Please enter current Password");
    } else {
      obj.password = passwordForAccMan;
      isValid = true;
    }
    obj.type = type;
    obj.userId = authUser && authUser.unique_id;
    if (
      isValid &&
      window.confirm(
        `Are you sure you want to ${
          type === "deactivate"
            ? "Deactivate your account"
            : type === "delete"
            ? "Delete your account"
            : ""
        }`
      )
    ) {
      manageAccountInfo(obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            setAuthUser(null);
            localStorage.removeItem("authUser");
            props.history.push(LOGIN_PAGE);
          }
        })
        .catch((error) => {
          if (error) {
            if (error.data.statusCode === 401) {
              toastr.error(
                "Requested action could not be performed. You are being logged out because you entered a wrong password."
              );
            }
          }
        });
    }
  };
  let displayStyle = "";
  if (authUser && !authUser.password_verified) {
    displayStyle = "none";
  }

  return (
    <div>
      <div className="new-profile-container">
        <div className={classes.root}>
          <Container maxWidth="lg">
            <Grid container className="profile-container-main">
              <div
                className="drop-box"
                style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              >
                <div className={`${classes.paper} header`} elevation={0}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className="title-name "
                  >
                    User Profile
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="subtitle1"
                    className="sub-title"
                  >
                    User profile settings
                  </Typography>
                </div>
              </div>

              <Grid container spacing={2} className="profile-right">
                <Grid item xs={12} sm={4} className="center">
                  <Grid item xs={12} md={12} className="avatar-blk">
                    <Avatar
                      alt="Profile Photo"
                      className="avatar"
                      src={profileImageUrl}
                    />
                    {editMode &&
                      authUser &&
                      authUser.login_provider === "local" && (
                        <Fragment>
                          <CameraAltIcon
                            className="changeProfileImg"
                            onClick={changeProfileImageClickHandler}
                            style={{ cursor: "pointer" }}
                          />
                          <AddPhotos
                            title="Manage Profile Photo"
                            isOpen={showingAddPhotoModal}
                            onClose={onCloseAddPhoto}
                            uid={authUser && authUser.unique_id}
                            collection="ProfilePictures"
                            profilephotoURL={profileImageUrl}
                            onProfilephotoURLChange={updateDefaultImageUrl}
                            canEdit={true}
                          />
                        </Fragment>
                      )}
                    <p className="profileName">
                      {values.firstName + " " + values.lastName}
                    </p>
                  </Grid>
                  <div className="userActivity">
                    <Grid container spacing={2}>
                      <ul>
                        <li>
                          <Grid item xs={12}>
                            <div>
                              <span>
                                {mySummary && mySummary.donationRequests
                                  ? mySummary.donationRequests.total
                                  : 0}
                              </span>
                              <p>My Requests</p>
                            </div>
                          </Grid>
                        </li>
                        <li>
                          <Grid item xs={12}>
                            <div>
                              <span>
                                {mySummary &&
                                mySummary.donations &&
                                mySummary.donations.currencies.length > 0
                                  ? mySummary.donations.currencies.map(
                                      (summary, i) => (
                                        <span>
                                          {summary.currency === "INR" ? (
                                            <FontAwesomeIcon
                                              color="#fff"
                                              size="1x"
                                              icon={faRupeeSign}
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              color="#fff"
                                              size="1x"
                                              icon={faDollarSign}
                                            />
                                          )}{" "}
                                          {summary.amountDonated}
                                          {mySummary.donations.currencies
                                            .length > 1 && (
                                            <span className={`seperator-${i}`}>
                                              {" "}
                                              |{" "}
                                            </span>
                                          )}
                                        </span>
                                      )
                                    )
                                  : "$0"}
                              </span>
                              <p>Amount Donated</p>
                            </div>
                          </Grid>
                        </li>
                        <li>
                          <Grid item xs={12}>
                            <div>
                              <span>
                                {mySummary &&
                                mySummary.donationRequests &&
                                mySummary.donationRequests.currencies.length > 0
                                  ? mySummary.donationRequests.currencies.map(
                                      (summary, i) => (
                                        <span>
                                          {summary.currency === "INR" ? (
                                            <FontAwesomeIcon
                                              color="#fff"
                                              size="1x"
                                              icon={faRupeeSign}
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              color="#fff"
                                              size="1x"
                                              icon={faDollarSign}
                                            />
                                          )}{" "}
                                          {summary.raisedAmount}
                                          {mySummary.donationRequests.currencies
                                            .length > 1 && (
                                            <span className={`seperator-${i}`}>
                                              {" "}
                                              |{" "}
                                            </span>
                                          )}
                                        </span>
                                      )
                                    )
                                  : "$0"}
                              </span>
                              <p>Total Amount Raised</p>
                            </div>
                          </Grid>
                        </li>
                        <li>
                          <Grid item xs={12}>
                            <div>
                              <span>
                                {mySummary && mySummary.favorites
                                  ? mySummary.favorites.total
                                  : 0}
                              </span>
                              <p>My Favourites</p>
                            </div>
                          </Grid>
                        </li>
                      </ul>
                    </Grid>
                    {/* {editMode &&
                      !isVolunteer &&
                      volunteerApplicationStatus !== 0 &&
                      volunteerApplicationStatus !== -1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          className="becomeVolunteerBtn"
                          onClick={() =>
                            props.history.push(`/volunteerProfile/${userId}`)
                          }
                        >
                          Become a TALScout
                        </Button>
                      )} */}
                    {/* {isVolunteer && (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className="becomeVolunteerBtn"
                        onClick={() =>
                          props.history.push(`/volunteerProfile/${userId}`)
                        }
                      >
                        TALScout Profile
                      </Button>
                    )} */}
                    {/* {editMode && volunteerApplicationStatus === 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className="becomeVolunteerBtn"
                        onClick={() =>
                          props.history.push(`/volunteerProfile/${userId}`)
                        }
                      >
                        View or Modify TALScout Application
                      </Button>
                    )}
                    {editMode && volunteerApplicationStatus === -1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        className="becomeVolunteerBtn"
                        onClick={() =>
                          props.history.push(`/volunteerProfile/${userId}`)
                        }
                      >
                        TALScout Application rejected. Modify and Resubmit
                      </Button>
                    )} */}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      className="myProfileBtn"
                      onClick={() =>
                        props.history.push(`/${authUser.username}`)
                      }
                    >
                      My Public Profile
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      className="myProfileBtn"
                      onClick={() => props.history.push("/myOrganizations")}
                    >
                      My Organizations
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      className="myProfileBtn"
                      onClick={() => props.history.push("/myContactsandGroups")}
                    >
                      My Contacts and Groups
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} className="right">
                  <div className="drop-box" style={{ padding: "20px" }}>
                    <div
                      className={`${classes.paper} prfDetails`}
                      elevation={0}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        className="prf-name"
                      >
                        {values.firstName + " " + values.lastName}
                      </Typography>
                      <Typography variant="subtitle1" component="subtitle1">
                        {address &&
                          (address.line1 && address.line1 !== ""
                            ? address.line1
                            : "") +
                            `${" "}` +
                            (address.line2 && address.line2 !== ""
                              ? address.line2
                              : "") +
                            `${" "}` +
                            (address.city && address.city !== null
                              ? address.city
                              : "") +
                            `${" "}` +
                            (address.state && address.state !== null
                              ? address.state
                              : "") +
                            `${" "}` +
                            (address.zip_code && address.zip_code !== ""
                              ? address.zip_code
                              : "") +
                            `${" "}` +
                            (address.country && address.country !== null
                              ? address.country
                              : "")}
                      </Typography>
                    </div>

                    <div
                      className={`userPointsBlk ${classes.paper}`}
                      elevation={0}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{ margin: "5px 0" }}
                      >
                        <b>Kindness Points:</b>
                        {userDetails &&
                          numberWithCommas(userDetails.kindness_score)}
                      </Typography>
                    </div>

                    <div
                      className={`${classes.paper} userprofileFormBlk`}
                      elevation={0}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="secHeader">
                          <p>About</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            First Name <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                errors && errors.firstName
                                  ? styles.bg_red
                                  : values.firstName
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="firstName"
                            disabled={!editMode}
                            value={values.firstName}
                            onChange={changeHandler}
                          />
                          {errors && errors.firstName && (
                            <Typography className={styles.custom_error}>
                              {errors.firstName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Last Name <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                errors && errors.lastName
                                  ? styles.bg_red
                                  : values.lastName
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            style={{ width: "100%" }}
                            name="lastName"
                            disabled={!editMode}
                            value={values.lastName}
                            onChange={changeHandler}
                          />
                          {errors && errors.lastName && (
                            <Typography className={styles.custom_error}>
                              {errors.lastName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Title
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            style={{ width: "100%" }}
                            name="title"
                            disabled={!editMode}
                            value={values.title}
                            onChange={changeHandler}
                            placeholder="Give your title. (Ex: I am a Photographer)"
                          />
                          {errors && errors.title && (
                            <Typography className="custom-error">
                              {errors.title}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Short Summary
                          </InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={6}
                            disabled={!editMode}
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            style={{ width: "100%" }}
                            name="summary"
                            value={values && values.summary && values.summary}
                            onChange={changeHandler}
                            inputProps={{ maxLength: 1000 }}
                          />
                          {errors && errors.summary && (
                            <Typography className="custom-error">
                              {errors.summary}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Detailed Summary
                          </InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={6}
                            disabled={!editMode}
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            style={{ width: "100%" }}
                            name="aboutMe"
                            value={values && values.aboutMe && values.aboutMe}
                            onChange={changeHandler}
                            inputProps={{ maxLength: 1000 }}
                          />
                          {errors && errors.aboutMe && (
                            <Typography className="custom-error">
                              {errors.aboutMe}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Email
                          </InputLabel>
                          <Typography
                            className={
                              styles.plasma_input + " " + "profile-email"
                            }
                            style={{ padding: "17px 6px" }}
                          >
                            {values.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Username
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                errors && errors.userName
                                  ? styles.bg_red
                                  : values.userName
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            style={{ width: "100%" }}
                            name="userName"
                            disabled={!editMode}
                            value={values.userName}
                            onChange={changeHandler}
                            required
                          />
                          {errors && errors.userName && (
                            <Typography className={styles.custom_error}>
                              {errors.userName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box component="label">Institution Name</Box>
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.institutionName && "error-text"
                            }`}
                            variant="outlined"
                            fullWidth
                            placeholder="Please enter institution name "
                            name="institutionName"
                            onChange={changeHandler}
                            value={values.institutionName}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box component="label">Institution Web site</Box>
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.institutionUrl && "error-text"
                            }`}
                            variant="outlined"
                            fullWidth
                            placeholder="Please enter institution website "
                            name="institutionUrl"
                            onChange={changeHandler}
                            value={values.institutionUrl}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box component="label">Student ID</Box>
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.studentId && "error-text"
                            }`}
                            variant="outlined"
                            fullWidth
                            placeholder="Please enter Student ID "
                            name="studentId"
                            onChange={changeHandler}
                            value={values.studentId}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box component="label">Select Your Role</Box>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel className="secFormLabel">
                              {" "}
                              Select Your Role
                            </InputLabel>
                            <Select
                              variant="outlined"
                              name="institutionalRole"
                              value={values.institutionalRole}
                              label="Select Your role"
                              onChange={changeHandler}
                            >
                              <MenuItem value="student">Student</MenuItem>
                              <MenuItem value="judge">Judge</MenuItem>
                              <MenuItem value="mentor">Mentor</MenuItem>
                              <MenuItem value="ventureCapitalist">
                                Venture Capitalist
                              </MenuItem>
                              <MenuItem value="volunteer">Volunteer</MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </Select>{" "}
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          //style={{ position: "relative" }}
                          style={{ width: "100%" }}
                        >
                          <InputLabel className="secFormLabel">
                            Phone
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <PhoneNumber
                            phone={userDetails && userDetails.phone}
                            isPhoneVerified={
                              userDetails && userDetails.phone_verified
                            }
                            ref={phoneRef}
                          />

                          {/* <PhoneInput
                            className={
                              "phoneBdr" +
                              " " +
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                otherErrors.phone
                                  ? styles.bg_red
                                  : phone
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            style={{ paddingLeft: "10px" }}
                            variant="outlined"
                            fullWidth
                            placeholder=" Phone number *"
                            name=" Phone number "
                            defaultCountry={
                              userLocaleInfo && userLocaleInfo.country_code
                            }
                            value={phone}
                            onChange={setPhone}
                            onKeyPress={(e) => handleEnterClick(e)}
                          /> */}
                          {otherErrors && otherErrors.phone && (
                            <Typography className={styles.custom_error}>
                              {otherErrors.phone}
                            </Typography>
                          )}
                        </Grid>
                        <Grid container className="address">
                          <InputLabel
                            style={{ margin: "10px 0 0 0" }}
                            className="secFormLabel"
                          >
                            Address
                          </InputLabel>
                          <NewAddress
                            value={address}
                            onChange={handleChangeAddress}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p className="secInlineHeader">Other Details</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Date of Birth
                          </InputLabel>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              showTodayButton
                              format="dd-MMM-yyyy"
                              style={{ width: "100%", padding: "6px 0" }}
                              disabled={!editMode}
                              className={
                                styles.plasma_input +
                                " " +
                                styles.bg_normal +
                                " " +
                                styles.date_b
                              }
                              name="dob"
                              value={dob}
                              onChange={handleDateofBirthChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              disableFuture
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Gender
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <Select
                            value={gender}
                            onChange={handleChangeGender}
                            variant="outlined"
                            disabled={!editMode}
                            className="profile-fields gender"
                            style={{ width: "100%" }}
                            required
                          >
                            <MenuItem value={" "}>Select Gender</MenuItem>
                            <MenuItem value={"m"}>Male</MenuItem>
                            <MenuItem value={"f"}>Female</MenuItem>
                            <MenuItem value={"o"}>Other</MenuItem>
                          </Select>
                          {otherErrors && otherErrors.gender && (
                            <Typography style={{ color: "red" }}>
                              {otherErrors.gender}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Facebook URL
                          </InputLabel>
                          <TextField
                            type="text"
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="facebookProfileUrl"
                            disabled={!editMode}
                            value={values.facebookProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.facebookProfileUrl && (
                            <Typography className="custom-error">
                              {errors.facebookProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Twitter URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            style={{ width: "100%" }}
                            name="twitterProfileUrl"
                            disabled={!editMode}
                            value={values.twitterProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.twitterProfileUrl && (
                            <Typography className="custom-error">
                              {errors.twitterProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Instagram URL
                          </InputLabel>
                          <TextField
                            type="text"
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="instagramProfileUrl"
                            disabled={!editMode}
                            value={values.instagramProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.instagramProfileUrl && (
                            <Typography className="custom-error">
                              {errors.instagramProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Linkedin URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            style={{ width: "100%" }}
                            name="linkedInProfileUrl"
                            disabled={!editMode}
                            value={values.linkedInProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.linkedInProfileUrl && (
                            <Typography className="custom-error">
                              {errors.linkedInProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p className="secInlineHeader">Languages</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl className={classes.formControl}>
                            <Select
                              id="userLanguages"
                              multiple
                              value={languages}
                              onChange={handleChangeLanguage}
                              input={<Input />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              className={
                                styles.plasma_input +
                                " " +
                                styles.bg_normal +
                                " " +
                                styles.select_normal
                              }
                              style={{ paddingRight: "10px" }}
                              variant="outlined"
                            >
                              {languagesList.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={languages.indexOf(name) > -1}
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p className="secInlineHeader">
                            Which default channel do you want to be played on
                            TALRadio
                          </p>
                          <Divider className="secDivide" />
                          <Select
                            id="defaultChannel"
                            value={defaultChannel}
                            onChange={handleChangeDefaultChannel}
                            variant="outlined"
                            disabled={!editMode}
                            className={
                              styles.plasma_input +
                              " " +
                              styles.bg_normal +
                              " " +
                              styles.select_normal
                            }
                            style={{ paddingRight: "10px", marginTop: "12px" }}
                          >
                            {channels &&
                              channels.length > 0 &&
                              channels.map((channel) => (
                                <MenuItem
                                  key={channel._id}
                                  value={channel.name}
                                >
                                  {channel.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {editMode && (
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              className="saveBtn"
                              onClick={submitHandler}
                            >
                              Save
                            </Button>

                            <Divider className="secMainDivide" />
                          </Grid>
                        )}
                        <Grid item xs={12} className="secHeader noEdit">
                          <h5>Bank Account Details</h5>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={12} className="noEdit">
                          <InputLabel className="secFormLabel">
                            Bank Name
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                bankErrors && bankErrors.label
                                  ? styles.bg_red
                                  : bankValues.label
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            style={{ width: "100%" }}
                            type="text"
                            disabled={!editMode}
                            name="label"
                            onChange={bankChangeHandler}
                            value={bankValues.label}
                          />
                          {bankErrors && bankErrors.label && (
                            <Typography className={styles.custom_error}>
                              {bankErrors.label}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} className="noEdit">
                          <InputLabel className="secFormLabel">
                            Account Number
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                bankErrors && bankErrors.accountNumber
                                  ? styles.bg_red
                                  : bankValues.accountNumber
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            style={{ width: "100%" }}
                            name="accountNumber"
                            disabled={!editMode}
                            onChange={bankChangeHandler}
                            type="number"
                            value={bankValues.accountNumber}
                          />
                          {bankErrors && bankErrors.accountNumber && (
                            <Typography className={styles.custom_error}>
                              {bankErrors.accountNumber}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} className="noEdit">
                          <InputLabel className="secFormLabel">
                            {region && region._id === "IN"
                              ? "IFSC Code"
                              : region &&
                                region._id === "US" &&
                                "Routing Number"}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="routingNumber"
                            disabled={!editMode}
                            // value={accountValues && accountValues.routingNumber}
                            onChange={bankChangeHandler}
                            className={
                              styles.plasma_input +
                              " " +
                              ` ${
                                bankErrors && bankErrors.routingNumber
                                  ? styles.bg_red
                                  : bankValues.routingNumber
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            type="text"
                            value={bankValues.routingNumber}
                          />
                          {bankErrors && bankErrors.routingNumber && (
                            <Typography className={styles.custom_error}>
                              {bankErrors.routingNumber}
                            </Typography>
                          )}
                        </Grid>
                        {editMode && (
                          <Grid item xs={12} className="noEdit">
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              className="saveBtn"
                              onClick={bankSubmitHandler}
                            >
                              Save
                            </Button>
                            <Divider className="secMainDivide" />
                          </Grid>
                        )}
                        {authUser && authUser.password_verified && (
                          <Fragment>
                            <Grid
                              item
                              xs={12}
                              className="secHeader noEdit"
                              style={{ display: displayStyle }}
                            >
                              <p>Change Password</p>
                              <Divider className="secDivide" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                Current Password
                              </InputLabel>
                              <TextField
                                autoComplete="off"
                                variant="outlined"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  ` ${
                                    passwordErrors &&
                                    passwordErrors.currentPassword
                                      ? styles.bg_red
                                      : passwordValues.currentPassword
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                style={{ width: "100%" }}
                                disabled={!editMode}
                                type="password"
                                name="currentPassword"
                                onChange={passwordChangeHandler}
                                value={passwordValues.currentPassword}
                              />
                              {passwordErrors &&
                                passwordErrors.currentPassword && (
                                  <Typography className={styles.custom_error}>
                                    {passwordErrors.currentPassword}
                                  </Typography>
                                )}
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                New Password
                              </InputLabel>
                              <TextField
                                autoComplete="off"
                                variant="outlined"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  ` ${
                                    passwordErrors && passwordErrors.newPassword
                                      ? styles.bg_red
                                      : passwordValues.newPassword
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                style={{ width: "100%" }}
                                disabled={!editMode}
                                name="newPassword"
                                onChange={passwordChangeHandler}
                                value={passwordValues.newPassword}
                                type="password"
                              />
                              {passwordErrors && passwordErrors.newPassword && (
                                <Typography className={styles.custom_error}>
                                  {passwordErrors.newPassword}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                Confirm New Password
                              </InputLabel>
                              <FormControl
                                className="full-width border-radius"
                                style={{ width: "100%", position: "relative" }}
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  name="confirmPassword"
                                  disabled={!editMode}
                                  onChange={passwordChangeHandler}
                                  className={
                                    styles.plasma_input +
                                    " " +
                                    ` ${
                                      passwordErrors &&
                                      passwordErrors.confirmPassword
                                        ? styles.bg_red
                                        : passwordValues.confirmPassword
                                        ? styles.bg_green
                                        : styles.bg_normal
                                    }`
                                  }
                                  type={showPassword ? "password" : "text"}
                                  value={passwordValues.confirmPassword}
                                />
                                <InputAdornment
                                  position="end"
                                  className="showpassword"
                                  style={{
                                    position: "absolute",
                                    right: 12,
                                    top: 26,
                                  }}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Tooltip title="Click to Show the Password">
                                        <VisibilityOff />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Click to hide the Password">
                                        <Visibility />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              </FormControl>

                              {passwordErrors &&
                                passwordErrors.confirmPassword && (
                                  <Typography className={styles.custom_error}>
                                    {passwordErrors.confirmPassword}
                                  </Typography>
                                )}
                            </Grid>
                          </Fragment>
                        )}
                        {editMode && authUser && authUser.password_verified && (
                          <Grid
                            item
                            xs={12}
                            className="noEdit"
                            style={{ display: displayStyle }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              className="saveBtn"
                              onClick={passwordSubmitHandler}
                            >
                              Save
                            </Button>
                            <Divider className="secMainDivide" />
                          </Grid>
                        )}
                        <Grid item xs={12} className="secHeader noEdit">
                          <p>Manage Account</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={12} className="noEdit">
                          <InputLabel
                            className="secFormLabel"
                            style={{ lineHeight: "20px", fontWeight: "normal" }}
                          >
                            If you want to take a break from TALTransformers,
                            you can deactivate account. If you want to
                            permanently delete your account, let us know.
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12} md={6} className="noEdit">
                          <FormControl
                            component="fieldset"
                            className="feedback-type"
                          >
                            <RadioGroup
                              aria-label="deactivateAccount"
                              name="deactivateAccount"
                              value={type}
                              onChange={handleAccMangementChange}
                            >
                              <FormControlLabel
                                value="deactivate"
                                control={<Radio />}
                                label="Deactivate My Account"
                              />
                            </RadioGroup>
                          </FormControl>
                          <InputLabel
                            className="secFormLabel"
                            style={{ lineHeight: "20px", fontWeight: "normal" }}
                          >
                            Deactivating your account can be temporary. Your
                            profile will be disabled and your name, photos will
                            be removed from the things you have shared. You have
                            the option to reactivate by simply signing in again.
                          </InputLabel>
                          {type && type === "deactivate" ? (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                Enter Current Password
                              </InputLabel>
                              <TextField
                                required
                                id="outlined-required"
                                autoComplete="off"
                                variant="outlined"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  ` ${
                                    errorPasswordMessage
                                      ? styles.bg_red
                                      : errorPasswordMessage.label
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  setPasswordForAccMan(e.target.value)
                                }
                                type="password"
                              />
                              {errorPasswordMessage &&
                                type === "deactivate" && (
                                  <Typography className="custom-error">
                                    {errorPasswordMessage}
                                  </Typography>
                                )}
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} className="noEdit">
                          <FormControl
                            component="fieldset"
                            className="feedback-type"
                          >
                            <RadioGroup
                              aria-label="deleteAccount"
                              name="deleteAccount"
                              value={type}
                              onChange={handleAccMangementChange}
                            >
                              <FormControlLabel
                                value="delete"
                                control={<Radio />}
                                label="Delete My Account"
                              />
                            </RadioGroup>
                          </FormControl>
                          <InputLabel
                            className="secFormLabel"
                            style={{ lineHeight: "20px", fontWeight: "normal" }}
                          >
                            When you delete your TALTransformers account, you
                            won't be able to retrieve the content or information
                            you have shared on TALTransformers. You will have
                            the option to recover your data on signing up again
                            using the same email address, but you need to
                            reverify your email address.
                          </InputLabel>
                          {type && type === "delete" ? (
                            <Grid item xs={12} md={12} className="noEdit">
                              <InputLabel className="secFormLabel">
                                Enter Current Password
                              </InputLabel>
                              <TextField
                                required
                                id="outlined-required"
                                autoComplete="off"
                                variant="outlined"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  ` ${
                                    errorPasswordMessage
                                      ? styles.bg_red
                                      : errorPasswordMessage.label
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                style={{ width: "100%" }}
                                disabled={!editMode}
                                onChange={(e) =>
                                  setPasswordForAccMan(e.target.value)
                                }
                                type="password"
                              />
                              {errorPasswordMessage && type === "delete" && (
                                <Typography className="custom-error">
                                  {errorPasswordMessage}
                                </Typography>
                              )}
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {editMode && (
                          <Grid item xs={12} className="noEdit">
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              className="saveBtn"
                              style={{ width: "40%" }}
                              onClick={submitAccountManagement}
                            >
                              {type === "deactivate"
                                ? "Deactivate Account"
                                : type === "delete"
                                ? "Delete Account"
                                : "Save"}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>

      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};
export default NewUserProfile;
