import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withRouter } from "react-router-dom";
import useFormValidation from "../../hooks/useFormValidation";
import { getEmailTemplates, launchEmailCampaign } from "../../utils/api";
import validateEmailCampaign from "./validateEmailCampaign";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import { appContext } from "../../App";

import "./EmailCampaign.scss";

function SimpleDialog(props) {
  const { onClose, previewText, previewTitle, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div style={{ display: "flex" }}>
        <DialogTitle id="simple-dialog-title">{previewTitle}</DialogTitle>
        <div className="close-image-popup">
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ padding: "10px" }}>{ReactHtmlParser(previewText)}</div>
    </Dialog>
  );
}

const DonorEmailCampaign = (props) => {
  const { currentDonationRequest } = useContext(appContext);
  const initialState = {};
  const [extraErrors, setExtraErrors] = useState({});
  const [whenDonatedStartDate, setWhenDonatedStartDate] = useState(null);
  const [whenDonatedEndDate, setWhenDonatedEndDate] = useState(null);
  const [emailTemplateId, setEmailTemplateId] = useState("");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [previewText, setPreviewText] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [canLaunchEmailCampaign, setCanLaunchEmailCampaign] = useState(true);

  useEffect(() => {
    if (props.match.params.id) {
      getEmailTemplates().then((response) => {
        setEmailTemplates(response.data.data);
      });
    }
  }, [props.match.params.id]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (previewText, previewTitle) => {
    setPreviewText(previewText);
    setPreviewTitle(previewTitle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDateChange = (date) => {
    setWhenDonatedStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setWhenDonatedEndDate(date);
  };
  const emailTemplateChangeHandler = (event) => {
    setEmailTemplateId(event.target.value);
  };

  const previewHandler = () => {
    const errors = {};
    const finalValues = values;
    finalValues.donationRequestId = props.match.params.id;
    if (!values.amountDonatedMin) {
      errors.amountDonatedMin = "Please enter Minimum Donated Amount";
    }
    if (!values.amountDonatedMax) {
      errors.amountDonatedMax = "Please enter Maximum Donated Amount";
    }
    if (Number(values.amountDonatedMin) > Number(values.amountDonatedMax)) {
      errors.amountDonatedMin =
        "Minimum Donated Amount should be less than Maximum Donated Amount";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }
    if (new Date(whenDonatedStartDate).getTime() === 0) {
      errors.whenDonatedStartDate =
        "Please select the start date of donated period";
    } else {
      finalValues.whenDonatedStartDate = new Date(
        whenDonatedStartDate
      ).getTime();
    }
    if (new Date(whenDonatedEndDate).getTime() === 0) {
      errors.whenDonatedEndDate =
        "Please select the end date of donated period";
    } else {
      finalValues.whenDonatedEndDate = new Date(whenDonatedEndDate).getTime();
    }
    if (
      new Date(whenDonatedStartDate).getTime() >
      new Date(whenDonatedEndDate).getTime()
    ) {
      errors.whenDonated =
        "Donated period (From Date) should be earlier than Donated period (To Date).";
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else {
      setExtraErrors({});
      finalValues.isPreview = true;
      launchEmailCampaign(finalValues).then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          let previewText = "<ul>";
          response.data.data.forEach((donation) => {
            previewText =
              previewText + "<li>" + donation.userInfo.email + "</li>";
          });
          previewText = previewText + "</ul>";
          handleClickOpen(previewText, "Preview Donors List");
        } else {
          setCanLaunchEmailCampaign(false);
          toastr.warning("No Donor List found for the selected criteria.");
        }
      });
    }
  };

  const emailCampaignSubmitHandler = () => {
    const errors = {};
    const finalValues = values;
    finalValues.donationRequestId = props.match.params.id;
    if (new Date(whenDonatedStartDate).getTime() === 0) {
      errors.whenDonatedStartDate =
        "Please select the start date of donated period";
    } else {
      finalValues.whenDonatedStartDate = new Date(
        whenDonatedStartDate
      ).getTime();
    }
    if (new Date(whenDonatedEndDate).getTime() === 0) {
      errors.whenDonatedEndDate =
        "Please select the end date of donated period";
    } else {
      finalValues.whenDonatedEndDate = new Date(whenDonatedEndDate).getTime();
    }
    if (!emailTemplateId) {
      errors.selectedEmailTemplate =
        "Please select an email template to be used for the email campaign";
    } else {
      finalValues.emailTemplateId = emailTemplateId;
    }
    if (
      new Date(whenDonatedStartDate).getTime() >
      new Date(whenDonatedEndDate).getTime()
    ) {
      errors.whenDonated =
        "Donated period (From Date) should be earlier than Donated period (To Date).";
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else {
      launchEmailCampaign(finalValues).then((response) => {
        props.history.push(`/donationRequest/${props.match.params.id}`);
        toastr.success("Email campaign is launched successfully");
      });
    }
  };

  const {
    values,
    errors,
    setErrors,
    changeHandler,
    submitHandler,
  } = useFormValidation(
    initialState,
    validateEmailCampaign,
    emailCampaignSubmitHandler
  );

  return (
    <div className="emailcampaign">
      {/* <Container maxWidth={"lg"}> */}
      <Grid container spacing={2}>
        <Grid item md={12} sm={12}>
          <h3>Start Email Campaign</h3>
          <p>
            Select the Criteria for Donor List to whom the email should be sent.
          </p>
          <p>
            For Donation Request:{" "}
            {currentDonationRequest && currentDonationRequest.title}
          </p>
        </Grid>
        <Grid item sm={6} xs={12}>
          <p>Pick the donors who donated an amount in this range:</p>
          <TextField
            type="number"
            required
            className={`input-field request-amount ${
              errors && errors.amountDonatedMin
                ? "bg-red"
                : values.amountDonatedMin
                ? "bg-green"
                : "bg-normal"
            }`}
            variant="outlined"
            placeholder="Minimum Donated Amount"
            label="Minimum Donated Amount"
            name="amountDonatedMin"
            value={values.amountDonatedMin}
            onChange={changeHandler}
          />
          {errors && errors.amountDonatedMin && (
            <div className="custom-error">{errors.amountDonatedMin}</div>
          )}

          <TextField
            type="number"
            required
            className={`input-field request-amount ${
              errors && errors.amountDonatedMax
                ? "bg-red"
                : values.amountDonatedMax
                ? "bg-green"
                : "bg-normal"
            }`}
            variant="outlined"
            placeholder="Maximum Donated Amount"
            label="Maximum Donated Amount"
            name="amountDonatedMax"
            value={values.amountDonatedMax}
            onChange={changeHandler}
          />
          {errors && errors.amountDonatedMax && (
            <div className="custom-error">{errors.amountDonatedMax}</div>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="p">
            Pick the donors who donated in this period:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              showTodayButton
              format="dd-MMM-yyyy"
              style={{ width: "100%" }}
              margin="normal"
              className={`input-field dob ${
                extraErrors && extraErrors.whenDonatedStartDate
                  ? "bg-red"
                  : whenDonatedStartDate
                  ? "bg-green"
                  : "bg-normal"
              }`}
              label="Donated Period (From Date)"
              name="startDate"
              value={whenDonatedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            {extraErrors && extraErrors.whenDonatedStartDate && (
              <Typography className="custom-error">
                {extraErrors.whenDonatedStartDate}
              </Typography>
            )}
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              showTodayButton
              format="dd-MMM-yyyy"
              style={{ width: "100%" }}
              margin="normal"
              className={`input-field dob ${
                extraErrors && extraErrors.whenDonatedEndDate
                  ? "bg-red"
                  : whenDonatedEndDate
                  ? "bg-green"
                  : "bg-normal"
              }`}
              label="Donated Period (To Date)"
              name="endDate"
              value={whenDonatedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            {extraErrors && extraErrors.whenDonatedEndDate && (
              <Typography className="custom-error">
                {extraErrors.whenDonatedEndDate}
              </Typography>
            )}
          </MuiPickersUtilsProvider>
          {extraErrors && extraErrors.whenDonated && (
            <Typography className="custom-error">
              {extraErrors.whenDonated}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p">
            Use this Template for the Email Campaign
          </Typography>
        </Grid>

        {emailTemplates && emailTemplates.length > 0 && (
          <div className="template">
            {emailTemplates.map((emailTemplate) => {
              return (
                <div>
                  <Radio
                    name="emailTemplate"
                    checked={emailTemplateId === emailTemplate._id}
                    value={emailTemplate._id}
                    onChange={emailTemplateChangeHandler}
                  />
                  <span>
                    {emailTemplate.name}{" "}
                    <Button
                      variant="outlined"
                      onClick={(e) =>
                        handleClickOpen(
                          emailTemplate.htmlText,
                          "Preview Email Content"
                        )
                      }
                    >
                      Preview the Email
                    </Button>
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {extraErrors && extraErrors.selectedEmailTemplate && (
          <Typography className="custom-error">
            {extraErrors.selectedEmailTemplate}
          </Typography>
        )}
        <SimpleDialog
          previewText={previewText}
          previewTitle={previewTitle}
          open={open}
          onClose={handleClose}
        />
        <Grid item xs={12}>
          <Button
            variant="contained"
            className="submit-btn"
            onClick={previewHandler}
          >
            Preview Donors List
          </Button>
          <Button
            variant="contained"
            className="submit-btn"
            onClick={submitHandler}
            disabled={!canLaunchEmailCampaign}
          >
            Launch Email Campaign
          </Button>
          <p>
            You will be able to launch an email campaign if there is at least on
            donor matching the criteria. Change the Donor List criteria and
            click on PREVIEW DONORS LIST button to see if there are matching
            donors list.
          </p>
          <p>
            The emails may not be delivered instantaneously. Each email would be
            kept in a que on a first come first served basis and will eventually
            be delivered to the recipient.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(DonorEmailCampaign);
