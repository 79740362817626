import React, { useState, useEffect } from "react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import { TextField, Grid, Switch, Box } from "@material-ui/core";
import { saveChannel, updateChannel } from "../../utils/api";

export default function ChannelsForm(props) {
  const {
    isEnableEdit,
    setIsEnableEdit,
    selectedId,
    setChannels,
    channels,
    setIsDisplayChannels,
    rows,
    setRows,
  } = props;

  const [isDefault, setIsDefault] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [firebaseRefPath, setFirebaseRefPath] = useState("");
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  const handleIsDefault = () => {
    setIsDefault(!isDefault);
  };

  function handleName(event) {
    setName(event.target.value);
  }

  function handleFirebaseRefPath(event) {
    setFirebaseRefPath(event.target.value);
  }
  function handleLabel(event) {
    setLabel(event.target.value);
  }

  const handleBack = () => {
    setIsEnableEdit(false);
    setIsDisplayChannels(true);
  };

  useEffect(() => {
    if (isEnableEdit && channels.length && selectedId) {
      const currentItem = channels.filter((item) => item._id === selectedId);
      setName(currentItem[0] && currentItem[0].name);
      setFirebaseRefPath(currentItem[0] && currentItem[0].firebaseRefPath);
      setLabel(currentItem[0] && currentItem[0].label);
      setIsDefault(currentItem[0] && currentItem[0].isDefault);
    }
  }, [isEnableEdit, channels, selectedId]);

  const saveChannelData = (finalValues) => {
    saveChannel(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setChannels([
            {
              _id: resp.data._id,
              name: finalValues.name,
              label: finalValues.label,
              firebaseRefPath: finalValues.firebaseRefPath,
              isDefault: isDefault,
            },
            ...channels,
          ]);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsDisplayChannels(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  const updateChannelData = (finalValues) => {
    updateChannel(selectedId, finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = rows.map((item) => {
            if (item._id === selectedId) {
              return {
                _id: resp.data._id,
                name: finalValues.name,
                label: finalValues.label,
                firebaseRefPath: finalValues.firebaseRefPath,
              };
            } else {
              return item;
            }
          });
          setRows(oldRows);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsEnableEdit(false);
        setIsDisplayChannels(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.response.data.message);
      });
  };

  const handleNext = async () => {
    const extraErrors = {};

    if (name === "") {
      extraErrors.name = "Please enter name";
    }

    if (label === "") {
      extraErrors.label = "Please enter label";
    }

    if (firebaseRefPath === "") {
      extraErrors.firebaseRefPath = "Please enter firbasePath";
    }
    setExtraErrors(extraErrors);
    if (extraErrors && Object.keys(extraErrors).length !== 0) return;

    const finalValues = {};
    finalValues.name = name;
    finalValues.label = label;
    finalValues.firebaseRefPath = firebaseRefPath;
    finalValues.isDefault = isDefault;
    setIsWaitingForResponse(true);
    if (isEnableEdit) {
      finalValues.selectedId = selectedId;
      updateChannelData(finalValues);
    } else {
      saveChannelData(finalValues);
    }
  };

  return (
    <Box>
      <Box className="drop-box schedule-streaming" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box fontWeight="400" fontSize="20px" color="#ef6c00">
              {" "}
              Channels{" "}
            </Box>
          </Grid>
          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="Name"
              placeholder="Give a title"
              name="name"
              value={name}
              error={
                extraErrors && extraErrors.name && extraErrors.name.length > 0
              }
              helperText={extraErrors.name}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="label"
              placeholder="Give a Label"
              name="label"
              value={label}
              error={
                extraErrors && extraErrors.label && extraErrors.label.length > 0
              }
              helperText={extraErrors.label}
              onChange={handleLabel}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="FirebaseRefPath"
              placeholder="Give a Label"
              name="firebaseRefPath"
              value={firebaseRefPath}
              error={
                extraErrors &&
                extraErrors.firebaseRefPath &&
                extraErrors.firebaseRefPath.length > 0
              }
              helperText={extraErrors.firebaseRefPath}
              onChange={handleFirebaseRefPath}
            />
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button
            style={{
              backgroundColor: "rgb(205, 38, 39)",
              marginLeft: "5px",
              color: "white",
            }}
            variant="contained"
            onClick={handleNext}
          >
            {isEnableEdit ? "Save" : "Create"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
