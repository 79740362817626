import React from "react";
import StopIcon from "@material-ui/icons/Stop";

export default function Stop(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
      <StopIcon />
    </button>
  );
}
