import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import RadioButton from "@material-ui/core/Radio";
import "./Volunteerings.scss";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShortAddress from "../common/ShortAddress";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createDonationRequest,
  getOrganizationList,
  updateDonationRequest,
  getDonationRequest,
} from "../../utils/api";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import AddPhotos from "../common/AddPhotos";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";

import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormGroup,
  Checkbox,
  Switch,
  Chip,
  OutlinedInput,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  faRupeeSign,
  faDollarSign,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  HOME_PAGE,
  STATUS_NEW,
  STATUS_APPROVED,
  STATUS_INCOMPLETE,
  getKeyByValue,
  PERKS,
  ROLE_MEMBER,
} from "../../utils/utils";
import styles from "./Volunteerings.module.scss";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  field: {
    width: "589px",
    margin: "auto",
    marginRight: "125",
  },
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(skill, skillSet, theme) {
  return {
    fontWeight:
      skillSet.indexOf(skill) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function getSteps() {
  return ["Volunteer Request", "Image"];
}
const Volunteering = (props) => {
  const {
    authUser,
    setAuthUser,
    region,
    regions,
    setRegion,
    orgList,
    isSuperAdmin,
    isAdmin,
    isVolunteer,
  } = useContext(appContext);
  const theme = useTheme();
  const initialState = {
    cashQuantity: "",
    title: "",
    description: "",
    requestType: "regular",
    cashUnit: "USD",
    status: "-2",
    fundsRecipient: "",
    profile: "",
    numberOfOpenings: "",
    startDate: moment(),
    endDate: moment(),
    duration: "",
    monthlyStipend: "",
    educationalQualification: "",
  };
  const [closeSideMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [interviewDeclaration, setInterviewDeclaration] = useState(false);
  const [neighboringCities, setNeighboringCities] = useState(false);
  const [trainingMode, setTrainingMode] = useState("");
  const classes = useStyles();
  const [isOrgMember, setIsOrgMember] = useState(false);

  const [partTime, setPartTime] = React.useState(false);
  const [preplacement, setPreplacement] = React.useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const [selectedPerks, setSelectedPerks] = useState([]);
  const [profile, setProfile] = useState("");

  const [description, setDescription] = useState("");
  const [volunteerResponsibilities, setVolunteerResponsibilities] =
    useState("");
  const [volunteerRequirements, setVolunteerRequirements] = useState("");

  const [skillSet, setSkillSet] = React.useState([]);
  const [perks, setPerks] = useState([]);
  const [id, setId] = useState("");
  const [step, setStep] = useState(0);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [imageUpload, setImageUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };
  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    } else if (!editMode) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success(
          "Default profile Image is set for this volunteering request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the volunteering request. " +
            error.message
        );
      });
  };
  /*tooltip*/
  const [toolTipStatus, setToolTipStatus] = React.useState({
    orgHelpIcon: false,
    profileHelpIcon: false,
    descripitionHelpIcon: false,
    startdateHelpIcon: false,
    durationHelpIcon: false,
    responsiveHelpIcon: false,
    skillsHelpIcon: false,
    stipendHelpIcon: false,
    educationHelpIcon: false,
    lastDateHelpIcon: false,
    applyHelpIcon: false,
    locationHelpIcon: false,
    AddcoverphotoHelpIcon: false,
    typeofHelpIcon: false,
    NumbersHelpIcon: false,
  });

  const tooTipClose = () => {
    Object.keys(toolTipStatus).forEach((item) => {
      setToolTipStatus((previousValue) => ({
        ...previousValue,
        [item]: false,
      }));
    });
  };

  const handleTooltipClose = (event) => {
    let toolTipId = Object.keys(toolTipStatus).filter(
      (item) => item === event.target.id
    );
    if (!toolTipId.length > 0) {
      tooTipClose();
    }
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    Object.keys(toolTipStatus).forEach((item) => {
      if (item === e.currentTarget.id) {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: true,
        }));
      } else {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: false,
        }));
      }
    });
  };
  /*tooltip*/

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };
  const handleChangePerks = (value) => {
    const index = selectedPerks.indexOf(value);
    if (index === -1) {
      setSelectedPerks([...selectedPerks, value]);
    } else {
      const newValues = [...selectedPerks];
      newValues.splice(index, 1);
      setSelectedPerks(newValues);
    }
  };

  const handleChangeTrainingMode = (event) => {
    setTrainingMode(event.target.value);
  };
  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
    setValues((currentValues) => {
      return {
        ...currentValues,
        cashUnit: event.target.value,
      };
    });
  };

  const {
    certificate,
    flexibleWorkHours,
    informalDressCode,
    letterOfRecommendation,
    fivedaysaweek,
    freeSnacksandBeverages,
  } = perks;
  const profiles = [
    "Business Development (Sales)",
    "Web Development",
    "Graphic Design",
    "Content Writing",
    "Social Media Marketing",
    "Marketing",
    "Operations",
    "Mobile App Development",
    "Digital Marketing",
    "Human Resources (HR)",
    "Law/Legal",
    "Campus Ambassador",
    "Other",
  ];
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handlePartTime = (event) => {
    setPartTime(event.target.checked);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const changeRequirementsHandler = (e, editor) => {
    setVolunteerRequirements(editor.getData());
  };

  const changeResponsibilitiesHandler = (e, editor) => {
    setVolunteerResponsibilities(editor.getData());
  };

  const handlePrePlacement = (event) => {
    setPreplacement(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const handleChangeProfile = (event) => {
    setProfile(event.target.value);
  };

  const handleCreatePost = () => {
    const finalValues = {};
    const errors = {};
    if (activeStep === 0) {
      if (values.fundsRecipient.length === 0) {
        errors.fundsRecipient = "Please Select or Add an Organization";
      }
      if (values.profile.length === 0) {
        errors.selectProfile = "Please Select Profile";
      }
      if (!trainingMode) {
        errors.trainingMode = "Please Select volunteering Type";
      }
      if (description.length === 0) {
        errors.description = "Please enter Description";
      } else if (description && description.length <= 10) {
        errors.description = "Please enter atleast 10 characters";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please Select your Location";
      }

      if (startDate === null) {
        errors.startDate = "Please Select Volunteering Start Date";
      }

      if (dueDate === null) {
        errors.dueDate = "Please Select Application Last Date";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      } else {
        finalValues.request_type = "volunteering";
        finalValues.sourceOfCreation = "talscouts";
        if (values.profile === "Other") {
          finalValues.title = profile;
        } else {
          finalValues.title = values.profile;
        }
        finalValues.brief = "";
        finalValues.creatorType = "volunteering organization";
        finalValues.skills = skillSet;
        finalValues.units = values.cashUnit;
        if (values.fundsRecipient === "self") {
          finalValues.orgId = "Self";
        } else {
          finalValues.orgId = values.fundsRecipient;
        }
        if (donationRequest.start_date === startDate) {
          finalValues.start_date = startDate;
        } else {
          finalValues.start_date = startDate.getTime();
        }
        if (donationRequest.due_date === dueDate) {
          finalValues.due_date = dueDate;
        } else {
          finalValues.due_date = dueDate.getTime();
        }
        finalValues.user_id = authUser.unique_id;
        finalValues.fundsRecipient = values.fundsRecipient;
        if (authUser && orgList && orgList.length > 0) {
          orgList.forEach((org) => {
            if (org && org.orgId && org.orgId._id == values.fundsRecipient) {
              if (org.role === ROLE_MEMBER) {
                setIsOrgMember(true);
              }
            }
          });
        }
        // finalValues.whyYouNeedHelp = "justifyToOthers";
        finalValues.description = description;
        finalValues.region = region._id;
        finalValues.city = tempAddress.city;
        finalValues.state = tempAddress.state;
        finalValues.country = tempAddress.country;
        finalValues.quantity = "0";

        const additionalInfo = {
          trainingMode: trainingMode,
          onlineInterviewAvailable: interviewDeclaration,
          partTimeAllowed: partTime,
          numberOfOpenings: values.numberOfOpenings,
          duration: values.duration,
          responsibilities: volunteerResponsibilities,
          requirements: volunteerRequirements,
          monthlyStipend: values.monthlyStipend,
          monthlyStipendUnits: values.cashUnit,
          perks: selectedPerks,
          placementOffer: preplacement,
          educationalQualification: values.educationalQualification,
        };
        finalValues.additionalInfo = additionalInfo;

        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Volunteering request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setId(response.data._id);
                setActiveStep(1);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              setActiveStep(0);
            });
        }
      }
    }
    if (activeStep === 1) {
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (imageUpload) {
        toastr.success(
          "Your Volunteering Request is successfully" +
            (props.match.params.id ? " updated" : " created ")
        );
        props.history.push(`/donationRequest/${name}`);
      }
    }
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };
  const onCloseModal = () => {
    setOpenOrgModal(false);
  };
  const handleInterviewDeclaration = (event) => {
    if (event.target.checked) {
      setInterviewDeclaration(true);
    } else {
      setInterviewDeclaration(false);
    }
  };

  const handleNeighboringCities = (event) => {
    if (event.target.checked) {
      setNeighboringCities(true);
    } else {
      setNeighboringCities(false);
    }
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  const loadRequestData = () => {
    const newValues = {};
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          setDonationRequest(response);
          setId(response._id);
          setDefaultImageUrl(response.defaultImageUrl);
          setStartDate(response.start_date);
          setDueDate(response.due_date);
          setSkillSet(response.skills);
          setDescription(response.description);
          const profileCheck = profiles.map((profile) => {
            if (profile === response.title) {
              return true;
            }
            return false;
          });
          newValues.profile = profileCheck.includes(true)
            ? response.title
            : "Other";
          if (newValues.profile === "Other") setProfile(response.title);
          newValues.fundsRecipient =
            response.orgId && response.orgId._id
              ? response.orgId && response.orgId._id
              : "self";
          newValues.cashUnit = response.units;
          newValues.numberOfOpenings =
            response.additionalInfo && response.additionalInfo.numberOfOpenings;
          newValues.duration =
            response.additionalInfo && response.additionalInfo.duration;
          newValues.monthlyStipend =
            response.additionalInfo && response.additionalInfo.monthlyStipend;
          newValues.monthlyStipendUnits =
            response.additionalInfo &&
            response.additionalInfo.monthlyStipendUnits;
          newValues.educationalQualification =
            response.additionalInfo &&
            response.additionalInfo.educationalQualification;

          setImageUpload(true);
          setName(response.name);
          setValues((currentValues) => {
            return {
              ...currentValues,
              ...newValues,
            };
          });
          response.shipping_address &&
            setAddress({
              line1: response.shipping_address.line1,
              line2: response.shipping_address.line2,
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
          setTrainingMode(
            response.additionalInfo && response.additionalInfo.trainingMode
          );
          setInterviewDeclaration(
            response.additionalInfo &&
              response.additionalInfo.onlineInterviewAvailable
          );
          setVolunteerResponsibilities(
            response.additionalInfo && response.additionalInfo.responsibilities
          );
          setVolunteerRequirements(
            response.additionalInfo && response.additionalInfo.requirements
          );
          response.additionalInfo &&
            response.additionalInfo.perks.map((item) => {
              perks[item] = true;
            });
          const perksAsArray = getKeyByValue(perks, true);
          setPerks(perks);
          setSelectedPerks(perksAsArray);
          setPartTime(
            response.additionalInfo && response.additionalInfo.partTimeAllowed
          );
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };

  useEffect(() => {
    if (props.match.params.id) {
      setEditMode(true);
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id]);

  return (
    <div
      className="career-help-container"
      onClick={(event) => handleTooltipClose(event)}
    >
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/volunteering.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "20px",
                      borderRadius: "100%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Volunteering Opportunity
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the gap between those who are in need with those
                    who are able to donate their time and efforts
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className={classes.root}>
              <Stepper
                activeStep={activeStep}
                style={{ background: "transparent" }}
                alternativeLabel
              >
                {getSteps().map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div className="add-cover-pic">
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <FormControl
                          error={errors && errors.fundsRecipient}
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select/Add Organization
                          </InputLabel>
                          <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.fundsRecipient}
                            onChange={changeHandler}
                            placeholder="Organization"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors && errors.fundsRecipient
                                  ? styles.bg_red
                                  : values.fundsRecipient
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="fundsRecipient"
                            label=" Select/Add Organization"
                          >
                            <MenuItem value={"self"}>Self</MenuItem>
                            {orgList &&
                              orgList.length > 0 &&
                              orgList.map((org) => {
                                return (
                                  <MenuItem
                                    value={org && org.orgId && org.orgId._id}
                                  >
                                    <img
                                      src={
                                        org &&
                                        org.orgId &&
                                        org.orgId.defaultImageUrl
                                          ? org.orgId.defaultImageUrl
                                          : "/images/orgLogo.png"
                                      }
                                      alt="orgLogo"
                                      width="20px"
                                      style={{ marginRight: 8 }}
                                    />{" "}
                                    {org && org.orgId && org.orgId.orgName}{" "}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "100%",
                                        textAlign: "end",
                                      }}
                                    >
                                      {org &&
                                      org.orgId &&
                                      org.orgId.status === 1 ? (
                                        <CheckCircleOutlineIcon
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <ErrorOutlineIcon
                                          style={{ color: "red" }}
                                        />
                                      )}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                            <MenuItem value="add" onClick={handleOrganization}>
                              Add Organization
                            </MenuItem>
                          </Select>{" "}
                          <FormHelperText>
                            <div className={styles.custom_error}>
                              {errors.fundsRecipient}
                            </div>
                          </FormHelperText>
                        </FormControl>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.orgHelpIcon}
                          title="Add your organization details"
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="orgHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <FormControl
                          className={styles.blood_grp}
                          error={errors && errors.selectProfile}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Profile
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.profile}
                            onChange={changeHandler}
                            name="profile"
                            label="Select Profile"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.selectProfile
                                  ? styles.bg_red
                                  : values.profile
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={"select profile"}>
                              Select Profile
                            </MenuItem>
                            {profiles.map((profile) => {
                              return (
                                <MenuItem value={profile}>{profile}</MenuItem>
                              );
                            })}
                          </Select>
                          {values.profile === "Other" && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              style={{
                                paddingTop: "30px",
                                paddingLeft: "1px",
                                fontStyle: "bold",
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="profile"
                              placeholder="Enter Profile"
                              value={profile}
                              onChange={handleChangeProfile}
                            />
                          )}
                          <FormHelperText>
                            {errors.selectProfile}
                          </FormHelperText>
                        </FormControl>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.profileHelpIcon}
                          title=" Select the profile you want to hire for"
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="profileHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Description for Volunteering
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.descripitionHelpIcon}
                          title="Describe the details about the  Volunteering opportunity."
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="descripitionHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          // className="recipient"
                          error={errors && errors.description}
                        >
                          <Grid className="ck-editor-css">
                            <CKEditor
                              style={{ padding: "10px 20px" }}
                              data={description}
                              name="description"
                              editor={ClassicEditor}
                              onChange={changeDescriptionHandler}
                              config={{
                                toolbar: [
                                  "Heading",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "Link",
                                ],
                                placeholder:
                                  "Give description about this Volunteer opportunity",
                              }}
                            />
                          </Grid>
                          <FormHelperText>{errors.description}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.requestType}
                          className="recipient"
                        >
                          <div className=" tooltip-css">
                            <Typography
                              component="p"
                              style={{
                                fontSize: "15px",
                                color: "#263238",

                                textAlign: "start",
                                fontWeight: "500",
                              }}
                              gutterBottom
                            >
                              Volunteering Type
                            </Typography>

                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              open={toolTipStatus.typeofHelpIcon}
                              title="Please select the Volunteering type"
                              arrow
                              placement="right"
                              className="tooltip-text"
                            >
                              <HelpIcon
                                id="typeofHelpIcon"
                                onClick={(e) => handleTooltipOpen(e)}
                              />
                            </Tooltip>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} display="flex">
                        <FormControl
                          style={{ width: "100%", paddingLeft: "10px" }}
                          error={errors && errors.trainingMode}
                        >
                          <RadioGroup
                            column
                            value={trainingMode}
                            onChange={handleChangeTrainingMode}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="offline"
                              control={<Radio />}
                              label="Regular (In-office/On-field)"
                            />

                            <FormControlLabel
                              value="online"
                              control={<Radio />}
                              label="Remote/WFH (Recommended due to COVID-19 situation)"
                            />
                          </RadioGroup>
                          <FormHelperText>{errors.trainingMode}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          component="fieldset"
                          error={errors && errors.interviewDeclare}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <>
                                  <Checkbox
                                    checked={interviewDeclaration}
                                    onChange={handleInterviewDeclaration}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    style={{
                                      marginTop: "0px",
                                      paddingTop: "0px",
                                    }}
                                  />
                                  <span
                                    className="checkaglin"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    I am willing to talk to the potential
                                    volunteers over phone or through online
                                    meeting instead of a physical meeting before
                                    the volunteering work starts
                                  </span>
                                </>
                              }
                            />
                          </FormGroup>
                          <FormHelperText>
                            {errors.interviewDeclare}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className=" recipient"
                          error={errors && errors.address}
                        >
                          <p>Select your location</p>
                          <ShortAddress
                            value={address}
                            onChange={handleChangeAddress}
                            error={errors && errors.address}
                          />

                          <FormHelperText>{errors.address}</FormHelperText>
                        </FormControl>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.locationHelpIcon}
                          title="Select the location of your organization"
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="locationHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ padding: "10px" }}
                          component="fieldset"
                          error={errors && errors.partTime}
                        >
                          <FormGroup>
                            <FormControlLabel
                              className="labelpad"
                              label="Is part-time allowed?"
                              labelPlacement="start"
                              control={
                                <>
                                  <Switch
                                    checked={partTime}
                                    onChange={handlePartTime}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </>
                              }
                            />
                          </FormGroup>
                          <FormHelperText>{errors.partTime}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.numberOfOpenings}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            style={{
                              paddingLeft: "1px",
                              fontStyle: "bold",
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              width: "100%",
                            }}
                            name="numberOfOpenings"
                            label="Number of volunteers"
                            placeholder="Enter Number of volunteers"
                            value={values.numberOfOpenings}
                            onChange={changeHandler}
                          />
                          <FormHelperText>
                            {errors.numberOfOpenings}
                          </FormHelperText>
                        </FormControl>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.NumbersHelpIcon}
                          title=" Mention how many candidates you are going to recruit "
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="NumbersHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering start date
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.startdateHelpIcon}
                          title="When will this Volunteering  start?"
                          arrow
                          placement="right"
                          className="tooltip-text"
                          style={{ marginTop: "10px" }}
                        >
                          <HelpIcon
                            id="startdateHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.startDate}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={
                                styles.plasma_input +
                                " " +
                                styles.date_b +
                                " " +
                                `input-field ${
                                  errors.startDate
                                    ? styles.bg_red
                                    : startDate
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              showTodayButton
                              format="dd-MMM-yyyy"
                              inputVariant="outlined"
                              label="Start Date"
                              name="startDate"
                              value={startDate}
                              onChange={handleStartDateChange}
                              disablePast
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <FormHelperText>{errors.startDate}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering duration (Days)
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.durationHelpIcon}
                          title="What is the duration of the Volunteering opprotunity?"
                          arrow
                          placement="right"
                          className="tooltip-text"
                          style={{ marginTop: "10px" }}
                        >
                          <HelpIcon
                            id="durationHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "17px",
                            }}
                            error={errors && errors.duration}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              variant="outlined"
                              style={{
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="duration"
                              label="Duration"
                              placeholder="Enter duration"
                              value={values.duration}
                              onChange={changeHandler}
                            />
                            <FormHelperText>{errors.duration}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteer responsibilities
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.responsiveHelpIcon}
                          title="Mention the day-to-day responsibilities of a selected Volunteer."
                          arrow
                          placement="right"
                          className="tooltip-text"
                          style={{ marginTop: "10px" }}
                        >
                          <HelpIcon
                            id="responsiveHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                            paddingLeft: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.responsibilities}
                        >
                          <Grid className="ck-editor-css">
                            <CKEditor
                              style={{ padding: "10px 20px" }}
                              maxLength={300}
                              data={volunteerResponsibilities}
                              editor={ClassicEditor}
                              onChange={changeResponsibilitiesHandler}
                              config={{
                                toolbar: [
                                  "Heading",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "Link",
                                ],
                                placeholder:
                                  "Selected volunteer's day-to-day responsibilities include:",
                              }}
                            />
                          </Grid>

                          <FormHelperText>
                            {errors.responsibilities}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Application last date
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.lastDateHelpIcon}
                          title="When will this Volunteering end?"
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="lastDateHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.dueDate}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              showTodayButton
                              format="dd-MMM-yyyy"
                              margin="normal"
                              className={
                                styles.plasma_input +
                                " " +
                                styles.date_b +
                                " " +
                                `input-field ${
                                  errors.dueDate
                                    ? styles.bg_red
                                    : dueDate
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              inputVariant="outlined"
                              label="End Date"
                              name="endDate"
                              value={dueDate}
                              onChange={handleDueDateChange}
                              disablePast
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {errors && errors.dueDate && (
                            <FormHelperText>{errors.dueDate}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "98%" }}></hr>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Volunteering Requirements
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Who can apply
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.applyHelpIcon}
                          title=" Describe who can apply for this Volunteering opportunity."
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="applyHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                            paddingLeft: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.requirements}
                        >
                          <Grid className="ck-editor-css">
                            <CKEditor
                              style={{ padding: "10px 20px" }}
                              maxLength={300}
                              data={volunteerRequirements}
                              editor={ClassicEditor}
                              onChange={changeRequirementsHandler}
                              config={{
                                toolbar: [
                                  "Heading",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "Link",
                                ],
                                placeholder:
                                  "Please describe who can apply for this volunteering opportunity",
                              }}
                            />
                          </Grid>

                          <FormHelperText>{errors.requirements}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Skills
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.skillsHelpIcon}
                          title="Add as many skills as possible that are relevant to your volunteering opportunity"
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="skillsHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.skillset}
                        >
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={[]}
                            value={skillSet}
                            onChange={(e, newval) => {
                              setSkillSet(newval);
                            }}
                            defaultValue={[]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Skills"
                                placeholder="Enter skills"
                              />
                            )}
                          />
                          <FormHelperText>{errors.skillset}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "96%" }}></hr>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Volunteering Benefits
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Stipend
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.stipendHelpIcon}
                          title="Mention monthly stipend if any "
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="stipendHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid container spacing={2} style={{ marginLeft: "0px" }}>
                        <Grid
                          item
                          sm={2}
                          xs={12}
                          style={{
                            marginTop: "8px",

                            // paddingTop: "15px",
                            // paddingLeft: "10px",
                            // paddingBottom:"0px",
                          }}
                        >
                          <FormControl style={{ width: "100%" }}>
                            <Select
                              variant="outlined"
                              style={{
                                paddingTop: "2px",
                                paddingLeft: "5px",
                                paddingBottom: "0px",
                                height: "55px",
                              }}
                              name="cashUnit"
                              value={
                                editMode
                                  ? values.cashUnit
                                  : region && region.currency
                              }
                              className="currency"
                              onChange={currencyChangeHandler}
                            >
                              {regions &&
                                regions.length > 0 &&
                                regions.map((region) => {
                                  return (
                                    <MenuItem value={region.currency}>
                                      {region.currency === "INR" ? (
                                        <FontAwesomeIcon
                                          color="#059585"
                                          size="2x"
                                          icon={faRupeeSign}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="#059585"
                                          size="2x"
                                          icon={faDollarSign}
                                        />
                                      )}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl
                            style={{ width: "100%", paddingTop: "10px" }}
                            error={errors && errors.monthlyStipend}
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              style={{
                                fontWeight: "300",
                                fontSize: "20px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="monthlyStipend"
                              label="Monthly Stipend"
                              placeholder="Enter Monthly Stipend"
                              value={values.monthlyStipend}
                              onChange={changeHandler}
                            />
                            <FormHelperText>
                              {errors.monthlyStipend}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                          }}
                          gutterBottom
                        >
                          Perks
                        </Typography>
                        {/* <Typography
                    component="p"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                    }}
                    
                  >
                    {"Optional"}
                  </Typography> */}
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.perks}
                        >
                          <div>
                            <>
                              {PERKS.map((item) => {
                                return (
                                  <Button
                                    className={
                                      styles.user_btn +
                                      " " +
                                      ` ${
                                        selectedPerks.includes(item)
                                          ? styles.active_btn
                                          : styles.btn_render
                                      }`
                                    }
                                    key={item}
                                    value={selectedPerks}
                                    id={selectedPerks}
                                    onClick={() => handleChangePerks(item)}
                                    name="selectedPerks"
                                  >
                                    {item}

                                    {selectedPerks.includes(item) ? (
                                      <CheckIcon />
                                    ) : (
                                      <AddIcon />
                                    )}
                                  </Button>
                                );
                              })}
                            </>
                          </div>
                          <FormHelperText>{errors.perks}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "98%" }}></hr>
                      <Grid item xs={12} className="tooltip-css">
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Education
                        </Typography>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.educationHelpIcon}
                          title="Basic education qualifications required for this volunteering opportunity "
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="educationHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.educationalQualification}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            style={{
                              fontWeight: "400",
                              fontSize: "20px",
                              color: "#000000",
                              width: "100%",
                            }}
                            name="educationalQualification"
                            label="Enter Education Qualification"
                            placeholder="Enter Education Qualification"
                            value={values.educationalQualification}
                            onChange={changeHandler}
                            inputProps={{
                              maxLength: 20,
                            }}
                          />
                          <span
                            className="title-length"
                            style={{
                              top: 20,
                              padding: "0 15px",
                              background: "none",
                            }}
                          >
                            {" "}
                            {values.educationalQualification
                              ? values.educationalQualification.length
                              : 0}
                            /20
                          </span>
                          <FormHelperText>
                            {errors.educationalQualification}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <div>
                        <Button
                          style={{
                            background: "rgb(243, 90, 87)",
                            margin: "30px",
                            float: "right",
                            color: "white",
                            marginRight: "10px",
                            width: "120px",
                          }}
                          className="submit-button"
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={handleCreatePost}
                        >
                          {activeStep === 0
                            ? editMode
                              ? "Update"
                              : "Create"
                            : "Save"}
                        </Button>
                      </div>
                    </>
                  )}
                  {activeStep === 1 && (
                    <div className="request-step-two">
                      <div className="request-two-step">
                        <div className="tooltip-css">
                          <h3 className="step-two-title">Add a cover photo</h3>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.AddcoverphotoHelpIcon}
                            title="Upload relevant images to showcase on the request"
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="AddcoverphotoHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <p className="image-desc">
                          This is the image that will feature right on top of
                          your page. Make it impactful!
                        </p>
                        <div className="fund-img-txt">
                          <p> A picture is worth a thousand words</p>
                          <ul>
                            <li>
                              <span>Keep it relevant</span>
                            </li>
                            <li>
                              <span>Keep it clear</span>
                            </li>
                            <li>
                              <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                            </li>
                            <li>
                              <span>Size: 700px X 400px</span>
                            </li>
                          </ul>
                        </div>
                        <div className="img-def-upload">
                          <MuiThemeProvider
                            theme={theme2}
                            className="req-tooltip-img"
                          >
                            <img
                              src={
                                defaultImageUrl
                                  ? defaultImageUrl
                                  : "/images/default-req-img.gif"
                              }
                              alt="default"
                              className="fund-cover-img"
                            />
                          </MuiThemeProvider>

                          {errors && errors.image && (
                            <div className="custom-error">{errors.image}</div>
                          )}
                          <div className="add-btn-fund">
                            <Button
                              style={{
                                font: "500 12px Roboto",
                                padding: "20px 0px",
                                margin: "10px 5px",
                                background: "transparent",
                                color: "#283E4A",
                                border: "1px solid #283E4A",
                              }}
                              onClick={() => setShowingAddPhotoModal(true)}
                            >
                              <FontAwesomeIcon
                                color="#283E4A"
                                size="1x"
                                style={{ marginRight: 6 }}
                                icon={faFileUpload}
                              />{" "}
                              Add Images
                            </Button>
                            <Button
                              style={{
                                padding: "17px",
                                background: "rgb(243, 90, 87)",
                                margin: "30px",
                                float: "right",
                                color: "white",
                                marginRight: "10px",
                                width: "120px",
                              }}
                              className="submit-button"
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleCreatePost}
                            >
                              {activeStep === 0 ? "Create" : "Save"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={onCloseModal}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(Volunteering);
