import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import toastr from "toastr";
import firebase from "../../firebase/firebase";
import "./Organization.scss";
import CloseIcon from "@material-ui/icons/Close";
import { TextareaAutosize } from "@material-ui/core";
import { updateDonationRequest, getDonationRequest } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const RejectionModal = (props) => {
  const classes = useStyles();
  const { authUser } = useContext(appContext);

  const [rejectionNotes, setRejectionNotes] = useState("");

  const { donationRequest } = props;

  const handleRejectingNotes = (event) => {
    setRejectionNotes(event.target.value);
  };

  const submitRejectionMessage = () => {
    if (!rejectionNotes) {
      toastr.error("Please write a short on this request");
    } else {
      const finalValues = {};
      // const values = {};
      // const ref = firebase.realtimeDb.ref(
      //   "/privateConversations/" + donationRequest && donationRequest._id
      // );
      // values.text = rejectionNotes;
      // values.userId = authUser && authUser.unique_id;
      // values.senderName =
      //   authUser &&
      //   authUser.name &&
      //   authUser.name.first_name + " " + authUser.name.last_name;
      // values.senderProfileImageUrl = authUser && authUser.profile_image_url;
      // values.whenSent = new Date().getTime();
      // ref.push(values);

      finalValues.status = -7;
      finalValues.user_id = authUser && authUser.unique_id;
      finalValues.rejectionReason = rejectionNotes;
      updateDonationRequest(finalValues, donationRequest && donationRequest._id)
        .then(() => {
          toastr.success(
            "Donation request rejection notes submitted  successfully."
          );
          props.func();
        })
        .catch((error) => {
          toastr.error(
            "Donation request rejection notes are not submitted  ." +
              error.message
          );
        });
      props.onCloseModal();
    }
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <p className={classes.orgTitle}>Write a short Note</p>
              <hr></hr>
              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onCloseModal} />
              </span>
            </div>
            <div style={{ width: "90%", margin: "0 auto", padding: "0 1px" }}>
              <TextareaAutosize
                style={{ width: "100%" }}
                variant="outlined"
                placeholder="Write a short Note"
                onChange={handleRejectingNotes}
                rows={8}
              ></TextareaAutosize>
            </div>
            <div className="plasma">
              <Button
                className={classes.saveBtn}
                onClick={submitRejectionMessage}
              >
                Reject
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(RejectionModal);
