import React, { useEffect, useContext, useState } from "react";
import Loader from "../common/Loader";
import jwt_decode from "jwt-decode";
import { Grid } from "@material-ui/core";
import { appContext } from "../../App";
import toastr from "toastr";
import { updateTeamMember } from "../../utils/api";
import Container from "@material-ui/core/Container";

const InviteTeamMember = (props) => {
  const { setTeamUserPayload, setTeamUnRegisteredUser, authUser } =
    useContext(appContext);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  const validateUser = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    const decoded = jwt_decode(token);
    setTeamUserPayload(decoded);
    if (decoded && decoded.user) {
      const { teamId, user, email, teamMemberId } = decoded;
      updateTeamMember(teamMemberId, {
        status: "accepted",
        userId: decoded.user,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(
              "User verified successfully and assigned to team - " + teamId
            );
            if (authUser !== null && authUser.unique_id === decoded.user) {
              props.history.push("/");
            } else {
              props.history.push("/login");
            }
          }
        })
        .catch((error) => {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          if (authUser !== null && authUser.unique_id === decoded.user) {
            props.history.push("/");
          } else {
            props.history.push("/login");
          }
        });
    } else {
      toastr.warning(
        "Please complete the signup process to become the member of the team."
      );
      props.history.push("/signup");
      setTeamUnRegisteredUser(true);
    }
  };

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    isComponentLoaded && validateUser();
  }, [isComponentLoaded]);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default InviteTeamMember;
