import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  Link,
  Typography,
  Button,
  TablePagination,
} from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";

import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import { HOME_PAGE, DATE_TIME_FORMAT } from "../../utils/utils";
import { getAllDonationRequests, deleteDonationRequest } from "../../utils/api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import DonationRequestForm from "../donationRequest/DonationRequestForm";
import { getDonationRequests } from "../../utils/api";

import "./Admin.scss";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },

  table: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      // "& Button": {
      //   padding: "12px 12px 12px 0",
      // },
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const AdminDonationRequests = (props) => {
  const classes = useStyles();
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } =
    useContext(appContext);
  const [donationRequests, setDonationRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDonationRequestModal, setIsOpenDonationRequestModal] =
    useState(false);
  const [donationRequestId, setDonationRequestId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === 0) {
      return "New";
    } else if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Completed";
    } else if (status === -2) {
      return "Incomplete";
    } else if (status === -3) {
      return "Spam";
    } else if (status === -4) {
      return "Expired";
    }
  };

  const loadDonationRequests = (page) => {
    setIsLoading(true);
    getAllDonationRequests(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);

          const donationRequestArray = [];
          donationRequests.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            donationRequestArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            responseArray.push(obj);
            return null;
          });
          setDonationRequests([...donationRequestArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  const loadDonationRequestsBasedOnKeyword = (
    keyword,
    page,
    donationRequests
  ) => {
    getDonationRequests(
      "",
      rowsPerPage,
      page * rowsPerPage,
      authUser && authUser.unique_id,
      "",
      keyword,
      ""
    )
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);

          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            responseArray.push(obj);
            return null;
          });
          setDonationRequests([...donationRequests, ...responseArray]);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  useEffect(() => {
    if (keyword) {
      setIsSearchMode(true);
      setPage(0);
      setDonationRequests([]);
      loadDonationRequestsBasedOnKeyword(keyword, 0, []);
    } else {
      setIsSearchMode(false);
      setDonationRequests([]);
      loadDonationRequests(page);
      setPage(0);
    }
  }, [keyword, rowsPerPage]);

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const handleChangePage = (event, newPage) => {
    if (isSearchMode) {
      loadDonationRequestsBasedOnKeyword(keyword, newPage, donationRequests);
    } else {
      loadDonationRequests(newPage);
    }
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editDonationRequest = (donationRequestId) => {
    setDonationRequestId(donationRequestId);
    setIsOpenDonationRequestModal(true);
  };

  const onDeleteDonationRequest = (donationRequestId) => {
    if (
      window.confirm("Are you sure you want to delete the donation request?")
    ) {
      deleteDonationRequest(donationRequestId, authUser.unique_id)
        .then(() => {
          // Update the list of donation requests
          setDonationRequests((donationRequests) => {
            return donationRequests.filter(
              (donationRequest) => donationRequest._id !== donationRequestId
            );
          });
          toastr.success("DonationRequest is deleted successfully.");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const updateSavedDonationRequest = (updatedDonationRequest) => {
    setDonationRequests((donationRequests) => {
      return donationRequests.map((donationRequest) => {
        if (donationRequest._id === updatedDonationRequest._id) {
          return {
            ...donationRequest,
            status: updatedDonationRequest.status,
            title: updatedDonationRequest.title,
            rank: updatedDonationRequest.rank,
          };
        }
        return donationRequest;
      });
    });
    setIsOpenDonationRequestModal(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
      }
    }
  };

  const clearSearchResultsHandler = () => {
    setDonationRequests([]);
    setIsSearchMode(false);
    setKeyword("");
    //setOffset(0);
    //setHasNextPage(true);
    setPage(0);
  };
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="search-right">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4 className="image-title">Manage Donation Requests</h4>
            </div>
            <div>
              <div className="searchbar-dr">
                <SearchRounded style={{ margin: "0 4px", height: "30px" }} />
                <InputBase
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onKeyPress={(e) => handleEnterClick(e)}
                />
              </div>

              {isSearchMode && (
                <p className="result" style={{ marginLeft: "20px" }}>
                  Search Results for
                  <span
                    style={{
                      // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                      // borderRadius: "5px",
                      padding: "3px 10px 5px 10px",
                      marginLeft: "10px",
                      fontWeight: " bold",
                      color: "#686868",
                      background: "#f8f8f8",
                    }}
                  >
                    {keyword}
                  </span>
                  <IconButton
                    edge="start"
                    aria-label="close"
                    style={{
                      padding: "3px",
                      color: "rgb(205, 38, 39)",
                      position: "relative",
                      top: "-2px",
                      margin: "0 0 0 10px",
                      border: "1px solid #ededed",
                    }}
                  >
                    <Tooltip title="Clear Search Results">
                      <CloseIcon onClick={clearSearchResultsHandler} />
                    </Tooltip>
                  </IconButton>
                </p>
              )}
            </div>
          </div>
        </Container>
      </div>

      <Container MaxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div className="table-fixed drop-box">
                <TableContainer className="container">
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="sticky table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow style={{ background: "#000", color: "#fff" }}>
                        <TableCell align={"left"} className="table-header-text">
                          Created Date
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Updated Date
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Title
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Region
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Request Type
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Donee
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Status
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Rank
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                    //ref={infiniteRef}
                    >
                      {donationRequests
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              // onClick={event => handleClick(event, row.name)}
                              // role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell align="left">
                                {moment(row.createdAt).format(DATE_TIME_FORMAT)}
                              </TableCell>
                              <TableCell align="left">
                                {moment(row.updatedAt).format(DATE_TIME_FORMAT)}
                              </TableCell>
                              <TableCell
                                align="left"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                <Link
                                  href={`/donationRequest/${row._id}`}
                                  target="blank"
                                >
                                  {row.title}
                                </Link>
                              </TableCell>
                              <TableCell align="left">{row.region}</TableCell>
                              <TableCell align="left">
                                {row.request_type}
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  href={`/newUserProfile/${row.user_info._id}`}
                                  target="blank"
                                >
                                  {row.donee}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                {getStatusLabel(row.status)}
                              </TableCell>
                              <TableCell align="left">{row.rank}</TableCell>
                              <TableCell align="center">
                                <Tooltip title="Edit">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() => editDonationRequest(row._id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>{" "}
                                <Tooltip title="Delete">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() =>
                                      onDeleteDonationRequest(row._id)
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
                {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
                {donationRequests && donationRequests.length === 0 && (
                  <Typography className="no-req-text">
                    No Donation Requests Found
                  </Typography>
                )}
              </div>

              <DonationRequestForm
                donationRequestId={donationRequestId}
                open={isOpenDonationRequestModal}
                close={() => setIsOpenDonationRequestModal(false)}
                onSave={updateSavedDonationRequest}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AdminDonationRequests;
