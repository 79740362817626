import React, { useState, useEffect, Fragment, useContext } from "react";
import toastr from "toastr";
import cuid from "cuid";
import getBlobDuration from "get-blob-duration";
import DropzoneInput from "../common/DropzoneInput";
import firebase from "../../firebase/firebase";
import useFormValidation from "../../hooks/useFormValidation";

import { validatePlaylistItemForm } from "../../utils/api";

import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextareaAutosize,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  TableContainer,
  Box,
  TablePagination,
  InputBase,
} from "@material-ui/core";
import validateAddPlayListItem from "./validateAddPlayListItem";
import VideoPlayer from "../common/VideoPlayer";
import Loader from "../common/Loader";
import PlaylistItemRow from "./PlaylistItemRow";
import ChannelSelectionBox from "./ChannelSelectBox";
import { appContext } from "../../App";
import FirestoreService from "../common/Firestore/FirestoreService";
import { SearchRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  liveplaylists: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "18px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
    },
  },
}));

const AddPlayListItem = (props) => {
  const { channel, playlistItems, setPlaylistItems } = useContext(appContext);

  const initialFormState = {
    name: "",
    channel: channel,
    note: "",
    isrc: "",
    iswc: "",
    artistName: "",
    writers: "",
    albumTitle: "",
  };
  const path = "talradio/audio";
  const collection = "/playlistitems";
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [document, setDocument] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPage(0);
    setSelectedDocuments(
      playlistItems && playlistItems.filter((doc) => doc.channel === channel)
    );
  }, [channel]);

  const saveOrUploadDocument = () => {
    setIsWaitingForResponse(true);
    const titleArtist = (values.name + values.artistName).toLowerCase();
    const match = playlistItems.filter(
      (item) =>
        item.title &&
        item.artistName &&
        titleArtist === (item.title + item.artistName).toLowerCase()
    );
    if (match.length === 0 || (match.length === 1 && editMode)) {
      if (!editMode) {
        uploadFile();
      } else {
        FirestoreService.update(collection, document._id, {
          channel: channel,
          description: values.note,
          title: values.name,
          isrc: values.isrc,
          iswc: values.iswc,
          albumTitle: values.albumTitle,
          artistName: values.artistName,
          writers: values.writers,
          updatedAt: new Date().getTime(),
        })
          .then(() => {
            const updatedDocuments = playlistItems.map((doc) => {
              if (doc._id === document._id) {
                return {
                  channel: channel,
                  description: values.note,
                  title: values.name,
                  isrc: values.isrc,
                  iswc: values.iswc,
                  albumTitle: values.albumTitle,
                  artistName: values.artistName,
                  writers: values.writers,
                };
              } else {
                return doc;
              }
            });
            setPlaylistItems(updatedDocuments);
            setIsWaitingForResponse(false);
            setValues(initialFormState);
            setEditMode(false);
            toastr.success("Playlist Item is updated successfully");
          })
          .catch((error) => {
            setIsWaitingForResponse(false);
            toastr.error(error);
          });
      }
    } else {
      setIsWaitingForResponse(false);
      toastr.error(
        "A playlist item with the same Title and Artist Name already exists. Please change either the Title or the Artist name."
      );
    }
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(
      initialFormState,
      validateAddPlayListItem,
      saveOrUploadDocument
    );

  useEffect(() => {
    if (!!files) {
      if (files.length === 1) {
        setValues({
          ...values,
          name: "",
          artistName: "",
          writers: "",
          albumTitle: "",
        });
        window.jsmediatags.read(files[0], {
          onSuccess: function (tag) {
            setValues({
              ...values,
              name: tag.tags.title,
              artistName: tag.tags.artist,
              albumTitle: tag.tags.album,
            });
          },
          onError: function (error) {
            console.log(error);
          },
        });
      }
      return URL.revokeObjectURL(files.preview);
    }
  }, [files]);

  useEffect(() => {
    setSelectedDocuments(
      playlistItems && playlistItems.filter((doc) => doc.channel === channel)
    );
  }, [playlistItems]);

  function uploadThumbnailImage(name) {
    return new Promise((resolve, reject) => {
      const fullPath = path + "/" + channel + "/thumbnails/" + name;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(fullPath);
      pathRef
        .put(thumbnailFile)
        .then(() => {
          storageRef
            .child(fullPath)
            .getDownloadURL()
            .then((thumbnailUrl) => {
              resolve(thumbnailUrl);
            })
            .catch(function (error) {
              reject("Problem uploading the thumbnail image: " + error.message);
            });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async function uploadFile() {
    if (files.length === 0) {
      setIsWaitingForResponse(false);
      setFileError("Please select an audio file to upload");
      return;
    } else if (files.length > 1) {
      setIsWaitingForResponse(false);
      setFiles([]);
      setFileError("Please select only one audio file.");
      return;
    } else {
      setFileError("");
    }
    setIsLoading(true);
    if (files.length === 1) {
      saveFileToCloud(files[0]);
    }
  }

  const saveFileToCloud = async (theFile) => {
    let name;
    let currentCuid = cuid();

    if (theFile && theFile.name) {
      name =
        currentCuid + theFile.name.substring(theFile.name.lastIndexOf("."));
    } else {
      name = currentCuid + ".mp3";
    }

    const fullPath = path + "/" + channel + "/" + name;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .put(theFile)
      .then(() => {
        storageRef
          .child(fullPath)
          .getDownloadURL()
          .then(async (url) => {
            URL.revokeObjectURL(theFile.preview);

            let duration = 0;
            try {
              //get uploaded file duration
              const fileDuration = await getBlobDuration(new Blob([theFile]));
              duration = fileDuration && parseInt(fileDuration);
            } catch (e) {
              console.log(e.message);
            }

            setFiles([]);

            let metadata = {
              customMetadata: {
                userGivenName: values.name,
                channel: channel,
                note: values.note,
                isrc: values.isrc,
                iswc: values.iswc,
                artistName: values.artistName,
                writers: values.writers,
                albumTitle: values.albumTitle,
                duration,
              },
            };

            pathRef
              .updateMetadata(metadata)
              .then(async (metadata) => {
                setPlaylistItems((prevDocuments) => [
                  {
                    _id: currentCuid,
                    downloadUrl: url,
                    name: metadata.name,
                    title:
                      metadata.customMetadata &&
                      metadata.customMetadata.userGivenName,
                    channel:
                      metadata.customMetadata &&
                      metadata.customMetadata.channel,
                    description:
                      metadata.customMetadata && metadata.customMetadata.note,
                    isrc:
                      metadata.customMetadata && metadata.customMetadata.isrc,
                    iswc:
                      metadata.customMetadata && metadata.customMetadata.iswc,
                    artistName:
                      metadata.customMetadata &&
                      metadata.customMetadata.artistName,
                    writers:
                      metadata.customMetadata &&
                      metadata.customMetadata.writers,
                    albumTitle:
                      metadata.customMetadata &&
                      metadata.customMetadata.albumTitle,
                    duration,
                  },
                  ...prevDocuments,
                ]);
                const currentTimeMillis = new Date().getTime();
                const inputData = {
                  title: values.name,
                  channel: channel,
                  description: values.note,
                  downloadUrl: url,
                  name,
                  duration,
                  isrc: values.isrc,
                  iswc: values.iswc,
                  albumTitle: values.albumTitle,
                  artistName: values.artistName,
                  writers: values.writers,
                  createdAt: currentTimeMillis,
                  updatedAt: currentTimeMillis,
                };
                if (thumbnailFile && thumbnailFile.name) {
                  uploadThumbnailImage(name)
                    .then((response) => {
                      let thumbnailUrl = response;
                      URL.revokeObjectURL(thumbnailFile.preview);
                      const saveObject = {
                        thumbnailUrl: thumbnailUrl,
                        ...inputData,
                      };

                      FirestoreService.create(collection, saveObject)
                        .then(() => {
                          toastr.success("The file is uploaded succesfully");
                          if (thumbnailFile) {
                            URL.revokeObjectURL(thumbnailFile.preview);
                          }
                          setThumbnailFile("");
                          setValues(initialFormState);
                          setIsLoading(false);
                          setIsWaitingForResponse(false);
                        })
                        .catch((error) => {
                          setIsWaitingForResponse(false);
                          setIsLoading(false);
                          toastr.error(error);
                        });
                    })
                    .catch((error) => {
                      setIsWaitingForResponse(false);
                      toastr.error(error);
                    });
                } else {
                  FirestoreService.create(collection, inputData)
                    .then(() => {
                      toastr.success("The file is uploaded succesfully");
                      if (thumbnailFile) {
                        URL.revokeObjectURL(thumbnailFile.preview);
                      }
                      setThumbnailFile("");
                      setValues(initialFormState);
                      setIsLoading(false);
                      setIsWaitingForResponse(false);
                    })
                    .catch((error) => {
                      setIsWaitingForResponse(false);
                      setIsLoading(false);
                      toastr.error(error);
                    });
                }
              })
              .catch(function (error) {
                toastr.error(
                  "Problem updating the file properties:" + error.message
                );
                setIsLoading(false);
              });
          });
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const deletePlayListItemFromDb = (document) => {
    FirestoreService.remove(collection, document._id)
      .then(() => {
        toastr.success("Playlist item deleted successfully");
        setPlaylistItems(
          playlistItems.filter((doc) => doc._id !== document._id)
        );
        setIsLoading(false);
        setEditMode(false);
      })
      .catch((error) => {
        toastr.error(error);
        setIsLoading(false);
      });
  };

  const deleteDocument = (document) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      setIsLoading(true);
      const storageRef = firebase.storage;
      const ref = storageRef.refFromURL(document.downloadUrl);
      storageRef
        .ref(ref.fullPath)
        .delete()
        .then(() => {
          if (document.thumbnailUrl) {
            deleteThumbnail(document.thumbnailUrl);
          }
          deletePlayListItemFromDb(document);
        })
        .catch((error) => {
          deletePlayListItemFromDb(document);
          toastr.error("Error deleting the file from firebase.");
        });
    }
  };

  const deleteThumbnail = (thumbnailUrl) => {
    const storageRef = firebase.storage;
    const ref = storageRef.refFromURL(thumbnailUrl);
    storageRef
      .ref(ref.fullPath)
      .delete()
      .then(() => {})
      .catch((error) => {
        toastr.error("Error deleting the thumbnail:" + error.message);
      });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const editingDocument = (document) => {
    setDocument(document);
    setEditMode(true);
    setValues({
      name: document.title,
      channel: document.channel,
      note: document.description,
      isrc: document.isrc,
      iswc: document.iswc,
      albumTitle: document.albumTitle,
      artistName: document.artistName,
      writers: document.writers,
    });
    scrollToTop();
    setDocument(document);
  };

  const onPlayVideo = (document) => {
    document.url = document.downloadUrl;
    setDocument(document);
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  const addFile = (file) => {
    setFiles([file]);
  };

  const cancelEditHandler = () => {
    setEditMode(false);
    setValues(initialFormState);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPage2(0);
  };

  const updateSearchKeyword = (event) => {
    setKeyword(event.target.value);
  };

  useEffect(() => {
    if (playlistItems && playlistItems.length > 0) {
      if (keyword) {
        setPage(0);
        setSelectedDocuments(
          playlistItems &&
            playlistItems.filter(
              (document) =>
                document.title &&
                document.artistName &&
                document.title.toLowerCase().includes(keyword.toLowerCase())
            )
        );
      } else {
        setPage(0);
        setSelectedDocuments(
          playlistItems &&
            playlistItems.filter((doc) => doc.channel === channel)
        );
      }
    }
  }, [keyword, playlistItems]);

  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <Box className="manage_podcast_media">
        <Box className="image-title drop-box" component="h4">
          Manage Playlist Items
        </Box>

        <div className="menu-container">
          <Box className="add-image-container" mt={2}>
            <Fragment>
              <div className="top-box">
                <Grid container spacing={2}>
                  {!editMode && (
                    <Grid item xs={12} md={4} sm={12}>
                      <div className="main-1 drop-box">
                        <Typography variant="h4">Select Audio File</Typography>

                        <DropzoneInput
                          setFiles={setFiles}
                          accept="audio/*"
                          multiple={false}
                        />
                        {fileError && (
                          <Typography style={{ color: "red" }}>
                            {fileError}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  )}
                  {!editMode && (
                    <Grid item xs={12} md={4} sm={12}>
                      <div className="main-2 drop-box">
                        <div className="select">
                          <Typography variant="h4">
                            Select Thumbnail Image File
                          </Typography>
                          <h5>
                            (Ensure width and height are equal. For eg. 300 x
                            300)
                          </h5>
                          <DropzoneInput
                            setFiles={setThumbnailFile}
                            accept="image/*"
                            multiple={false}
                          />
                        </div>
                      </div>
                    </Grid>
                  )}

                  <Grid item xs={12} md={4} sm={12}>
                    <div className="main-3 drop-box">
                      <div className="cropper child-container">
                        {files && files.length > 0 && (
                          <Fragment>
                            <Typography>Selected Files:</Typography>
                            {files.map((eachFile, index) => {
                              return (
                                <Typography key={index}>
                                  {eachFile.name}
                                </Typography>
                              );
                            })}
                          </Fragment>
                        )}
                        {thumbnailFile && thumbnailFile[0] && (
                          <Fragment>
                            <Typography>Thumbnail Image File:</Typography>
                            <Typography>{thumbnailFile[0].name}</Typography>
                          </Fragment>
                        )}
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder="Give a title"
                            name="name"
                            value={values.name}
                            onChange={changeHandler}
                          />
                          {errors.name && (
                            <Typography style={{ color: "red" }}>
                              {errors.name}
                            </Typography>
                          )}
                        </div>
                        {editMode ? (
                          <FormControl style={{ width: "100%" }} disabled>
                            <InputLabel
                              style={{ paddingLeft: "10px", marginTop: "-3px" }}
                            >
                              Select Channel
                            </InputLabel>
                            <ChannelSelectionBox />
                            {errors && errors.channel && (
                              <div className="custom-error">
                                {errors.channel}
                              </div>
                            )}
                          </FormControl>
                        ) : (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel
                              style={{ paddingLeft: "10px", marginTop: "-3px" }}
                            >
                              Select Channel
                            </InputLabel>
                            <ChannelSelectionBox />
                            {errors && errors.channel && (
                              <div className="custom-error">
                                {errors.channel}
                              </div>
                            )}
                          </FormControl>
                        )}
                        <div>
                          <FormControl style={{ width: "100%" }}>
                            <TextareaAutosize
                              name="note"
                              multiline
                              rows="4"
                              className="input-field"
                              placeholder="Enter a description"
                              value={values.note}
                              onChange={changeHandler}
                            />
                            {errors.note && (
                              <Typography>{errors.note}</Typography>
                            )}
                          </FormControl>
                        </div>
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder=" ISRC"
                            name="isrc"
                            value={values.isrc}
                            onChange={changeHandler}
                          />
                          {errors.isrc && (
                            <Typography style={{ color: "red" }}>
                              {errors.isrc}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder=" ISWC"
                            name="iswc"
                            value={values.iswc}
                            onChange={changeHandler}
                          />
                          {errors.iswc && (
                            <Typography style={{ color: "red" }}>
                              {errors.iswc}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder="Album Title"
                            name="albumTitle"
                            value={values.albumTitle}
                            onChange={changeHandler}
                          />
                          {errors.albumTitle && (
                            <Typography style={{ color: "red" }}>
                              {errors.albumTitle}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder="Artist Name"
                            name="artistName"
                            value={values.artistName}
                            onChange={changeHandler}
                          />
                          {errors.artistName && (
                            <Typography style={{ color: "red" }}>
                              {errors.artistName}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <TextField
                            required
                            variant="filled"
                            className="input-field"
                            placeholder="Writer Names"
                            name="writers"
                            value={values.writers}
                            onChange={changeHandler}
                          />
                          {errors.writers && (
                            <Typography style={{ color: "red" }}>
                              {errors.writers}
                            </Typography>
                          )}
                        </div>
                        {!editMode && (
                          <Button
                            className="upload-btn"
                            onClick={submitHandler}
                            disabled={isLoading}
                          >
                            upload
                          </Button>
                        )}
                        {editMode && (
                          <div style={{ display: "flex", gap: "5px" }}>
                            <Button
                              fullWidth
                              onClick={cancelEditHandler}
                              variant="outlined"
                            >
                              Cancel Edit
                            </Button>
                            <Button
                              fullWidth
                              className="upload-btn"
                              onClick={submitHandler}
                              disabled={isLoading}
                            >
                              Save
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  className="buttonProgress"
                                />
                              )}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Fragment>

            <Box
              className="searchbar-dr boder4"
              whiteSpace="noWrap"
              style={{ marginLeft: "10px" }}
            >
              <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
              <InputBase
                placeholder="Enter search keyword"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={updateSearchKeyword}
              />
            </Box>

            <FormControl
              className="drop-box"
              style={{ width: "100%", margin: "10px 0", padding: "5px" }}
            >
              <InputLabel style={{ paddingLeft: "25px" }}>
                Select Channel
              </InputLabel>
              <ChannelSelectionBox />
            </FormControl>
            <div className="table-responsive drop-box">
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="podcast-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Audio Name</TableCell>
                      <TableCell>Album Name</TableCell>
                      <TableCell>Artist/s</TableCell>
                      <TableCell>ISRC Code</TableCell>
                      <TableCell>Edit/Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!props.isPlaylistItemsLoading &&
                      selectedDocuments &&
                      selectedDocuments.length > 0 &&
                      selectedDocuments
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((document, index) => {
                          return (
                            <PlaylistItemRow
                              key={index}
                              document={{
                                title: document.title,
                                album: document.albumTitle,
                                artist: document.artistName,
                                writer: document.writers,
                                isrc: document.isrc,
                                iswc: document.iswc,
                              }}
                              onPlayHandler={() => onPlayVideo(document)}
                              onDeleteHandler={() => deleteDocument(document)}
                              onEditHandler={() => editingDocument(document)}
                              canEdit={true}
                            />
                          );
                        })}
                    {props.isPlaylistItemsLoading && (
                      <TableRow align="center">
                        <TableCell colSpan="10">
                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              padding: "10px 0",
                            }}
                          >
                            Loading...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {selectedDocuments &&
                      selectedDocuments.length === 0 &&
                      !props.isPlaylistItemsLoading && (
                        <TableRow align="center">
                          <TableCell colSpan="10">
                            <Typography
                              style={{
                                textAlign: "center",
                              }}
                            >
                              No records found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={selectedDocuments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <VideoPlayer
                isOpen={isPlayingVideo}
                onClose={onClosePlayVideo}
                name={document && document.userGivenName}
                url={document && document.url}
                note={document && document.note}
              />
            </div>
          </Box>
        </div>
        <Loader
          isOpen={isWaitingForResponse}
          onClose={() => setIsWaitingForResponse(false)}
        />
      </Box>
    </div>
  );
};
export default AddPlayListItem;
