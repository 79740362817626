import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import toastr from "toastr";
import moment from "moment";
import jwt_decode from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import SideMenu from "./SideMenu";
import Headers from "./Header";
import Box from "@material-ui/core/Box";
import "./Transformers.scss";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getQueryStringValue } from "../../utils/utils";
import Avatar from "@material-ui/core/Avatar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { appContext } from "../../App";
import { updateProfile, getUserData } from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateParticipantProfile from "./ValidateParticipantProfile";
import NewAddress from "../common/NewAddress";
import AddPhotos from "../common/AddPhotos";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { DATE_FORMAT, HOME_PAGE, numberWithCommas } from "../../utils/utils";
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import validator from "validator";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "80%",
    },
  },
}));

const ParticipantProfile = (props) => {
  const { authUser, setAuthUser, userLocaleInfo } = useContext(appContext);
  const classes = useStyles();

  const [profileImageUrl, setProfileImageUrl] = useState(
    "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/Ce2a424ef4ceda0e290183969f80b8bf3/projects/M5a82fbbd9de4261e887571c42aefeae91571702605323/images/M2f21aa2c1aae080d85ba324736f6e4d91586935513274"
  );
  const initialState = {
    firstName: "",
    lastName: "",
    institutionName: "",
    institutionUrl: "",
    educationalQualification: "",
    educationalQualificationOthers: "",
    otherEdu: "",
    mentorInfo: "",
    gender: "",
    studentId: "",
    institutionalRole: "",
  };
  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };
  const [phone, setPhone] = useState(null);
  const [gender, setGender] = useState("");
  const [educationalQualification, setEducationalQualification] = useState("");
  const [educationalQualificationOthers, setEducationalQualificationOthers] =
    useState("");
  const [dob, setDob] = useState(null);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [others, setOthers] = useState("");
  const [isAssignMentor, setIsAssignMentor] = useState(false);

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleChangeEducationalQualification = (event) => {
    setEducationalQualification(event.target.value);
  };
  const handleMentorAllocation = (event) => {
    if (event.target.checked) {
      setIsAssignMentor(true);
    } else {
      setIsAssignMentor(false);
    }
  };
  const handleChangeEducationalQualificationOthers = (event) => {
    setEducationalQualificationOthers(event.target.value);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleDateofBirthChange = (date) => {
    setDob(date);
  };

  const onSave = () => {
    updateUserProfile(false);
  };

  const updateUserProfile = (goToNextStep = true) => {
    const finalValues = {};
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Please Enter First Name";
    }
    if (!values.lastName) {
      errors.lastName = "Please Enter Last Name";
    }

    if (!values.institutionName) {
      errors.institutionName = "Please Enter Institute Name";
    }

    if (!values.institutionUrl) {
      errors.institutionUrl = "Please Enter Institution Website";
    } else if (
      values.institutionUrl &&
      !validator.isURL(values.institutionUrl.trim())
    ) {
      errors.institutionUrl = "Please enter a valid URL";
    }

    if (!values.mlastname && values.mfirstname) {
      errors.mlastname = "Please enter mentor last name";
    }

    if (!values.memail && (values.mfirstname || values.mlastname)) {
      errors.memail = "Please enter mentor email";
    }
    if (values.memail && !validator.isEmail(values.memail.trim())) {
      errors.memail = "Please enter a valid email.";
    }
    if (new Date(dob).getTime() === 0) {
      errors.dob = "Please select date of Birth";
    }

    if (phone && !isValidPhoneNumber(phone)) {
      errors.phone = "Please enter valid Phone Number";
    }

    if (!educationalQualification) {
      errors.educationalQualification =
        "Please select Educational qualification";
    }

    if (educationalQualification === "Others") {
      if (!educationalQualificationOthers) {
        errors.educationalQualificationOthers =
          "Please select Educational qualification";
      }
    }

    if (dob === 0) {
      errors.dob = "Please select date of Birth";
    }
    if (!gender) {
      errors.gender = "Please select Gender";
    }
    if (
      !tempAddress ||
      !tempAddress.city ||
      !tempAddress.state ||
      !tempAddress.country
    ) {
      errors.address = "Please select country, state and city";
    }
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      toastr.error("Please enter the mandatory fields ");
      return;
    } else {
      finalValues.institutionName = values.institutionName;
      finalValues.institutionUrl = values.institutionUrl;
      finalValues.educationalQualification = educationalQualification;
      finalValues.educationalQualificationOthers =
        educationalQualificationOthers;
      finalValues.dob = new Date(dob).getTime();
      finalValues.gender = gender;
      finalValues.studentId = values.studentId;
      finalValues.institutionalRole = values.institutionalRole;
      finalValues.isAssignMentor = isAssignMentor;
      finalValues.name = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
      finalValues.mentorInfo = {
        firstName: values.mfirstname,
        lastName: values.mlastname,
        phone: phone,
        email: values.memail,
      };

      if (
        tempAddress &&
        (tempAddress.line1 ||
          tempAddress.line2 ||
          tempAddress.city ||
          tempAddress.state ||
          tempAddress.country ||
          tempAddress.zip_code)
      ) {
        const newAddress = {};
        newAddress.line1 = tempAddress.line1;
        newAddress.line2 = tempAddress.line2;
        newAddress.city = tempAddress.city;
        newAddress.state = tempAddress.state;
        newAddress.country = tempAddress.country;
        newAddress.zip_code = tempAddress.zip_code;
        finalValues.address = newAddress;
      }
    }

    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        setAuthUser(response.data.data);
        localStorage.setItem("authUser", JSON.stringify(response.data.data));
        toastr.success("Participant Profile Updated Successfully");
        if (goToNextStep) {
          props.history.push("/transformers/Challenges");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            toastr.error(error.response.data.errors[0].msg);
          } else {
            toastr.error(error.response.message);
          }
        } else {
          toastr.error(error.message);
        }
      });
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const updateDefaultImageUrl = (url) => {
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProfileImageUrl(url);
          setErrors({});
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
          return true;
        } else {
          toastr.error(response.data.message);
          return false;
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        return false;
      });
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);

  const changeProfileImageClickHandler = () => {
    setShowingAddPhotoModal(true);
  };

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        localStorage.setItem("authUser", JSON.stringify(response));
        setAuthUser(response);
        localStorage.setItem(
          "stripeCustomerId",
          response.stripeCustomerId !== "" ? response.stripeCustomerId : "null"
        );
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  /*
   * If token is available then call the API and set localstorage data & authUser data
   */
  useEffect(() => {
    const userTokenParam = getQueryStringValue("token");

    if (userTokenParam) {
      //decrypt jwt token and set local storage
      try {
        const decodedToken = jwt_decode(userTokenParam);
        if (!decodedToken) {
          toastr.error("Invalid token");
          props.history.push("/");
        }
        loadUserData(decodedToken && decodedToken.user);
      } catch (error) {
        toastr.error("Failed to validate the user data" + error.message);
        props.history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      let userData = authUser;
      setValues((currentValues) => {
        return {
          ...currentValues,
          firstName: userData.name.first_name,
          lastName: userData.name.last_name,
          institutionName: userData.institutionName,
          institutionUrl: userData.institutionUrl,
          gender: userData.gender,
          studentId: userData.studentId,
          institutionalRole: userData.institutionalRole,
          educationalQualification: userData.educationalQualification,
          dob: userData.dob ? moment(userData.dob).format("DD-MM-YYYY") : "",
          mfirstname: userData.mentorInfo && userData.mentorInfo.firstName,
          mlastname: userData.mentorInfo && userData.mentorInfo.lastName,
          phone: userData.mentorInfo && userData.mentorInfo.phone,
          memail: userData.mentorInfo && userData.mentorInfo.email,
          morganization:
            userData.mentorInfo && userData.mentorInfo.organization,
          morganizationWebsite:
            userData.mentorInfo && userData.mentorInfo.organizationWebsite,
          isAssignMentor: userData.isAssignMentor,
        };
      });
      if (userData.address) {
        setAddress({
          line1: userData.address.hasOwnProperty("line1")
            ? userData.address.line1
            : "",
          line2: userData.address.hasOwnProperty("line2")
            ? userData.address.line2
            : "",
          city: userData.address.hasOwnProperty("city")
            ? userData.address.city
            : "",
          state: userData.address.hasOwnProperty("state")
            ? userData.address.state
            : "",
          zip_code: userData.address.hasOwnProperty("zip_code")
            ? userData.address.zip_code
            : "",
          country: userData.address.hasOwnProperty("country")
            ? userData.address.country
            : "",
        });
      }
      if (userData.dob) {
        setDob(moment(userData.dob));
      }
      setPhone(userData.mentorInfo && userData.mentorInfo.phone);
      if (userData.profile_image_url) {
        setProfileImageUrl(userData.profile_image_url);
      }
      setGender(userData.gender);
      setIsAssignMentor(userData.isAssignMentor);
      setEducationalQualification(userData.educationalQualification);
      setEducationalQualificationOthers(
        userData.educationalQualificationOthers
      );
    }
  }, [authUser]);

  return (
    <>
      <Headers />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Box component="h3" mt={2} mb={2}>
          Participant Profile
        </Box>
        <Grid item xs={12} md={12} className="avatar-blk midimg">
          <Box component="div" className="avatar-pic">
            <Avatar
              alt="Profile Photo"
              className="avatar-tr"
              src={profileImageUrl}
            />
            {authUser && authUser.login_provider === "local" && (
              <Fragment>
                <Box
                  component="div"
                  className="changeProfileImg"
                  onClick={changeProfileImageClickHandler}
                >
                  <CameraAltIcon />
                </Box>
                <AddPhotos
                  title="Manage Profile Photo"
                  isOpen={showingAddPhotoModal}
                  onClose={onCloseAddPhoto}
                  uid={authUser && authUser.unique_id}
                  collection="ProfilePictures"
                  profilephotoURL={profileImageUrl}
                  onProfilephotoURLChange={updateDefaultImageUrl}
                  canEdit={true}
                />
              </Fragment>
            )}
          </Box>

          <Box className="profileName clrblack" component="h5" mb={3} mt={1}>
            {values.firstName + " " + values.lastName}
          </Box>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} sm={6} xs={12}>
            <p>
              First Name of the Participant{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <TextField
              variant="outlined"
              className="f100"
              placeholder="First Name of the Participant"
              name="firstName"
              value={values.firstName}
              onChange={changeHandler}
            />
            {errors && errors.firstName && (
              <Typography className="custom-error">
                {errors.firstName}
              </Typography>
            )}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <p>
              Last Name of the Participant{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <TextField
              variant="outlined"
              className="f100"
              name="lastName"
              value={values.lastName}
              onChange={changeHandler}
            />
            {errors && errors.lastName && (
              <Typography className="custom-error">
                {errors.lastName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Date of Birth <span style={{ color: "red" }}>*</span>
            </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                showTodayButton
                format="dd-MMM-yyyy"
                style={{ width: "100%" }}
                margin="normal"
                className="profile-field dob"
                name="dob"
                value={dob}
                onChange={handleDateofBirthChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disableFuture
              />
            </MuiPickersUtilsProvider>
            {errors && errors.dob && (
              <Typography className="custom-error">{errors.dob}</Typography>
            )}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <p>
              Name of the Institution <span style={{ color: "red" }}>*</span>
            </p>
            <TextField
              variant="outlined"
              className="f100"
              placeholder="Please enter institution name "
              name="institutionName"
              onChange={changeHandler}
              value={values.institutionName}
              onKeyPress={(e) => handleEnterClick(e)}
            />
            {errors && errors.institutionName && (
              <Typography className="custom-error">
                {errors.institutionName}
              </Typography>
            )}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <p>
              Grade/Class <span style={{ color: "red" }}>*</span>
            </p>

            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              variant="outlined"
              className="f100"
              //  style={{ width: "100%" ,backgroundColor:"unset" }}
              required
              value={educationalQualification}
              onChange={handleChangeEducationalQualification}
            >
              {" "}
              <option aria-label="None" value="" />
              <optgroup label="Select Grade/class">
                <option value={"Grade6"}> Grade 6</option>
                <option value={"Grade7"}> Grade 7</option>
                <option value={"Grade8"}> Grade 8</option>
                <option value={"Grade9"}> Grade 9</option>
                <option value={"Grade10"}> Grade 10</option>
                <option value={"Grade11"}> Grade 11</option>
                <option value={"Grade12"}> Grade 12</option>
              </optgroup>
              <optgroup label="College">
                <option value={"Associate"}>Associate</option>
                <option value={"Bachelors"}>Bachelors</option>
                <option value={"Others"}>Others</option>
              </optgroup>
            </Select>
            {errors && errors.educationalQualification && (
              <Typography className="custom-error">
                {errors.educationalQualification}
              </Typography>
            )}

            {educationalQualification === "Others" && (
              <TextField
                variant="outlined"
                className="f100 bgcolor"
                style={{ paddingTop: "15px" }}
                placeholder="Enter educational qualification"
                name="educationalQualificationOthers"
                value={educationalQualificationOthers}
                onChange={handleChangeEducationalQualificationOthers}
              />
            )}

            {educationalQualification === "Others" &&
              errors &&
              errors.educationalQualificationOthers && (
                <Typography style={{ color: "red" }}>
                  {errors.educationalQualificationOthers}
                </Typography>
              )}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <p>
              Website of the Institution <span style={{ color: "red" }}>*</span>
            </p>
            <TextField
              variant="outlined"
              className={`full-width  f100 border-radius signup-input ${
                errors.institutionUrl && "error-text"
              }`}
              fullWidth
              placeholder="Please enter institution website "
              name="institutionUrl"
              onChange={changeHandler}
              value={values.institutionUrl}
              onKeyPress={(e) => handleEnterClick(e)}
            />
            {errors && errors.institutionUrl && (
              <Typography className="custom-error">
                {errors.institutionUrl}
              </Typography>
            )}
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <p>
              Gender <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              variant="outlined"
              className="f100"
              required
              value={gender}
              onChange={handleChangeGender}
            >
              <span style={{ fontWeight: "600", paddingLeft: "15px" }}>
                Select Gender
              </span>
              <MenuItem value={"m"}>Male</MenuItem>
              <MenuItem value={"f"}>Female</MenuItem>
              <MenuItem value={"o"}>Other</MenuItem>
            </Select>
            {errors && errors.gender && (
              <Typography className="custom-error">{errors.gender}</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <p>Student ID</p>

            <TextField
              variant="outlined"
              className="f100"
              name="studentId"
              value={values.studentId}
              onChange={changeHandler}
              onKeyPress={(e) => handleEnterClick(e)}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            {/* <Box component="label">Select Role</Box> */}

            <FormControl style={{ width: "100%" }}>
              <p>Select Your Role</p>
              <Select
                variant="outlined"
                name="institutionalRole"
                value={values.institutionalRole}
                onChange={changeHandler}
              >
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="judge">Judge</MenuItem>
                <MenuItem value="mentor">Mentor</MenuItem>
                <MenuItem value="ventureCapitalist">
                  Venture Capitalist
                </MenuItem>
                <MenuItem value="volunteer">Volunteer</MenuItem>
                <MenuItem value="other">Others</MenuItem>
              </Select>{" "}
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="new-addresst">
            <p>
              Address <span style={{ color: "red" }}>*</span>
            </p>
            <NewAddress value={address} onChange={handleChangeAddress} />
            {errors && errors.address && (
              <Typography className="custom-error">{errors.address}</Typography>
            )}
          </Grid>
          <Grid item md={12}>
            <h4> Mentor Contact Information</h4>
          </Grid>
        </Grid>
        {!isAssignMentor && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                component="div"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <span className="checkaglin" variant="body1" gutterBottom>
                  I already have a mentor who is guiding me. Here is my mentor's
                  contact information:
                </span>
              </Box>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <p> Mentor's First Name</p>

              <TextField
                variant="outlined"
                className="f100"
                name="mfirstname"
                // value={values && values.mentorInfo && values.mentorInfo.firstName}
                value={values.mfirstname}
                onChange={changeHandler}
              />
              {errors && errors.mfirstname && (
                <div className="custom-error">{errors.mfirstname}</div>
              )}
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <p> Mentor's Last Name</p>
              <TextField
                variant="outlined"
                className="f100"
                name="mlastname"
                value={values.mlastname}
                onChange={changeHandler}
              />
              {errors && errors.mlastname && (
                <div className="custom-error">{errors.mlastname}</div>
              )}
            </Grid>

            <Grid item sm={6} xs={12} className="new-phone">
              <p> Mentor's Phone Number</p>
              <Box
                component="div"
                border="1px solid #ccc"
                borderRadius="5px"
                p={2}
              >
                <PhoneInput
                  variant="outlined"
                  fullWidth
                  placeholder=" Phone number "
                  name=" Phone number"
                  defaultCountry={userLocaleInfo && userLocaleInfo.country_code}
                  value={phone}
                  onChange={setPhone}
                  onKeyPress={(e) => handleEnterClick(e)}
                />
              </Box>

              {errors && errors.phone && (
                <div className="custom-error">{errors.phone}</div>
              )}
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <p> Mentor's Email</p>
              <TextField
                variant="outlined"
                className="f100"
                type="email"
                name="memail"
                value={values.memail}
                onKeyPress={(e) => handleEnterClick(e)}
                onChange={changeHandler}
              />
              {errors && errors.memail && (
                <Typography style={{ color: "red" }}>
                  {errors.memail}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                component="div"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                (OR)
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl
            style={{ width: "100%", padding: "10px" }}
            component="fieldset"
          >
            <FormGroup>
              <FormControlLabel
                style={{ alignItems: "unset" }}
                control={
                  <>
                    <Checkbox
                      name="isAssignMentor"
                      checked={isAssignMentor}
                      onChange={handleMentorAllocation}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      style={{
                        marginTop: "0px",
                        paddingTop: "0px",
                      }}
                    ></Checkbox>
                    <Box
                      component="div"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      <span className="checkaglin" variant="body1" gutterBottom>
                        I don't have a mentor. I request the admin to assign a
                        mentor who can guide me in the process of submitting the
                        idea(s)
                      </span>
                    </Box>
                  </>
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item md={12} className="tals_buttons">
          <Button onClick={(e) => props.history.push(HOME_PAGE)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="#ffff"
            onClick={() => onSave()}
          >
            Save
          </Button>
          <Button type="submit" onClick={updateUserProfile}>
            Continue
          </Button>
        </Grid>
      </Container>
    </>
  );
};
export default ParticipantProfile;
