import React from "react";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

export default function Play(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
      <PlayArrowIcon />
    </button>
  );
}
