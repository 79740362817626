import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";

import { appContext } from "../../App";

import "../Organization/Organization.scss";

import CloseIcon from "@material-ui/icons/Close";

import validator from "validator";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    // paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
  },

  title: {
    color:"var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "#F44234",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
  },
  deleteBtn: {
    marginLeft: "50px",
  },
  btnCntr: {
    // width: "40%",
    padding: "20px",
    margin: "auto",
  },
  contactListCntr: {
    // width: "60%",
    margin: "auto",
    overflowX: "auto",
    height: "auto",
    maxHeight: "300px",
  },
  list: {
    borderBottom: "1px solid rgb(246, 247, 251)",
  },
}));
const headCells = [
  { id: "sno", numeric: true, disablePadding: false, label: "SL. NO." },
  { id: "name", numeric: true, disablePadding: true, label: "NAMES" },
  { id: "email", numeric: true, disablePadding: false, label: "EMAILS" },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const EmailGroupContactsSelectionModel = (props) => {
  const {
    contacts,
    talContacts,
    contactsByGroupName,
    contactsCountByGroupName,
    selectedContactsForEmailCampaign,
    setSelectedContactsForEmailCampaign,
  } = useContext(appContext);
  const { currentContactGroup, updateGroupsandCount } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const handleSelectAllClick = (event) => {
    const checkedIds = checked
      .filter((contact) => validator.isEmail(contact.email))
      .map((contact) => contact._id);
    if (event.target.checked) {
      if (currentContactGroup) {
        //setSelectedGroups([...selectedGroups, currentContactGroup.name]);
        setSelectedContactsForEmailCampaign(
          [...selectedContactsForEmailCampaign, ...checkedIds].filter(function (
            item,
            index,
            inputArray
          ) {
            return inputArray.indexOf(item) === index;
          })
        );
        updateGroupsandCount();
      }
      return;
    }
    const selObj = selectedContactsForEmailCampaign.filter(
      (item) => checkedIds.indexOf(item) === -1
    );
    setSelectedContactsForEmailCampaign(selObj);
    updateGroupsandCount();
  };
  const handleToggle = (value) => () => {
    const currentIndex = selectedContactsForEmailCampaign.indexOf(value);
    const newChecked = [...selectedContactsForEmailCampaign];
    if (currentContactGroup) {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelectedContactsForEmailCampaign(newChecked);
      updateGroupsandCount();
    }
  };

  useEffect(() => {
    // if (currentContactGroup && currentContactGroup === "Previous Donors") {
    //   setChecked(contactsByGroupName[currentContactGroup]);
    //   updateGroupsandCount();
    // } else
    if (currentContactGroup) {
      setChecked(contactsByGroupName[currentContactGroup.name]);
      updateGroupsandCount();
    }
  }, [props.openModal]);

  let i = 1;

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 15px 0px 15px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>Select/Unselect Contacts</h1>
              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  position: "relative",
                  top: "-7px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onModalClose} />
              </span>
            </div>
            <div className={classes.plasma}>
              <div className={classes.contactListCntr}>
                {currentContactGroup === "Previous Donors" &&
                checked &&
                checked.length > 0 ? (
                  <TableContainer
                    style={{
                      border: "1px solid #E5E5E5",
                      borderRadius: "5px",
                    }}
                  >
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={
                          contactsCountByGroupName[currentContactGroup]
                        }
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={
                          contactsByGroupName[currentContactGroup].length
                        }
                      />
                      <TableBody>
                        {talContacts.map((contact) => {
                          if (checked.indexOf(contact) === -1) return null;
                          const labelId = `checkbox-list-label-${contact._id}`;
                          return (
                            <TableRow
                              role="checkbox"
                              padding="checkbox"
                              hover
                              key={contact._id}
                              dense
                              button
                              onClick={handleToggle(contact._id)}
                              className={classes.list}
                            >
                              <TableCell align="center">
                                {validator.isEmail(contact.email) && (
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      selectedContactsForEmailCampaign.indexOf(
                                        contact._id
                                      ) >= 0
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">{i++}</TableCell>
                              <TableCell align="center">{`${contact.name.first_name} ${contact.name.last_name}`}</TableCell>
                              <TableCell align="center">
                                <span
                                  style={{
                                    color: validator.isEmail(contact.email)
                                      ? ""
                                      : "#FF0000",
                                  }}
                                >
                                  {contact.email || contact.phoneNumber}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : currentContactGroup &&
                  currentContactGroup !== "Previous Donors" &&
                  checked &&
                  checked.length > 0 ? (
                  <TableContainer
                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px" }}
                  >
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={
                          contactsCountByGroupName[currentContactGroup.name]
                        }
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={
                          contactsByGroupName[currentContactGroup.name].length
                        }
                      />
                      <TableBody>
                        {contacts.map((contact) => {
                          if (checked.indexOf(contact) === -1) return null;
                          const labelId = `checkbox-list-label-${contact._id}`;
                          return (
                            <TableRow
                              role="checkbox"
                              padding="checkbox"
                              hover
                              key={contact._id}
                              dense
                              button
                              onClick={handleToggle(contact._id)}
                              className={classes.list}
                            >
                              <TableCell align="center">
                                {validator.isEmail(contact.email) && (
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      selectedContactsForEmailCampaign.indexOf(
                                        contact._id
                                      ) >= 0
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">{i++}</TableCell>
                              <TableCell align="center">{`${contact.firstName} ${contact.lastName}`}</TableCell>
                              <TableCell align="center">
                                <span
                                  style={{
                                    color: validator.isEmail(contact.email)
                                      ? ""
                                      : "#FF0000",
                                  }}
                                >
                                  {contact.email || contact.phoneNumber}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    No Contacts
                  </div>
                )}
              </div>
              <div className={classes.btnCntr}>
                <Button
                  className={classes.saveBtn}
                  onClick={props.onModalClose}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EmailGroupContactsSelectionModel;
