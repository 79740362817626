import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";

import "./styles.scss";

const DocumentRow = (props) => {
  const { userGivenName, url, note } = props.document;
  return (
    <TableRow>
      <TableCell>
        <a
          style={{ color: "blue" }}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {userGivenName}
        </a>
      </TableCell>
      <TableCell>{note}</TableCell>
      {props.canEdit && (
        <TableCell>
          <Tooltip title="Edit This Document">
            <IconButton className="del-btn" onClick={props.onEditHandler}>
              <EditIcon />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Delete This Document">
            <IconButton className="del-btn" onClick={props.onDeleteHandler}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DocumentRow;
