import React, { useContext, useState } from "react";
import { Button, Divider, Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import {
  DATE_TIME_FORMAT,
  getObjectValuesInStringFormat,
} from "../../utils/utils";
import WithdrawalCard from "./WithdrawalCard";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { appContext } from "../../App";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideMenu from "../SideMenu/Sidemenu";




function RequestRaised(props) {

  
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };


  const { authUser } = useContext(appContext);
  const { finalValues } = props.location.state;
  const { region } = useContext(appContext);
  const handleContinue = () => {
    props.history.push("/");
  };
  return (


    <Box>

      
    <div className="barnav">
      <Container MaxWidth="lg">
        <div style={{ display: "flex" }}>
          {authUser && (
            <Button onClick={handleCloseSideMenu}>
              {!closeSideMenu && <MenuIcon />}
              {closeSideMenu && <ArrowBackIcon />}
            </Button>
          )}
         
        </div>
      </Container>
    </div>


    <Container maxWidth="lg">

    <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <Box className="drop-box" p={2}>
            <Box
              component="h2"
              color="var(--tal_primary) "
              pb={1}
              mb={1}
              borderBottom="1px solid #f1f1f1"
            >
              Your request has been placed
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                Dear user, we have received your withdrawal request. The team
                will verify the documents and details you have given, and the
                process will be initiated soon. Remember, the process usually
                takes 15 working days or sometimes more, depending on the mode
                of transaction and location of beneficiary.
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                Order Id
              </Grid>
              <Grid item sm={8} xs={12}>
                {finalValues.requestId}
              </Grid>
              <Grid item sm={4} xs={12}>
                Order Date
              </Grid>
              <Grid item sm={8} xs={12}>
                {moment(finalValues.updatedAt).format(DATE_TIME_FORMAT)}
              </Grid>
            </Grid>

            <Box>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="h6" component="h6">
                    Request Details
                  </Typography>
                  <Divider variant="fullWidth" className="postDividerIn" />
                </Grid>
                <Grid item sm={4} xs={12}>
                  Withdraw Type
                </Grid>
                <Grid item sm={8} xs={12}>
                  {finalValues.requestType}
                </Grid>
                <Grid item sm={4} xs={12}>
                  Withdraw Amount
                </Grid>
                <Grid item sm={8} xs={12}>
                  {finalValues.amount}
                </Grid>

                {finalValues.requestType === "online" ? (
                  <>
                    <Grid item sm={4} xs={12}>
                      Account Name
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {finalValues.payeeName}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      Account Number
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {finalValues.bankInfo.accountNumber}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      {region.name === "India" ? "IFSC Code" : "Routing Number"}
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {finalValues.bankInfo.routingNumber}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={4} xs={12}>
                      Cheque Name (In Favour of)
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {finalValues.payeeName}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      Address
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {getObjectValuesInStringFormat(finalValues.address)}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <div
              style={{
                textAlign: "right",
                margin: "10px 0px",
              }}
            >
              <Button
                onClick={handleContinue}
                variant="contained"
                style={{
                  background: "#f44336",
                  color: "white",
                  textTransform: "capitalize",
                  marginLeft: "10px",
                }}
                aria-label="withdraw funds"
              >
                Continue
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <WithdrawalCard donationRequestId={props.match.params.id} />
        </Grid>
      </Grid>
   
   </div>
    </Container>
 
 

 </Box>
 );
}
export default RequestRaised;
