import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import { Grid, Button, Typography } from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { appContext } from "../../App";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PlasmaRequest from "../PlasmaRequest/PlasmaRequest";
import PlasmaDonor from "../PlasmaDonorReq/PlasmaDonor";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalTitle: {
    font: "600 20px Roboto",
    margin: "15px 0",
  },
  modalDesc: {
    font: "400 16px Roboto",
  },

  otpCont: {
    "& div": {
      justifyContent: "center",
      "& input": {
        width: "2.5em !important",
        height: "2.5em",
      },
    },
  },
  resendOtp: {
    float: "right",
    color: "#aaa",
    cursor: "pointer",
  },
  saveBtn: {
    background: "var(--tal_primary) !important",
    color: "#fff!important",
    padding: "15px 20px",
    margin: "30px 0",
    display: "inline-block",
  },
  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
    
    [theme.breakpoints.down('xs')]: {
    textAlign:"center",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlasmaForms = () => {
  const classes = useStyles();
  const { authUser } = useContext(appContext);

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const [plasmaPerson, setPlasmaPerson] = useState(0);

  const handleChange = (event, newValue) => {
    setPlasmaPerson(newValue);
  };
  return (
    <div className="plasma-container">
      {/* <Hidden mdUp>
        {" "}
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden> */}

      <Container maxWidth="lg">
        <div className="menu-container">
          {/* {closeSideMenu && (
            <Hidden smUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )} */}
 
          <Hidden smDown>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          </Hidden>

          <div
            className="container-center drop-box"
            style={{ width: "100%", padding: "0" }}
          >
            <div className={classes.header}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/plasma.svg"
                    alt="plasma"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "20px",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    variant="title"
                    gutterBottom
                  >
                    Plasma Donation
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    variant="headline"
                    gutterBottom
                  >
                    Bridging the gap between donors and organizations that can
                    make a difference.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <div style={{ padding: "20px" }}>
              <div>
                <Tabs
                  value={plasmaPerson}
                  onChange={handleChange}
                  aria-label="plasama person tabs"
                  style={{
                    borderBottom: "1px solid #E1E1E1",
                    marginBottom: "20px",
                  }}
                >
                  <Tab label="Plasma Donee" {...a11yProps(0)} />
                  <Tab label="Plasma Donor" {...a11yProps(1)} />
                </Tabs>
              </div>
              <div>
                <TabPanel value={plasmaPerson} index={0}>
                  <PlasmaRequest />
                </TabPanel>
                <TabPanel value={plasmaPerson} index={1}>
                  <PlasmaDonor />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(PlasmaForms);
