const ValidatePassword = (values) => {
  let errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = "Please enter Current Password";
  }

  if (!values.newPassword) {
    errors.newPassword = "Please enter New Password";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Please enter Confirm Password";
  }

  if (
    values.newPassword.length > 0 &&
    values.confirmPassword.length > 0 &&
    values.newPassword !== values.confirmPassword
  ) {
    errors.newPassword = "New Password and Confirm Password not matching";
  }

  return errors;
};

export default ValidatePassword;
