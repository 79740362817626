const ValidateWithdrawal = (values) => {
  let errors = {};

  if (!values.userName) {
    errors.userName = "Please enter Name";
  }

  if (!values.amount || values.amount <= 0) {
    errors.amount = "Please enter valid amount";
  }

  if (values.withdrawType === "online") {
    if (!values.accountNumber) {
      errors.accountNumber = "Please enter account number";
    }
    if (!values.routingNumber) {
      errors.routingNumber = "please enter IFSC or Routing Number";
    }
  }

  return errors;
};

export default ValidateWithdrawal;
