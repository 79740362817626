import React, { useState, useEffect } from "react";
import toastr from "toastr";
import { Grid, Typography, TextField } from "@material-ui/core";
import validateAddress from "./validateAddress";
import useSpecialFormValidation from "../../hooks/useSpecialFormValidation";
import { getAllCountries, getAllStates, getAllCities } from "../../utils/api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "./styles.scss";

const BillingAddress = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [shouldUpdateValues, setShouldUpdateValues] = useState(true);

  const handleChangeSelectedCountry = (event) => {
    getAllStates(event.target.value)
      .then((result) => {
        setStates(result.data);
      })
      .catch((error) => {
        toastr.error("Problem in getting states: " + error.message);
      });
    values.country = countries.find(
      (country) => country.id === event.target.value
    ).name;
    values.state = "";
    values.city = "";
  };

  const handleChangeSelectedState = (event) => {
    getAllCities(event.target.value)
      .then((result) => {
        setCities(result.data);
      })
      .catch((error) => {
        toastr.error("Problem in getting cities: " + error.message);
      });
    values.state = states.find((state) => state.id === event.target.value).name;
    values.city = "";
  };

  const handleChangeSelectedCity = (event) => {
    values.city = cities.find((city) => city.id === event.target.value).name;
    onConfirm();
  };

  useEffect(() => {
    getAllCountries().then((result) => {
      setCountries(result.data);
    });
  }, []);

  useEffect(() => {
    if (props && props.value && props.value.city && shouldUpdateValues) {
      setValues({
        line1: props.value.line1,
        line2: props.value.line2,
        city: props.value.city,
        state: props.value.state,
        country: props.value.country,
        zip: props.value.zip,
      });
      setShouldUpdateValues(false);
    }
  }, [props.value]);

  const initialState = {};

  const onConfirm = () => {
    const address = {
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      state: values.state,
      country: values.country,
      zip: values.zip,
    };
    props.onChange(address);
  };

  const { values, setValues, errors, changeHandler } = useSpecialFormValidation(
    initialState,
    validateAddress,
    onConfirm
  );

  return (
    <Grid container spacing={2} className="newAddr-container">
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          id="line1"
          name="line1"
          autoComplete="new-password"
          className="input-field addr-line1"
          placeholder="Address Line1"
          disabled={props.disabled}
          value={values.line1}
          onChange={changeHandler}
          inputProps={{
            maxLength: 30,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          id="line2"
          name="line2"
          autoComplete="new-password"
          className="input-field addr-line2"
          placeholder="Address Line2"
          disabled={props.disabled}
          value={values.line2}
          onChange={changeHandler}
          inputProps={{
            maxLength: 30,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Select
          name="country"
          placeholder="Country"
          value={-1}
          onChange={handleChangeSelectedCountry}
          variant="outlined"
          className="input-field fundraiserFor"
          style={{ width: "100%" }}
        >
          {values.country && (
            <MenuItem value={-1} disabled>
              {values.country}
            </MenuItem>
          )}
          {!values.country && (
            <MenuItem value={-1} disabled>
              Select Country
            </MenuItem>
          )}
          {countries &&
            countries.length > 0 &&
            countries.map((country, index) => {
              return (
                <MenuItem value={country.id} key={index}>
                  {country.name}
                </MenuItem>
              );
            })}
        </Select>
      </Grid>
      <Grid item xs={12} md={6} className="addr-select-container">
        <Select
          placeholder="State"
          value={-1}
          onChange={handleChangeSelectedState}
          variant="outlined"
          className="input-field fundraiserFor"
          style={{ width: "100%" }}
        >
          {values.state && (
            <MenuItem value={-1} disabled>
              {values.state}
            </MenuItem>
          )}
          {!values.state && (
            <MenuItem value={-1} disabled>
              Select State
            </MenuItem>
          )}
          {states &&
            states.length > 0 &&
            states.map((state, index) => {
              return <MenuItem value={state.id}>{state.name}</MenuItem>;
            })}
        </Select>
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Select
          placeholder="City"
          value={-1}
          onChange={handleChangeSelectedCity}
          variant="outlined"
          className="input-field fundraiserFor"
          style={{ width: "100%" }}
        >
          {values.city && (
            <MenuItem value={-1} disabled>
              {values.city}
            </MenuItem>
          )}
          {!values.city && (
            <MenuItem value={-1} disabled>
              Select City
            </MenuItem>
          )}
          {cities &&
            cities.length > 0 &&
            cities.map((city, index) => {
              return <MenuItem value={city.id}>{city.name}</MenuItem>;
            })}
        </Select>
      </Grid>
      <Grid item xs={12} md={6} className="addr-select-container">
        <TextField
          type="text"
          id="zip"
          name="zip"
          autoComplete="new-password"
          placeholder="Zip"
          disabled={props.disabled}
          className="input-field pincode"
          value={values.zip}
          onChange={changeHandler}
          inputProps={{
            maxLength: 10,
          }}
        />
        {errors && errors.zip && (
          <Typography style={{ color: "red" }}>{errors.zip}</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          {values.line1} {values.line1 && ", "} {values.line2}{" "}
          {values.line2 && ", "} {values.city} {values.city && ", "}{" "}
          {values.state}
          {values.state && ", "} {values.country}
          {values.country && ", "} {values.zip}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BillingAddress;
