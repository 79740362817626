import React, { useState, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { TextField, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateFnsUtils from "@date-io/date-fns";
import AddDocument from "../common/AddDocument";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import validatePlasmaRequest from "./validatePlasmaRequest";
import useFormValidation from "../../hooks/useFormValidation";
import toastr from "toastr";
import { appContext } from "../../App";
import { createDonationRequest, updateDonationRequest } from "../../utils/api";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import "./PlasmaRequest.scss";
import PhoneNumber from "../Phone/PhoneNumber";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalTitle: {
    font: "600 20px Roboto",
    margin: "15px 0",
  },
  modalDesc: {
    font: "400 16px Roboto",
  },

  otpCont: {
    "& div": {
      justifyContent: "center",
      "& input": {
        width: "2.5em !important",
        height: "2.5em",
      },
    },
  },
  resendOtp: {
    float: "right",
    color: "#aaa",
    cursor: "pointer",
  },
  saveBtn: {
    background: "var(--tal_primary)  !important",
    color: "#fff!important",
    padding: "15px 20px",
    margin: "30px 0",
    display: "inline-block",
  },
  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
    marginBottom: "20px",
  },
}));

function getSteps() {
  return ["Basic Info", "Communication Info"];
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PlasmaRequest = (props) => {
  const classes = useStyles();
  const { authUser, region } = useContext(appContext);
  const [extraErrors, setExtraErrors] = useState({});
  const [id, setId] = useState("");
  const [agree, setAgree] = useState(false);
  const [notHold, setNotHold] = useState(false);
  const [phoneContact, setPhoneContact] = useState(false);
  const [emailContact, setMailContact] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [title, setTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [dueDate, setDueDate] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [age, setAge] = useState(null);
  const [description, setDescription] = useState("");
  const initialState = {
    title: "",
    blood: "",
    patientName: "",
    gender: "",
  };

  const handleCheckbox = (e, type) => {
    if (type === "agree") {
      setAgree(e.target.checked);
    }
    if (type === "not-hold") {
      setNotHold(e.target.checked);
    }
    if (type === "phone") {
      setPhoneContact(e.target.checked);
    }
    if (type === "mail") {
      setMailContact(e.target.checked);
    }
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };

  const phoneRef = useRef();
  const handleShowStepper = () => {
    if (phoneRef.current.isValid()) {
      setShowStepper(true);
    }
  };

  const handleBack = () => {
    if (activeStep >= 1) {
      setEditMode(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setShowStepper(false);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleAgeChange = (date) => {
    setAge(date);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const previewHandler = () => {
    localStorage.setItem("donationRequestDescription", description);
    localStorage.setItem("donationRequestBrief", values.brief);
    localStorage.setItem("donationRequestLocation", values.location);
    localStorage.setItem("isPreviewMode", "true");
    window.open(`/donationRequest/${name}`, "blank");
  };

  const handleNext = () => {
    savePlasmaRequest();
  };

  const savePlasmaRequest = () => {
    const finalValues = values;
    const errors = {};
    finalValues.user_id = authUser.unique_id;
    finalValues.region = region._id;
    finalValues.request_type = "plasma";
    finalValues.quantity = 1;
    finalValues.status = -2;
    finalValues.creatorType = "donee";
    finalValues.requested_for = values.blood;
    finalValues.title = values.title;
    finalValues.blood = values.blood;
    finalValues.fullname = values.fullname;
    finalValues.gender = values.gender;

    if (dueDate === null) {
      errors.dueDate = "Please enter end date";
    } else {
      finalValues.due_date = moment(dueDate).valueOf();
    }
    if (age === null) {
      errors.age = "Please enter date of birth";
    }
    if (activeStep === 0) {
      if (Object.keys(errors).length > 0) {
        setExtraErrors(errors);
      } else {
        setActiveStep(1);
      }
    } else if (activeStep === 1) {
      if (description === "") {
        errors.description = "Please write a story for your plasma request.";
      }
      if (!values.hospName) {
        errors.hospName = "Please enter hospital name";
      }
      if (!values.brief) {
        errors.brief = "Please enter brief.";
      } else if (values.brief && values.brief.length > 120) {
        errors.brief = "Brief cannot be more than 120 characters";
      }
      if (!values.ipNum) {
        errors.ipNum = "Please enter IPNumber";
      }
      if (!documentUrl) {
        errors.documentUrl = "Please upload recovery documents";
      }
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else if (editMode) {
      let additionalValues = {};
      additionalValues.user_id = authUser.unique_id;
      additionalValues.status = 0;
      additionalValues.documentUrl = documentUrl;
      additionalValues.description = description;
      additionalValues.shipping_address = {
        city: values.location,
      };
      additionalValues.brief = values.brief;
      additionalValues.additionalInfo = {
        hospital_name: values.hospName,
        ip_number: values.ipNum,
        contactByPhone: phoneContact,
        contactByEmail: emailContact,
        endDate: moment(dueDate).valueOf(),
        dob: moment(age).valueOf(),
      };

      updateDonationRequest(additionalValues, id)
        .then((response) => {
          toastr.success("Request created successfully");
          if (activeStep === 0) {
            setActiveStep(1);
          }
          props.history.push(`/donationRequest/${response.name}`);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    } else {
      createDonationRequest(finalValues).then((resp) => {
        if (resp.statusCode === 200) {
          setId(resp.data._id);
          setName(resp.data.name);
          setTitle(resp.data.title);
          setEditMode(true);
          setActiveStep(1);
        }
      });
    }
  };
  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    validatePlasmaRequest,
    handleNext
  );

  return (
    <div className="plasma-container">
      <div className="menu-container">
        <div
          className="container-center"
          style={{ width: "100%", padding: "0" }}
        >
          <div>
            <div>
              <h1 className="stepper-title">Here we go!</h1>
              <hr className="sub-hr" />
              <p className="stepper-subheader">
                Please complete Plasma Donation Request Form
              </p>
            </div>
            {!showStepper ? (
              <div>
                <div className="plasma-otp">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-c"
                      name="agree"
                      checked={agree}
                      onChange={(e) => handleCheckbox(e, "agree")}
                    />
                    <label for="checkbox-c">Checkbox 3</label>
                  </div>
                  <span>
                    I voluntarily agree to recieve plasma from COVID-19
                    recovered patients.
                  </span>
                </div>
                <div className="plasma-otp">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-d"
                      name="not-hold"
                      checked={notHold}
                      onChange={(e) => handleCheckbox(e, "not-hold")}
                    />
                    <label for="checkbox-d">Checkbox 3</label>
                  </div>
                  <span>
                    I do not hold Plasma donor for any side effects that may be
                    suffered by me later.
                  </span>
                </div>
                <div className="plasma-otp">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-e"
                      name="phone"
                      checked={phoneContact}
                      onChange={(e) => handleCheckbox(e, "phone")}
                    />
                    <label for="checkbox-e">Checkbox 3</label>
                  </div>
                  <span>Donors may contact me by phone</span>
                </div>
                <div className="plasma-otp">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-f"
                      name="mail"
                      checked={emailContact}
                      onChange={(e) => handleCheckbox(e, "mail")}
                    />
                    <label for="checkbox-f">Checkbox 3</label>
                  </div>
                  <span>Donors may contact me by mail</span>
                </div>
                <InputLabel className="secFormLabel">Phone</InputLabel>
                <div className="phonebutton">
                  <PhoneNumber
                    phone={authUser && authUser.phone}
                    isPhoneVerified={authUser && authUser.phone_verified}
                    ref={phoneRef}
                  />
                  {phoneError && (
                    <div className="custom-error">{phoneError}</div>
                  )}
                </div>

                <div>
                  <Button
                    className="savebtn"
                    disabled={agree && notHold ? false : true}
                    style={{
                      opacity: agree && notHold ? 1 : 0.5,
                    }}
                    onClick={handleShowStepper}
                  >
                    Save & Continue
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  alternativeLabel
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === getSteps().length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>Reset</Button>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.instructions}>
                        {activeStep === 0 && (
                          <div className="request-step-one">
                            <form
                              noValidate
                              autoComplete="off"
                              className="plasma-request-form"
                            >
                              <div style={{ position: "relative" }}>
                                <TextField
                                  type="text"
                                  required
                                  className={`input-field request-title ${
                                    errors && errors.title
                                      ? "bg-red"
                                      : values.title
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  variant="outlined"
                                  label="Request Title"
                                  name="title"
                                  value={values.title}
                                  onChange={changeHandler}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                />
                                <span className="title-length">
                                  {" "}
                                  {values.title ? values.title.length : 0}/50
                                </span>
                              </div>
                              {errors && errors.title && (
                                <div className="custom-error">
                                  {errors.title}
                                </div>
                              )}
                              <FormControl className="blood-grp">
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  style={{ top: 8, left: 5 }}
                                >
                                  Blood Group *
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={values.blood}
                                  onChange={changeHandler}
                                  variant="outlined"
                                  className={`input-field request-amount ${
                                    errors && errors.blood
                                      ? "bg-red"
                                      : values.blood
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  name="blood"
                                  label="Blood Group *"
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value={"A+"}>A+</MenuItem>
                                  <MenuItem value={"A-"}>A-</MenuItem>
                                  <MenuItem value={"B+"}>B+</MenuItem>
                                  <MenuItem value={"B-"}>B-</MenuItem>
                                  <MenuItem value={"AB+"}>AB+</MenuItem>
                                  <MenuItem value={"AB-"}>AB-</MenuItem>
                                  <MenuItem value={"O+"}>O+</MenuItem>
                                  <MenuItem value={"O-"}>O-</MenuItem>
                                </Select>{" "}
                                {errors && errors.blood && (
                                  <div className="custom-error">
                                    {errors.blood}
                                  </div>
                                )}
                              </FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  showTodayButton
                                  style={{ width: "100%" }}
                                  margin="normal"
                                  className={`input-field dob`}
                                  format="dd-MMM-yyyy"
                                  label="End Date *"
                                  name="dueDate"
                                  value={dueDate}
                                  onChange={handleDueDateChange}
                                  disablePast
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              {extraErrors && extraErrors.dueDate && (
                                <div className="custom-error">
                                  {extraErrors.dueDate}
                                </div>
                              )}
                              <TextField
                                type="text"
                                required
                                className={`input-field request-title ${
                                  errors && errors.fullname
                                    ? "bg-red"
                                    : values.fullname
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                variant="outlined"
                                label="Patient Full Name"
                                name="fullname"
                                value={values.fullname}
                                onChange={changeHandler}
                                inputProps={{
                                  maxLength: 50,
                                }}
                              />
                              {errors && errors.fullname && (
                                <div className="custom-error">
                                  {errors.fullname}
                                </div>
                              )}
                              <FormControl className="blood-grp">
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  style={{ top: 8, left: 5 }}
                                >
                                  Gender *
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={values.gender}
                                  onChange={changeHandler}
                                  variant="outlined"
                                  className={`input-field request-amount ${
                                    errors && errors.gender
                                      ? "bg-red"
                                      : values.gender
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  name="gender"
                                  label="Gender *"
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value={"Male"}>Male</MenuItem>
                                  <MenuItem value={"Female"}>Female</MenuItem>
                                  <MenuItem value={"Others"}>Others</MenuItem>
                                </Select>{" "}
                                {errors && errors.gender && (
                                  <div className="custom-error">
                                    {errors.gender}
                                  </div>
                                )}
                              </FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%" }}
                                  margin="normal"
                                  className={`input-field dob`}
                                  // variant="inline"
                                  format="dd-MMM-yyyy"
                                  label="Date of Birth *"
                                  disableFuture
                                  name="age"
                                  value={age}
                                  onChange={handleAgeChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              {extraErrors && extraErrors.age && (
                                <div className="custom-error">
                                  {extraErrors.age}
                                </div>
                              )}
                            </form>
                          </div>
                        )}
                        {activeStep === 1 && (
                          <div className="request-step-two">
                            <form
                              noValidate
                              autoComplete="off"
                              className="plasma-request-form"
                            >
                              {/* <TextField
                                type="text"
                                required
                                className={`input-field request-title ${
                                  errors && errors.medDocs
                                    ? "bg-red"
                                    : values.medDocs
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                variant="outlined"
                                label="Medical Documents"
                                name="medDocs"
                                value={values.medDocs}
                                onChange={changeHandler}
                              />

                              {errors && errors.medDocs && (
                                <div className="custom-error">
                                  {errors.medDocs}
                                </div>
                              )} */}
                              <TextField
                                type="text"
                                required
                                className={`input-field request-title ${
                                  errors && errors.hospName
                                    ? "bg-red"
                                    : values.hospName
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                variant="outlined"
                                label="Hospital Name"
                                name="hospName"
                                value={values.hospName}
                                onChange={changeHandler}
                              />

                              {extraErrors && extraErrors.hospName && (
                                <div className="custom-error">
                                  {extraErrors.hospName}
                                </div>
                              )}
                              <TextField
                                type="number"
                                required
                                className={`input-field request-title ${
                                  errors && errors.ipNum
                                    ? "bg-red"
                                    : values.ipNum
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                variant="outlined"
                                label="IP Number"
                                name="ipNum"
                                value={values.ipNum}
                                onChange={changeHandler}
                              />

                              {extraErrors && extraErrors.ipNum && (
                                <div className="custom-error">
                                  {extraErrors.ipNum}
                                </div>
                              )}
                              <TextField
                                type="text"
                                required
                                className={`input-field request-title ${
                                  errors && errors.location
                                    ? "bg-red"
                                    : values.location
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                variant="outlined"
                                label="City"
                                name="location"
                                value={values.location}
                                onChange={changeHandler}
                              />

                              {extraErrors && extraErrors.location && (
                                <div className="custom-error">
                                  {extraErrors.location}
                                </div>
                              )}

                              <div style={{ position: "relative" }}>
                                <TextField
                                  type="text"
                                  required
                                  className={`input-field request-title ${
                                    errors && errors.brief
                                      ? "bg-red"
                                      : values.brief
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  variant="outlined"
                                  label="Brief"
                                  name="brief"
                                  value={values.brief}
                                  onChange={changeHandler}
                                  inputProps={{
                                    maxLength: 120,
                                  }}
                                />

                                <span className="title-length">
                                  {" "}
                                  {values.brief ? values.brief.length : 0}/120
                                </span>
                              </div>

                              {extraErrors && extraErrors.brief && (
                                <div className="custom-error">
                                  {extraErrors.brief}
                                </div>
                              )}
                              <CKEditor
                                data={description}
                                editor={ClassicEditor}
                                placeholder="More detailed information about the Fundraiser *"
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                }}
                              />
                              {extraErrors && extraErrors.description && (
                                <div className="custom-error">
                                  {extraErrors.description}
                                </div>
                              )}
                            </form>

                            <Button
                              className="document-btn"
                              onClick={() => setShowingAddDocumentModal(true)}
                              style={{
                                width: "60%",
                                font: "500 12px Roboto",
                                padding: "20px 30px",
                                margin: "10px 5px",
                                background: "transparent",
                                color: "#283E4A",
                                border: "1px solid #283E4A",
                              }}
                            >
                              <CloudUploadIcon
                                style={{ fontSize: 14, marginRight: 6 }}
                              />{" "}
                              Upload supporting document(s) *
                            </Button>
                            {extraErrors && extraErrors.documentUrl && (
                              <div className="custom-error">
                                {extraErrors.documentUrl}
                              </div>
                            )}
                          </div>
                        )}

                        <div>
                          <Button
                            onClick={handleBack}
                            style={{
                              border: "1px solid #ccc",
                              width: activeStep === 0 ? "18%" : "10%",
                            }}
                            className={classes.backButton}
                          >
                            Back
                          </Button>
                          {activeStep === 1 && (
                            <Button
                              className="preview-btn"
                              style={{
                                border: "1px solid var(--tal_primary)",
                                color: "var(--tal_primary)",
                                background: "#fff",
                                marginLeft: 10,
                              }}
                              onClick={previewHandler}
                            >
                              Preview
                            </Button>
                          )}
                          <Button
                            style={{
                              width: activeStep === 0 ? "40%" : "38%",
                            }}
                            variant="contained"
                            className={`save-btn ${
                              activeStep === 0 ? "short-btn" : "continue-btn"
                            }`}
                            onClick={submitHandler}
                          >
                            {activeStep === getSteps().length - 1
                              ? "Submit"
                              : "Save & Continue"}
                          </Button>
                        </div>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="DonationRequest"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className={classes.modalTitle}>
              Request for Plasma Donation
            </h2>
            {showSuccess ? (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Mobile number verification has been successful!
                </p>
                <input type="checkbox" className="tick-check" id="chk" />
                <label for="chk"></label>
                <div>
                  <Button className="savebtn" onClick={handleFinishOtp}>
                    Continue
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Please enter the OTP that has been sent to{" "}
                  {phone && phone.replace(/(\+\d{3})\d{7}/, "$1*******")}
                </p>
                <div className={classes.otpCont}>
                  <OtpInput
                    onChange={(e) => handleOtpCode(e)}
                    numInputs={6}
                    value={otpValues}
                    separator={<span> &nbsp; &nbsp; </span>}
                    shouldAutoFocus={true}
                  />
                </div>
                <p className={classes.resendOtp} onClick={() => resendOtp()}>
                  Resend OTP
                </p>
              </div>
            )}
          </div>
        </Fade>
      </Modal> */}
    </div>
  );
};

export default withRouter(PlasmaRequest);
