import React, { useContext, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@material-ui/core";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Menu from "@material-ui/core/Menu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Grid from "@material-ui/core/Grid";
import Organization from "../Organization/Organization";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {
  SearchRounded,
  AccountCircleRounded,
  AvTimerTwoTone,
} from "@material-ui/icons";
import MoreIcon from "@material-ui/icons/MoreVert";
import toastr from "toastr";
import PaymentsPopUp from "../donationDetails/PaymentsPopUp";
import { appContext } from "../../App";
import {
  HOME_PAGE,
  formatScore,
  AVATAR_DONOR,
  AVATAR_DONEE,
  TALMEDIA_PAGE,
  DATE_TIME_FORMAT,
  numberWithCommas,
} from "../../utils/utils";
import "./Topbar.scss";
import { updateNotification, getUserNotifications } from "../../utils/api";
import { Hidden } from "@material-ui/core";
/*menu*/
import MenuIcon from "@material-ui/icons/Menu";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Collapse from "@material-ui/core/Collapse";
import { isAndroid, isIOS } from "react-device-detect";
import CloseIcon from "@material-ui/icons/Close";
/*menu*/
const useStyles = makeStyles((theme) => ({
  rootli: {
    width: "100%",
  },
  menuButton: {
    "& img": {
      width: "250px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
  },
  grow: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 7,
  },
  // appbar: {
  //   minHeight: 51,
  //   width: "980px",
  //   margin: "0 auto",
  //   maxWidth: "1170px",
  // },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    width: 550,
    borderRadius: 10,
    textAlign: "center",
    height: 510,
  },
  modalImage: {
    width: 650,
    position: "relative",
    right: 50,
    top: 10,
    margin: "25px 0",
  },
  modalTitle: {
    font: "500 24px Roboto",
    padding: 15,
  },
  modalDesc: {
    font: "400 18px Roboto",
    padding: 15,
    marginTop: 0,
    color: "#555",
    paddingTop: 0,
  },
  modalButton: {
    width: "100%",
    background: "var(--tal_primary) ",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: 5,
    fontSize: 18,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 212,
    height: 29,
    top: "25px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      top: "-10px",

      MuiInputBaseRoot: {
        width: "100%",
      },
    },

    add_menu: {
      [theme.breakpoints.down("sm")]: {
        padding: "12px 0 12px 0",
      },
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    // height: "100%",
    position: "absolute",
    pointerEvents: "none",
    zIndex: 1,
    color: "#aaa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px 6px",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: "calc(1em + 15px)",
    transition: theme.transitions.create("width"),
    width: "100%",
    background: "white",
    height: 19,
    borderRadius: 2,
    [theme.breakpoints.up("md")]: {
      width: "145px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("md")]: {
      borderTop: "1px solid #707070",
    },
  },
  sectionMobile: {
    display: "flex",
    // margin: "12px 0",
    color: "white",
    position: "relative",
    right: " 0",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  slid_menu: {
    gap: "5px",
    display: "flex",
    padding: " 5px ",
    borderRadius: "50px",
    backgroundColor: "#f1f1f1",
    minWidth: "100%",

    "& Button": {
      padding: "5px 10px",
      border: "none",
      borderRadius: "50px",
      width: "100%",
    },
  },
  mobile_menu: {
    "& li": {
      minHeight: "38px",
    },
    "& p": {
      margin: "0",
    },
  },
}));

const Topbar = (props) => {
  const {
    authUser,
    setAuthUser,
    regions,
    region,
    setRegion,
    hideLoginButton,
    avatars,
    avatar,
    setAvatar,
    userSummary,
    userNotifications,
    setUserNotifications,
  } = useContext(appContext);

  const classes = useStyles();
  const [radioMenu, setRadioMenu] = useState("");

  const handleEditProfile = () => {
    setRadioMenu("");
    props.history.push(`/newUserProfile/${authUser.unique_id}`);
  };

  const handleMyRequests = () => {
    hideMyProfile();
    setRadioMenu("");
    setAvatar(AVATAR_DONEE);
    props.history.push(HOME_PAGE);
  };
  const handleMyDonations = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/myDonations");
  };
  const handleMyWithdrawals = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/myWithdrawals");
  };
  const handleMyApplications = () => {
    setRadioMenu("");
    hideMyProfile();
    props.history.push("/myApplications");
  };
  const showFavourites = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/myfavourites");
  };
  const handleOrgDetails = (orgData) => {
    setRadioMenu("");
    if (orgData.orgId) {
      props.history.push(`/organization/${orgData.orgId._id}`);
    }
  };

  const handleOrganization = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };

  const handleDownloadCertificate = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push(`/downloads/myCertificates`);
  };

  const createAudioRoom = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/createAudioRoom");
  };

  const viewAudioRooms = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/chatTabs");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isPaymentsPopUpOpen, setIsPaymentsPopUpOpen] = useState(false);
  const [switchAVatar, setSwitchAvatar] = useState(false);
  const [myProfile, setMyProfile] = useState(false);
  const [showIcons, setShowIcons] = useState(true);
  const [active, setActive] = useState("avatar");
  const [showNotification, setShowNotification] = useState(false);
  const [showTopbar, setShowTopbar] = React.useState(false);
  const [isRegionSelected, setIsRegionSelected] = useState(
    localStorage.getItem("isRegionSelected")
  );
  const [donor, setDonor] = useState("");
  const [donee, setDonee] = useState("active");
  const [expandApps, setExpandApps] = useState(false);
  const [openMobile, setOpenMobile] = React.useState(true);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [myRegion, setMyRegion] = React.useState(false);
  const wrapperRef = useRef(null);
  const [totalMyApplicationsCount, setTotalMyApplicationsCount] = useState(0);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hideMyProfile();
          hideSwitchAvatar();
          hideMyRegion();
          hideNotification();
          hideApps();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const pathname = window.location.pathname;
  useEffect(() => {
    if (pathname === "/publicPayment") {
      setShowTopbar(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      pathname === "/StripePayment" ||
      pathname === "/thankyou" ||
      pathname === "/publicPayment" ||
      pathname === "/billingInfo"
    ) {
      setShowIcons(false);
    } else {
      setShowIcons(true);
    }
  });

  useEffect(() => {
    if (userSummary) {
      let total = 0;
      userSummary &&
        userSummary.myApplications &&
        userSummary.myApplications.requestTypes &&
        userSummary.myApplications.requestTypes.forEach((requestType) => {
          if (
            [
              "scholarship",
              "internship",
              "volunteering",
              "career",
              "homework help",
            ].includes(requestType._id)
          ) {
            total += requestType.count;
          }
        });
      setTotalMyApplicationsCount(total);
    }
  }, [userSummary]);

  const handleSwitchAvatar = () => {
    setActive("avatar");
    setSwitchAvatar(true);
    hideMyProfile();
    hideMyRegion();
    hideApps();
    hideNotification();
  };

  const handleExpandApps = () => {
    setActive("plus");
    setExpandApps(true);
    hideMyProfile();
    hideSwitchAvatar();
    hideMyRegion();
    hideNotification();
  };

  const hideApps = () => {
    setExpandApps(false);
  };

  const handleTalRadio = () => {
    props.history.push(TALMEDIA_PAGE);
    setMyProfile(false);
    setActive("radio");
    hideSwitchAvatar();
    hideMyRegion();
    hideApps();
    hideNotification();
  };

  const handleMyProfile = () => {
    setActive("profile");
    setMyProfile(true);
    hideSwitchAvatar();
    hideMyRegion();
    hideApps();
    hideNotification();
  };

  const handleDonationType = (type) => {
    if (type === "cash" && donor === "active") {
      setAvatar(AVATAR_DONOR);
      props.history.push(HOME_PAGE);
    } else if (type === "cash" && donee === "active") {
      setAvatar(AVATAR_DONEE);
      setMyProfile(false);
      props.history.push("/new-request");
    }
    if (type === "plasma" && donor === "active") {
      props.history.push("/plasma-donor-request");
    } else if (type === "plasma" && donee === "active") {
      props.history.push("/plasma-donee-donor");
    }
    if (type === "careerhelp") {
      props.history.push("/careerhelp-request");
    }
    if (type === "covidhelp") {
      props.history.push("/covidhelp-request");
    }
    hideApps();
  };

  const notification = () => {
    setActive("notification");
    setShowNotification(true);
    hideSwitchAvatar();
    hideMyRegion();
    hideMyProfile();
    hideApps();
  };

  const hideMyProfile = () => {
    setMyProfile(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      props.history.push(`/search/${event.target.value}`);
    }
  };

  const hideMyRegion = () => {
    setMyRegion(false);
  };

  const hideNotification = () => {
    setShowNotification(false);
  };

  const regionChangeHandler = (reg) => {
    setRegion(regions.find((region) => reg._id === region._id));
    setMyRegion(false);
  };

  const handleStartGiving = () => {
    if (authUser) {
      //setIsPaymentsPopUpOpen(true);
      window.open(
        process.env.REACT_APP_TALGIVING_URL +
          `/publicPayment?donationfor=tal&currency=${
            region && region.currency
          }&userid=${authUser && authUser.unique_id}&email=${
            authUser && authUser.email
          }&name=${
            authUser &&
            authUser.name &&
            authUser.name.first_name +
              "%20" +
              authUser.name.middle_name +
              "%20" +
              authUser.name.last_name
          }`
      );
    } else {
      window.open(
        process.env.REACT_APP_TALGIVING_URL +
          `/publicPayment?donationfor=tal&currency=${region && region.currency}`
      );
    }
    setMyProfile(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const hideSwitchAvatar = () => {
    setSwitchAvatar(false);
  };

  const handleAvatar = (avatar) => {
    setAvatar(avatar);
    setSwitchAvatar(false);
    props.history.push(HOME_PAGE);
  };

  const handleMyRegion = () => {
    setActive("region");
    setMyRegion(true);
    hideMyProfile();
    hideApps();
    hideSwitchAvatar();
    hideNotification();
  };

  const logout = () => {
    setAnchorEl(null);
    setAuthUser(null);
    setAvatar(AVATAR_DONOR);
    localStorage.removeItem("authUser");
    localStorage.clear();
    window.location.reload();
    props.history.push(HOME_PAGE);
  };

  const handleMyProfileOptions = (option) => {
    setMyProfile(false);
    hideMyProfile();
    option === "edit" &&
      props.history.push(`/newUserProfile/${authUser.unique_id}`);
    option === "public" && props.history.push(`/${authUser.username}`);
    option === "helpdesk" && props.history.push("/user/help-desk");
    option === "feedback" && props.history.push("/user/feedback");
    option === "logout" && logout();
    option === "organization" && handleOrganization();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNewRequest = () => {
    setMyProfile(false);
    setActive("fundraise");
    if (authUser) {
      props.history.push("/new-request");
    } else {
      toastr.warning("Please signup or login to create a fundraiser.");
      props.history.push("/login");
    }
  };

  const showAllNotifications = () => {
    setShowNotification(false);
    props.history.push("/notifications");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      className={classes.mobile_menu}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleNewRequest}>
        <p>Fundraiser</p>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          props.history.push(TALMEDIA_PAGE);
        }}
      >
        <Box
          component="p"
          bgcolor="#f1f1f1"
          borderRadius="20px"
          width="100%"
          padding="10px"
        >
          TALMedia
        </Box>
      </MenuItem>

      {authUser && (
        <MenuItem>
          <Box className={classes.slid_menu}>
            {avatars &&
              avatars.length > 0 &&
              avatars.map((avt) => {
                return (
                  <Box
                    component="Button"
                    value={AvTimerTwoTone}
                    style={{ backgroundColor: avatar === avt ? "white" : "" }}
                    onClick={() => handleAvatar(avt)}
                  >
                    {avt}
                  </Box>
                );
              })}
          </Box>
        </MenuItem>
      )}
      <MenuItem>
        <Box className={classes.slid_menu}>
          {regions &&
            regions.length > 0 &&
            regions.map((rgn, index) => {
              return (
                <Box
                  component="Button"
                  key={index}
                  value={avatar}
                  style={{ backgroundColor: region === rgn ? "white" : "" }}
                  onClick={() => regionChangeHandler(rgn)}
                >
                  <img
                    src={`/images/${rgn._id}.svg`}
                    width="20px"
                    alt={rgn.regionCodeIso3}
                    style={{ marginRight: 10 }}
                  />{" "}
                  {rgn.regionCodeIso3}
                </Box>
              );
            })}
        </Box>
      </MenuItem>
    </Menu>
  );

  const showNotificationCount = (notiLength) => {
    let arrayCount = [];
    notiLength.map((data) => {
      if (data.status === 0) {
        arrayCount.push(data);
      }
      return null;
    });
    return arrayCount.length;
  };

  const updateNotificationStatus = (data) => {
    if (data && data.status === 0 && authUser) {
      updateNotification(authUser.unique_id, data._id)
        .then((response) => {
          if (response) {
            // hideNotification();
            // props.history.push(`/notifications`);
            getUserNotifications(authUser.unique_id)
              .then((response) => {
                setUserNotifications(response);
              })
              .catch((error) => {
                toastr.error(error.data.message);
              });
          }
        })
        .catch((error) => {
          toastr.error(error.data.message);
        });
    }
  };

  useEffect(() => {
    if (
      pathname.includes("/createAudioRoom") ||
      pathname.includes("/chatTabs")
    ) {
      setActive("audio");
    } else if (pathname.includes("/talmedia")) {
      setActive("radio");
    }
  }, []);

  /*top bar new menu*/
  const [drawerstate, setDrawerstate] = React.useState(false);

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerstate({ ...state, right: isopen });
  };
  /*menu*/

  return (
    <div className={classes.grow} ref={wrapperRef}>
      <AppBar
        position="static"
        style={{
          background: "#283E4A",
          // height: 51,
          boxShadow: "none",
          zIndex: 1,
          position: "sticky",
          top: "0",
        }}
      >
        {(isAndroid || isIOS) && (
          <Hidden mdUp>
            <Collapse in={openMobile}>
              <Box component="section" className="app-dn-icon">
                <Box component="div" display="flex">
                  <Box component="div" flexDirection="column" pl={1}>
                    <Box
                      component="p"
                      alignSelf="centre"
                      fontSize="14px"
                      fontWeight="600"
                      color="var(--tal_primary)"
                      m={0}
                    >
                      Download TALTransformers App
                    </Box>
                  </Box>

                  <Box component="div" alignSelf="centre">
                    {isAndroid ? (
                      <Box
                        component="a"
                        color="white"
                        href="https://play.google.com/store/apps/details?id=com.touchalife.talscouts"
                      >
                        <img
                          src="/images/playstore-1.png"
                          alt="TALTransformers Android App"
                          className="app-icon-mobi"
                        />
                      </Box>
                    ) : isIOS ? (
                      <Box
                        color="white"
                        component="a"
                        href="https://apps.apple.com/us/app/talscouts/id1591797656"
                      >
                        <img
                          src="/images/appstore.png"
                          alt="TALTransformers ios App"
                          className="app-icon-mobi"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenMobile(false);
                    }}
                  >
                    <CloseIcon className="close-btn" />
                  </IconButton>
                </Box>
              </Box>
            </Collapse>
          </Hidden>
        )}
        <Container MaxWidth="md">
          <div className="topbar">
            {/* <Toolbar> */}

            <Grid container>
              <Grid
                item
                xs={4}
                style={{ display: "flex", position: "relative" }}
              >
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  <Hidden xsDown>
                    <img src="/images/tal-logo.png" alt="TALTransformers" />
                  </Hidden>

                  <Hidden smUp>
                    <img src="/images/tal-m-logo.svg" alt="TALTransformers" />
                  </Hidden>
                </IconButton>

                <Hidden smDown>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchRounded />
                    </div>
                    <InputBase
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onKeyPress={(e) => handleEnterClick(e)}
                    />
                  </div>
                </Hidden>
              </Grid>

              <Grid item xs={8} className="menu-pull">
                {showIcons && (
                  <div className="desktop-view">
                    <div className={classes.sectionDesktop}>
                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "avatar" && "active"
                          }`}
                          color="inherit"
                          style={{ position: "relative" }}
                        >
                          <p onClick={handleSwitchAvatar}>
                            <HomeOutlinedIcon
                              style={{
                                color:
                                  active === "avatar" ? "#cd2729" : "#ffffff",
                              }}
                            />
                          </p>
                          <p
                            onClick={handleSwitchAvatar}
                            style={{
                              color:
                                active === "avatar" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            {avatar === AVATAR_DONEE
                              ? AVATAR_DONEE
                              : AVATAR_DONOR}{" "}
                            <span>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>
                          {switchAVatar && (
                            <div className="tab-box" style={{ left: "3px" }}>
                              {avatars &&
                                avatars.length > 0 &&
                                avatars.map((avatar) => {
                                  return (
                                    <MenuItem
                                      value={avatar}
                                      onClick={() => handleAvatar(avatar)}
                                    >
                                      {avatar}
                                    </MenuItem>
                                  );
                                })}
                            </div>
                          )}
                        </IconButton>
                      )}

                      {/* <IconButton
                        className={`top-icon-btn ${
                          active === "audio" && "active"
                        }`}
                        color="inherit"
                        onClick={viewAudioRooms}
                      >
                        <p>
                          <RecordVoiceOverOutlinedIcon />
                        </p>
                        <p
                          style={{
                            color: active === "audio" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          Audio Rooms
                        </p>
                      </IconButton> */}
                      {/* <IconButton
                        className={`top-icon-btn ${
                          active === "radio" && "active"
                        }`}
                        color="inherit"
                        onClick={handleTalRadio}
                      >
                        <p>
                          <img
                            src={`/images/${
                              active === "radio"
                                ? "talradio-active"
                                : "talradio-inactive"
                            }.png`}
                            alt="TALMedia"
                            width="100%"
                          />
                        </p>
                        <p
                          style={{
                            color: active === "radio" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          TALMedia
                        </p>
                      </IconButton> */}

                      <IconButton
                        style={{ padding: "12px 7px", position: "relative" }}
                        className={`top-icon-btn ${
                          active === "plus" && "active"
                        }`}
                        color="inherit"
                      >
                        <p onClick={handleExpandApps}>
                          <AddCircleOutlineRoundedIcon
                            style={{
                              color: active === "plus" ? "#cd2729" : "#ffffff",
                            }}
                          />
                        </p>
                        <p
                          onClick={handleExpandApps}
                          style={{
                            color: active === "plus" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          Add
                          <span style={{ margin: 0 }}>
                            <ExpandMoreOutlinedIcon />
                          </span>
                        </p>
                        {expandApps && (
                          <div className="tab-box apps-cont add">
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => {
                                props.history.push("/volunteer-request");
                                hideApps();
                              }}
                            >
                              <p>
                                <img
                                  src="/images/volunteering.png"
                                  alt="Volunteering"
                                />{" "}
                              </p>
                              <div>Volunteering </div>
                            </MenuItem>

                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => {
                                props.history.push("/careerhelp-request");
                                hideApps();
                              }}
                            >
                              <p>
                                <img
                                  src="/images/mentor-mentee.png"
                                  alt="Career Help"
                                />{" "}
                              </p>
                              <div> Career Help</div>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                props.history.push("/homework-request");
                                hideApps();
                              }}
                            >
                              <p>
                                <img
                                  src="/images/homework.png"
                                  alt="Homework"
                                  width="25px"
                                />{" "}
                              </p>
                              <div> Home Work Help</div>
                            </MenuItem>
                          </div>
                        )}
                      </IconButton>

                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "notification" && "active"
                          }`}
                          color="inherit"
                        >
                          <span className="kindness-points">
                            {userNotifications &&
                              showNotificationCount(userNotifications)}
                          </span>
                          <p onClick={notification}>
                            {userNotifications && (
                              <AllInboxIcon
                                style={{
                                  color:
                                    active === "notification"
                                      ? "#cd2729"
                                      : "#ffffff",
                                }}
                              />
                            )}
                          </p>
                          <p
                            onClick={notification}
                            style={{
                              color:
                                active === "notification"
                                  ? "#cd2729"
                                  : "#ffffff",
                            }}
                          >
                            Inbox{" "}
                            <span style={{ margin: 0 }}>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>
                          {userNotifications && showNotification && (
                            <div className="fix">
                              <div className="tab-box">
                                <List className="indox">
                                  {userNotifications &&
                                  userNotifications.length > 0 ? (
                                    userNotifications
                                      .slice(0, 4)
                                      .map((data) => {
                                        return (
                                          <List>
                                            <ListItem
                                              alignItems="flex-start"
                                              style={{
                                                cursor: "pointer",
                                                "background-color":
                                                  data.status === 0
                                                    ? "#f1f1f1"
                                                    : "transparent",
                                              }}
                                              onClick={() =>
                                                updateNotificationStatus(data)
                                              }
                                            >
                                              <ListItemAvatar>
                                                <Avatar
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                  alt="Remy Sharp"
                                                  src={data.sourceImageUrl}
                                                />
                                              </ListItemAvatar>
                                              <ListItemText
                                                primary={
                                                  data &&
                                                  data.destinationUrlReference ? (
                                                    <Link
                                                      href={`/donationRequest/${data.destinationUrlReference}`}
                                                      target="blank"
                                                    >
                                                      <span
                                                        style={{
                                                          "font-weight":
                                                            data.status === 0
                                                              ? "bold"
                                                              : "normal",
                                                        }}
                                                      >
                                                        {data.message}
                                                      </span>
                                                    </Link>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        "font-weight":
                                                          data.status === 0
                                                            ? "bold"
                                                            : "normal",
                                                      }}
                                                    >
                                                      {data.message}
                                                    </span>
                                                  )
                                                }
                                                secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      color="textPrimary"
                                                      style={{ right: "0" }}
                                                    >
                                                      <small>
                                                        {" "}
                                                        {moment(
                                                          data.createdAt
                                                        ).format(
                                                          DATE_TIME_FORMAT
                                                        )}
                                                      </small>
                                                    </Typography>
                                                  </React.Fragment>
                                                }
                                              />
                                            </ListItem>
                                            <hr />
                                          </List>
                                        );
                                      })
                                  ) : (
                                    <span>You have no new notifications</span>
                                  )}

                                  <ListItem
                                    alignItems="flex-start"
                                    className="view"
                                    onClick={showAllNotifications}
                                  >
                                    {userNotifications.length > 0 ? (
                                      <a href="#" color="#868686">
                                        view all
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          )}
                        </IconButton>
                      )}
                      <IconButton
                        className={`top-icon-btn ${
                          active === "region" && "active"
                        }`}
                        color="inherit"
                        style={{ position: "relative" }}
                      >
                        <p onClick={handleMyRegion} style={{ width: "30px" }}>
                          {region && (
                            <img
                              src={`/images/${region._id}.svg`}
                              alt={region.regionCodeIso3}
                              style={{ width: "80%" }}
                            />
                          )}
                        </p>
                        <p
                          onClick={handleMyRegion}
                          style={{
                            color: active === "region" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          {region && region.regionCodeIso3}

                          <span style={{ margin: 0 }}>
                            <ExpandMoreOutlinedIcon />
                          </span>
                        </p>
                        {myRegion && (
                          <div className="tab-box" style={{ left: "-15px" }}>
                            {regions &&
                              regions.length > 0 &&
                              regions.map((region, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={avatar}
                                    onClick={() => regionChangeHandler(region)}
                                  >
                                    <img
                                      src={`/images/${region._id}.svg`}
                                      width="25px"
                                      alt={region.regionCodeIso3}
                                      style={{ marginRight: 15 }}
                                    />{" "}
                                    {region.regionCodeIso3}
                                  </MenuItem>
                                );
                              })}
                          </div>
                        )}
                      </IconButton>
                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "profile" && "active"
                          }`}
                          color="inherit"
                          style={{ position: "relative" }}
                        >
                          <span className="kindness-points">
                            {userSummary &&
                              formatScore(userSummary.kindnessScore)}
                          </span>
                          <p onClick={handleMyProfile}>
                            {authUser && authUser.profile_image_url ? (
                              <img
                                style={{
                                  width: "25px",
                                  border: "2px solid white",
                                  height: "25px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                src={
                                  authUser.profile_image_url
                                    ? authUser.profile_image_url
                                    : "/images/default-profile-photo.png"
                                }
                                alt="User Profile"
                                className="user-profile-image"
                                height="100%"
                                // onClick={handlePostMenuClick}
                              />
                            ) : (
                              <AccountCircleRounded
                                style={{
                                  color:
                                    active === "profile"
                                      ? "#cd2729"
                                      : "#ffffff",
                                }}
                              />
                            )}
                          </p>
                          <p
                            onClick={handleMyProfile}
                            style={{
                              color:
                                active === "profile" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            Me{" "}
                            <span>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>

                          {myProfile && (
                            <div className="tab-box" style={{ left: "-50px" }}>
                              <MenuItem>
                                Hi{" "}
                                {authUser &&
                                  authUser.name &&
                                  authUser.name.first_name}
                                ,
                              </MenuItem>
                              <MenuItem>
                                <div style={{ display: "flex" }}>
                                  <div> Your Kindness Score: </div>
                                  <div>
                                    {userSummary &&
                                      numberWithCommas(
                                        userSummary.kindnessScore
                                      )}
                                  </div>
                                </div>
                              </MenuItem>

                              <hr />
                              <MenuItem
                                onClick={() => handleMyProfileOptions("edit")}
                              >
                                Edit Profile
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleMyProfileOptions("public")}
                              >
                                Public Profile
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("organization")
                                }
                              >
                                Add Organization
                              </MenuItem>

                              <hr />

                              <MenuItem onClick={handleMyRequests}>
                                <span>My Requests</span>
                                <span>
                                  {userSummary &&
                                    userSummary.donationRequests &&
                                    userSummary.donationRequests.total}
                                </span>
                              </MenuItem>
                              <MenuItem onClick={handleMyDonations}>
                                <span>My Donations</span>
                                <span>
                                  {userSummary &&
                                    userSummary.donations &&
                                    userSummary.donations.total}
                                </span>
                              </MenuItem>

                              <MenuItem onClick={handleMyWithdrawals}>
                                <span>My Withdrawals</span>
                                <span>
                                  {userSummary &&
                                    userSummary.withdrawals &&
                                    userSummary.withdrawals.total}
                                </span>
                              </MenuItem>
                              <MenuItem onClick={handleMyApplications}>
                                <span>My Applications </span>
                                <span>{totalMyApplicationsCount}</span>
                              </MenuItem>
                              <MenuItem onClick={showFavourites}>
                                <span>Favourites</span>
                                <span>
                                  {userSummary &&
                                    userSummary.favorites &&
                                    userSummary.favorites.total}
                                </span>
                              </MenuItem>

                              <hr />

                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("feedback")
                                }
                              >
                                Feedback
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("helpdesk")
                                }
                              >
                                Help Desk
                              </MenuItem>
                              <hr />
                              <MenuItem
                                onClick={() => handleMyProfileOptions("logout")}
                              >
                                Logout
                              </MenuItem>
                            </div>
                          )}
                        </IconButton>
                      )}
                      {pathname &&
                        pathname.indexOf("/donationRequest/") < 0 && (
                          <Button
                            className="donate-to-tal"
                            style={{
                              minWidth: "110px",
                              background: authUser ? "#f35a57 " : "transparent",
                            }}
                            onClick={handleStartGiving}
                          >
                            Donate to TAL &nbsp;
                            {region && region.currency === "INR" ? (
                              <FontAwesomeIcon
                                color="#fff"
                                size="1x"
                                icon={faRupeeSign}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="#fff"
                                size="1x"
                                icon={faDollarSign}
                              />
                            )}
                          </Button>
                        )}
                      {!authUser && !hideLoginButton && (
                        <Button
                          className="login-btn"
                          onClick={() => {
                            props.history.push("/login");
                          }}
                        >
                          Login/Sign up
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                <div className="mltMobile">
                  {pathname && pathname.indexOf("/donationRequest/") < 0 && (
                    <Button
                      className="donate-to-tal"
                      style={{
                        minWidth: "110px",
                        background: authUser ? "#f35a57 " : "#f35a57",
                      }}
                      onClick={handleStartGiving}
                    >
                      Donate to TAL &nbsp;
                      {region && region.currency === "INR" ? (
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          icon={faRupeeSign}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          icon={faDollarSign}
                        />
                      )}
                    </Button>
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" className="msidemenu">
                      <Toolbar>
                        <IconButton
                          size="large"
                          edge="start"
                          aria-label="menu"
                          onClick={toggleDrawer("right", true)}
                          style={{ paddingRight: "0" }}
                        >
                          <MenuIcon />
                        </IconButton>
                      </Toolbar>
                      <Drawer
                        anchor="right"
                        open={drawerstate["right"]}
                        onClose={toggleDrawer("right", false)}
                      >
                        <Box>
                          <Paper
                            sx={{ width: 320, maxWidth: "100%" }}
                            className="menu-mobi"
                          >
                            <MenuList>
                              {authUser && (
                                <>
                                  <MenuItem>
                                    <div className={classes.rootli}>
                                      <Accordion
                                        className="accmobile"
                                        expanded={switchAVatar}
                                        onChange={handleSwitchAvatar}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            switchAVatar ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accsum"
                                        >
                                          <HomeOutlinedIcon /> &nbsp; Donor
                                        </AccordionSummary>
                                        <AccordionDetails className="submenu-mobi">
                                          <MenuList>
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  setAvatar(AVATAR_DONOR);
                                                  props.history.push(HOME_PAGE);
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Donor
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  setAvatar(AVATAR_DONEE);
                                                  props.history.push(
                                                    "/new-request"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Donee
                                              </ListItemText>
                                            </MenuItem>
                                          </MenuList>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </MenuItem>
                                  <Divider />
                                </>
                              )}
                              {/* <MenuItem>
                                <ListItemText
                                  style={{
                                    color:
                                      active === "audio"
                                        ? "#cd2729"
                                        : "#000000",
                                  }}
                                  onClick={() => {
                                    viewAudioRooms();
                                    setDrawerstate(false);
                                  }}
                                >
                                  {" "}
                                  <RecordVoiceOverOutlinedIcon /> &nbsp; Audio
                                  Rooms
                                </ListItemText>
                              </MenuItem>
                              <Divider />
                              <MenuItem>
                                <ListItemText
                                  onClick={() => {
                                    props.history.push(TALMEDIA_PAGE);
                                    setDrawerstate(false);
                                  }}
                                  style={{
                                    color:
                                      active === "radio"
                                        ? "#cd2729"
                                        : "#000000",
                                  }}
                                >
                                  <img
                                    src="/images/talradio-mobile.png"
                                    alt="TALMedia"
                                    className="talmediamobi"
                                  />{" "}
                                  &nbsp; TALMedia
                                </ListItemText>
                              </MenuItem> */}
                              <Divider />
                              <MenuItem>
                                <div className={classes.rootli}>
                                  <Accordion
                                    className="accmobile"
                                    style={{
                                      color:
                                        active === "plus"
                                          ? "#cd2729"
                                          : "#000000",
                                    }}
                                    expanded={expandApps}
                                    onChange={handleExpandApps}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        expandApps ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )
                                      }
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                      className="accsum"
                                    >
                                      <AddCircleOutlineRoundedIcon /> &nbsp; Add
                                    </AccordionSummary>
                                    <AccordionDetails className="submenu-mobi">
                                      <MenuList>
                                        <Divider />
                                        <MenuItem>
                                          <ListItemText
                                            onClick={() => {
                                              props.history.push(
                                                "/volunteer-request"
                                              );
                                              setDrawerstate(false);
                                            }}
                                            className="add-fund-mobi"
                                          >
                                            <span>
                                              {" "}
                                              <img
                                                src="/images/volunteering.png"
                                                alt="Volunteering"
                                              />
                                            </span>
                                            <span> Volunteering </span>
                                          </ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem>
                                          <ListItemText
                                            onClick={() => {
                                              handleDonationType("careerhelp");
                                              setDrawerstate(false);
                                            }}
                                            className="add-fund-mobi"
                                          >
                                            <span>
                                              {" "}
                                              <img
                                                src="/images/mentor-mentee.png"
                                                alt="Career Help"
                                              />
                                            </span>
                                            <span> Career Help </span>
                                          </ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem>
                                          <ListItemText
                                            onClick={() => {
                                              props.history.push(
                                                "/homework-request"
                                              );
                                              setDrawerstate(false);
                                            }}
                                            className="add-fund-mobi"
                                          >
                                            <span>
                                              {" "}
                                              <img
                                                src="/images/homework.png"
                                                alt="Home Work"
                                              />{" "}
                                            </span>
                                            <span> Home Work </span>
                                          </ListItemText>
                                        </MenuItem>
                                      </MenuList>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </MenuItem>
                              {authUser && (
                                <>
                                  <Divider />
                                  <MenuItem>
                                    <div className={classes.rootli}>
                                      <Accordion
                                        className="accmobile"
                                        expanded={myProfile}
                                        onChange={handleMyProfile}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            myProfile ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )
                                          }
                                          aria-controls="panel3a-content"
                                          id="panel3a-header"
                                          className="accsum"
                                        >
                                          <PersonOutlineOutlinedIcon /> &nbsp;
                                          Me
                                        </AccordionSummary>
                                        <AccordionDetails className="submenu-mobi">
                                          <MenuList>
                                            <MenuItem>
                                              <ListItemText>
                                                {"Hi " +
                                                  (authUser &&
                                                    authUser.name &&
                                                    authUser.name.first_name)}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText>
                                                {"Your Kindness Score : " +
                                                  (userSummary &&
                                                    numberWithCommas(
                                                      userSummary.kindnessScore
                                                    ))}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyProfileOptions(
                                                    "edit"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Edit Profile
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyProfileOptions(
                                                    "public"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {" "}
                                                Public Profile
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyProfileOptions(
                                                    "organization"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Add Organization
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyRequests();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {"My Requests " +
                                                  (userSummary &&
                                                    userSummary.donationRequests &&
                                                    userSummary.donationRequests
                                                      .total)}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyDonations();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {"My Donations " +
                                                  (userSummary &&
                                                    userSummary.donations &&
                                                    userSummary.donations
                                                      .total)}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyWithdrawals();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {"My Withdrawals " +
                                                  (userSummary &&
                                                    userSummary.withdrawals &&
                                                    userSummary.withdrawals
                                                      .total)}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  showFavourites();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {"Favourites " +
                                                  (userSummary &&
                                                    userSummary.favorites &&
                                                    userSummary.favorites
                                                      .total)}
                                              </ListItemText>
                                            </MenuItem>
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyApplications();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                {"My Applications " +
                                                  ": " +
                                                  totalMyApplicationsCount}
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            {/* <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  createAudioRoom();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Create Audio Room
                                              </ListItemText>
                                            </MenuItem> */}
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  viewAudioRooms();
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                View Ongoing and Future Rooms
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyProfileOptions(
                                                    "feedback"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Feedback
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleMyProfileOptions(
                                                    "helpdesk"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Help Desk
                                              </ListItemText>
                                            </MenuItem>
                                          </MenuList>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </MenuItem>
                                </>
                              )}
                              <Divider />
                              {authUser && (
                                <>
                                  <MenuItem>
                                    <ListItemText
                                      onClick={() => {
                                        showAllNotifications();
                                        setDrawerstate(false);
                                      }}
                                    >
                                      <AllInboxIcon /> &nbsp; Inbox
                                    </ListItemText>
                                  </MenuItem>
                                </>
                              )}
                              <Divider />
                              <MenuItem>
                                <MenuItem>
                                  <Box className={classes.slid_menu}>
                                    {regions &&
                                      regions.length > 0 &&
                                      regions.map((rgn, index) => {
                                        return (
                                          <Box
                                            component="Button"
                                            key={index}
                                            value={avatar}
                                            style={{
                                              backgroundColor:
                                                region === rgn ? "white" : "",
                                            }}
                                            onClick={() => {
                                              regionChangeHandler(rgn);
                                              setMobileMoreAnchorEl(null);
                                            }}
                                          >
                                            <img
                                              src={`/images/${rgn._id}.svg`}
                                              width="20px"
                                              alt={rgn.regionCodeIso3}
                                              style={{ marginRight: 10 }}
                                            />{" "}
                                            {rgn.regionCodeIso3}
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                </MenuItem>
                              </MenuItem>
                              <Divider />
                              <MenuItem>
                                <ListItemText>
                                  {authUser !== null && (
                                    <div
                                      className="mobi-logout"
                                      onClick={() => {
                                        handleMyProfileOptions("logout");
                                        setDrawerstate(false);
                                      }}
                                    >
                                      Logout
                                    </div>
                                  )}
                                </ListItemText>
                              </MenuItem>
                              <MenuItem>
                                <ListItemText>
                                  {!authUser && !hideLoginButton && (
                                    <div
                                      className="mobi-logout"
                                      onClick={() => {
                                        props.history.push("/login");
                                        setDrawerstate(false);
                                      }}
                                    >
                                      Login/Sign up
                                    </div>
                                  )}
                                </ListItemText>
                              </MenuItem>
                            </MenuList>
                          </Paper>
                        </Box>
                      </Drawer>
                    </AppBar>
                  </Box>
                </div>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Box
                display="flex"
                justifyContent="space-between"
                minWidth="100%"
                backgroundColor="red"
                marginBottom="20px"
                borderTop="1px solid #595959"
              >
                {!authUser && !hideLoginButton && (
                  <Button
                    className="login-btn"
                    onClick={() => {
                      props.history.push("/login");
                    }}
                  >
                    Login/Sign up
                  </Button>
                )}

                <div>
                  {pathname && pathname.indexOf("/donationRequest/") < 0 && (
                    <Button
                      className="donate-to-tal-mobile"
                      style={{
                        whiteSpace: "nowrap",
                        background: authUser
                          ? "var(--tal_primary)  "
                          : "transparent",
                      }}
                      onClick={handleStartGiving}
                    >
                      Donate to TAL &nbsp;
                      {region && region.currency === "INR" ? (
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          icon={faRupeeSign}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="#fff"
                          size="1x"
                          icon={faDollarSign}
                        />
                      )}
                    </Button>
                  )}
                </div>
              </Box>

              <div>
                {showIcons && (
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchRounded />
                    </div>
                    <InputBase
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onKeyPress={(e) => handleEnterClick(e)}
                    />
                  </div>
                )}
              </div>
            </Hidden>

            {/* </Toolbar> */}
          </div>
        </Container>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      <PaymentsPopUp
        isOpen={isPaymentsPopUpOpen}
        donationFor="tal"
        onClose={() => setIsPaymentsPopUpOpen(false)}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(Topbar);
