const validateKindTimeDonation = (values) => {
  let errors = {};
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity that you would like to donate";
  } else if (values.quantity <= 0) {
    errors.quantity = "Please enter a valid quantity";
  }
  return errors;
};

export default validateKindTimeDonation;
