import React, { useContext, useEffect, useState } from "react";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { deletePlaylist, getPlaylists, updatePlaylist } from "../../utils/api";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Button,
  IconButton,
  Switch,
  TablePagination,
  Typography,
  InputBase,
} from "@material-ui/core";
import firebase from "../../firebase/firebase";
import { appContext } from "../../App";
import { withRouter } from "react-router";
import VideoPlayer from "../common/VideoPlayer";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistForm from "./PlaylistForm";
import ChannelSelectionBox from "./ChannelSelectBox";
import { SearchRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  liveplaylists: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "18px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
    },
  },
}));

function Playlists(props) {
  const { playlists, setPlaylists, channel } = useContext(appContext);
  const { categories, isLoading } = props;
  const [isDisplayPlaylists, setIsDisplayPlaylists] = useState(true);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedChannelItems, setSelectedChannelItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");

  const handlePlaylists = (id) => {
    setIsDisplayPlaylists(false);
    if (id) {
      setIsEnableEdit(true);
      setSelectedId(id);
    }
  };

  const deleteMediaStorageFile = (filePath) => {
    //delete file storage
    var deleteFileRef = firebase.storage.refFromURL(filePath);

    // Delete the file
    deleteFileRef
      .delete()
      .then(function () {
        // File deleted successfully
      })
      .catch(function () {
        //error occurred!
      });
  };

  const confirmDelete = (recordObj) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const recordId = recordObj._id;
      console.log(recordObj);
      getPlaylists(recordId)
        .then((response) => {
          if (response) {
            const storageRef = firebase.storage;
            const ref = storageRef.refFromURL(
              response.data && response.data.thumbnailImageUrl
            );
            storageRef
              .ref(ref.fullPath)
              .delete()
              .then(() => {})
              .catch((error) => {
                toastr.error("Error deleting the thumbnail:" + error.message);
              });
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching Playlist:" + error.message);
        });
      deletePlaylist(recordId)
        .then((delResp) => {
          if (delResp.statusCode === 200) {
            var oldRows = playlists.filter((item) => {
              return item._id !== recordId;
            });
            setPlaylists([...oldRows]);

            try {
              //delete thumbnail
              if (recordObj.thumbnailImageUrl)
                deleteMediaStorageFile(recordObj.thumbnailImageUrl);

              //delete mp3 file
              if (recordObj.downloadUrl)
                deleteMediaStorageFile(recordObj.downloadUrl);
            } catch (e) {
              console.log("Failed to delete the storage file", e.message);
            }

            toastr.success(delResp.message);
          } else {
            toastr.error("Error while deleting the playlist");
          }
        })
        .catch((e) => {
          toastr.error("Failed to delete the playlist", e.message);
        });
    }
  };

  const toggleEditorPick = (event, index, recordId) => {
    const checkedStatus = event.target.checked;
    updatePlaylist(recordId, { editorPick: checkedStatus })
      .then((updateResp) => {
        if (updateResp.statusCode === 200) {
          var filterRows = playlists;
          filterRows[index].editorPick = checkedStatus;
          setPlaylists([...filterRows]);

          toastr.success(updateResp.message);
        } else {
          toastr.error("Error while updating the playliist");
        }
      })
      .catch(() => {
        toastr.error("Editor Pick: Failed to update the playlist");
      });
  };

  const onPlayAudio = (row) => {
    setSelectedRow(row);
    setIsPlayingAudio(true);
  };
  const handlePlayerClose = () => {
    setSelectedRow({});
    setIsPlayingAudio(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPlaylists([]);
  };
  const updateSearchKeyword = (event) => {
    setKeyword(event.target.value);
  };

  useEffect(() => {
    if (playlists && playlists.length > 0) {
      if (keyword) {
        setPage(0);
        setSelectedChannelItems(
          playlists.filter(
            (row) =>
              row.channel === channel &&
              row.title &&
              row.title.toLowerCase().includes(keyword.toLowerCase())
          )
        );
      } else {
        setPage(0);
        setSelectedChannelItems(
          playlists.filter((row) => row.channel === channel)
        );
      }
    }
  }, [keyword, playlists, channel]);

  const classes = useStyles();
  if (!isDisplayPlaylists) {
    return (
      <PlaylistForm
        setIsDisplayPlaylists={setIsDisplayPlaylists}
        categories={categories}
        isEnableEdit={isEnableEdit}
        setIsEnableEdit={setIsEnableEdit}
        selectedId={selectedId}
        deleteMediaStorageFile={deleteMediaStorageFile}
        isPlaylistItemsLoading={props.isPlaylistItemsLoading}
      />
    );
  } else
    return (
      <Box>
        <Box className="drop-box" p={1} mb={1}>
          <Box className={classes.liveplaylists}>
            <Grid container>
              <Grid item xs={12} sm={6} md={9} container>
                <Box display="flex" alignItems="center">
                  <h2>Playlists</h2>
                </Box>

                <Box
                  className="searchbar-dr boder4"
                  whiteSpace="noWrap"
                  style={{ marginLeft: "10px" }}
                >
                  <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
                  <InputBase
                    placeholder="Enter search keyword"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={updateSearchKeyword}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                className="display-flex justify-end"
              >
                <Box className={classes.rightmenu}>
                  {/* <Button
                    variant="contained"
                    onClick={() => props.loadPlaylists()}
                  >
                    Reload Playlist
                  </Button> */}
                  <Button
                    variant="contained"
                    onClick={() => handlePlaylists()}
                    disabled={props.isPlaylistItemsLoading}
                    style={{
                      backgroundColor: props.isPlaylistItemsLoading && "grey",
                    }}
                  >
                    + Add Playlist{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="drop-box" mb={1} p={1}>
          <ChannelSelectionBox />
        </Box>
        <Box className={classes.table_tab}>
          <TableContainer component={Paper}>
            <Table aria-label="all playlists table">
              <TableHead>
                <TableRow>
                  <TableCell>Thumbnail</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Producer</TableCell>
                  <TableCell>RJ</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Audio Length</TableCell>
                  <TableCell>Editors Pick</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedChannelItems &&
                  selectedChannelItems.length > 0 &&
                  selectedChannelItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell>
                          <Button onClick={() => onPlayAudio(row)}>
                            <img
                              src={
                                row.thumbnailImageUrl
                                  ? row.thumbnailImageUrl
                                  : "/images/radio_thumbnail.png"
                              }
                              width="40px"
                              height="40px"
                              title="play this audio"
                              alt="playlist"
                            />
                          </Button>
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.producer}</TableCell>
                        <TableCell>{row.rjUserId}</TableCell>
                        <TableCell>
                          {row.categories &&
                            row.categories.length > 0 &&
                            row.categories.map((cat) => cat.name).join(",")}
                        </TableCell>
                        <TableCell>
                          {row.duration &&
                            row.duration > 0 &&
                            new Date(row.duration * 1000)
                              .toISOString()
                              .substr(11, 8)}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={row.editorPick}
                            name="editorPick"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onClick={(event) =>
                              toggleEditorPick(event, index, row._id)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handlePlaylists(row._id)}
                          >
                            <EditIcon
                              style={{
                                color: "green",
                                padding: "5px",
                                margin: "5px",
                                borderRadius: "50px",
                                fontSize: "30px",
                                boxShadow: "0 0 9px #e8eaf5",
                                backgroundColor: "#ffffff",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => confirmDelete(row)}
                          >
                            <DeleteIcon
                              style={{
                                color: "red",
                                padding: "5px",
                                margin: "5px",
                                borderRadius: "50px",
                                fontSize: "30px",
                                boxShadow: "0 0 9px #e8eaf5",
                                backgroundColor: "#ffffff",
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                {isLoading && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          padding: "10px 0",
                        }}
                      >
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {selectedChannelItems &&
                  selectedChannelItems.length === 0 &&
                  !isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={selectedChannelItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <VideoPlayer
            isOpen={isPlayingAudio}
            onClose={handlePlayerClose}
            name={selectedRow.title}
            url={selectedRow.downloadUrl}
            note={selectedRow.description}
          />
        </Box>
      </Box>
    );
}
export default withRouter(Playlists);
