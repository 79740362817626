import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/database";
import "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    // Initialize Firebase
    app.initializeApp(firebaseConfig);
    this.analytics = app.analytics();
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.realtimeDb = app.database();
    if (app.messaging.isSupported()) {
      this.messaging = app.messaging();
    } else {
      this.messaging = null;
    }
    this.storageDonationRequests = app
      .app()
      .storage(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DONATION_REQUESTS);
  }
}

const firebase = new Firebase();
export default firebase;
