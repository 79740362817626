import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Box from "@material-ui/core/Box";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

function HelpDesk() {
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Header />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Grid container>
          <Box component="h3" mt={2} mb={2}>
            FAQs
          </Box>
          <Grid item md={12}>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                How can I access my account if I forgot the password?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol type="1">
                  <li>Go on Transformers 2023 page</li>
                  <li>Press the “Login” button</li>
                  <li>Press the “Forgot Password” link</li>
                  <li>Enter the e-mail address you used to register for your Transformers-2023 account, and press the “Submit” button. We will send you instructions for password reset to your mail id right away!</li>
                </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How can I update my participant information?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ol type="1">
                    <li> Login to your Transformers-2023 account</li>
                    <li>Select “Participant Profile” from the left bar</li>
                    <li>From this page, you can update all your information</li>
                    <li>Type in your password to confirm changes.</li>
                    <li>
                      Press the “Continue” button at the bottom of the page when
                      you’ve finished updating your information.
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>What is an application form?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                    {" "}
                    An application form is a form with details essential to the program you are applying for, which you have to fill out in order to successfully apply for a program
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>How do I fill out my application form?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                  Once you submit all the details related to Participant Profile, you will observe that there are two buttons. If you click on Learning Labs you would continue to our resources page containing materials related to the program. Press the <strong>Getting Started</strong> button for the application form. Please select the applicable track, your team and mentor, and SDGs your solution caters to. Following this, continue to the application and start filling the application by answering the sections. 
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel15"}
              onChange={handleChange("panel15")}
            >
              <AccordionSummary
                aria-controls="panel15d-content"
                id="panel15d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>How can I add a team member to my application?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p><strong>Please note:</strong> You would only be able to invite team members if the team members have registered for the Transformers-2023 program.</p>
                  <ol type="1">
                    <li>In the <strong>“Getting Started Tab”</strong>, Click on Create Team and Add Members</li>
                    <li>You can give a name to the team and also add additional team members</li>
                    <li>A 5 member team is a good start and the maximum limit for a team. Any exceptions requested will have to be sent to parvathi.v@touchalife.org</li>
                    </ol>

                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Can I edit my application after submitting?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                    {" "}
                    Yes, If the deadline for the program has <strong>NOT</strong> passed, you may
                    edit your application to make changes before <strong>re-submitting</strong>.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How can I edit my application after submitting it?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ol type="1">
                    <li> Go to the <strong>“Status”</strong> tab and press <strong>“Edit”</strong> button</li>

                    <li>Make the changes</li>
                    <li>Re-submit your application</li>
                    <li>
                      Please be advised that you need to make the changes before
                      the deadline, otherwise you won’t be able to re-submit
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  Can I save my application halfway and come back later to
                  finish it?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                    Yes, in order to save your application, you will have to
                    press the <strong>“Save”</strong> button after updating your information. You
                    can login and go to your account page in order to access
                    your application anytime.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel9d-content"
                id="panel9d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  What can I do if the deadline has passed?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>All applications must be submitted before the competition deadline, i.e.Monday 10th July, 2023, post which we cannot accept your application. If you encountered technical problems that prevent you from submitting your application on time you may contact our team at parvathi.v@touchalife.org with a detailed explanation of the issue you had and screenshots related to this issue.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                aria-controls="panel10d-content"
                id="panel10d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  How do i know if my application has been correctly submitted?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                    You can check the status of your application in <strong>“Status Tab”</strong>
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                aria-controls="panel11d-content"
                id="panel11d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Where can I see my results?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>Once the submissions have been judged by our team of experts and academicians, the teams that have successfully reached the finals will be displayed on our website and social media handles, with the teams also being informed through mail and/or call.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default HelpDesk;
