import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Chip, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ImageGallery from "react-image-gallery";
import { Fragment } from "react";
import DocumentRow from "../common/DocumentRow";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class DownloadIdea extends React.Component {
  render() {
    const { document } = this.props;
    const { judges } = this.props;
    const { images } = this.props;
    const { imageDocuments } = this.props;
    const { nonImageDocuments } = this.props;
    const dob = document.user_info.dob;
    const age = moment().diff(document.user_info.dob, "years");

    const pitchDeckVideoUrl = document && document.pitchDeckVideoUrl;
    if (pitchDeckVideoUrl) {
      document.pitchDeckVideoUrl = document.pitchDeckVideoUrl =
        !/^https?:\/\//i.test(document.pitchDeckVideoUrl)
          ? `http://${document.pitchDeckVideoUrl}`
          : document.pitchDeckVideoUrl;
    }

    return (
      <Container>
        <Box>
          <Paper elevation={2} style={{ padding: "10px 30px" }}>
            <Grid component="h4">Idea Title: </Grid>
            <Grid component="h5">{document && document.title}</Grid>
            <Grid component="h4">Submitted for: </Grid>
            <Grid component="p" className="text-ucase">
              {document &&
              document.requested_for ===
                "ou-social-entrepreneurship-pitch-fest-2023"
                ? "Social Entrepreneurship Pitchfest"
                : document && document.requested_for === "talkindnessday"
                ? "TAL Kindness day"
                : document.requested_for}
            </Grid>
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />
            <Box component="h5">Idea Track </Box>
            <Grid component="p">{document && document.request_type}</Grid>
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />
            <Box component="h5">Idea Category </Box>
            <Box component="ol">
              <Grid item md={8} xs={12} className="text-ucase">
                {document.supportedSDGs &&
                  Array.isArray(document.supportedSDGs) && (
                    <>
                      <Grid container style={{ gap: "5px" }}>
                        {document.supportedSDGs.map((data) => {
                          return (
                            <Chip
                              className=" bg-secondary"
                              label={data && data.toString()}
                              style={{ color: "#fff" }}
                              variant="outlined"
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
              </Grid>
            </Box>
            {document &&
              document.request_type === "social entrepreneurship" && (
                <div>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">
                    Which community has a problem and will benefit from your
                    solution
                  </Box>
                  <Box component="p">{document && document.description}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">
                    {" "}
                    Please describe the social problem you have identified on a
                    local or a global issue
                  </Box>
                  <Box component="p">{document && document.socialProblem}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">Proposed Solution</Box>

                  <Box
                    style={{
                      whiteSpace: "pre-line",
                      lineHeight: "1.5",
                    }}
                  >
                    {document && document.solutionProposing}
                  </Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">
                    {" "}
                    select the SDGs your solution supports
                  </Box>
                  <Grid item md={8} xs={12}>
                    <p
                      style={{
                        whiteSpace: "pre-line",
                        lineHeight: "1.5",
                      }}
                    >
                      {document.supportedSDGs &&
                        Array.isArray(document.supportedSDGs) && (
                          <>
                            <Grid container style={{ gap: "5px" }}>
                              {document.supportedSDGs.map((data) => {
                                return (
                                  <Chip
                                    className=" bg-secondary"
                                    label={data && data.toString()}
                                    style={{ color: "#fff" }}
                                    variant="outlined"
                                  />
                                );
                              })}
                            </Grid>
                          </>
                        )}
                    </p>
                  </Grid>

                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5"> Lives it will change</Box>
                  <Box component="p">
                    {" "}
                    {document && document.howManyLivesWillItChange}
                  </Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">Target Impact</Box>
                  <Box component="ul">
                    <Box component="li">
                      {" "}
                      {document && document.targetImpact}
                    </Box>
                  </Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />

                  <Box component="h5">Unique Value Proposition</Box>
                  <Box component="p">
                    {document && document.uniqueValueProposition}
                  </Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5"> Minimum Viable Product </Box>
                  <Box component="p">
                    {document && document.uniqueValueProposition}
                  </Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">Target Partners </Box>
                  <Box component="p">{document && document.targetPartners}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                  <Box component="h5">Cost Structure </Box>
                  <Box component="p">{document && document.costStructure}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />

                  <Box component="h5"> Revenue Streams </Box>
                  <Box component="p">{document && document.revenueStreams}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />

                  <Box component="h5">Potential Risks </Box>
                  <Box component="p">{document && document.potentialRisks}</Box>
                  <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                </div>
              )}
            {document && document.request_type === "social advocacy" && (
              <div>
                <Box component="h5">Addressed Social Problem</Box>
                <Box component="p">{document && document.description}</Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">Social Change impact </Box>
                <Box component="p">
                  {document && document.addressSocialChange}
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">Proposed Solution</Box>

                <Box
                  style={{
                    whiteSpace: "pre-line",
                    lineHeight: "1.5",
                  }}
                >
                  {document && document.solutionSustainableSocialImpact}
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">External Support Needed </Box>
                <Box component="p">{document && document.externalSupport}</Box>

                <Box component="h5">Support Needed from TALTransformers </Box>
                <Box component="p">
                  {document && document.supportFromTALScouts}
                </Box>
              </div>
            )}
            {document && document.request_type === "cash" && (
              <div>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">
                  Purpose of Your Fundraiser ( Describe the social cause you
                  want to address)
                </Box>
                <Box component="p">{document && document.description}</Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">Goal of your fundraiser (Amount)</Box>
                <Box component="p">
                  {" "}
                  {document &&
                  document.units &&
                  document.units.toUpperCase() === "INR" ? (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faRupeeSign}
                    />
                  ) : (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faDollarSign}
                    />
                  )}{" "}
                  {document && document.quantity}
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">Goal of your fundraiser </Box>

                <Box component="p">{document && document.brief}</Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">Benificiary of your fundraiser</Box>
                <Box component="p">
                  {document && document.fundsRecipientName}
                </Box>

                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">
                  {" "}
                  Beneficiary of your fundraiser (Address)
                </Box>
                <Box component="p">
                  {" "}
                  {document &&
                    document.shipping_address &&
                    document.shipping_address.line1}
                  {"  "}
                  {document &&
                    document.shipping_address &&
                    document.shipping_address.line2}
                  {"  "}
                  {document &&
                    document.shipping_address &&
                    document.shipping_address.city}
                  {"  "}
                  {document &&
                    document.shipping_address &&
                    document.shipping_address.state}
                  {"  "}
                  {document &&
                    document.shipping_address &&
                    document.shipping_address.country}
                  {"  "}
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">
                  {" "}
                  Describe how your fundraiser creates a sustainable social
                  impact solution
                </Box>
                <Box component="ul">
                  <Box component="li">
                    {" "}
                    {document && document.solutionSustainableSocialImpact}
                  </Box>
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />

                <Box component="h5">select the SDGs your solution supports</Box>
                <Box component="p">
                  <Grid item md={8} xs={12} className="text-ucase">
                    {document.supportedSDGs &&
                      Array.isArray(document.supportedSDGs) && (
                        <>
                          <Grid container style={{ gap: "5px" }}>
                            {document.supportedSDGs.map((data) => {
                              return (
                                <Chip
                                  className=" bg-secondary"
                                  label={data && data.toString()}
                                  style={{ color: "#fff" }}
                                  variant="outlined"
                                />
                              );
                            })}
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5"> Key Target Metrics </Box>
                <Box component="p">{document && document.keyTargetMetrics}</Box>
                <Divider style={{ width: "100%", backgroundColor: "#000" }} />
                <Box component="h5">
                  what support do you need from TALTransformers?
                </Box>
                <Box component="p">
                  {document && document.supportFromTALScouts}
                </Box>
              </div>
            )}
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />
            <Box component="h5">Pitch Deck </Box>
            <Box component="p">
              <Grid item md={8} xs={12}>
                <ImageGallery
                  showBullets={false}
                  defaultImage={
                    document && document.request_type === "cash"
                      ? document.defaultImageUrl
                      : "/images/default-donation-request.jpg"
                  }
                  showThumbnails={false}
                  lazyLoad={true}
                  showPlayButton={false}
                  items={images}
                  showFullscreenButton={false}
                />
              </Grid>

              <Divider style={{ width: "100%", marginBottom: "15px" }} />
              <Grid item md={4} xs={12}>
                <p className="p100"> Documents </p>
              </Grid>
              <Grid item md={8} xs={12} className="text-ucase">
                {((imageDocuments && imageDocuments.length > 0) ||
                  (nonImageDocuments && nonImageDocuments.length > 0)) && (
                  <Box>
                    <div class="inter-links">
                      <span id="documents"></span>
                    </div>

                    {imageDocuments &&
                      (imageDocuments.length > 0 ||
                        nonImageDocuments.length > 0) && (
                        <Fragment>
                          <Typography className="donation-header">
                            Supporting Documents:
                          </Typography>
                          {imageDocuments.map((document, index) => {
                            return (
                              <Fragment key={index}>
                                <figure
                                  style={{
                                    display: "grid",
                                    placeContent: "center",
                                    background: " #fafafa",
                                    justifyContent: "start",
                                  }}
                                >
                                  <a
                                    target="_blank"
                                    href={document && document.url}
                                  >
                                    <img
                                      style={{
                                        maxHeight: "100px",
                                      }}
                                      alt={document && document.userGivenName}
                                      src={document && document.url}
                                    ></img>
                                  </a>
                                </figure>
                              </Fragment>
                            );
                          })}
                          {nonImageDocuments.length > 0 && (
                            <Grid container>
                              <Table celled striped>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Note</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {nonImageDocuments.map((document, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <DocumentRow
                                          key={index}
                                          document={document}
                                          canEdit={false}
                                        />
                                      </Fragment>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Grid>
                          )}
                        </Fragment>
                      )}
                  </Box>
                )}
              </Grid>
            </Box>
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />

            <Box component="h5"> Video Pitch : </Box>
            <Box component="p">
              <Link
                target="_blank"
                href={pitchDeckVideoUrl}
                style={{ color: "rgb(0, 127, 255)" }}
              >
                {pitchDeckVideoUrl}
              </Link>
            </Box>

            <Divider style={{ width: "100%", backgroundColor: "#000" }} />

            <Box component="h5"> Team Details </Box>
            <Box component="p">
              {" "}
              {document && document.teamId && document.teamId.name}
            </Box>
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />
            <Box component="h5">Participant Proﬁle</Box>
            <Grid container>
              <Grid item xs={6}>
                Name:
              </Grid>
              <Grid item xs={6}>
                {document &&
                  document.user_info &&
                  document.user_info.name &&
                  document.user_info.name.first_name}{" "}
                {""}
                {document &&
                  document.user_info &&
                  document.user_info.name &&
                  document.user_info.name.last_name}
              </Grid>
              <Grid item xs={6}>
                Age:{" "}
              </Grid>
              <Grid item xs={6}>
                {age} {""}
              </Grid>
              <Grid item xs={6}>
                institutionName{" "}
              </Grid>
              <Grid item xs={6}>
                {document &&
                  document.user_info &&
                  document.user_info.institutionName}{" "}
                {""}
              </Grid>
              <Grid item xs={6}>
                Class:{" "}
              </Grid>
              <Grid item xs={6}>
                {document &&
                  document.user_info &&
                  document.user_info.educationalQualification}
              </Grid>
              <Grid item xs={6}>
                {" "}
                Location
              </Grid>
              <Grid item xs={6}>
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.city}
                {","}
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.country}
                {","}
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.line1}
                {","}
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.line2}
                {","}
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.state}
                {","}
                {document &&
                  document.user_info &&
                  document.user_info.address &&
                  document.user_info.address.zip_code}
              </Grid>
            </Grid>
            <Divider style={{ width: "100%", backgroundColor: "#000" }} />
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "#f35a57" }}>
                      Rating & Review
                    </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="col"
                    style={{ color: "#f35a57" }}
                  >
                    Judges
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="col"
                    style={{ color: "#f35a57" }}
                  >
                    Ratings
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="col"
                    style={{ color: "#f35a57" }}
                  >
                    Notes
                  </TableCell>
                </TableRow>
                <TableBody>
                  {judges &&
                    judges.length > 0 &&
                    judges.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell>
                            {row &&
                              row.judgeId &&
                              row.judgeId.name &&
                              row.judgeId.name.first_name}
                            {"    "}
                            {row &&
                              row.judgeId &&
                              row.judgeId.name &&
                              row.judgeId.name.last_name}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {row.ratings &&
                              Object.values(row.ratings).reduce(
                                (a, b) => parseInt(a, 10) + parseInt(b, 10),
                                0
                              )}
                          </TableCell>
                          <TableCell> {row.note}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: "10px" }}>
              Average Rating:{document && document.avgRating}
            </div>
          </Paper>
        </Box>
      </Container>
    );
  }
}

export default DownloadIdea;
