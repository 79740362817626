import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import "./Faq.scss";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#eee",
    margin: "10px",
  },
  heading: {
    font: "600 14px Roboto",
    flexBasis: "90%",
    flexShrink: 0,
  },
  answers: {
    font: "400 14px Roboto",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function FAQ(props) {
  const classes = useStyles();
  const [generalExpanded, setGeneralExpanded] = React.useState(1);
  const [donorExpanded, setDonorExpanded] = React.useState(1);
  const [volunteerExpanded, setVolunteerExpanded] = React.useState(1);
  const [doneeExpanded, setDoneeExpanded] = React.useState(1);
  const [accountExpanded, setAccountExpanded] = React.useState(1);
  const [troubleshootExpanded, setTroubleshootExpanded] = React.useState(1);

  const general = [
    {
      index: 1,
      question: "What is TALTransformers? ",
      answer:
        "TALTransformers is a digital marketplace for kindness powered by Touch-A-Life Foundation. TALTransformers supports nonprofits, charities and individuals in need by connecting them with a community of donors that are willing to give from the convenience of their smartphones or computers. ",
    },
    {
      index: 2,
      question: "Can I donate to TALTransformers?",
      answer:
        "Yes! You can either donate as a guest user or a registered user at www.touchalife.org or app.touchalife.org or by downlading our mobile applications",
    },
    {
      index: 3,
      question:
        "I'm interested in partnering with TALTransformers. How can I get in touch?",
      answer:
        "On occasion, TALTransformers will partner with organizations, institutions, or other entities that are aligned with our interests. If you'd like to explore that sort of partnership, you can reach us at info@touchalife.org. We'll follow up if it feels like a good fit!",
    },
    {
      index: 4,
      question: "How do I create an account?",
      answer:
        "Creating an account in TALTransformers is simple. 1. You can use any of the accounts from Facebook, Google, Twitter or LinkedIn for easy login. 2. You can register a new TAL account with your email address here.",
    },
    {
      index: 5,
      question:
        "How do I ensure my information is protected and not shared to unauthorized sources?",
      answer:
        "Our team has followed strong technical guidelines with industry standard partners, that ensures the safety and security of the TALTransformers users data with complete data encryption, and authentications.",
    },
    {
      index: 6,
      question: "Can I change the email address associated with my account?",
      answer:
        "Unfortunately no! We encourage user to use an email account with regular access as all confirmations are done through email.  ",
    },
    {
      index: 7,
      question: "Which organization is associated with the TALTransformers app?",
      answer:
        "TALTransformers is powered by Touch-A-Life Foundation Inc. a 501(c)(3) non-profit based in San Jose, CA. Learn more at www.touchalife.org",
    },
    {
      index: 8,
      question: "Is TALTransformers free?",
      answer:
        "Yes! TALTransformers is absolutely free with 0% charges from any user. ",
    },
    {
      index: 9,
      question: "I forgot my password, how do I log in?",
      answer:
        "To request a password reset, click the 'Forgot Password?' link on the login page. Then enter the email address associated with your account and we'll send over an OTP to reset your password. ",
    },
    {
      index: 10,
      question:
        "My email address is not recognized when I try to reset my password? ",
      answer:
        "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account. If you’re certain that you’re entering the correct email, it could be that the email address entered may have had a typo or a variant spelling. In this case, you’ll need to get in touch with us to answer a few security questions in order for us to locate your TALTransformers account. Please contact our Support team and we’ll be happy to help you with this process as quickly as possible. Please have the details of your most recent pledge handy before contacting us. ",
    },
  ];
  const donor = [
    {
      index: 1,
      question: "How do I donate?",
      answer:
        "Firstly, make sure that you’re logged in to a TALTransformers account. If you don’t have one you can set one up here. Alternatively, you can also donate to TALTransformers as a guest user and we will complete the request with your donation. ",
    },
    {
      index: 2,
      question: "Can I support more than one cause?",
      answer: "Yes, of course. You may support as many causes as you wish.",
    },
    {
      index: 3,
      question:
        "Is it mandatory to register as a user to donate or can I donate being a guest?",
      answer:
        "If you want to donate for a specific request or cause submitted by a user, you have to register in TALTransformers. Nevertheless, you can donate as a guest user for a global cause at TALTransformers directly here.",
    },
    {
      index: 4,
      question: "How secure is it to donate in TALTransformers?",
      answer:
        "Our payment partners, Stripe and PayPal, meet and exceed the most stringent industry standards for security. You can learn more about the technical details of Stripe's secure infrastructure here. Stripe (https://stripe.com/docs/security) and PayPal (https://www.paypal.com/re/webapps/mpp/paypal-safety-and-security) TALTransformers does not store any card or payment details. For more information, please review our Privacy Policy.",
    },
    {
      index: 5,
      question: "Is there a limit for donations?",
      answer:
        "We believe the concept of 'every dollar counts' and the minimum donation is $1.",
    },
    {
      index: 6,
      question: "What forms of payment can I use to make a donation?",
      answer:
        "We try to be flexible, so we currently accept credit/debit cards, Netbanking, PayPal, Apple Pay.",
    },
    {
      index: 7,
      question: "Will I get a receipt for my donation?",
      answer:
        "Yes! You will receive a receipt immediately after successful completion of the payment via. email. You can also download the same in your TALTransformers profile.",
    },
    {
      index: 8,
      question:
        "If I make a donation, what information does the donee receive?",
      answer:
        "If you’re donating from TAL account, the donees will see your profile name, and donated amount. When donated as guest user, the donee will not receive any information. ",
    },
    {
      index: 9,
      question: "Can I donate to International requests?",
      answer:
        "Yes! Anyone can donate to the requests in TALTransformers, from anywhere in the world. The only requirement is International cards.",
    },
  ];
  const donee = [
    {
      index: 1,
      question: "What can I raise money for?",
      answer:
        "You can raise funds for varied causes through TALTransformers. ie., Animals, Arts, Culture, Humanities, Community Development, Education, Environment, Health, Human Services, Food Banks, Food Pantries, and Food Distribution, Homeless Services, Rescue Missions, Development and Relief Services, Humanitarian Relief Supplies, Research and Public Policy Human and Civil Rights.",
    },
    {
      index: 2,
      question: "How do I cancel my request?",
      answer:
        "You can write to us in the 'Support' section from a registered account. Remember, we verify the user and the authentication of the email, and take an action accordingly. Make sure, when you are writing to us, please include your request details or URL. ",
    },
    {
      index: 3,
      question: "What should I consider when setting my funding goal?",
      answer:
        "Consider your donor base and who will be helping to fulfill the request. Remember that you can have multiple requests so focus on projects, events, or single instances to fulfill rather than large overarchiving amounts. ",
    },
    {
      index: 4,
      question: "Are there any deadlines or timelimits for the requests added?",
      answer:
        "No, never. With TALTransformers, there are no deadlines or time limits. Your request will remain live as per the date you choose while submitting the request. ",
    },
    {
      index: 5,
      question: "How do I get donations?",
      answer:
        "TALTransformers provides an easy way to raise money from your friends, family, and online community. Our platform makes it simple to share your request in a variety of ways to bring in donations, track your progress, and post updates to engage the community. ",
    },
    {
      index: 6,
      question: "Can I raise requests for others?",
      answer:
        "Absolutely. With TALTransformers, any user can start a request for other individuals, or organizations or schools etc. ",
    },
    {
      index: 7,
      question: "How will I receive the funds raised?",
      answer:
        "TAL Admins and volunteers work to faciliate donations and fulfilling final deliverables. ",
    },
    {
      index: 8,
      question: "What if I don't reach my target amount/goal?",
      answer:
        "Don't be discouraged. The TAL team is here to offer support in marketing and fulfillment to help you reach your fundraising goals.",
    },
    {
      index: 9,
      question: "Does TALTransformers charge a platform fee for donations? ",
      answer:
        "No! TALTransformers is a 0% platform fee program. We want to ensure that as a Donee you get the most from donations so all operation costs are covered by Touch-A-Life Charter Members. ",
    },
    {
      index: 10,
      question: "Can TALTransformers be used to fundraise for anything?",
      answer:
        "You can raise funds for varied causes through TALTransformers. ie., Animals, Arts, Culture, Humanities, Community Development, Education, Environment, Health, Human Services, Food Banks, Food Pantries, and Food Distribution, Homeless Services, Rescue Missions, Development and Relief Services, Humanitarian Relief Supplies, Research and Public Policy Human and Civil Rights.",
    },
  ];
  const volunteer = [
    {
      index: 1,
      question: "Who can apply for this TALTransformers program?",
      answer: "Any school student is eligible to register for the program.",
    },
    {
      index: 2,
      question:
        "Do I need any qualifications/degree for this TALTransformers Program?",
      answer:
        "It is a voluntary project so educational qualifications are not a priority here, all you need is, to be good with your communication in English which is the major requirement.",
    },
    {
      index: 3,
      question: "Can I find any paid TALTransformers programs with TALTransformers program?",
      answer: "No! TALTransformers Program has no stipend provided.",
    },
    {
      index: 4,
      question: "How do I know that TALTransformers is a legitimate organization?",
      answer:
        "Touch-A-Life Foundation is a registered 501(c)(3) non-profit organization founded on the principle that helping others is good for society.",
    },
    {
      index: 5,
      question: "Will I get a mentorship during this program?",
      answer:
        "Yes, once you are selected and assigned with a project task, you will be assisted with mentor and thorough email support. Additionally after you complete your experience, you will receive a certificate from Touch-A-Life Foundation detailing your scope of work with TALTransformers",
    },
    {
      index: 6,
      question: "How can I apply for this TALTransformers program?",
      answer:
        "You can apply at www.app.touchalife.org at the Become a TALTransformers Section in your profile.",
    },
    {
      index: 7,
      question: "What type of TALTransformers work can I do?",
      answer:
        "Facilitate, Quality check the request, Communicate with the mentor and admins (as required), be proactive, Marketing activities.",
    },
    {
      index: 8,
      question: "Will I get any certificate for being a part of this program?",
      answer:
        "Yes, after completing your experience, you will be getting a certificate from Touch-A-Life Foundation detailing your scope of work with TALTransformers.",
    },
    {
      index: 9,
      question:
        "Is there a minimum number of hours/time I need to TALTransformers for?",
      answer:
        "Yes, you need to TALTransformers for a minimum of 1 hour. You have the flexibility of volunteering on a weekly basis, monthly basis or quarterly basis. Depending on your interests and capabilities, the TAL Scout role's scope will be outlined with you. ",
    },
  ];
  const accountability = [
    {
      index: 1,
      question:
        "What happens if a user breaks TALTransformers rules in submission of requests?",
      answer:
        "Our Trust & Safety team reviews any projects flagged internally or reported by members of the TALTransformers community. They look for violations of any rules, and take the appropriate administrative action according to our Terms of Use. If you’d like to report a project, please use the “Report” button on the request. This will flag the project to our Trust & Safety team, who will perform a full review.",
    },
    {
      index: 2,
      question: "Does TALTransformers issue refunds?",
      answer:
        "TALTransformers is not a store and we do not issue refunds. When you donate a request, you’re supporting and spreading kindness for the donee —and agreeing to go along for the ride of kindness. For more information, please read our Terms of Use.",
    },
    {
      index: 3,
      question: "What does TALTransformers do to protect the community?",
      answer:
        "We have a dedicated Trust & Safety team that monitors the system for suspicious activity. They screen reports sent to us by our community and take action if they find something that does not align with our rules.",
    },
    {
      index: 4,
      question: "What are the fees?",
      answer:
        "TALTransformers is a 0% Platform Fee product. All users have free access to the platform with NO percentage of donations collection from TALTransformers or Touch-A-Life Foundation. ",
    },
  ];
  const troubleshooting = [
    {
      index: 1,
      question:
        "Who do I contact for technical difficulties / feedback on the TALTransformers platform?",
      answer:
        "You can write to us directly with your issues at 'Support' and our team will take necessary action against the same. You can also write us at support@touchalife.org",
    },
    {
      index: 2,
      question: "How can I report something to TALTransformers?",
      answer:
        "You can write to us directly with your issues at 'Support' and our team will take necessary action against the same. You can also write us at support@touchalife.org",
    },
    {
      index: 3,
      question: "How do I report a copyright violation?",
      answer:
        "You can write to us directly with your issues at 'Support' and our team will take necessary action against the same. You can also write us at support@touchalife.org",
    },
  ];

  const handleChange = (panel, type) => (event, isExpanded) => {
    type === "general" && setGeneralExpanded(isExpanded ? panel : false);
    type === "donor" && setDonorExpanded(isExpanded ? panel : false);
    type === "donee" && setDoneeExpanded(isExpanded ? panel : false);
    type === "volunteer" && setVolunteerExpanded(isExpanded ? panel : false);
    type === "accountability" && setAccountExpanded(isExpanded ? panel : false);
    type === "troubleshoot" &&
      setTroubleshootExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="faq-container ">
      <Container maxWidth="lg">
        <div className="faq-title">
          <h2>FAQS</h2>
          <p>
            <hr className="hor-line" />
            Common Questions
            <hr className="hor-line" />
          </p>
        </div>
        <div className="faq-body">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">General</h4>
                {general.map((faq, index) => (
                  <ExpansionPanel
                    expanded={generalExpanded === faq.index}
                    onChange={handleChange(faq.index, "general")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              generalExpanded === faq.index
                                ? "#0088cf"
                                : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {generalExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            generalExpanded === faq.index ? "#0088cf" : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">Donor</h4>
                {donor.map((faq, index) => (
                  <ExpansionPanel
                    expanded={donorExpanded === faq.index}
                    onChange={handleChange(faq.index, "donor")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              donorExpanded === faq.index ? "#0088cf" : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {donorExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            donorExpanded === faq.index ? "#0088cf" : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">Donee</h4>
                {donee.map((faq, index) => (
                  <ExpansionPanel
                    expanded={doneeExpanded === faq.index}
                    onChange={handleChange(faq.index, "donee")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              doneeExpanded === faq.index ? "#0088cf" : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {doneeExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            doneeExpanded === faq.index ? "#0088cf" : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="faq-body">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">TALTransformers (Volunteer Program)</h4>
                {volunteer.map((faq, index) => (
                  <ExpansionPanel
                    expanded={volunteerExpanded === faq.index}
                    onChange={handleChange(faq.index, "volunteer")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              volunteerExpanded === faq.index
                                ? "#0088cf"
                                : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {volunteerExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            volunteerExpanded === faq.index
                              ? "#0088cf"
                              : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">Accountability</h4>
                {accountability.map((faq, index) => (
                  <ExpansionPanel
                    expanded={accountExpanded === faq.index}
                    onChange={handleChange(faq.index, "accountability")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              accountExpanded === faq.index
                                ? "#0088cf"
                                : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {accountExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            accountExpanded === faq.index ? "#0088cf" : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="faq-individual drop-box">
                <h4 align="center">Troubleshooting</h4>
                {troubleshooting.map((faq, index) => (
                  <ExpansionPanel
                    expanded={troubleshootExpanded === faq.index}
                    onChange={handleChange(faq.index, "troubleshoot")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <span
                          style={{
                            color:
                              troubleshootExpanded === faq.index
                                ? "#0088cf"
                                : "#000",
                            font: "800 20px Roboto",
                          }}
                        >
                          {troubleshootExpanded === faq.index ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </span>
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:
                            troubleshootExpanded === faq.index
                              ? "#0088cf"
                              : "#000",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography className={classes.answers}>
                        {faq.answer}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
        <Divider light />
        <div className="faq-footer drop-box">
          <h1>Still can't find what you need?</h1>
          {/* <p>
            <a href="">Contact Us</a>
          </p> */}
          <Button
            onClick={() => {
              props.history.push("/user/feedback");
            }}
          >
            Contact Support
          </Button>
        </div>
      </Container>
    </div>
  );
}
