import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SideMenu from "./SideMenu";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { appContext } from "../../App";
import { HOME_PAGE } from "../../utils/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = (props) => {
  const { authUser } = useContext(appContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  /*top bar new menu*/
  const [drawerstate, setDrawerstate] = React.useState(false);

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerstate({ ...state, left: isopen });
  };

  const logout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("stripeCustomerId");
    window.location = HOME_PAGE;
  };

  /*menu*/
  return (
    <Grid container className="fixed-top">
      <Grid item xs={12} sm={6} md={6} className="headerT">
        <Box className="mobi-menu">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer("left", true)}
              style={{ paddingLeft: "0" }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Drawer
            anchor="left"
            open={drawerstate["left"]}
            onClose={toggleDrawer("left", false)}
          >
            <SideMenu />
          </Drawer>
        </Box>
        <a href={HOME_PAGE} className="logo">
          <img src="../../images/talscouts.svg" alt="Logo" class="img-fluid" />
        </a>
      </Grid>
      {authUser && (
        <Grid item xs={12} sm={6} md={6} className="avat-pic">
          <div className="logout-pic">
            <img
              style={{
                width: "25px",
                border: "2px solid white",
                height: "25px",
                margin: "0px 5px 0px 5px",
                borderRadius: "50%",
              }}
              src={
                authUser.profile_image_url
                  ? authUser.profile_image_url
                  : "/images/default-profile-photo.png"
              }
              alt="User Profile"
              className="user-profile-image"
              height="100%"
            />
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              Hello {authUser && authUser.name && authUser.name.first_name}
              <ExpandMoreIcon />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={logout}>
                          <Link>Logout</Link>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default withRouter(Header);
