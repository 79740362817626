import React, { useState, useEffect, Fragment } from "react";
import toastr from "toastr";
import cuid from "cuid";
import DropzoneInput from "./DropzoneInput";
import firebase from "../../firebase/firebase";
import useFormValidation from "../../hooks/useFormValidation";
import validateAddDocument from "./validateAddDocument";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import DocumentRow from "./DocumentRow";
import CircularProgress from "@material-ui/core/CircularProgress";

// import "./styles.scss";
import "./AddDocument.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDocument = (props) => {
  const initialFormState = {
    name: "",
    note: "",
  };
  const [path, setPath] = useState("");
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const saveOrUploadDocument = () => {
    if (editMode) {
      saveDocument();
    } else {
      uploadDocument();
    }
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(
      initialFormState,
      validateAddDocument,
      saveOrUploadDocument
    );

  useEffect(() => {
    if (props.collection && props.uid) {
      setDocuments([]);
      let path = `${props.collection}/${props.uid}`;
      if (props.subCollection) {
        path = path + "/" + props.subCollection;
      } else {
        path = path + "/documents";
      }
      setPath(path);
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                setDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              })
              .catch((error) => {
                toastr.error(error.message);
              });
          });
        });
      });
    }
  }, [props.collection, props.uid]);

  const saveDocument = () => {
    setIsLoading(true);
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path + "/" + document.name);
    let metadata = {
      customMetadata: {
        userGivenName: values.name,
        note: values.note,
      },
    };
    pathRef
      .updateMetadata(metadata)
      .then((metadata) => {
        setDocuments(
          documents.map((document) => {
            if (document.name === metadata.name) {
              return {
                ...document,
                userGivenName: metadata.customMetadata
                  ? metadata.customMetadata.userGivenName
                  : metadata.name,
                note: metadata.customMetadata && metadata.customMetadata.note,
              };
            } else {
              return document;
            }
          })
        );
        setValues(initialFormState);
        setEditMode(false);
        toastr.success("Document properties are updated successfully.");
        setIsLoading(false);
      })
      .catch(function (error) {
        toastr.error(
          "Problem updating the document properties:" + error.message
        );
        setIsLoading(false);
      });
  };

  async function uploadDocument() {
    if (files && files.length > 0) {
      if (!files[0].name) {
        setFileError("Please select a file to upload");
        return;
      } else {
        setFileError("");
      }
    } else {
      setFileError("Please select a file to upload");
      return;
    }
    let ext = files[0] && files[0].name && files[0].name.split(".").pop();
    setIsLoading(true);
    const name = cuid();
    const fullPath = path + "/" + name + "." + ext;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .put(files[0])
      .then((snapshot) => {
        storageRef
          .child(fullPath)
          .getDownloadURL()
          .then((url) => {
            URL.revokeObjectURL(files[0].preview);
            setFiles([]);
            let metadata = {
              customMetadata: {
                userGivenName: values.name,
                note: values.note,
              },
            };
            pathRef
              .updateMetadata(metadata)
              .then((metadata) => {
                setDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
                setValues(initialFormState);
                props.onSavingDocument(
                  "Document is uploaded successfully",
                  url,
                  fullPath
                );
                setIsLoading(false);
              })
              .catch(function (error) {
                toastr.error(
                  "Problem updating the document properties:" + error.message
                );
                setIsLoading(false);
              });
          });
      })
      .catch((error) => {
        toastr.error(error.message);
        setIsLoading(false);
      });
  }

  const deleteDocument = (name) => {
    const fullPath = path + "/" + name;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .delete()
      .then(() => {
        setDocuments(documents.filter((document) => document.name !== name));
        toastr.success(
          "The document is successfully deleted from the database"
        );
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const editingDocument = (document) => {
    if (document) {
      setDocument(document);
      setEditMode(true);
      setValues({
        name: document.userGivenName ? document.userGivenName : document.name,
        note: document.note,
      });
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      className="upload-cntnr"
    >
      <div className="adddocument">
        <div className="dialog-box">
          <h2 className="image-title drop-box">{props.title}</h2>

          <div className="close-image-popup">
            <IconButton
              // edge="start"
              // color="inherit"

              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="add-image-container">
          {props.canEdit && (
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="part-1">
                    <div className="image child-container">
                      <Typography variant="h4">Step 1 - Select File</Typography>
                      <DropzoneInput
                        setFiles={setFiles}
                        accept={
                          props && props.allowOnlyPdf
                            ? "application/pdf"
                            : "application/pdf,.doc,.docx"
                        }
                        multiple={false}
                        disabled={props.singlePdfOnly && documents.length > 0}
                      />
                    </div>
                    {fileError && (
                      <Typography style={{ color: "var(--tal_primary)" }}>
                        {fileError}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="part-2">
                    <div className="cropper child-container">
                      <Typography variant="h4">Step 2 - Upload</Typography>
                      {files[0] && (
                        <Fragment>
                          <Typography
                            style={{
                              padding: " 5px 0px",
                              background: "#e5e5e5",
                              marginTop: "5px",
                            }}
                          >
                            Selected File {files[0].name}
                          </Typography>
                        </Fragment>
                      )}
                    </div>
                    {props && props.allowOnlyPdf && (
                      <p style={{ color: "red" }}>
                        Please upload .pdf files only
                      </p>
                    )}

                    <div>
                      <TextField
                        required
                        variant="filled"
                        className="input-field"
                        placeholder="Document Name"
                        name="name"
                        value={values.name}
                        onChange={changeHandler}
                        disabled={props.singlePdfOnly && documents.length > 0}
                      />
                      {errors.name && (
                        <Typography style={{ color: "var(--tal_primary)" }}>
                          {errors.name}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <TextField
                        type="text"
                        name="note"
                        className="input-field"
                        placeholder="Add A Note"
                        value={values.note}
                        onChange={changeHandler}
                        disabled={props.singlePdfOnly && documents.length > 0}
                      />
                      {errors.note && (
                        <Typography style={{ color: "red" }}>
                          {errors.note}
                        </Typography>
                      )}
                    </div>
                    <Button
                      className="upload-btn"
                      onClick={submitHandler}
                      disabled={props.singlePdfOnly && documents.length > 0}
                      style={{
                        opacity:
                          props.singlePdfOnly && documents.length > 0 ? 0.5 : 1,
                      }}
                    >
                      {editMode ? "Save" : "Upload"}{" "}
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
          )}
          {documents && documents.length > 0 && (
            <Grid item xs={12} md={12}>
              <div style={{ background: "rgb(248, 248, 248)", margin: "10px" }}>
                <Table celled striped>
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Note</TableCell>
                      {props.canEdit && <TableCell>Edit/Delete</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((document, index) => {
                      return (
                        <DocumentRow
                          key={index}
                          document={document}
                          onDeleteHandler={(e) => deleteDocument(document.name)}
                          onEditHandler={(e) => editingDocument(document)}
                          canEdit={props.canEdit}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AddDocument;
