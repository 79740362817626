const validatePlasmaRequest = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = "Please enter title";
  } else if (values.title && values.title.length > 50) {
    errors.title = "Title cannot be more than 50 characters";
  }
  if (!values.blood) {
    errors.blood = "Please select blood group";
  }
  if (!values.first_name) {
    errors.first_name = "Please enter first name";
  }
  if (!values.last_name) {
    errors.last_name = "Please enter last name";
  }
  if (!values.weight) {
    errors.weight = "Please enter your weight";
  }
  if (!values.covRecover) {
    errors.covRecover = "Please select yes/no";
  }
  // if (!values.gender) {
  //   errors.gender = "Please select gender";
  // }
  return errors;
};
export default validatePlasmaRequest;
