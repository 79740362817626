import React, { useState, useEffect, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SideMenu from "./SideMenu";
import Header from "./Header";
import LearningLabsMedia from "./LearningLabsMedia";
import toastr from "toastr";
import { appContext } from "../../App";
import "../liveStreaming/styles.scss";
import VideoPlayer from "../common/VideoPlayer";
import { getLearningLabs } from "../../utils/api";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function LearningLabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [path, setPath] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [sdgDocs, setSdgDocs] = useState([]);
  const [transformerTalksDocs, setTransformerTalksDocs] = useState([]);
  const [ebookDocs, setEbookDocs] = useState([]);
  const [blogDocs, setBlogDocs] = useState([]);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [learningLabsType, setLearningLabsType] = useState(0);

  const loadMediaDocumentsKeyword = (keyword) => {
    setIsLoading(true);
    getLearningLabs(
      (keyword && keyword.length > 0) || learningLabsType === "all"
        ? ""
        : learningLabsType,

      "",
      "",
      keyword,
      sortOrder
    )
      .then((response) => {
        if (response) {
          setDocuments([...response.data]);
          let sdgsRows = response.data.filter((item) => item.type == 1);
          let transformerTalksRows = response.data.filter(
            (item) => item.type == 2
          );
          let ebooksRows = response.data.filter((item) => item.type == 3);
          let blogsRows = response.data.filter((item) => item.type == 4);
          setSdgDocs(sdgsRows);
          setTransformerTalksDocs(transformerTalksRows);
          setEbookDocs(ebooksRows);
          setBlogDocs(blogsRows);

          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(offset + rowsPerPage);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Podcast:" + error);
      });
  };

  const onPlayVideo = (documents) => {
    setDocument(documents);
    setIsPlayingVideo(true);
  };
  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  useEffect(() => {
    loadMediaDocumentsKeyword(keyword);
  }, [learningLabsType, rowsPerPage, page]);

  useEffect(() => {
    if (keyword && isSearchMode) {
      // setOffset(0);
      setPage(0);
      loadMediaDocumentsKeyword(keyword);
    }
  }, [keyword, isSearchMode]);

  return (
    <>
      <Header />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Grid container>
          <Grid item md={12} xs={12}>
            <Box component="h3" mb={2}>
              Learning Labs
            </Box>
            <div className={classes.root}>
              <AppBar
                position="static"
                color="default"
                className="learning-labs"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="scrollable auto tabs example"
                  style={{ overflowX: "auto" }}
                >
                  {/* <Tab label="SDGs" {...a11yProps(0)} />
                  <Tab label="Transformer Talks" {...a11yProps(1)} /> */}
                  <Tab label="E-Books" {...a11yProps(2)} />
                  <Tab label="Blog" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              {/* <TabPanel value={value} index={0}>
                <Grid container className="le-tabs" spacing={3}>
                  {sdgDocs &&
                    sdgDocs.length > 0 &&
                    sdgDocs.map((document, index) => {
                      return (
                        <LearningLabsMedia
                          key={index}
                          document={document}
                          onPlayHandler={() => onPlayVideo(document)}
                          media={document.mediaUrl}
                          title={document.title}
                          thumbnailUrl={document.thumbnailImageUrl}
                          note={document.description}
                        />
                      );
                    })}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container className="le-tabs" spacing={3}>
                  {transformerTalksDocs &&
                    transformerTalksDocs.length > 0 &&
                    transformerTalksDocs.map((document, index) => {
                      return (
                        <LearningLabsMedia
                          key={index}
                          document={document}
                          title={document.title}
                          onPlayHandler={() => onPlayVideo(document)}
                          media={document.mediaUrl}
                          thumbnailUrl={document.thumbnailImageUrl}
                          note={document.description}
                        />
                      );
                    })}
                </Grid>
              </TabPanel> */}
              <TabPanel value={value} index={0}>
                <Grid container className="le-tabs " spacing={3}>
                  {ebookDocs &&
                    ebookDocs.length > 0 &&
                    ebookDocs
                      //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((document, index) => {
                        return (
                          <LearningLabsMedia
                            key={index}
                            title={document.title}
                            document={document}
                            media={document.mediaUrl}
                            thumbnailUrl={document.thumbnailImageUrl}
                            note={document.description}
                          />
                        );
                      })}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container className="le-tabs " spacing={3}>
                  {blogDocs &&
                    blogDocs.length > 0 &&
                    blogDocs
                      //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((document, index) => {
                        return (
                          <LearningLabsMedia
                            key={index}
                            document={document}
                            title={document.title}
                            media={document.mediaUrl}
                            thumbnailUrl={document.thumbnailImageUrl}
                            note={document.description}
                          />
                        );
                      })}
                </Grid>
              </TabPanel>
            </div>
            <VideoPlayer
              isOpen={isPlayingVideo}
              onClose={onClosePlayVideo}
              name={document && document.title}
              url={document && document.mediaUrl}
              note={document && document.description}
              document={document}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LearningLabs;
