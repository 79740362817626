import validator from "validator";

const validateSignup = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!validator.isEmail(values.email.trim())) {
    errors.email = "Please enter a valid email address.";
  }

  if (!values.first_name) {
    errors.first_name = "Please enter your first name.";
  }

  if (!values.last_name) {
    errors.last_name = "Please enter your last name.";
  }
  if (!values.institutionName) {
    errors.institutionName = "Please enter your Institution name.";
  }
  if (values.institutionUrl && !validator.isURL(values.institutionUrl.trim())) {
    errors.institutionUrl = "Please Enter a Valid URL";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (
    !values.password.match(/[a-z]/) ||
    !values.password.match(/[A-Z]+/) ||
    !values.password.match(/\d/) ||
    !values.password.match(/[^a-zA-Z0-9\-/]/) ||
    values.password.length < 6
  ) {
    errors.password = "The password does not meet all the below requirements";
  }
  if (values?.institutionalRole === "student" && !values.registeredForEvent) {
    errors.registeredForEvent = "Please select an Event";
  }
  return errors;
};

export default validateSignup;
