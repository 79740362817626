import validator from "validator";
const validateOrganization = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Please enter organization name";
  }
  if (!values.type) {
    errors.type = "Please select type";
  }
  if (!values.description) {
    errors.description = "Please enter description";
  }
  if (values.email) {
    if (!validator.isEmail(values.email)) {
      errors.email = "Please enter valid email address";
    }
  }
  if (values.isCSRWing === "") {
    errors.isCSRWing = "Please select CSR Wing option";
  }
  return errors;
};
export default validateOrganization;
