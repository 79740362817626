import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReactHtmlParser from "react-html-parser";
import firebase from "../../firebase/firebase";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import LocationOn from "@material-ui/icons/LocationOn";
import { green } from "@material-ui/core/colors";
import { lighten, withStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FacebookIcon from "@material-ui/icons/Facebook";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import SideMenu from "../SideMenu/Sidemenu";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ShareIcon from "@material-ui/icons/Share";
import DonationRequestUpdate from "./DonationRequestUpdate";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import {
  DATE_FORMAT_FULL_MONTH,
  donationRequestShareUrl,
  getDonationRequestSharedUrl,
  STATUS_EXPRIED,
  TIME_FORMAT,
} from "../../utils/utils";
import ForumIcon from "@material-ui/icons/Forum";
import Chip from "@material-ui/core/Chip";
import { CommentOutlined } from "@material-ui/icons";
import RejectionModal from "../Organization/RejectionModal";
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  Modal,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import {
  getDonationRequest,
  updateDonationRequest,
  deleteDonationRequest,
  getDonorsList,
  CareerHelpShareDetails,
  getDonationRequestResponse,
  updateDonationRequestAnalytics,
  applyDonationRequest,
} from "../../utils/api";
import AddDocument from "../common/AddDocument";
import AddVideo from "../common/AddVideo";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { favourite, likeOrUnlike } from "../../utils/api";
import KindTimeDonation from "../donationDetails/KindTimeDonation";

import { appContext } from "../../App";
import Payments from "../donationDetails/PaymentsPopUp";
import "./DonationRequest.scss";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EmailContactGroupsModal from "./EmailContactGroupsModal";
import Loader from "../common/Loader";
import {
  formatNumber,
  isImage,
  HOME_PAGE,
  DATE_FORMAT,
  DONATION_REQUEST_STATUS_MAP,
  STATUS_NEW,
  STATUS_INCOMPLETE,
  STATUS_SPAM,
  STATUS_REJECTED,
  STATUS_APPROVED,
  getObjectValuesInStringFormat,
  getShortUrl,
} from "../../utils/utils";
import DocumentRow from "../common/DocumentRow";
import StickyBox from "react-sticky-box";
import OfflineDonations from "./OfflineDonationDetails";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

//ALert
import { Alert, AlertTitle } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import DonationRequestForm from "./DonationRequestForm";
import DonationRequestNotLoggedInDialog from "./DonationRequestNotLoggedInDialog";

import ChatRoom from "../ChatRooms/ChatRoom";
import ShareOnSocialMedia from "../share/ShareOnSocialMedia";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Forum from "../ForumConversations/Forum";
import ApplyPost from "../ApplyPost/ApplyPost";
import AddPhotos from "../common/AddPhotos";
import "./heart.scss";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div" className="details-child">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

// table columns sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "userName", numeric: false, label: "Name" },
  { id: "amount", numeric: true, label: "Amount" },
  { id: "donatedDate", numeric: true, label: "Date" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#ccc" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  root: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
    flexGrow: 1,
    gap: "5px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
  modalAnalytic: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: "10px",
    borderRadius: "10px",
    width: "356px",
    height: "368px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },
}));

const DonationRequest = (props) => {
  const [open, setOpen] = useState(false);
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [isKindTimeDonationOpen, setIsKindTimeDonationOpen] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [openIntern, setOpenIntern] = useState(false);
  const [isAppliedScholarship, setIsAppliedScholarship] = useState(false);

  const {
    authUser,
    isAdmin,
    isVolunteer,
    isSuperAdmin,
    orgList,
    regions,
    setSelectedOrganization,
  } = useContext(appContext);
  const audioRef = useRef(null);

  const [id, setId] = useState("");
  const classes = useStyles();
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [startDate, setStartDate] = useState(0);
  const [dueDate, setDueDate] = useState(0);
  const [address, setAddress] = useState("");
  const [values, setValues] = useState(null);
  const [images, setImages] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [donationRequest, setDonationRequest] = useState(null);
  const [active25, setActive25] = useState(false);
  const [active50, setActive50] = useState(false);
  const [active100, setActive100] = useState(false);
  const [active250, setActive250] = useState(false);
  const [active500, setActive500] = useState(false);
  const [activeOther, setActiveOther] = useState(false);
  const [showDonors, setShowDonors] = useState(false);
  const [donatedperc, setDonatedperc] = useState(0);
  const [donAmt, setDonAmt] = useState(0);
  const [shareIcons, setShareIcons] = useState(false);
  const [showLiked, setShowLiked] = useState(false);
  const [showFavorited, setShowFavorited] = useState(false);
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false);
  const [donorsList, setDonorsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("amount");
  const [openOfflineDonationPopUp, setOpenOfflineDonationPopUp] =
    useState(false);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isOpenDonationRequestModal, setIsOpenDonationRequestModal] =
    useState(false);
  const [value, setValue] = React.useState(
    props.location.state && props.location.state.isUpdate
      ? 1
      : props.location.state && props.location.state.isAudioRoom
      ? 2
      : 0
  );
  const [isOrgOwnerOrAdmin, setIsOrgOwnerOrAdmin] = useState(false);

  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [openForum, setOpenForum] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [donationRequestResponse, setDonationRequestResponse] = useState(null);
  const [shortUrl, setShortUrl] = useState("");
  const [showApproveButton, setShowApproveButton] = useState(false);
  const [radioMenu, setRadioMenu] = useState("");
  const galleryRef = useRef(null);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [openAnalyticsModal, setOpenAnalyticsModal] = useState(false);
  const [sharesCount, setSharesCount] = useState(0);
  const [buttonClicks, setButtonClicks] = useState(0);
  const [favouritesCount, setFavouritesCount] = useState(0);

  const handleAnalytics = () => {
    setOpenAnalyticsModal(true);
  };
  const handleAnalyticsClose = () => {
    setOpenAnalyticsModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 2) {
      window.scrollTo({ top: audioRef.current.offsetTop });
    }
  }, [images]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const onClick = () => {
    setRefresh(!refresh);
  };
  const fromRejectModal = () => {
    setRefresh(!refresh);
    setShowApproveButton(true);
  };

  const handleOrganization = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
  };

  const donateButtonHandler = async () => {
    updateDonationRequestAnalytics(
      donationRequest && donationRequest._id,
      "clickbutton"
    )
      .then((response) => {
        setButtonClicks(response.data.noOfButtonClicks);
      })
      .catch((error) => {
        toastr.error(error);
      });
    if (isPreviewMode) {
      return;
    }
    if (authUser && authUser.unique_id) {
      if (donationRequest && donationRequest.request_type === "cash") {
        setIsPaymentsOpen(true);
      } else if (donationRequest && donationRequest.request_type === "career") {
        if (
          authUser &&
          authUser.isMentor &&
          donationRequest.creatorType === "mentee"
        ) {
          setIsLoading(true);
          setIsMailSent(1);
          const participantName = {
            first_name: authUser.name.first_name,
            last_name: authUser.name.last_name,
          };
          let Obj = {
            donation_request_info: donationRequest._id,
            user_info: donationRequest.user_info._id,
            participantName: participantName,
            participantEmailAddress: authUser.email,
            participantPhone: authUser.phone,
            participantGender: authUser.gender,
            address: authUser.address,
          };
          applyDonationRequest(Obj)
            .then((resp) => {
              if (resp.statusCode === 200) {
                setIsMailSent(2);
                setIsLoading(false);
                setDonationRequest &&
                  setDonationRequest({
                    ...donationRequest,
                    isScholarshipApplied: true,
                  });
              }
            })
            .catch((error) => {
              toastr.error(
                error.response &&
                  error.response.data &&
                  error.response.data.message
              );
              setIsMailSent(false);
              setIsLoading(false);
            });
        } else {
          props.history.push("/careerhelp-request");
        }
      } else if (
        donationRequest &&
        (donationRequest.request_type === "internship" ||
          donationRequest.request_type === "homework help" ||
          donationRequest.request_type === "volunteering" ||
          donationRequest.request_type === "scholarship")
      ) {
        setOpenIntern(true);
      } else {
        setIsKindTimeDonationOpen(true);
      }
    } else {
      if (donationRequest && donationRequest.request_type === "cash") {
        setOpenOptions(true);
      } else {
        localStorage.setItem(
          "redirectUri",
          `/donationRequest/${donationRequest._id}`
        );
        props.history.push("/login");
      }
    }
  };

  const saveDonationHandler = (donatedQuantity) => {
    setIsKindTimeDonationOpen(false);
    setIsPaymentsOpen(false);

    setValues((currentValues) => {
      return {
        ...currentValues,
        donatedQuantity:
          currentValues.donatedQuantity + Number(donatedQuantity),
      };
    });
    toastr.success("Thank you for making a contribution.");
  };

  const makeFavourite = () => {
    if (isPreviewMode) {
      return;
    }
    setShowFavorited((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = donationRequest["_id"];
      obj.userId = authUser.unique_id;
      if (showFavorited === false) {
        obj.type = "favourite"; // favourite or unfavourite
      } else if (showFavorited === true) {
        obj.type = "unfavourite";
      }
      favourite(obj);
    } else {
      props.history.push("/login");
      toastr.warning("Please signup or login to complete this action");
      setShowFavorited(donationRequest && donationRequest.isFavourite);
    }
  };

  const handleEdit = () => {
    if (donationRequest && canEdit) {
      const requestType = donationRequest.request_type;
      if (requestType === "cash") {
        props.history.push(`/new-request/${donationRequest._id}`);
      } else if (requestType === "plasma") {
        props.history.push(`/plasma-donee-donor/${donationRequest._id}`);
      } else if (requestType === "career") {
        props.history.push(`/careerhelp-request/${donationRequest._id}`);
      } else if (requestType === "internship") {
        props.history.push(`/internship-request/${donationRequest._id}`);
      } else if (requestType === "volunteering") {
        props.history.push(`/volunteer-request/${donationRequest._id}`);
      } else if (requestType === "help") {
        props.history.push(`/covidhelp-request/${donationRequest._id}`);
      } else if (requestType === "board member") {
        props.history.push(`/boardmember-request/${donationRequest._id}`);
      } else if (requestType === "podcast") {
        props.history.push(`/podcast-request/${donationRequest._id}`);
      } else if (requestType === "homework help") {
        props.history.push(`/homework-request/${donationRequest._id}`);
      } else if (requestType === "scholarship") {
        props.history.push(`/scholarship-request/${donationRequest._id}`);
      }
    }
  };
  const handleLikeDonation = () => {
    if (isPreviewMode) {
      return;
    }
    setShowLiked((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = donationRequest["_id"];
      obj.userId = authUser.unique_id;
      if (showLiked === false) {
        donationRequest.likesCount = donationRequest.likesCount + 1;
        obj.type = "like"; // like or unlike
      } else if (showLiked === true) {
        donationRequest.likesCount = donationRequest.likesCount - 1;
        obj.type = "unlike"; // like or unlike
      }
      likeOrUnlike(obj);
    } else {
      props.history.push("/login");
      toastr.warning("Please signup or login to complete this action");
      setShowLiked(donationRequest && donationRequest.isLike);
    }
  };
  const loadDonationRequest = (donationRequestId) => {
    setIsLoading(true);
    const localStorageDescription = localStorage.getItem(
      "donationRequestDescription"
    );
    const localStorageBrief = localStorage.getItem("donationRequestBrief");
    const localStorageLocation = localStorage.getItem(
      "donationRequestLocation"
    );
    const localStorageJustifyToOthers = localStorage.getItem(
      "donationRequestJustifyToOthers"
    );
    const isPreview = localStorage.getItem("isPreviewMode");
    localStorage.removeItem("donationRequestDescription");
    localStorage.removeItem("donationRequestBrief");
    localStorage.removeItem("donationRequestLocation");
    localStorage.removeItem("donationRequestJustifyToOthers");
    localStorage.removeItem("isPreviewMode");
    if (localStorageDescription || isPreview) {
      setIsPreviewMode(true);
    }
    const localAuthUser = localStorage.getItem("authUser");
    let parsedAuthUser = JSON.parse(localAuthUser);
    getDonationRequest(
      donationRequestId,
      parsedAuthUser && parsedAuthUser.unique_id
    )
      .then((response) => {
        setIsLoading(false);
        loadDonorsList(response._id);
        loadResponses(response._id);
        setDonationRequest(response);
        setId(response._id);
        setIsPrivate(response.isPrivate);
        setDefaultImageUrl(response.defaultImageUrl);
        setStartDate(moment(response.start_date));
        setDueDate(moment(response.due_date));
        setIsAppliedScholarship(response.isScholarshipApplied);
        setSharesCount(response.sharesCount);
        setButtonClicks(response.noOfButtonClicks);
        setFavouritesCount(
          response && response.favourites ? response.favourites.length : 0
        );
        setAddress({
          addressLine1: response.shipping_address.line1,
          addressLine2: response.shipping_address.line2,
          city: localStorageLocation
            ? localStorageLocation
            : response.shipping_address.city,
          region: response.shipping_address.state,
          zip_code: response.shipping_address.zip_code,
          country: response.shipping_address.country,
        });
        let donatedPercentage = 0;
        if ((response.donated_quantity * 100) / response.quantity > 100) {
          donatedPercentage = 100;
        } else {
          donatedPercentage = Math.round(
            (response.donated_quantity * 100) / response.quantity
          );
        }
        setDonatedperc(donatedPercentage);

        setValues((currentValues) => {
          return {
            ...currentValues,
            requestType: response.request_type,
            title: response.title,
            description: localStorageDescription
              ? localStorageDescription
              : response.description,
            brief: localStorageBrief ? localStorageBrief : response.brief,
            quantity: response.quantity,
            unit: response.units,
            donatedQuantity: response.donated_quantity,
            whyYouNeedHelp: localStorageJustifyToOthers
              ? localStorageJustifyToOthers
              : response.whyYouNeedHelp,
            donatedPercentage: donatedPercentage,
            userInfo: response.user_info,
            createdAt: response.createdAt,
            due_date: response.due_date,
          };
        });
        if (response.request_type === "cash") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              cashQuantity: response.quantity,
              cashUnit: response.units && response.units.toUpperCase(),
            };
          });
        } else if (response.request_type === "kind") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              kindQuantity: response.quantity,
              kindUnit: response.units && response.units.toUpperCase(),
            };
          });
        } else if (response.request_type === "help") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              requestedForDetails: response.requestedForDetails,
            };
          });
        } else if (response.request_type === "time") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              timeQuantity: response.quantity,
              timeUnit: response.units,
            };
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.data && error.data.statusCode === 422) {
          toastr.error(error.data.message);
          props.history.push(HOME_PAGE);
        }
      });
  };

  useEffect(() => {
    if (id) {
      fetchDocuments(id);
    }
  }, [id]);

  useEffect(() => {
    if (donationRequest && donationRequest._id) {
      setShowLiked(donationRequest.isLike);
      setShowFavorited(donationRequest.isFavourite);
      fetchImages(donationRequest._id)
        .then(() => {
          fetchVideos(donationRequest._id)
            .then(() => {
              fetchDocuments(donationRequest._id);
            })
            .catch();
        })
        .catch((error) => {
          toastr.error("Problem in fetching images: " + error.message);
        });
      updateDonationRequestAnalytics(donationRequest._id, "view")
        .then((response) => {
          // setViewsCount(response.data.noOfViews);
        })
        .catch((error) => {
          toastr.error(error);
        });
    }
  }, [donationRequest, fetchImages, fetchDocuments]);

  const handleUpdateIsPrivate = () => {
    setIsPrivate(!isPrivate);
    const finalValues = {
      isPrivate: !isPrivate,
      user_id: authUser && authUser.unique_id,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success(
          "Updated donation request to " + (!isPrivate ? " Private" : " Public")
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while updating the donation request. " + error.message
        );
      });
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success(
          "Default profile Image is set for this donation request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the donation request. " +
            error.message
        );
      });
  };

  const submitForApprovalHandler = () => {
    const finalValues = {
      description: values.description,
      user_id: authUser && authUser.unique_id,
      status: 0,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        if (donationRequest.request_type === "help") {
          toastr.success("Covid help request created successfully");
        } else {
          toastr.success("Fundraiser created successfully");
        }
        props.history.push(HOME_PAGE);
      })
      .catch((error) => {
        toastr.error("Error while saving fundraiser: " + error.message);
      });
  };

  const loadDonorsList = (donationRequestId) => {
    setIsLoading(true);
    getDonorsList(donationRequestId)
      .then((response) => {
        setIsLoading(false);
        const detailsArray = [];
        response.data.data.map((details) => {
          const obj = {};
          obj.donationId = details["_id"];
          obj.units =
            details.units && details.units !== ""
              ? details.units.toUpperCase()
              : "";
          obj.amount = details.quantity;
          if (details.anonymous === true) {
            obj.userName = "Anonymous";
          } else {
            if (details.user_info) {
              obj.userName = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
              obj.profileImage = details.user_info.image_url;
            } else if (details.externalUserInfo) {
              obj.userName = details.externalUserInfo.name;
              obj.profileImage = "";
            }
          }
          obj.donatedDate = details.createdAt;
          detailsArray.push(obj);
          return null;
        });
        setDonorsList(detailsArray);
      })
      .catch(() => {
        setIsLoading(false);
        //toastr.error(error);
      });
  };
  const loadResponses = (id) => {
    setIsLoading(true);
    getDonationRequestResponse()
      .then((response) => {
        setDonationRequestResponse(
          response.filter(
            (res) =>
              res &&
              res.donation_request_info &&
              res.donation_request_info._id === id
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(
          "Error getting donation request response:" + error.message
        );
      });
  };

  // Load DonationRequest Object
  useEffect(() => {
    if (props.match.params.id) {
      loadDonationRequest(props.match.params.id);
    }
  }, [props.match.params.id, refresh]);

  useEffect(() => {
    if (
      isSuperAdmin ||
      isAdmin ||
      isVolunteer ||
      (authUser &&
        donationRequest &&
        (donationRequest.status === Number(STATUS_NEW) ||
          donationRequest.status === Number(STATUS_INCOMPLETE)) &&
        donationRequest.user_info &&
        authUser.unique_id === donationRequest.user_info._id)
    ) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    if (authUser && donationRequest) {
      const donateNowButtonClicked = localStorage.getItem(
        "donateNowButtonClicked"
      );
      localStorage.removeItem("donateNowButtonClicked");
      if (donateNowButtonClicked && donateNowButtonClicked === "true") {
        donateButtonHandler();
      }
    }

    if (donationRequest) {
      //get comments count
      const ref = firebase.realtimeDb.ref(
        "/conversations/" + donationRequest._id
      );

      ref.on("value", (snapshot) => {
        const comments = snapshot.val();
        const commentsArray = comments ? comments : [];
        setCommentsCount(
          commentsArray
            ? commentsArray && Object.keys(commentsArray).length
            : "0"
        );
      });
    }
    if (donationRequest) {
      //get comments count
      const ref = firebase.realtimeDb.ref(
        "/forumConversations/" + donationRequest._id
      );

      ref.on("value", (snapshot) => {
        const comments = snapshot.val();
        const commentsArray = comments ? comments : [];
        setQuestionsCount(
          commentsArray
            ? commentsArray && Object.keys(commentsArray).length
            : "0"
        );
      });
    }
  }, [authUser, donationRequest]);

  useEffect(() => {
    if (authUser && donationRequest && donationRequest.orgId) {
      if (authUser.unique_id === donationRequest.orgId.user_info) {
        setIsOrgOwnerOrAdmin(true);
      } else if (orgList && orgList.length > 0) {
        orgList.forEach((org) => {
          if (org.orgId && org.orgId._id === donationRequest.orgId._id) {
            if (org.role === "owner" || org.role === "admin") {
              setIsOrgOwnerOrAdmin(true);
            }
          }
        });
      }
    }
  }, [authUser, donationRequest, orgList]);

  const BorderLinearProgress = withStyles({
    root: {
      height: 12,
      borderRadius: 10,
      backgroundColor: lighten("#E0E0E0", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor:
        donatedperc !== null && donatedperc < 26
          ? "red"
          : donatedperc < 76
          ? "orange"
          : "green",
    },
  })(LinearProgress);

  const fetchImages = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch images
      const path = `DonationRequest/${uid}/images`;
      const storageRef = firebase.storageDonationRequests.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            setImages([]);
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: url,
                      thumbnail: url,
                    },
                  ];
                });
              });
            });
            resolve();
          } else if (
            donationRequest.request_type !== "internship" &&
            donationRequest.request_type !== "volunteering" &&
            donationRequest.request_type !== "homework help" &&
            donationRequest.request_type !== "scholarship"
          ) {
            setImages([
              {
                original: `${
                  donationRequest && donationRequest.defaultImageUrl
                    ? donationRequest.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
                thumbnail: `${
                  donationRequest && donationRequest.defaultImageUrl
                    ? donationRequest.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
              },
            ]);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const fetchVideos = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch videos
      const path = `DonationRequest/${uid}/videos`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: "/images/default-thumbnail.jpeg",
                      thumbnail: "/images/default-thumbnail.jpeg",
                      embedUrl: url,
                      renderItem: _renderVideo.bind(this),
                    },
                  ];
                });
              });
            });
            resolve();
          }
        })
        .catch(() => {});
    });
  };

  const handleSlide = () => {
    const currentIndex = galleryRef.current.getCurrentIndex();
    const slideElement = document.querySelector(
      `.image-gallery-slide:nth-child(${currentIndex + 1})`
    );
    const video = slideElement.querySelector("video");

    if (video) {
      video.pause();
    }
  };

  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const fetchDocuments = (uid) => {
    return new Promise((resolve, reject) => {
      const path = `DonationRequest/${uid}/documents`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        setImageDocuments([]);
        setNonImageDocuments([]);
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                if (isImage(metadata.contentType)) {
                  setImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                } else {
                  setNonImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                }
              })
              .catch(() => {
                //toastr.error(error.message);
              });
          });
        });
        resolve();
      });
    });
  };

  const onCloseAddPhoto = () => {
    fetchImages(id).then(() => {});
    setShowingAddPhotoModal(false);
  };

  const onCloseAddDocument = () => {
    fetchDocuments(id).then(() => {});
    setShowingAddDocumentModal(false);
  };

  const onCloseAddVideo = () => {
    fetchVideos(id).then(() => {});
    setShowingAddVideoModal(false);
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success(message);
  };

  const userProfileHandler = () => {
    if (values && values.userInfo && values.userInfo.username) {
      props.history.push(`/${values.userInfo.username}`);
    }
  };

  const orgProfileHandler = (orgId) => {
    props.history.push(`/organization/${orgId}`);
  };

  const handleDonationAmount = (identifier, amt) => {
    if (identifier !== "other") {
      setDonAmt(amt);
    } else {
      setDonAmt();
    }
    if (identifier === "25") {
      setActive25(!active25);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "50") {
      setActive25(false);
      setActive50(!active50);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "100") {
      setActive25(false);
      setActive50(false);
      setActive100(!active100);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "250") {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(!active250);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "500") {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(!active500);
      setActiveOther(false);
    } else {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(!activeOther);
    }
  };

  const handleOtherAmt = (e) => {
    setActive25(false);
    setActive50(false);
    setActive100(false);
    setActive250(false);
    setActive500(false);
    setActiveOther(true);
    setDonAmt(Number(e.target.value));
  };

  const showDonorsList = () => {
    setShowDonors(!showDonors);
  };

  const openOfflineDonationDetails = () => {
    setOpenOfflineDonationPopUp(!openOfflineDonationPopUp);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateSavedDonationRequest = (updatedDonationRequest) => {
    setIsOpenDonationRequestModal(false);
    loadDonationRequest(updatedDonationRequest._id);
  };

  const handlePlasmaDonate = (req) => {
    const finalValues = {
      donated_quantity: 1,
      user_id: req.user_info._id,
      status: 2,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success("Updated successfully.");
        setRefresh(!refresh);
      })
      .catch((error) => {
        toastr.error(
          "Error while updating the donation request. " + error.message
        );
      });
  };

  const approvalSubmit = () => {
    const finalValues = {
      status: 0,
      user_id: authUser && authUser.unique_id,
    };

    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success("Donation request was approved successfully.");
        setRefresh(!refresh);
      })
      .catch((error) => {
        toastr.error(
          "Error while approving  the donation request. " + error.message
        );
      });
    setShowApproveButton(true);
  };

  const handleDeleteRequest = () => {
    if (window.confirm("Are you sure you want to delete this request?")) {
      deleteDonationRequest(donationRequest._id, authUser.unique_id)
        .then(() => {
          toastr.success("The request is deleted successfully");
          props.history.push("/");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const launchEmailCampaign = () => {
    if (authUser && authUser.unique_id) {
      setSelectedOrganization(null);
      setOpenModal(true);
    } else {
      localStorage.setItem(
        "redirectUri",
        `/donationRequest/${props.match.params.id}`
      );
      toastr.warning("Please signup or login to complete this action.");
      props.history.push("/login");
    }
  };
  const handleCloseGroups = () => {
    setOpenModal(false);
  };

  const handleClickOpen = () => {
    // Get shortUrl from the API for sharing the donation request
    getShortUrl(
      getDonationRequestSharedUrl(donationRequest),
      "donationRequest",
      donationRequest._id
    )
      .then((shortUrl) => {
        setShortUrl(shortUrl);
        setOpen(true);
      })
      .catch((errorMessage) => {
        toastr.error(errorMessage);
      });
    updateDonationRequestAnalytics(donationRequest._id, "share")
      .then((response) => {
        setSharesCount(response.data.shareCount);
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  const handleComments = () => {
    setValue(2);
    setIsCommentsDialogOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {donationRequest && donationRequest.status === -3 && !isAdmin ? (
        <div className="spam-text">
          <i>
            This Fundraiser is reported as <b>{donationRequest.flagReason}</b>.
            If you created this Fundraiser and think it is not spam, please
            email{" "}
            <a href="mailto:support@touchalife.org" className="anchorColor">
              support@touchalife.org
            </a>{" "}
            explaining why you think it is not spam.
          </i>
        </div>
      ) : (
        <div className="don-details-container">
          {/*         
          <Hidden mdUp>
            {" "}
            {authUser && (
              <Button
                onClick={handleCloseSideMenu}
                style={{
                  color: "#ef6c00",
                  minWidth: "50px",
                  height: "50px",
                  borderRadius: "0px 50px 50px 0",
                  marginRight: "10px",
                  background: "white",
                  position: "fixed",
                  zIndex: "3",
                  top: "40%",
                  boxShadow: "0 0 5px #0000003b",
                }}
              >
                {!closeSideMenu && <MenuIcon />}
                {closeSideMenu && <ArrowBackIcon />}
              </Button>
            )}
          </Hidden> */}

          <div className="admin-statuse-requast-main">
            {donationRequest &&
              DONATION_REQUEST_STATUS_MAP[donationRequest.status] &&
              !isPreviewMode && (
                <Collapse in={openAlert}>
                  <Box
                    className="admin-statuse-requast"
                    bgcolor={
                      donationRequest &&
                      DONATION_REQUEST_STATUS_MAP[donationRequest.status]
                        .bgcolor
                    }
                  >
                    <Container maxWidth="lg">
                      <Alert
                        style={{ padding: "0" }}
                        variant="filled"
                        icon={true}
                        severity={" "}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <AlertTitle>
                          {" "}
                          {donationRequest &&
                            DONATION_REQUEST_STATUS_MAP[
                              donationRequest.status
                            ] &&
                            DONATION_REQUEST_STATUS_MAP[donationRequest.status]
                              .label}
                        </AlertTitle>
                      </Alert>
                    </Container>
                  </Box>
                </Collapse>
              )}
          </div>

          <Container maxWidth="lg">
            {donationRequest &&
              !showApproveButton &&
              isOrgOwnerOrAdmin &&
              donationRequest.status == 3 && (
                <div>
                  <div className="btn-v2 approval-Flex  drop-box">
                    <div className="pending1">
                      <p>Pending for Approval</p>
                    </div>
                    <div className="btn-margin">
                      <Button className="approve-btn" onClick={approvalSubmit}>
                        {" "}
                        Approve
                      </Button>
                      <Button
                        className="reject-btn"
                        onClick={handleOrganization}
                      >
                        {" "}
                        Reject
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            <div className="menu-container">
              {closeSideMenu && (
                <Hidden mdUp>
                  {" "}
                  <div style={{ marginRight: "15px", width: "340px" }}>
                    <SideMenu />
                  </div>
                </Hidden>
              )}

              {/* <Hidden smDown> <div style={{marginRight:"15px", width:"340px"  }}><SideMenu /></div></Hidden> */}

              <div style={{ width: "100%" }}>
                <Typography component="div" className="head-container">
                  <div className="spacing_mobile">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8}>
                        <Typography component="div" className="image">
                          <div className="fill-color drop-box">
                            <Typography className="donat-title" component="h2">
                              {values && values.title}{" "}
                              <span className="postTitle-fav">
                                {showFavorited === false ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      className="checkbox"
                                      onClick={() => makeFavourite()}
                                    />
                                    <label for="checkbox">
                                      <Tooltip
                                        title="Add to Favourite"
                                        arrow
                                        placement="top"
                                      >
                                        <svg
                                          className="heart-svg"
                                          viewBox="467 392 58 57"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{ zIndex: "9" }}
                                        >
                                          <g
                                            id="Group"
                                            fill="none"
                                            fill-rule="evenodd"
                                            transform="translate(467 392)"
                                          >
                                            <path
                                              d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                                              id="heart"
                                              fill="#AAB8C2"
                                            />
                                            <circle
                                              id="main-circ"
                                              fill="#E2264D"
                                              opacity="0"
                                              cx="29.5"
                                              cy="29.5"
                                              r="1.5"
                                            />

                                            <g
                                              id="grp7"
                                              opacity="0"
                                              transform="translate(7 6)"
                                            >
                                              <circle
                                                id="oval1"
                                                fill="#9CD8C3"
                                                cx="2"
                                                cy="6"
                                                r="2"
                                              />
                                              <circle
                                                id="oval2"
                                                fill="#8CE8C3"
                                                cx="5"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp6"
                                              opacity="0"
                                              transform="translate(0 28)"
                                            >
                                              <circle
                                                id="oval1"
                                                fill="#CC8EF5"
                                                cx="2"
                                                cy="7"
                                                r="2"
                                              />
                                              <circle
                                                id="oval2"
                                                fill="#91D2FA"
                                                cx="3"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp3"
                                              opacity="0"
                                              transform="translate(52 28)"
                                            >
                                              <circle
                                                id="oval2"
                                                fill="#9CD8C3"
                                                cx="2"
                                                cy="7"
                                                r="2"
                                              />
                                              <circle
                                                id="oval1"
                                                fill="#8CE8C3"
                                                cx="4"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp2"
                                              opacity="0"
                                              transform="translate(44 6)"
                                            >
                                              <circle
                                                id="oval2"
                                                fill="#CC8EF5"
                                                cx="5"
                                                cy="6"
                                                r="2"
                                              />
                                              <circle
                                                id="oval1"
                                                fill="#CC8EF5"
                                                cx="2"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp5"
                                              opacity="0"
                                              transform="translate(14 50)"
                                            >
                                              <circle
                                                id="oval1"
                                                fill="#91D2FA"
                                                cx="6"
                                                cy="5"
                                                r="2"
                                              />
                                              <circle
                                                id="oval2"
                                                fill="#91D2FA"
                                                cx="2"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp4"
                                              opacity="0"
                                              transform="translate(35 50)"
                                            >
                                              <circle
                                                id="oval1"
                                                fill="#F48EA7"
                                                cx="6"
                                                cy="5"
                                                r="2"
                                              />
                                              <circle
                                                id="oval2"
                                                fill="#F48EA7"
                                                cx="2"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>

                                            <g
                                              id="grp1"
                                              opacity="0"
                                              transform="translate(24)"
                                            >
                                              <circle
                                                id="oval1"
                                                fill="#9FC7FA"
                                                cx="2.5"
                                                cy="3"
                                                r="2"
                                              />
                                              <circle
                                                id="oval2"
                                                fill="#9FC7FA"
                                                cx="7.5"
                                                cy="2"
                                                r="2"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </Tooltip>
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      className="checkbox"
                                      checked
                                      onClick={() => makeFavourite()}
                                    />
                                    <label for="checkbox">
                                      <svg
                                        className="heart-svg"
                                        viewBox="467 392 58 57"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="Group"
                                          fill="none"
                                          fill-rule="evenodd"
                                          transform="translate(467 392)"
                                        >
                                          <path
                                            d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                                            id="heart"
                                            fill="#AAB8C2"
                                          />
                                          <circle
                                            id="main-circ"
                                            fill="#E2264D"
                                            opacity="0"
                                            cx="29.5"
                                            cy="29.5"
                                            r="1.5"
                                          />

                                          <g
                                            id="grp7"
                                            opacity="0"
                                            transform="translate(7 6)"
                                          >
                                            <circle
                                              id="oval1"
                                              fill="#9CD8C3"
                                              cx="2"
                                              cy="6"
                                              r="2"
                                            />
                                            <circle
                                              id="oval2"
                                              fill="#8CE8C3"
                                              cx="5"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp6"
                                            opacity="0"
                                            transform="translate(0 28)"
                                          >
                                            <circle
                                              id="oval1"
                                              fill="#CC8EF5"
                                              cx="2"
                                              cy="7"
                                              r="2"
                                            />
                                            <circle
                                              id="oval2"
                                              fill="#91D2FA"
                                              cx="3"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp3"
                                            opacity="0"
                                            transform="translate(52 28)"
                                          >
                                            <circle
                                              id="oval2"
                                              fill="#9CD8C3"
                                              cx="2"
                                              cy="7"
                                              r="2"
                                            />
                                            <circle
                                              id="oval1"
                                              fill="#8CE8C3"
                                              cx="4"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp2"
                                            opacity="0"
                                            transform="translate(44 6)"
                                          >
                                            <circle
                                              id="oval2"
                                              fill="#CC8EF5"
                                              cx="5"
                                              cy="6"
                                              r="2"
                                            />
                                            <circle
                                              id="oval1"
                                              fill="#CC8EF5"
                                              cx="2"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp5"
                                            opacity="0"
                                            transform="translate(14 50)"
                                          >
                                            <circle
                                              id="oval1"
                                              fill="#91D2FA"
                                              cx="6"
                                              cy="5"
                                              r="2"
                                            />
                                            <circle
                                              id="oval2"
                                              fill="#91D2FA"
                                              cx="2"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp4"
                                            opacity="0"
                                            transform="translate(35 50)"
                                          >
                                            <circle
                                              id="oval1"
                                              fill="#F48EA7"
                                              cx="6"
                                              cy="5"
                                              r="2"
                                            />
                                            <circle
                                              id="oval2"
                                              fill="#F48EA7"
                                              cx="2"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>

                                          <g
                                            id="grp1"
                                            opacity="0"
                                            transform="translate(24)"
                                          >
                                            <circle
                                              id="oval1"
                                              fill="#9FC7FA"
                                              cx="2.5"
                                              cy="3"
                                              r="2"
                                            />
                                            <circle
                                              id="oval2"
                                              fill="#9FC7FA"
                                              cx="7.5"
                                              cy="2"
                                              r="2"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </label>
                                  </>
                                )}
                              </span>
                              <CardHeader
                                onClick={
                                  donationRequest &&
                                  donationRequest.orgId &&
                                  donationRequest.fundsRecipient !== "self"
                                    ? () =>
                                        props.history.push(
                                          `/organization/${donationRequest.orgId._id}`
                                        )
                                    : () =>
                                        props.history.push(
                                          `/${donationRequest.user_info.username}`
                                        )
                                }
                                avatar={
                                  <Avatar
                                    aria-label="recipe"
                                    className={classes.avatar}
                                  >
                                    <figure>
                                      <img
                                        src={
                                          donationRequest &&
                                          donationRequest.orgId &&
                                          donationRequest.fundsRecipient !==
                                            "self"
                                            ? (donationRequest &&
                                                donationRequest.orgId &&
                                                donationRequest.orgId
                                                  .defaultImageUrl) ||
                                              "/images/orgLogo.png"
                                            : (donationRequest &&
                                                donationRequest.user_info &&
                                                donationRequest.user_info
                                                  .image_url) ||
                                              "/images/donor.png"
                                        }
                                        alt="user-logo"
                                        style={{
                                          verticalAlign: "middle",
                                          width: "100%",
                                        }}
                                      />
                                    </figure>
                                  </Avatar>
                                }
                                title={
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <span
                                        style={{
                                          fontSize: " 14px",
                                          fontWeight: "600",
                                          color: "grey",
                                          textTransform: "capitalize",
                                          cursor: "pointer",
                                        }}
                                      >
                                        By{" "}
                                        {donationRequest &&
                                        donationRequest.orgId &&
                                        donationRequest.fundsRecipient !==
                                          "self" &&
                                        donationRequest.orgId.orgName
                                          ? donationRequest.orgId.orgName
                                          : donationRequest &&
                                            donationRequest.user_info &&
                                            donationRequest.user_info.name &&
                                            donationRequest.user_info.name
                                              .first_name +
                                              " " +
                                              donationRequest.user_info.name
                                                .last_name}
                                      </span>
                                      {donationRequest &&
                                        donationRequest.orgId &&
                                        donationRequest.orgId.status === 1 && (
                                          <>
                                            <span>
                                              &nbsp;&nbsp;
                                              <VerifiedUser
                                                style={{
                                                  color: "green",
                                                  fontSize: 14,
                                                }}
                                              />
                                            </span>

                                            <div
                                              style={{
                                                color: "#aaa",
                                                font: "400 12px Roboto",
                                              }}
                                            >
                                              <span>
                                                {donationRequest &&
                                                  moment(
                                                    donationRequest.createdAt
                                                  ).format(DATE_FORMAT)}
                                              </span>
                                              <span> | </span>
                                              <span>
                                                {donationRequest &&
                                                  donationRequest.createdAt &&
                                                  moment(
                                                    donationRequest.createdAt
                                                  )
                                                    .startOf("seconds")
                                                    .fromNow()}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </span>
                                }
                                className="postBy"
                              />
                            </Typography>

                            <Typography
                              component="h4"
                              style={{ paddingLeft: 20 }}
                            >
                              {donationRequest &&
                                donationRequest.request_type === "help" &&
                                values &&
                                values.brief}
                            </Typography>

                            <ImageGallery
                              showBullets={false}
                              defaultImage={
                                donationRequest &&
                                donationRequest.request_type === "plasma"
                                  ? donationRequest.defaultImageUrl
                                  : "/images/default-donation-request.jpg"
                              }
                              showThumbnails={false}
                              lazyLoad={true}
                              showPlayButton={false}
                              items={images}
                              showFullscreenButton={false}
                              ref={galleryRef}
                              onBeforeSlide={handleSlide}
                            />

                            <div class="inter-links">
                              <span id="comments"></span>
                            </div>
                            <div className="req-date">
                              <div className="category">
                                <Box component="div">
                                  <Box
                                    component="span"
                                    fontWeight="bold"
                                    color=" var(--tal_primary)"
                                  >
                                    Id:{" "}
                                  </Box>
                                  {donationRequest && donationRequest._id && (
                                    <span className="req-name">
                                      <label>{donationRequest._id}</label>
                                    </span>
                                  )}
                                </Box>

                                {donationRequest &&
                                  donationRequest.categoryId && (
                                    <Box component="div">
                                      <Box
                                        component="span"
                                        fontWeight="bold"
                                        color=" var(--tal_primary)"
                                      >
                                        Category:{" "}
                                      </Box>

                                      <span className="req-name">
                                        <Chip
                                          label={
                                            donationRequest.categoryId.name
                                          }
                                          onClick={handleClick}
                                        />
                                      </span>
                                    </Box>
                                  )}
                                {donationRequest &&
                                  donationRequest.categoryId &&
                                  donationRequest.categoryId.tags &&
                                  donationRequest.categoryId.tags.length >
                                    0 && (
                                    <Box component="div" display="flex" my={1}>
                                      <Box
                                        component="span"
                                        fontWeight="bold"
                                        color=" var(--tal_primary)"
                                        alignSelf="center"
                                      >
                                        Tags:{" "}
                                      </Box>
                                      <Box
                                        component="div"
                                        className={classes.root}
                                      >
                                        {donationRequest.categoryId.tags.map(
                                          (tag) => (
                                            <Chip
                                              label={tag}
                                              onClick={handleClick}
                                            />
                                          )
                                        )}
                                      </Box>
                                    </Box>
                                  )}

                                <div className="shear-btn">
                                  <div
                                    style={{
                                      margin: "17px 0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {values &&
                                      moment(values.createdAt).format(
                                        DATE_FORMAT
                                      )}
                                  </div>
                                </div>
                                <div className="details-share-btn">
                                  <div className="icon-btn">
                                    <Button color="primary">
                                      <IconButton
                                        className="action-btn"
                                        aria-label="add to favorites"
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                        }}
                                        onClick={handleLikeDonation}
                                      >
                                        <ThumbUpOutlinedIcon
                                          style={{
                                            color: showLiked
                                              ? "#f35a57"
                                              : "#666",
                                          }}
                                        />
                                        <span
                                          style={{
                                            font: "500 11px Roboto",
                                            marginLeft: 4,
                                            color: showLiked
                                              ? "#f35a57"
                                              : "#666",
                                          }}
                                        >
                                          Like (
                                          {donationRequest &&
                                            donationRequest.likesCount}
                                          )
                                        </span>
                                      </IconButton>
                                    </Button>
                                    <Tooltip title="COMMENTS">
                                      <Button
                                        onClick={() => setOpenComments(true)}
                                      >
                                        <IconButton className="action-btn">
                                          <ForumIcon />

                                          <span
                                            style={{
                                              font: "500 11px Roboto",
                                              marginLeft: 4,
                                              position: "relative",
                                              textTransform: "capitalize",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                          >
                                            <a
                                              href="#comments"
                                              className="comments_link"
                                              style={{
                                                color: "#666",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              {`Comments ( ${
                                                commentsCount > 0
                                                  ? commentsCount
                                                  : 0
                                              } )`}
                                            </a>
                                          </span>
                                        </IconButton>
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Forums">
                                      <Button
                                        onClick={() => setOpenForum(true)}
                                      >
                                        <IconButton className="action-btn">
                                          <CommentOutlined />

                                          <span
                                            style={{
                                              font: "500 11px Roboto",
                                              marginLeft: 4,
                                              position: "relative",
                                              textTransform: "capitalize",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                          >
                                            <a
                                              href="#comments"
                                              className="comments_link"
                                              style={{
                                                color: "#666",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              {`Forums ( ${questionsCount} )`}
                                            </a>
                                          </span>
                                        </IconButton>
                                      </Button>
                                    </Tooltip>
                                    {donationRequest && (
                                      <Forum
                                        openForum={openComments}
                                        commentType={"comment"}
                                        onClose={() => setOpenComments(false)}
                                        path={
                                          "/conversations/" +
                                          donationRequest._id
                                        }
                                      />
                                    )}
                                    {donationRequest && (
                                      <Forum
                                        openForum={openForum}
                                        commentType={"forum"}
                                        onClose={() => setOpenForum(false)}
                                        path={
                                          "/forumConversations/" +
                                          donationRequest._id
                                        }
                                      />
                                    )}

                                    <div
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                        padding: "0px 15px 0 0",
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                      }}
                                      onMouseOver={() => setShareIcons(true)}
                                      className="action-btn"
                                    >
                                      <Tooltip title="Share request">
                                        <Button
                                          color="primary"
                                          onClick={handleClickOpen}
                                        >
                                          <IconButton
                                            aria-label="share"
                                            className="share-req-icon"
                                          >
                                            <ShareIcon />
                                            <span
                                              style={{
                                                font: "500 11px Roboto",
                                                marginLeft: 4,
                                                position: "relative",
                                                pointerEvents: isPreviewMode
                                                  ? "none"
                                                  : "auto",
                                              }}
                                            >
                                              Share
                                              {/* (
                                                {donationRequest &&
                                                  donationRequest.sharesCount}
                                                ) */}
                                            </span>
                                          </IconButton>
                                        </Button>
                                      </Tooltip>

                                      {donationRequest && (
                                        <ShareOnSocialMedia
                                          open={open}
                                          handleClose={() => setOpen(false)}
                                          url={shortUrl}
                                          quote={donationRequest.title}
                                          subject={`[Touch-A-Life] Have you seen the ${
                                            donationRequest.request_type ===
                                            "cash"
                                              ? "fundraiser"
                                              : "request"
                                          } "${donationRequest.title}"?`}
                                          content={`Hello,<br /><br />I thought you might be interested in supporting this ${
                                            donationRequest.request_type
                                          } request:<br /> ${shortUrl} <br /><br /><img width="400" id=${
                                            donationRequest.name
                                          } src=${
                                            donationRequest.defaultImageUrl
                                          }/><br/><br /> ${
                                            donationRequest.request_type ===
                                            "cash"
                                              ? " Even a small donation could help and reach their fundraising goal. And if you can't make a donation, it would be great if you could share the fundraiser to help spread the word. Please contribute to the fundraiser that I am sharing with you."
                                              : ""
                                          } <br /><br /> Thanks, <br />Touch-A-Life Foundation`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {donationRequest &&
                                    ((authUser &&
                                      !donationRequest.orgId &&
                                      donationRequest.user_info._id ===
                                        authUser.unique_id) ||
                                      (donationRequest.orgId &&
                                        isOrgOwnerOrAdmin) ||
                                      isAdmin) && (
                                      <div>
                                        <Tooltip title="Insights">
                                          <IconButton
                                            className="details-action-btn Insight-btn "
                                            aria-label="add to favorites"
                                            style={{
                                              marginRight: "7px",
                                              marginBottom: "7px",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={handleAnalytics}
                                          >
                                            <img
                                              src="/images/bar-chart-2.svg"
                                              alt="Insights"
                                            />
                                            <span className="details-action-Text">
                                              Insights
                                            </span>
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>

                            <Typography
                              component="div"
                              className="details-child"
                              marginTop="10px"
                            >
                              <Typography component="div">
                                <Typography className="adding-container">
                                  <div className="donationrequst-edit">
                                    {canEdit && (
                                      <div>
                                        <Typography>
                                          <Tooltip title="Edit Request">
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                pointerEvents: isPreviewMode
                                                  ? "none"
                                                  : "auto",
                                              }}
                                              onClick={() => handleEdit()}
                                            >
                                              <EditOutlinedIcon
                                                style={{ fontSize: 36 }}
                                              />
                                            </span>
                                          </Tooltip>
                                          <DonationRequestForm
                                            donationRequestId={
                                              donationRequest &&
                                              donationRequest._id
                                            }
                                            open={isOpenDonationRequestModal}
                                            close={() =>
                                              setIsOpenDonationRequestModal(
                                                false
                                              )
                                            }
                                            onSave={updateSavedDonationRequest}
                                          />
                                        </Typography>
                                      </div>
                                    )}

                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Images">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddPhotoModal(true)
                                            }
                                          >
                                            <PhotoCameraOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddPhotos
                                          title={
                                            canEdit
                                              ? "Add Images"
                                              : "View Images"
                                          }
                                          isOpen={showingAddPhotoModal}
                                          onClose={onCloseAddPhoto}
                                          uid={id}
                                          collection="DonationRequest"
                                          profilephotoURL={defaultImageUrl}
                                          onProfilephotoURLChange={
                                            updateDefaultImageUrl
                                          }
                                          canEdit={canEdit}
                                          multiple={true}
                                        />
                                      </Typography>
                                    </div>

                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Supporting Documents">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddDocumentModal(true)
                                            }
                                          >
                                            <DescriptionOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddDocument
                                          title={
                                            canEdit
                                              ? "Add Supporting Documents"
                                              : "View Supporting Documents"
                                          }
                                          isOpen={showingAddDocumentModal}
                                          onClose={onCloseAddDocument}
                                          uid={id}
                                          collection="DonationRequest"
                                          onSavingDocument={onSavingDocument}
                                          canEdit={canEdit}
                                        />
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Videos">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddVideoModal(true)
                                            }
                                          >
                                            <VideocamOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddVideo
                                          title={
                                            canEdit
                                              ? "Add Videos"
                                              : "View Videos"
                                          }
                                          isOpen={showingAddVideoModal}
                                          onClose={onCloseAddVideo}
                                          uid={id}
                                          collection="DonationRequest"
                                          onSavingDocument={onSavingVideo}
                                          canEdit={canEdit}
                                        />
                                      </Typography>
                                    </div>
                                    {donationRequest &&
                                      values &&
                                      canEdit &&
                                      (donationRequest.status ===
                                        Number(STATUS_NEW) ||
                                        donationRequest.status ===
                                          Number(STATUS_INCOMPLETE)) &&
                                      (donationRequest.donated_quantity === 0 ||
                                        values.requestType ===
                                          "scholarship") && (
                                        <div>
                                          <Typography>
                                            <Tooltip title="Delete Request">
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  pointerEvents: isPreviewMode
                                                    ? "none"
                                                    : "auto",
                                                }}
                                                onClick={handleDeleteRequest}
                                              >
                                                <DeleteForeverIcon
                                                  style={{ fontSize: 36 }}
                                                />
                                              </span>
                                            </Tooltip>
                                          </Typography>
                                        </div>
                                      )}
                                  </div>
                                </Typography>
                                <Typography
                                  className="tab_space"
                                  ref={audioRef}
                                >
                                  <AppBar
                                    position="static"
                                    style={{
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <Tabs
                                      value={value}
                                      onChange={handleChange}
                                      aria-label="simple tabs example"
                                    >
                                      <Tab label="Details" {...a11yProps(0)} />
                                      <Tab
                                        label={`Updates ( ${
                                          donationRequest &&
                                          donationRequest.totalUpdatesCount
                                            ? donationRequest.totalUpdatesCount
                                            : 0
                                        } )`}
                                        {...a11yProps(1)}
                                      />
                                      {/* <Tab
                                        // style={{ color: "#fff" }}
                                        label={`Comments ( ${
                                          commentsCount > 0 ? commentsCount : 0
                                        } )`}
                                        {...a11yProps(2)}
                                        onClick={() =>
                                          setIsCommentsDialogOpen(true)
                                        }
                                      /> */}
                                      {donationRequest &&
                                        donationRequest.user_info._id && (
                                          <Tab
                                            // style={{ color: "#fff" }}
                                            label={`Audio Rooms`}
                                            {...a11yProps(3)}
                                          />
                                        )}
                                    </Tabs>
                                  </AppBar>
                                  <TabPanel
                                    component="div"
                                    value={value}
                                    index={0}
                                    style={{ padding: 0 }}
                                  >
                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "cash" && (
                                        <Typography>
                                          <Typography
                                            className="donation-header"
                                            style={{
                                              paddingTop: "10px",
                                            }}
                                          >
                                            Why you should help me?{" "}
                                          </Typography>
                                          {values && values.whyYouNeedHelp && (
                                            <TextareaAutosize
                                              name="whyYouNeedHelp"
                                              variant="outlined"
                                              multiline
                                              disabled
                                              // rows="4"
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                resize: "none",
                                                background: "transparent",
                                              }}
                                              className={
                                                "input-field request-title justifyToOthers"
                                              }
                                              label="Justify why you need help from others"
                                              defaultValue={
                                                values && values.whyYouNeedHelp
                                              }
                                            />
                                          )}

                                          <Typography className="donation-text">
                                            {donationRequest &&
                                              donationRequest.request_type ===
                                                "plasma" &&
                                              donationRequest.creatorType ===
                                                "donee" &&
                                              values &&
                                              values.brief}
                                          </Typography>

                                          <Typography className="donation-header">
                                            Description:{" "}
                                          </Typography>
                                          <Typography className="donation-text">
                                            {values && values.description
                                              ? ReactHtmlParser(
                                                  values.description
                                                )
                                              : donationRequest &&
                                                donationRequest.request_type ===
                                                  "plasma" &&
                                                donationRequest.creatorType ===
                                                  "donee" &&
                                                donationRequest &&
                                                ReactHtmlParser(
                                                  donationRequest.description
                                                )}
                                          </Typography>
                                        </Typography>
                                      )}
                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "career" && (
                                        <>
                                          <Typography className="donation-header">
                                            Description:
                                          </Typography>
                                          <Typography className="donation-text">
                                            {ReactHtmlParser(
                                              values && values.description
                                            )}
                                          </Typography>
                                        </>
                                      )}
                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "help" && (
                                        <Typography>
                                          <Typography className="donation-header">
                                            Covid Help Type:{" "}
                                          </Typography>
                                          <Typography className="donation-text">
                                            {values &&
                                              values.requestedFor &&
                                              values.requestedFor.replace(
                                                /\b\w/g,
                                                function (l) {
                                                  return l.toUpperCase();
                                                }
                                              )}
                                          </Typography>
                                          <Typography className="donation-header">
                                            Requirements:{" "}
                                          </Typography>
                                          <Typography className="donation-text">
                                            {values &&
                                              values.requestedForDetails}
                                          </Typography>
                                        </Typography>
                                      )}
                                    {donationRequest &&
                                      (donationRequest.request_type ===
                                        "plasma" ||
                                        donationRequest.request_type ===
                                          "career") && (
                                        <Typography>
                                          <Typography
                                            className="donation-header"
                                            style={{
                                              paddingTop: "10px",
                                            }}
                                          >
                                            Brief{" "}
                                          </Typography>
                                          <Typography className="donation-text">
                                            {ReactHtmlParser(
                                              values && values.brief
                                            )}
                                          </Typography>
                                        </Typography>
                                      )}
                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "career" && (
                                        <Typography className="donation-header">
                                          Categories Interested
                                        </Typography>
                                      )}
                                    {donationRequest &&
                                      (donationRequest.request_type ===
                                        "internship" ||
                                        donationRequest.request_type ===
                                          "volunteering") && (
                                        <>
                                          <Grid
                                            className={classes.root}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                            container
                                          >
                                            {donationRequest.description && (
                                              <>
                                                {" "}
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      paddingTop: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Description
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    {ReactHtmlParser(
                                                      donationRequest.description
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}

                                            <Grid container spacing={3}>
                                              {donationRequest.fundsRecipient && (
                                                <>
                                                  <Grid item sm={3} xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Organization
                                                    </Typography>
                                                    {donationRequest && (
                                                      <Typography
                                                        className="donation-text"
                                                        style={{
                                                          fontSize: "16px",
                                                          fontWeight: "regular",
                                                        }}
                                                      >
                                                        {donationRequest.fundsRecipient ===
                                                        "self"
                                                          ? "Self"
                                                          : donationRequest.orgId &&
                                                            donationRequest
                                                              .orgId.orgName
                                                          ? donationRequest.orgId &&
                                                            donationRequest
                                                              .orgId.orgName
                                                          : donationRequest.fundsRecipient}
                                                      </Typography>
                                                    )}
                                                  </Grid>
                                                </>
                                              )}

                                              {donationRequest.start_date && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Start Date
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {moment(
                                                      donationRequest.start_date
                                                    ).format(DATE_FORMAT)}
                                                  </Typography>
                                                </Grid>
                                              )}

                                              {donationRequest.additionalInfo
                                                .duration && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Duration (in Days)
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {donationRequest
                                                      .additionalInfo.duration +
                                                      " days "}
                                                  </Typography>
                                                </Grid>
                                              )}

                                              {donationRequest.due_date && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Apply By
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {moment(
                                                      donationRequest.due_date
                                                    ).format(DATE_FORMAT)}
                                                  </Typography>
                                                </Grid>
                                              )}
                                            </Grid>
                                            <Grid container spacing={3}>
                                              {donationRequest.additionalInfo
                                                .monthlyStipend && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Monthly Stipend
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {donationRequest
                                                      .additionalInfo
                                                      .monthlyStipend &&
                                                    donationRequest.region
                                                      ? (donationRequest.region ===
                                                        "IN"
                                                          ? "₹ "
                                                          : "$ ") +
                                                        donationRequest
                                                          .additionalInfo
                                                          .monthlyStipend
                                                      : "No Stipend"}
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {donationRequest.additionalInfo
                                                .trainingMode && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {donationRequest.request_type ===
                                                    "internship"
                                                      ? "Internship"
                                                      : "Volunteering"}{" "}
                                                    Type
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {donationRequest
                                                      .additionalInfo
                                                      .trainingMode ===
                                                    "offline"
                                                      ? "Regular (In-office/On-field)"
                                                      : "Remote/WFH (Recommended due to COVID-19 situation)"}
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {donationRequest.additionalInfo
                                                .numberOfOpenings && (
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    No. of. Openings
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {
                                                      donationRequest
                                                        .additionalInfo
                                                        .numberOfOpenings
                                                    }
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {
                                                <Grid item sm={3} xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Part Time Allowed
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {donationRequest
                                                      .additionalInfo
                                                      .partTimeAllowed === true
                                                      ? "Yes"
                                                      : "No"}
                                                  </Typography>
                                                </Grid>
                                              }
                                            </Grid>
                                            {donationRequest.additionalInfo
                                              .responsibilities && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      // paddingTop: "30px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Responsibilities
                                                  </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    {ReactHtmlParser(
                                                      donationRequest
                                                        .additionalInfo
                                                        .responsibilities
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {donationRequest.skills && (
                                              <>
                                                {" "}
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      // paddingTop: "30px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Skills Required
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                    }}
                                                  >
                                                    {donationRequest.skills.map(
                                                      (data) => {
                                                        return (
                                                          <Chip
                                                            style={{
                                                              padding: "2px",
                                                              marginRight:
                                                                "5px",
                                                              // marginBottom:
                                                              //   "10px",
                                                              display:
                                                                "inline-block",
                                                            }}
                                                            label={data}
                                                            variant="outlined"
                                                          />
                                                        );
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {donationRequest.additionalInfo
                                              .requirements && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      // paddingTop: "30px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Who can apply
                                                  </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                    }}
                                                  >
                                                    {ReactHtmlParser(
                                                      donationRequest
                                                        .additionalInfo
                                                        .requirements
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {donationRequest.additionalInfo
                                              .perks && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "18px",
                                                      // paddingTop: "30px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Perks
                                                  </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                    }}
                                                  >
                                                    {donationRequest.additionalInfo.perks.map(
                                                      (data) => {
                                                        return (
                                                          <li
                                                            style={{
                                                              padding: "2px",
                                                              marginRight:
                                                                "5px",
                                                              // marginBottom:
                                                              //   "10px",

                                                              listStyle: "none",
                                                            }}
                                                          >
                                                            {data}
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .educationalQualification && (
                                                <>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "18px",
                                                        // paddingTop: "30px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Education Qualification
                                                    </Typography>
                                                  </Grid>

                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        // paddingTop: "10px",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                        donationRequest.additionalInfo &&
                                                        donationRequest
                                                          .additionalInfo
                                                          .educationalQualification && (
                                                          <Chip
                                                            style={{
                                                              padding: "2px",
                                                              marginRight:
                                                                "5px",
                                                              // marginBottom:
                                                              //   "10px",
                                                            }}
                                                            label={
                                                              donationRequest
                                                                .additionalInfo
                                                                .educationalQualification
                                                            }
                                                            variant="outlined"
                                                          />
                                                        )}
                                                    </Typography>
                                                  </Grid>
                                                </>
                                              )}
                                            <hr></hr>
                                            {donationRequest &&
                                              donationRequest.orgId &&
                                              donationRequest.orgId
                                                .description && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      paddingTop: "20px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    About Company
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.orgId &&
                                              donationRequest.orgId
                                                .description && (
                                                <>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        paddingTop: "10px",
                                                      }}
                                                    >
                                                      {
                                                        donationRequest.orgId
                                                          .description
                                                      }
                                                    </Typography>
                                                  </Grid>
                                                  {/* {donationRequest
                                                              .orgId.websiteUrl===null?( 
                                                              <Grid item xs={12}>
                                                    <Chip
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        paddingTop: "10px",
                                                      }}
                                                      label={
                                                        <a
                                                          href={
                                                            "https://" +
                                                            donationRequest
                                                              .orgId.websiteUrl
                                                          }
                                                        ></a>
                                                      }
                                                    ></Chip>
                                                  </Grid>):""} */}
                                                </>
                                              )}
                                            {nonImageDocuments.length > 0 && (
                                              <Fragment>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    paddingTop: "30px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Supporting Documents:
                                                </Typography>

                                                {nonImageDocuments.length >
                                                  0 && (
                                                  <Grid container>
                                                    <Table celled striped>
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell>
                                                            Document Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Note
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {nonImageDocuments.map(
                                                          (document, index) => {
                                                            return (
                                                              <Fragment
                                                                key={index}
                                                              >
                                                                <DocumentRow
                                                                  key={index}
                                                                  document={
                                                                    document
                                                                  }
                                                                  canEdit={
                                                                    false
                                                                  }
                                                                />
                                                              </Fragment>
                                                            );
                                                          }
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                  </Grid>
                                                )}
                                              </Fragment>
                                            )}
                                            {/* {donationRequest &&
                                              authUser &&
                                              donationRequest.user_info &&
                                              (authUser.unique_id ===
                                                donationRequest.user_info._id ||
                                                isAdmin ||
                                                isSuperAdmin) &&
                                              (donationRequest.status ===
                                                Number(STATUS_APPROVED) ||
                                                donationRequest.status ===
                                                  Number(STATUS_EXPRIED)) && (
                                                <>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "18px",
                                                        // paddingTop: "20px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Responses Received
                                                    </Typography>
                                                  </Grid>
                                                  {donationRequestResponse &&
                                                  donationRequestResponse.length >
                                                    0 ? (
                                                    <Grid
                                                      container
                                                      className="table-detail"
                                                    >
                                                      <Table celled striped>
                                                        <TableHead>
                                                          <TableRow>
                                                            <TableCell>
                                                              Participant Name
                                                            </TableCell>
                                                            <TableCell>
                                                              Participant Gender
                                                            </TableCell>
                                                            <TableCell>
                                                              Email address
                                                            </TableCell>
                                                            <TableCell>
                                                              Phone number
                                                            </TableCell>
                                                            <TableCell>
                                                              Address
                                                            </TableCell>
                                                            <TableCell>
                                                              Participant Resume
                                                              DownloadUrl
                                                            </TableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                          {donationRequest &&
                                                            authUser &&
                                                            donationRequest.user_info &&
                                                            (authUser.unique_id ===
                                                              donationRequest
                                                                .user_info
                                                                ._id ||
                                                              isAdmin ||
                                                              isSuperAdmin) &&
                                                            (donationRequest.status ===
                                                              Number(
                                                                STATUS_APPROVED
                                                              ) ||
                                                              donationRequest.status ===
                                                                Number(
                                                                  STATUS_EXPRIED
                                                                )) &&
                                                            donationRequestResponse &&
                                                            donationRequestResponse.map(
                                                              (data) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        data.user_info
                                                                      }
                                                                    >
                                                                      <TableCell>
                                                                        {data
                                                                          .participantName
                                                                          .first_name +
                                                                          " " +
                                                                          data
                                                                            .participantName
                                                                            .last_name}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {data.participantGender ===
                                                                        "m"
                                                                          ? "Male"
                                                                          : data.participantGender ===
                                                                            "f"
                                                                          ? "Female"
                                                                          : data.participantGender ===
                                                                            "o"
                                                                          ? "Other"
                                                                          : ""}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          data.participantEmailAddress
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          data.participantPhone
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {data.address &&
                                                                          getObjectValuesInStringFormat(
                                                                            data.address
                                                                          )}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <a
                                                                          style={{
                                                                            color:
                                                                              "#666",
                                                                            textDecoration:
                                                                              "none",
                                                                          }}
                                                                          href={
                                                                            data.participantResumeDownloadUrl
                                                                          }
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                        >
                                                                          {data.participantResumeDownloadUrl
                                                                            ? data
                                                                                .participantName
                                                                                .first_name +
                                                                              " " +
                                                                              data
                                                                                .participantName
                                                                                .last_name
                                                                            : " "}
                                                                        </a>
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                        </TableBody>
                                                      </Table>
                                                    </Grid>
                                                  ) : donationRequestResponse &&
                                                    donationRequestResponse.length ==
                                                      0 &&
                                                    !isLoading ? (
                                                    "No responses received so far"
                                                  ) : (
                                                    "Loading..."
                                                  )}
                                                </>
                                              )} */}
                                          </Grid>
                                        </>
                                      )}

                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "scholarship" && (
                                        <>
                                          <Grid
                                            className={classes.root}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                            container
                                          >
                                            {donationRequest &&
                                              donationRequest.description && (
                                                <>
                                                  {" "}
                                                  <Grid item xs={12}>
                                                    <Typography className="donation-header">
                                                      Description
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <Typography className="donation-text">
                                                      {ReactHtmlParser(
                                                        donationRequest.description
                                                      )}
                                                    </Typography>
                                                  </Grid>
                                                </>
                                              )}
                                            <Grid container spacing={3}>
                                              {donationRequest &&
                                                donationRequest.size !== 0 && (
                                                  <>
                                                    <Grid item sm={3} xs={12}>
                                                      <Typography
                                                        className="donation-text"
                                                        style={{
                                                          fontSize: "15px",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Scholarship Qty.
                                                      </Typography>
                                                      {donationRequest &&
                                                        donationRequest.size !==
                                                          0 && (
                                                          <Typography
                                                            className="donation-text"
                                                            style={{
                                                              fontSize: "16px",
                                                              fontWeight:
                                                                "regular",
                                                            }}
                                                          >
                                                            {"Total " +
                                                              donationRequest.size !==
                                                              0 &&
                                                              donationRequest.size}
                                                          </Typography>
                                                        )}
                                                    </Grid>
                                                  </>
                                                )}

                                              {donationRequest &&
                                                donationRequest.due_date && (
                                                  <Grid item sm={3} xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Last Date
                                                    </Typography>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "regular",
                                                      }}
                                                    >
                                                      {moment(
                                                        donationRequest.due_date
                                                      ).format(DATE_FORMAT)}
                                                    </Typography>
                                                  </Grid>
                                                )}

                                              {donationRequest &&
                                                donationRequest.quantity >
                                                  0 && (
                                                  <Grid item sm={3} xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Scholarship Amount
                                                    </Typography>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "regular",
                                                      }}
                                                    >
                                                      {" "}
                                                      {donationRequest &&
                                                      donationRequest.units &&
                                                      donationRequest.units.toUpperCase() ===
                                                        "INR" ? (
                                                        <FontAwesomeIcon
                                                          color="#000"
                                                          size="1x"
                                                          icon={faRupeeSign}
                                                        />
                                                      ) : (
                                                        <FontAwesomeIcon
                                                          color="#000"
                                                          size="1x"
                                                          icon={faDollarSign}
                                                        />
                                                      )}
                                                      {"  "}
                                                      {donationRequest.quantity >
                                                        0 &&
                                                        donationRequest.quantity}
                                                      {donationRequest.maxQuantity &&
                                                      donationRequest.maxQuantity >
                                                        0
                                                        ? " - "
                                                        : ""}
                                                      {donationRequest.maxQuantity &&
                                                      donationRequest.maxQuantity >
                                                        0
                                                        ? donationRequest.maxQuantity
                                                        : ""}
                                                    </Typography>
                                                  </Grid>
                                                )}

                                              {donationRequest &&
                                                donationRequest.requested_for && (
                                                  <Grid item sm={3} xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Category
                                                    </Typography>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "regular",
                                                      }}
                                                    >
                                                      {
                                                        donationRequest.requested_for
                                                      }
                                                    </Typography>
                                                  </Grid>
                                                )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              className="donation-header"
                                            >
                                              Eligibility Criteria
                                            </Grid>
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              (donationRequest.additionalInfo
                                                .minAge > 0 ||
                                                donationRequest.additionalInfo
                                                  .maxAge > 0) && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Age Limit:{" "}
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                      donationRequest.additionalInfo &&
                                                      donationRequest
                                                        .additionalInfo.minAge >
                                                        0 &&
                                                      donationRequest
                                                        .additionalInfo.maxAge >
                                                        0
                                                        ? donationRequest
                                                            .additionalInfo
                                                            .minAge +
                                                          " - " +
                                                          donationRequest
                                                            .additionalInfo
                                                            .maxAge
                                                        : donationRequest
                                                            .additionalInfo
                                                            .minAge
                                                        ? "Min Age - " +
                                                          donationRequest
                                                            .additionalInfo
                                                            .minAge
                                                        : "Max Age - " +
                                                          donationRequest
                                                            .additionalInfo
                                                            .maxAge}
                                                    </span>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .highestEducation && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Highest Education :{" "}
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                        donationRequest.additionalInfo &&
                                                        donationRequest
                                                          .additionalInfo
                                                          .highestEducation}
                                                    </span>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.skills && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Eligible Courses :{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest.skills.map(
                                                        (data) => {
                                                          return (
                                                            <Chip
                                                              style={{
                                                                marginTop:
                                                                  "5px",
                                                                padding: "2px",
                                                                marginRight:
                                                                  "5px",
                                                                marginBottom:
                                                                  "10px",
                                                                display:
                                                                  "inline-block",
                                                              }}
                                                              label={data}
                                                              variant="outlined"
                                                            />
                                                          );
                                                        }
                                                      )}
                                                    </Grid>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .yearOfStudy && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Year of Study / Grade Level
                                                    :{" "}
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                        donationRequest.additionalInfo &&
                                                        donationRequest
                                                          .additionalInfo
                                                          .yearOfStudy}
                                                    </span>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .gpa && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    GPA :{" "}
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                        donationRequest.additionalInfo &&
                                                        donationRequest
                                                          .additionalInfo.gpa}
                                                    </span>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .eligibilityCriteria && (
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Eligibility Criteria :{" "}
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                      }}
                                                    >
                                                      {donationRequest &&
                                                        donationRequest.additionalInfo &&
                                                        donationRequest
                                                          .additionalInfo
                                                          .eligibilityCriteria}
                                                    </span>
                                                  </Typography>
                                                </Grid>
                                              )}
                                            {donationRequest.targetImpact && (
                                              <Grid item xs={12}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Who can apply{" "}
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {donationRequest &&
                                                      donationRequest.targetImpact &&
                                                      ReactHtmlParser(
                                                        donationRequest.targetImpact
                                                      )}
                                                  </span>
                                                </Typography>
                                              </Grid>
                                            )}
                                            {/* {donationRequest &&
                                              authUser &&
                                              donationRequest.user_info &&
                                              (authUser.unique_id ===
                                                donationRequest.user_info._id ||
                                                isAdmin ||
                                                isSuperAdmin) &&
                                              (donationRequest.status ===
                                                Number(STATUS_APPROVED) ||
                                                donationRequest.status ===
                                                  Number(STATUS_EXPRIED)) && (
                                                <>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "18px",
                                                        // paddingTop: "20px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Responses Received
                                                    </Typography>
                                                  </Grid>
                                                  {donationRequestResponse &&
                                                  donationRequestResponse.length >
                                                    0 ? (
                                                    <Grid
                                                      container
                                                      className="table-detail"
                                                    >
                                                      <Table celled striped>
                                                        <TableHead>
                                                          <TableRow>
                                                            <TableCell>
                                                              Participant Name
                                                            </TableCell>
                                                            <TableCell>
                                                              Email address
                                                            </TableCell>
                                                            <TableCell>
                                                              Phone number
                                                            </TableCell>
                                                            <TableCell>
                                                              Address
                                                            </TableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                          {donationRequest &&
                                                            authUser &&
                                                            donationRequest.user_info &&
                                                            (authUser.unique_id ===
                                                              donationRequest
                                                                .user_info
                                                                ._id ||
                                                              isAdmin ||
                                                              isSuperAdmin) &&
                                                            (donationRequest.status ===
                                                              Number(
                                                                STATUS_APPROVED
                                                              ) ||
                                                              donationRequest.status ===
                                                                Number(
                                                                  STATUS_EXPRIED
                                                                )) &&
                                                            donationRequestResponse &&
                                                            donationRequestResponse.map(
                                                              (data) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        data.user_info
                                                                      }
                                                                    >
                                                                      <TableCell>
                                                                        {data
                                                                          .participantName
                                                                          .first_name +
                                                                          " " +
                                                                          data
                                                                            .participantName
                                                                            .last_name}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          data.participantEmailAddress
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {
                                                                          data.participantPhone
                                                                        }
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        {data.address &&
                                                                          getObjectValuesInStringFormat(
                                                                            data.address
                                                                          )}
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                        </TableBody>
                                                      </Table>
                                                    </Grid>
                                                  ) : donationRequestResponse &&
                                                    donationRequestResponse.length ==
                                                      0 &&
                                                    !isLoading ? (
                                                    "No responses received so far"
                                                  ) : (
                                                    "Loading..."
                                                  )}
                                                </>
                                              )} */}
                                          </Grid>
                                        </>
                                      )}

                                    {donationRequest &&
                                      donationRequest.request_type ===
                                        "homework help" && (
                                        <>
                                          <Grid
                                            className={classes.root}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                            container
                                          >
                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  // paddingTop: "10px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Description
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  // paddingTop: "10px",
                                                }}
                                              >
                                                {donationRequest.description &&
                                                  ReactHtmlParser(
                                                    donationRequest.description
                                                  )}
                                              </Typography>
                                            </Grid>

                                            <Grid container spacing={3}>
                                              {donationRequest.user_info
                                                .occupation && (
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{ paddingLeft: "0" }}
                                                >
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      // paddingTop: "10px",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Occupation
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {
                                                      donationRequest.user_info
                                                        .occupation
                                                    }
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {donationRequest.due_date && (
                                                <Grid item xs={3}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      // paddingTop: "10px",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Deadline
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {moment(
                                                      donationRequest.due_date
                                                    ).format(DATE_FORMAT)}
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {donationRequest.additionalInfo
                                                .preferred_language && (
                                                <Grid item xs={3}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      // paddingTop: "10px",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Preferred Language
                                                  </Typography>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "regular",
                                                    }}
                                                  >
                                                    {
                                                      donationRequest
                                                        .additionalInfo
                                                        .preferred_language
                                                    }
                                                  </Typography>
                                                </Grid>
                                              )}
                                            </Grid>
                                            {donationRequest.requested_for && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Subject
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                    }}
                                                  >
                                                    {
                                                      donationRequest.requested_for
                                                    }
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {donationRequest.title && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Title
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "10px",
                                                    }}
                                                  >
                                                    {donationRequest.title}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}

                                            {donationRequest.brief && (
                                              <Grid item xs={12}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    // paddingTop: "10px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Brief
                                                </Typography>
                                              </Grid>
                                            )}

                                            {donationRequest.brief && (
                                              <Grid item xs={12}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    // paddingTop: "10px",
                                                  }}
                                                >
                                                  {donationRequest.brief}
                                                </Typography>
                                              </Grid>
                                            )}
                                            <hr></hr>

                                            {donationRequest.additionalInfo
                                              .linkedInProfile && (
                                              <Grid item xs={12}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    // paddingTop: "10px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  LinkedIn Profile
                                                </Typography>
                                              </Grid>
                                            )}

                                            {donationRequest.additionalInfo
                                              .linkedInProfile && (
                                              <Grid item xs={12}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    paddingTop: "30px",
                                                  }}
                                                >
                                                  {
                                                    donationRequest
                                                      .additionalInfo
                                                      .linkedInProfile
                                                  }
                                                </Typography>
                                              </Grid>
                                            )}

                                            {nonImageDocuments.length > 0 && (
                                              <Fragment>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    className="donation-text"
                                                    style={{
                                                      fontSize: "16px",
                                                      // paddingTop: "30px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Supporting Documents:
                                                  </Typography>
                                                </Grid>

                                                {nonImageDocuments.length >
                                                  0 && (
                                                  <Grid container>
                                                    <Table celled striped>
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell>
                                                            Document Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Note
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {nonImageDocuments.map(
                                                          (document, index) => {
                                                            return (
                                                              <Fragment
                                                                key={index}
                                                              >
                                                                <DocumentRow
                                                                  key={index}
                                                                  document={
                                                                    document
                                                                  }
                                                                  canEdit={
                                                                    false
                                                                  }
                                                                />
                                                              </Fragment>
                                                            );
                                                          }
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                  </Grid>
                                                )}
                                              </Fragment>
                                            )}
                                            {/* {donationRequest &&
                                              authUser &&
                                              donationRequest.user_info &&
                                              (authUser.unique_id ===
                                                donationRequest.user_info._id ||
                                                isAdmin ||
                                                isSuperAdmin) &&
                                              (donationRequest.status ===
                                                Number(STATUS_APPROVED) ||
                                                donationRequest.status ===
                                                  Number(STATUS_EXPRIED)) && (
                                                <>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      className="donation-text"
                                                      style={{
                                                        fontSize: "16px",
                                                        // paddingTop: "20px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Responses Received
                                                    </Typography>
                                                  </Grid>
                                                  {donationRequestResponse &&
                                                  donationRequestResponse.length >
                                                    0 ? (
                                                    <Grid container>
                                                      <Table celled striped>
                                                        <TableHead>
                                                          <TableRow>
                                                            <TableCell>
                                                              User Name
                                                            </TableCell>
                                                            <TableCell>
                                                              User public
                                                              profile url
                                                            </TableCell>
                                                            <TableCell>
                                                              Participant
                                                              AudioRoomUrl
                                                            </TableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                          {donationRequest &&
                                                            authUser &&
                                                            donationRequest.user_info &&
                                                            (authUser.unique_id ===
                                                              donationRequest
                                                                .user_info
                                                                ._id ||
                                                              isAdmin ||
                                                              isSuperAdmin) &&
                                                            donationRequest &&
                                                            donationRequest.status &&
                                                            donationRequest.status ===
                                                              Number(
                                                                STATUS_APPROVED
                                                              ) &&
                                                            donationRequest &&
                                                            donationRequestResponse &&
                                                            donationRequestResponse.map(
                                                              (data) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        data.user_info
                                                                      }
                                                                    >
                                                                      <TableCell>
                                                                        {data
                                                                          .participantName
                                                                          .first_name +
                                                                          " " +
                                                                          data
                                                                            .participantName
                                                                            .last_name}
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <a
                                                                          href={
                                                                            "/" +
                                                                            data
                                                                              .user_info
                                                                              .username
                                                                          }
                                                                          style={{
                                                                            color:
                                                                              "blue",
                                                                          }}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                        >
                                                                          User
                                                                          Profile
                                                                        </a>
                                                                      </TableCell>
                                                                      <TableCell>
                                                                        <a
                                                                          style={{
                                                                            color:
                                                                              "blue",
                                                                          }}
                                                                          href={
                                                                            data.participantAudioRoomUrl
                                                                          }
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                        >
                                                                          {data.participantAudioRoomUrl
                                                                            ? data
                                                                                .participantName
                                                                                .first_name +
                                                                              " " +
                                                                              data
                                                                                .participantName
                                                                                .last_name
                                                                            : ""}
                                                                        </a>
                                                                      </TableCell>
                                                                    </TableRow>{" "}
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                        </TableBody>
                                                      </Table>
                                                    </Grid>
                                                  ) : donationRequestResponse &&
                                                    donationRequestResponse.length ==
                                                      0 &&
                                                    !isLoading ? (
                                                    "No responses received so far"
                                                  ) : (
                                                    "Loading..."
                                                  )}
                                                </>
                                              )} */}
                                          </Grid>
                                        </>
                                      )}

                                    {donationRequest &&
                                      donationRequest.additionalInfo &&
                                      donationRequest.request_type ===
                                        "career" &&
                                      Object.entries(
                                        donationRequest.additionalInfo
                                      ).map(([key, value]) => {
                                        return (
                                          <div
                                            key={key}
                                            style={{ margin: "10px " }}
                                          >
                                            <span>
                                              <b
                                                style={{
                                                  color: "rgb(40, 62, 74)",
                                                }}
                                              >
                                                {key}
                                              </b>
                                              {value &&
                                                value.length > 0 &&
                                                value.map((item) => {
                                                  return (
                                                    <li
                                                      style={{
                                                        marginLeft: "10px",
                                                        listStyle: "circle",
                                                        padding: "0",
                                                      }}
                                                    >
                                                      {item}
                                                    </li>
                                                  );
                                                })}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    {/* {donationRequest &&
                                      donationRequest.request_type ===
                                        "career" &&
                                      authUser &&
                                      donationRequest.user_info &&
                                      (authUser.unique_id ===
                                        donationRequest.user_info._id ||
                                        isAdmin ||
                                        isSuperAdmin) &&
                                      (donationRequest.status ===
                                        Number(STATUS_APPROVED) ||
                                        donationRequest.status ===
                                          Number(STATUS_EXPRIED)) && (
                                        <>
                                          <Grid item xs={12}>
                                            <Typography
                                              className="donation-text"
                                              style={{
                                                fontSize: "18px",
                                                // paddingTop: "20px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Responses Received
                                            </Typography>
                                          </Grid>
                                          {donationRequestResponse &&
                                          donationRequestResponse.length > 0 ? (
                                            <Grid
                                              container
                                              className="table-detail"
                                            >
                                              <Table celled striped>
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      Participant Name
                                                    </TableCell>
                                                    <TableCell>
                                                      Participant Gender
                                                    </TableCell>
                                                    <TableCell>
                                                      Email address
                                                    </TableCell>
                                                    <TableCell>
                                                      Phone number
                                                    </TableCell>
                                                    <TableCell>
                                                      Address
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {donationRequest &&
                                                    authUser &&
                                                    donationRequest.user_info &&
                                                    (authUser.unique_id ===
                                                      donationRequest.user_info
                                                        ._id ||
                                                      isAdmin ||
                                                      isSuperAdmin) &&
                                                    (donationRequest.status ===
                                                      Number(STATUS_APPROVED) ||
                                                      donationRequest.status ===
                                                        Number(
                                                          STATUS_EXPRIED
                                                        )) &&
                                                    donationRequestResponse &&
                                                    donationRequestResponse.map(
                                                      (data) => {
                                                        return (
                                                          <>
                                                            <TableRow
                                                              key={
                                                                data.user_info
                                                              }
                                                            >
                                                              <TableCell>
                                                                {data
                                                                  .participantName
                                                                  .first_name +
                                                                  " " +
                                                                  data
                                                                    .participantName
                                                                    .last_name}
                                                              </TableCell>
                                                              <TableCell>
                                                                {data.participantGender ===
                                                                "m"
                                                                  ? "Male"
                                                                  : data.participantGender ===
                                                                    "f"
                                                                  ? "Female"
                                                                  : data.participantGender ===
                                                                    "o"
                                                                  ? "Other"
                                                                  : ""}
                                                              </TableCell>
                                                              <TableCell>
                                                                {
                                                                  data.participantEmailAddress
                                                                }
                                                              </TableCell>
                                                              <TableCell>
                                                                {
                                                                  data.participantPhone
                                                                }
                                                              </TableCell>
                                                              <TableCell>
                                                                {data.address &&
                                                                  getObjectValuesInStringFormat(
                                                                    data.address
                                                                  )}
                                                              </TableCell>
                                                            </TableRow>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </TableBody>
                                              </Table>
                                            </Grid>
                                          ) : donationRequestResponse &&
                                            donationRequestResponse.length ==
                                              0 &&
                                            !isLoading ? (
                                            <p style={{ textAlign: "center" }}>
                                              No responses received so far
                                            </p>
                                          ) : (
                                            <p style={{ textAlign: "center" }}>
                                              Loading...
                                            </p>
                                          )}
                                        </>
                                      )} */}
                                  </TabPanel>
                                  <TabPanel value={value} index={1}>
                                    <DonationRequestUpdate
                                      donationRequest={donationRequest}
                                    />
                                  </TabPanel>
                                  {donationRequest &&
                                    donationRequest.user_info &&
                                    donationRequest.user_info._id && (
                                      <TabPanel value={value} index={2}>
                                        <ChatRoom
                                          id={donationRequest.user_info._id}
                                        />
                                      </TabPanel>
                                    )}
                                </Typography>
                              </Typography>
                              {values && values.requestType === "cash" && (
                                <Payments
                                  isOpen={isPaymentsOpen}
                                  donationFor="donationRequest"
                                  donationRequestId={id}
                                  amt={donAmt}
                                  currency={values && values.unit}
                                  onClose={() => setIsPaymentsOpen(false)}
                                />
                              )}
                              {values && values.requestType !== "cash" && (
                                <KindTimeDonation
                                  isOpen={isKindTimeDonationOpen}
                                  donationRequest={donationRequest}
                                  requestType={values.requestType}
                                  donationFor="donationRequest"
                                  onClose={() =>
                                    setIsKindTimeDonationOpen(false)
                                  }
                                  onSavingDonation={saveDonationHandler}
                                />
                              )}
                              {values && openOfflineDonationPopUp && (
                                <OfflineDonations
                                  isOpen={openOfflineDonationPopUp}
                                  donationFor="donationRequest"
                                  donationRequestId={id}
                                  donationDetails={values}
                                  onClose={() =>
                                    setOpenOfflineDonationPopUp(false)
                                  }
                                />
                              )}
                            </Typography>
                          </div>

                          {/* <Typography
                            className="share-option-btns"
                            style={{
                              pointerEvents: isPreviewMode ? "none" : "auto",
                            }}
                          >
                            <WhatsappShareButton
                              url={`${donationRequestShareUrl(
                                donationRequest
                              )}`}
                              quote={donationRequest && donationRequest.title}
                              style={{ background: "#4baf4f", borderRadius: 4 }}
                            >
                              <Button className="whatsapp">
                                <WhatsAppIcon /> Share
                              </Button>
                            </WhatsappShareButton>

                            <FacebookShareButton
                              url={`${donationRequestShareUrl(
                                donationRequest
                              )}`}
                              quote={donationRequest && donationRequest.title}
                              style={{ background: "#3b5997", borderRadius: 4 }}
                            >
                              <Button className="facebook">
                                <FacebookIcon /> Share
                              </Button>
                            </FacebookShareButton>

                            <EmailContactGroupsModal
                              openModal={openModal}
                              onModalClose={handleCloseGroups}
                              donationRequest={donationRequest}
                            ></EmailContactGroupsModal>
                            {donationRequest &&
                              donationRequest.request_type === "cash" &&
                              (donationRequest.status === 0 ||
                                donationRequest.status === 1) && (
                                <Button
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  variant="contained"
                                  color="primary"
                                  onClick={launchEmailCampaign}
                                  startIcon={<MailOutlineIcon />}
                                  style={{ backgroundColor: "#4e342e" }}
                                >
                                  Email Campaign
                                </Button>
                              )}
                          </Typography> */}

                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "10px" }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography className="fundraiser-container drop-box">
                                <Typography
                                  component="p"
                                  className="fundraiser-title"
                                >
                                  Created By
                                </Typography>

                                <CardHeader
                                  onClick={userProfileHandler}
                                  style={{
                                    pointerEvents: isPreviewMode
                                      ? "none"
                                      : "auto",
                                    cursor: "pointer",
                                    padding: "16px 16px 16px 0",
                                  }}
                                  avatar={
                                    <Avatar
                                      aria-label="recipe"
                                      className="avatar-container"
                                    >
                                      <figure>
                                        <img
                                          src={
                                            (values &&
                                              values.userInfo &&
                                              values.userInfo.image_url &&
                                              values.userInfo.image_url) ||
                                            "/images/donor.png"
                                          }
                                          alt="user-logo"
                                          style={{
                                            verticalAlign: "middle",
                                            width: "100%",
                                          }}
                                        />
                                      </figure>
                                    </Avatar>
                                  }
                                  title={
                                    <span style={{ cursor: "pointer" }}>
                                      <Tooltip title="View Public Profile">
                                        <Fragment>
                                          {values &&
                                            values.userInfo &&
                                            values.userInfo.name.first_name +
                                              " " +
                                              values.userInfo.name.last_name}
                                          <VerifiedUser
                                            style={{
                                              color: green[500],
                                              width: 34,
                                              paddingTop: 6,
                                            }}
                                          />
                                        </Fragment>
                                      </Tooltip>
                                    </span>
                                  }
                                  subheader={
                                    <span>
                                      <Typography
                                        component="span"
                                        className="date"
                                        style={{ fontSize: 13 }}
                                      >
                                        {values &&
                                          moment(values.createdAt).format(
                                            DATE_FORMAT
                                          )}
                                      </Typography>
                                      {address && address.city && (
                                        <Typography
                                          component="span"
                                          className="location"
                                        >
                                          <LocationOn
                                            style={{ fontSize: 13 }}
                                          />
                                          {address && address.city
                                            ? address.city
                                            : ""}
                                        </Typography>
                                      )}
                                    </span>
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {donationRequest &&
                                donationRequest.orgId &&
                                donationRequest.orgId._id && (
                                  <Typography className="fundraiser-container drop-box">
                                    <Typography
                                      component="p"
                                      className="fundraiser-title"
                                    >
                                      Beneficiary
                                    </Typography>

                                    <CardHeader
                                      onClick={
                                        donationRequest &&
                                        donationRequest.orgId &&
                                        donationRequest.fundsRecipient !==
                                          "self"
                                          ? () =>
                                              props.history.push(
                                                `/organization/${donationRequest.orgId._id}`
                                              )
                                          : () =>
                                              props.history.push(
                                                `${donationRequest.user_info.username}`
                                              )
                                      }
                                      style={{
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                        padding: "16px 16px 16px 0",
                                      }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-container"
                                        >
                                          <figure>
                                            <img
                                              src={
                                                donationRequest &&
                                                donationRequest.orgId &&
                                                donationRequest.orgId
                                                  .defaultImageUrl
                                                  ? donationRequest.orgId
                                                      .defaultImageUrl
                                                  : "/images/orgLogo.png"
                                              }
                                              alt="user-logo"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "100%",
                                                height: "74.9px",
                                              }}
                                            />
                                          </figure>
                                        </Avatar>
                                      }
                                      title={
                                        <span style={{ cursor: "pointer" }}>
                                          <Tooltip title="View Organization Public Profile">
                                            <Fragment>
                                              {donationRequest &&
                                                donationRequest.orgId &&
                                                donationRequest.orgId.orgName}
                                              <VerifiedUser
                                                style={{
                                                  color: green[500],
                                                  width: 34,
                                                  paddingTop: 6,
                                                }}
                                              />
                                            </Fragment>
                                          </Tooltip>
                                        </span>
                                      }
                                      subheader={
                                        <span>
                                          <Typography
                                            component="span"
                                            className="date"
                                          >
                                            {moment(
                                              donationRequest.createdAt
                                            ).format(DATE_FORMAT)}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            <LocationOn
                                              style={{ fontSize: 14 }}
                                            />
                                            {donationRequest &&
                                            donationRequest.orgId &&
                                            donationRequest.orgId.orgAddress &&
                                            donationRequest.orgId.orgAddress
                                              .city
                                              ? donationRequest.orgId.orgAddress
                                                  .city
                                              : ""}
                                          </Typography>
                                        </span>
                                      }
                                    />
                                  </Typography>
                                )}
                              {donationRequest &&
                                donationRequest.request_type === "cash" &&
                                donationRequest.fundsRecipient &&
                                donationRequest.fundsRecipient.toLowerCase() !==
                                  "self" &&
                                donationRequest.fundsRecipientName && (
                                  <Typography className="fundraiser-container drop-box">
                                    <Typography
                                      component="p"
                                      className="fundraiser-title"
                                    >
                                      Beneficiary
                                    </Typography>

                                    <CardHeader
                                      style={{
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                        padding: "16px 16px 16px 0",
                                      }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-container"
                                        >
                                          <figure>
                                            <img
                                              src={"/images/orgLogo.png"}
                                              alt="Logo"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "100%",
                                              }}
                                            />
                                          </figure>
                                        </Avatar>
                                      }
                                      title={
                                        <span style={{ cursor: "pointer" }}>
                                          <Fragment>
                                            {donationRequest &&
                                              donationRequest.fundsRecipientName}
                                            <VerifiedUser
                                              style={{
                                                color: green[500],
                                                width: 34,
                                                paddingTop: 6,
                                              }}
                                            />
                                          </Fragment>
                                        </span>
                                      }
                                      subheader={
                                        <span>
                                          <Typography
                                            component="span"
                                            className="date"
                                          >
                                            {moment(
                                              donationRequest.createdAt
                                            ).format(DATE_FORMAT)}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            <LocationOn
                                              style={{ fontSize: 14 }}
                                            />
                                            {address && address.city
                                              ? address.city
                                              : ""}
                                          </Typography>
                                        </span>
                                      }
                                    />
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        className="donation-left-bar"
                      >
                        <StickyBox
                          offsetTop={120}
                          offsetBottom={-150}
                          className="title-container"
                        >
                          <Typography component="div">
                            {isPreviewMode && (
                              <Fragment>
                                <Button
                                  className="submit-post"
                                  onClick={submitForApprovalHandler}
                                >
                                  <CheckBoxIcon /> Submit Request for Approval
                                </Button>
                                <Button className="facebook-post">
                                  <FacebookIcon /> Help Spread Awareness
                                </Button>
                              </Fragment>
                            )}

                            <Typography>
                              {donationRequest &&
                                donationRequest.request_type === "plasma" && (
                                  <Typography
                                    component="section"
                                    className="drop-box"
                                  >
                                    <Typography
                                      component="div"
                                      className="amount-text"
                                    >
                                      {donationRequest &&
                                      donationRequest.status &&
                                      donationRequest.status === 2 ? (
                                        <Typography
                                          component="h2"
                                          className="payment-header"
                                          style={{ color: "#000" }}
                                        >
                                          {donationRequest &&
                                          donationRequest.creatorType ===
                                            "donor" ? (
                                            donationRequest.user_info &&
                                            authUser &&
                                            donationRequest.user_info._id ===
                                              authUser.unique_id ? (
                                              <span>
                                                You marked the Plasma donation
                                                post as COMPLETED
                                              </span>
                                            ) : (
                                              <span>
                                                The Donor marked this Plasma
                                                Donation as COMPLETED
                                              </span>
                                            )
                                          ) : donationRequest.user_info &&
                                            authUser &&
                                            donationRequest.user_info._id ===
                                              authUser.unique_id ? (
                                            <span>
                                              You marked the Plasma Request post
                                              as COMPLETED
                                            </span>
                                          ) : (
                                            <span>
                                              The Donee marked this Plasma
                                              Request as COMPLETED
                                            </span>
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography component="div">
                                          <Typography className="donation-header">
                                            Contact Information
                                          </Typography>
                                          <Typography
                                            component="div"
                                            className="payment-desc-container"
                                          >
                                            {(donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .contactByPhone) ||
                                            (donationRequest &&
                                              donationRequest.additionalInfo &&
                                              donationRequest.additionalInfo
                                                .contactByEmail) ||
                                            (address && address.city) ? (
                                              <div
                                                className="contact-information
                                                  "
                                              >
                                                {donationRequest &&
                                                  donationRequest.additionalInfo &&
                                                  donationRequest.additionalInfo
                                                    .contactByEmail && (
                                                    <p
                                                      style={{
                                                        color: "#555",
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      <MailOutlineIcon />{" "}
                                                      {donationRequest &&
                                                        donationRequest.user_info &&
                                                        donationRequest
                                                          .user_info.email}{" "}
                                                    </p>
                                                  )}
                                                {donationRequest &&
                                                  donationRequest.additionalInfo &&
                                                  donationRequest.additionalInfo
                                                    .contactByPhone && (
                                                    <p
                                                      style={{ color: "#555" }}
                                                    >
                                                      <PhoneIcon />{" "}
                                                      {donationRequest &&
                                                        donationRequest.user_info &&
                                                        donationRequest
                                                          .user_info.phone}{" "}
                                                    </p>
                                                  )}
                                                {address && address.city && (
                                                  <p style={{ color: "#555" }}>
                                                    <LocationOn />{" "}
                                                    {address && address.city
                                                      ? address.city
                                                      : ""}{" "}
                                                  </p>
                                                )}
                                              </div>
                                            ) : (
                                              <p>
                                                <i>No information available</i>
                                              </p>
                                            )}
                                            {authUser &&
                                              donationRequest &&
                                              authUser.unique_id ===
                                                donationRequest.user_info._id &&
                                              !(
                                                donationRequest &&
                                                (donationRequest.status ===
                                                  STATUS_INCOMPLETE ||
                                                  donationRequest.status ===
                                                    STATUS_SPAM ||
                                                  donationRequest.status ===
                                                    STATUS_REJECTED)
                                              ) && (
                                                <Button
                                                  className="plasma-btn"
                                                  style={{
                                                    pointerEvents: isPreviewMode
                                                      ? "none"
                                                      : "auto",
                                                  }}
                                                  onClick={() =>
                                                    handlePlasmaDonate(
                                                      donationRequest
                                                    )
                                                  }
                                                  disabled={
                                                    isPreviewMode ? true : false
                                                  }
                                                >
                                                  I{" "}
                                                  {donationRequest &&
                                                  donationRequest.creatorType ===
                                                    "donee"
                                                    ? "Received Plasma"
                                                    : "Donated Plasma"}
                                                </Button>
                                              )}
                                          </Typography>
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                )}

                              <Typography component="section">
                                {values && values.requestType === "cash" && (
                                  <Hidden smDown>
                                    <Typography
                                      component="div"
                                      className="amount-text"
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ alignItems: "center" }}
                                      >
                                        <Grid
                                          xs={4}
                                          item
                                          style={{
                                            width: "30%",
                                            position: "relative",
                                          }}
                                        >
                                          <Box
                                            component="div"
                                            style={{
                                              position: "absolute",
                                              top: "43%",
                                              left: "41%",
                                              color: "red",
                                            }}
                                          >{`${
                                            values && values.donatedPercentage
                                          }%`}</Box>
                                          <CircularProgressbar
                                            value={
                                              values && values.donatedPercentage
                                            }
                                            styles={buildStyles({
                                              // This is in units relative to the 100x100px
                                              // SVG viewbox.
                                              textSize: "14px",
                                              textColor: "red",
                                              pathColor: "green",
                                              trailColor: "#eee",
                                            })}
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography>Raised</Typography>
                                          <Typography>
                                            {values &&
                                              formatNumber(
                                                values.donatedQuantity
                                              )}{" "}
                                            {values &&
                                            values.requestType === "cash"
                                              ? values.cashUnit
                                              : values &&
                                                values.requestType === "kind"
                                              ? values && values.kindUnit
                                              : values && values.timeUnit}{" "}
                                            {values &&
                                            values.requestType === "kind"
                                              ? values.requested_for
                                              : ""}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography>Goal</Typography>
                                          <Typography>
                                            {values &&
                                              formatNumber(
                                                values.quantity
                                              )}{" "}
                                            {values &&
                                            values.requestType === "cash"
                                              ? values.cashUnit
                                              : values &&
                                                values.requestType === "kind"
                                              ? values && values.kindUnit
                                              : values && values.timeUnit}{" "}
                                            {values &&
                                            values.requestType === "kind"
                                              ? values.requested_for
                                              : ""}
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Typography component="p">
                                        <div className="progress-text">
                                          <p
                                            style={{
                                              color: "red",
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => showDonorsList(e)}
                                          >
                                            {donorsList && donorsList.length}
                                            {"  "}
                                            {donorsList &&
                                            donorsList.length === 1
                                              ? "Supporter"
                                              : "Supporters"}
                                            {showDonors ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )}
                                          </p>

                                          {donationRequest &&
                                            donationRequest.due_date &&
                                            moment(
                                              donationRequest.due_date
                                            ).diff(moment().toDate()) > 0 && (
                                              <span
                                                style={{
                                                  float: "right",
                                                  textTransform: "none",
                                                }}
                                              >
                                                {moment(
                                                  donationRequest.due_date
                                                ).diff(
                                                  moment().toDate(),
                                                  "days"
                                                )}{" "}
                                                days to go
                                              </span>
                                            )}
                                        </div>

                                        {showDonors && (
                                          <div>
                                            {donorsList &&
                                              donorsList.length > 0 && (
                                                <div
                                                  style={{
                                                    border: "1px solid #ccc",
                                                  }}
                                                >
                                                  <TableContainer>
                                                    <Table>
                                                      <EnhancedTableHead
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={
                                                          handleRequestSort
                                                        }
                                                        rowCount={
                                                          donorsList.length
                                                        }
                                                      />
                                                      <TableBody>
                                                        {stableSort(
                                                          donorsList,
                                                          getComparator(
                                                            order,
                                                            orderBy
                                                          )
                                                        )
                                                          .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                              rowsPerPage
                                                          )
                                                          .map((data) => (
                                                            <TableRow
                                                              key={data.id}
                                                            >
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {data.userName}
                                                              </TableCell>
                                                              <TableCell>
                                                                {data.units}{" "}
                                                                {data.amount}
                                                              </TableCell>
                                                              <TableCell>
                                                                {moment(
                                                                  data.donatedDate
                                                                ).format(
                                                                  DATE_FORMAT
                                                                )}
                                                              </TableCell>
                                                            </TableRow>
                                                          ))}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                  <TablePagination
                                                    rowsPerPageOptions={[
                                                      5, 10, 20, 50, 100,
                                                    ]}
                                                    component="div"
                                                    className="page-grid"
                                                    count={donorsList.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={
                                                      handleChangePage
                                                    }
                                                    onChangeRowsPerPage={
                                                      handleChangeRowsPerPage
                                                    }
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      component="div"
                                      className="amount-text"
                                    >
                                      <Typography
                                        component="h3"
                                        className="payment-header"
                                      ></Typography>
                                      <Typography
                                        component="div"
                                        className="payment-desc-container"
                                      >
                                        <Typography
                                          component="p"
                                          className="payment-sub-header"
                                        >
                                          Donate
                                          {(isSuperAdmin || isAdmin) && (
                                            <Tooltip title="Add off-line Donations">
                                              <div
                                                style={{
                                                  float: "right",
                                                  fontSize: 17,
                                                  color: "var(--tal_primary)",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  openOfflineDonationDetails(e)
                                                }
                                              >
                                                <AddCircleIcon className="add-circle" />
                                              </div>
                                            </Tooltip>
                                          )}
                                        </Typography>
                                        <Typography
                                          className="payment-btn-container"
                                          style={{
                                            pointerEvents: isPreviewMode
                                              ? "none"
                                              : "auto",
                                          }}
                                        >
                                          <div className="amount-btn">
                                            <Button
                                              className={
                                                active25 && "activeColor"
                                              }
                                              onClick={() =>
                                                handleDonationAmount(
                                                  "25",
                                                  donationRequest &&
                                                    donationRequest.units &&
                                                    donationRequest.units.toUpperCase() ===
                                                      "INR"
                                                    ? 500
                                                    : 25
                                                )
                                              }
                                            >
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR"
                                                ? 500
                                                : 25}
                                            </Button>
                                            <Button
                                              className={
                                                active50 && "activeColor"
                                              }
                                              onClick={() =>
                                                handleDonationAmount(
                                                  "50",
                                                  donationRequest &&
                                                    donationRequest.units &&
                                                    donationRequest.units.toUpperCase() ===
                                                      "INR"
                                                    ? 1000
                                                    : 50
                                                )
                                              }
                                            >
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR"
                                                ? 1000
                                                : 50}
                                            </Button>
                                            <Button
                                              className={
                                                active100 && "activeColor"
                                              }
                                              onClick={() =>
                                                handleDonationAmount(
                                                  "100",
                                                  donationRequest &&
                                                    donationRequest.units &&
                                                    donationRequest.units.toUpperCase() ===
                                                      "INR"
                                                    ? 2000
                                                    : 100
                                                )
                                              }
                                            >
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR"
                                                ? 2000
                                                : 100}
                                            </Button>
                                            <Button
                                              className={
                                                active250 && "activeColor"
                                              }
                                              onClick={() =>
                                                handleDonationAmount(
                                                  "250",
                                                  donationRequest &&
                                                    donationRequest.units &&
                                                    donationRequest.units.toUpperCase() ===
                                                      "INR"
                                                    ? 5000
                                                    : 250
                                                )
                                              }
                                            >
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR"
                                                ? 5000
                                                : 250}
                                            </Button>
                                            <Button
                                              className={
                                                active500 && "activeColor"
                                              }
                                              onClick={() =>
                                                handleDonationAmount(
                                                  "500",
                                                  donationRequest &&
                                                    donationRequest.units &&
                                                    donationRequest.units.toUpperCase() ===
                                                      "INR"
                                                    ? 10000
                                                    : 500
                                                )
                                              }
                                            >
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  size="1x"
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {donationRequest &&
                                              donationRequest.units &&
                                              donationRequest.units.toUpperCase() ===
                                                "INR"
                                                ? 10000
                                                : 500}
                                            </Button>
                                          </div>
                                          <TextField
                                            type="number"
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            placeholder="Enter other amount"
                                            onChange={handleOtherAmt}
                                          ></TextField>
                                        </Typography>
                                      </Typography>
                                    </Typography>

                                    <Typography
                                      component="div"
                                      className="footer-sec"
                                    >
                                      <Button
                                        size="small"
                                        className="donate-btn"
                                        onClick={donateButtonHandler}
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                          opacity:
                                            donationRequest &&
                                            donationRequest &&
                                            donationRequest.status !== 1 &&
                                            donationRequest.status !== 0
                                              ? // donationRequest.donated_quantity >=
                                                //   donationRequest.quantity
                                                "0.5"
                                              : 1,

                                          margin: "0 0 10px 0",
                                        }}
                                        disabled={
                                          donationRequest &&
                                          donationRequest.status !== 1 &&
                                          donationRequest.status !== 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Donate Now
                                      </Button>
                                    </Typography>
                                  </Hidden>
                                )}
                                <RejectionModal
                                  func={fromRejectModal}
                                  openOrgModal={openOrgModal}
                                  onCloseModal={(e) => setOpenOrgModal(false)}
                                  donationRequest={donationRequest}
                                />
                                {values && values.requestType === "career" && (
                                  <Typography
                                    component="section"
                                    className="drop-box"
                                  >
                                    <Typography
                                      component="div"
                                      className="amount-text"
                                    >
                                      <CardHeader
                                        onClick={userProfileHandler}
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                          cursor: "pointer",
                                          padding: "0 16px 16px 0",
                                        }}
                                        avatar={
                                          <Avatar
                                            aria-label="recipe"
                                            className="avatar-container"
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  (values &&
                                                    values.userInfo &&
                                                    values.userInfo.image_url &&
                                                    values.userInfo
                                                      .image_url) ||
                                                  "/images/donor.png"
                                                }
                                                alt="user-logo"
                                                style={{
                                                  verticalAlign: "middle",
                                                  width: "100%",
                                                }}
                                              />
                                            </figure>
                                          </Avatar>
                                        }
                                        title={
                                          <span
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Tooltip title="View Public Profile">
                                              <Fragment>
                                                {values &&
                                                  values.userInfo &&
                                                  values.userInfo.username}
                                                <VerifiedUser
                                                  style={{
                                                    color: green[500],
                                                    width: 34,
                                                    paddingTop: 6,
                                                  }}
                                                />
                                              </Fragment>
                                            </Tooltip>
                                          </span>
                                        }
                                        subheader={
                                          <span>
                                            <Typography
                                              component="span"
                                              className="date"
                                              style={{ fontSize: 13 }}
                                            >
                                              {values &&
                                                moment(values.createdAt).format(
                                                  DATE_FORMAT
                                                )}
                                              <span> | </span>
                                              <span>
                                                {donationRequest &&
                                                  donationRequest.createdAt &&
                                                  moment(
                                                    donationRequest.createdAt
                                                  )
                                                    .startOf("seconds")
                                                    .fromNow()}
                                              </span>
                                            </Typography>
                                            <Typography
                                              component="span"
                                              className="location"
                                            >
                                              <LocationOn
                                                style={{ fontSize: 13 }}
                                              />
                                              {address && address.city
                                                ? address.city
                                                : ""}
                                            </Typography>
                                          </span>
                                        }
                                      />
                                      <Typography
                                        component="div"
                                        className="payment-desc-container"
                                      >
                                        <Typography
                                          component="p"
                                          className="payment-info no-capitalise"
                                        >
                                          {donationRequest.title}
                                        </Typography>
                                        <Typography>
                                          {isLoading && (
                                            <div>
                                              <br />
                                              <span
                                                style={{
                                                  color: "grey",
                                                }}
                                              >
                                                Contact Details
                                              </span>
                                              <p
                                                style={{
                                                  color: "#5f83c0",
                                                  fontWeight: "500",
                                                  marginTop: "5px",
                                                  lineHeight: "22px",
                                                  marginBottom: "40px",
                                                }}
                                              >
                                                {
                                                  donationRequest.user_info
                                                    .phone
                                                }{" "}
                                                <br />
                                                {
                                                  donationRequest.user_info
                                                    .email
                                                }
                                              </p>
                                            </div>
                                          )}
                                          {isMailSent === 1 && (
                                            <p>
                                              Please Wait &nbsp;&nbsp;
                                              <CircularProgress
                                                size={24}
                                                className="buttonProgress"
                                              />
                                            </p>
                                          )}
                                          {isMailSent === 2 && (
                                            <p className="confirmText-Career ">
                                              <CheckCircleOutlineIcon
                                                style={{
                                                  color: "green",
                                                }}
                                              />
                                              Mentee contact info is also sent
                                              to your email.
                                            </p>
                                          )}
                                        </Typography>
                                        <Typography
                                          className="payment-btn-container"
                                          style={{
                                            pointerEvents: isPreviewMode
                                              ? "none"
                                              : "auto",
                                          }}
                                        ></Typography>
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      component="div"
                                      className="footer-sec"
                                    >
                                      {(!authUser ||
                                        (authUser &&
                                          authUser.unique_id !==
                                            donationRequest.user_info._id &&
                                          isMailSent !== 2)) &&
                                        donationRequest.creatorType ===
                                          "mentee" && (
                                          <Button
                                            size="small"
                                            className="donate-btn"
                                            onClick={donateButtonHandler}
                                            style={{
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                              opacity:
                                                donationRequest &&
                                                (donationRequest.status !== 1 ||
                                                  donationRequest.isScholarshipApplied) &&
                                                donationRequest.request_type ===
                                                  "career"
                                                  ? "0.5"
                                                  : 1,
                                              margin: "0 0 10px 0",
                                            }}
                                            disabled={
                                              donationRequest &&
                                              (donationRequest.status !== 1 ||
                                                donationRequest.isScholarshipApplied) &&
                                              donationRequest.request_type ===
                                                "career"
                                            }
                                          >
                                            {donationRequest.request_type ===
                                              "career" &&
                                            (window.location.pathname ===
                                              "/myApplications" ||
                                              donationRequest.isScholarshipApplied)
                                              ? "Interest sent"
                                              : "I'm Interested"}
                                          </Button>
                                        )}
                                    </Typography>
                                    {donationRequest &&
                                      donationRequest.request_type &&
                                      authUser &&
                                      authUser.unique_id ===
                                        donationRequest.user_info._id &&
                                      donationRequest.request_type ===
                                        "career" &&
                                      (donationRequest.status ===
                                        Number(STATUS_APPROVED) ||
                                        donationRequest.status ===
                                          Number(STATUS_EXPRIED)) && (
                                        <>
                                          <Typography
                                            component="div"
                                            className="footer-sec"
                                          >
                                            <Button
                                              size="small"
                                              className="donate-btn"
                                              onClick={() =>
                                                props.history.push(
                                                  `/viewApplicants/${donationRequest._id}`
                                                )
                                              }
                                            >
                                              View Applicants
                                            </Button>
                                          </Typography>
                                        </>
                                      )}
                                  </Typography>
                                )}
                                {donationRequest &&
                                  donationRequest.request_type === "help" && (
                                    <Typography
                                      className="fundraiser-container drop-box"
                                      style={{ padding: 20 }}
                                    >
                                      <Typography component="div">
                                        <Typography
                                          component="h3"
                                          className="payment-header"
                                          style={{ color: "#000" }}
                                        >
                                          Contact Information
                                        </Typography>
                                        {donationRequest &&
                                          donationRequest.additionalInfo &&
                                          donationRequest.additionalInfo
                                            .fullname && (
                                            <Typography
                                              component="div"
                                              className="payment-desc-container"
                                            >
                                              Patient Name:{" "}
                                              {donationRequest &&
                                                donationRequest.additionalInfo &&
                                                donationRequest.additionalInfo
                                                  .fullname}
                                            </Typography>
                                          )}
                                        <Typography
                                          component="div"
                                          className="payment-desc-container"
                                        >
                                          {(donationRequest &&
                                            donationRequest.additionalInfo &&
                                            donationRequest.additionalInfo
                                              .contactByPhone) ||
                                          (donationRequest &&
                                            donationRequest.additionalInfo &&
                                            donationRequest.additionalInfo
                                              .contactByEmail) ||
                                          address ? (
                                            <div
                                              className="contact-information
                                                  "
                                            >
                                              {donationRequest &&
                                                donationRequest.additionalInfo &&
                                                donationRequest.additionalInfo
                                                  .contactByEmail && (
                                                  <p
                                                    style={{
                                                      color: "#555",
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >
                                                    <MailOutlineIcon />{" "}
                                                    {donationRequest &&
                                                      donationRequest.user_info &&
                                                      donationRequest.user_info
                                                        .email}{" "}
                                                  </p>
                                                )}
                                              {donationRequest &&
                                                donationRequest.additionalInfo &&
                                                donationRequest.additionalInfo
                                                  .contactByPhone && (
                                                  <p
                                                    style={{
                                                      color: "#555",
                                                    }}
                                                  >
                                                    <PhoneIcon />{" "}
                                                    {donationRequest &&
                                                      donationRequest.user_info &&
                                                      donationRequest.user_info
                                                        .phone}{" "}
                                                  </p>
                                                )}
                                              {donationRequest &&
                                                donationRequest.additionalInfo &&
                                                donationRequest.additionalInfo
                                                  .alternateMobileNumber && (
                                                  <p
                                                    style={{
                                                      color: "#555",
                                                    }}
                                                  >
                                                    <PhoneIcon />{" "}
                                                    {donationRequest &&
                                                      donationRequest.additionalInfo &&
                                                      donationRequest
                                                        .additionalInfo
                                                        .alternateMobileNumber}{" "}
                                                  </p>
                                                )}
                                              <p style={{ color: "#555" }}>
                                                <LocationOn />{" "}
                                                {address &&
                                                  getObjectValuesInStringFormat(
                                                    address
                                                  )}
                                              </p>
                                            </div>
                                          ) : (
                                            <p>
                                              <i>No information available</i>
                                            </p>
                                          )}
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  )}
                                {values &&
                                  values.requestType === "cash" &&
                                  authUser &&
                                  donationRequest &&
                                  donationRequest.request_type === "cash" &&
                                  donationRequest.user_info &&
                                  authUser.unique_id ===
                                    donationRequest.user_info._id && (
                                    <Box p={2}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                      >
                                        <Box
                                          component="h3"
                                          fontWeight="500"
                                          fontSize="16px"
                                        >
                                          {isPrivate
                                            ? "This request is private. Make it public"
                                            : "This request is public. Make it private"}
                                        </Box>
                                        <Box component="span">
                                          <Box
                                            component="label"
                                            container
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Box component="span">
                                              <AntSwitch
                                                checked={isPrivate}
                                                onChange={handleUpdateIsPrivate}
                                                name="isPrivateRequest"
                                                inputProps={{
                                                  "aria-label":
                                                    "secondary checkbox",
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                      >
                                        A Private request is not available for
                                        public to view and can be viewed by a
                                        user only when the url is available
                                      </Typography>
                                    </Box>
                                  )}
                                <Box>
                                  {donationRequest &&
                                    authUser &&
                                    donationRequest.request_type === "cash" &&
                                    donationRequest.user_info &&
                                    authUser.unique_id ===
                                      donationRequest.user_info._id && (
                                      <>
                                        <Typography
                                          component="div"
                                          className="footer-sec"
                                        >
                                          <Box className="line">
                                            Power in Sharing
                                          </Box>
                                        </Typography>
                                        <Typography
                                          component="div"
                                          className="footer-sec"
                                        >
                                          <Button
                                            size="small"
                                            className="share-btn-detail"
                                            onClick={handleClickOpen}
                                            style={{
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                              opacity:
                                                donationRequest &&
                                                donationRequest &&
                                                donationRequest.status !== 1 &&
                                                donationRequest.status !== 0
                                                  ? // donationRequest.donated_quantity >=
                                                    //   donationRequest.quantity
                                                    "0.5"
                                                  : 1,

                                              margin: "0 0 10px 0",
                                            }}
                                            disabled={
                                              donationRequest &&
                                              donationRequest.status !== 1 &&
                                              donationRequest.status !== 0
                                                ? true
                                                : false
                                            }
                                          >
                                            Share
                                          </Button>
                                        </Typography>
                                      </>
                                    )}
                                  {donationRequest &&
                                    donationRequest.request_type === "cash" &&
                                    (donationRequest.status === 0 ||
                                      donationRequest.status === 1) &&
                                    ((authUser &&
                                      authUser.unique_id ===
                                        donationRequest.user_info._id) ||
                                      isSuperAdmin ||
                                      isAdmin) && (
                                      <Typography
                                        component="div"
                                        className="footer-sec"
                                      >
                                        <Button
                                          size="small"
                                          className="email-btn"
                                          onClick={launchEmailCampaign}
                                          style={{
                                            pointerEvents: isPreviewMode
                                              ? "none"
                                              : "auto",
                                            opacity:
                                              donationRequest &&
                                              donationRequest &&
                                              donationRequest.status !== 1 &&
                                              donationRequest.status !== 0
                                                ? // donationRequest.donated_quantity >=
                                                  //   donationRequest.quantity
                                                  "0.5"
                                                : 1,

                                            margin: "0 0 10px 0",
                                          }}
                                          disabled={
                                            donationRequest &&
                                            donationRequest.status !== 1 &&
                                            donationRequest.status !== 0
                                              ? true
                                              : false
                                          }
                                        >
                                          Send Email Campaign
                                        </Button>
                                        <EmailContactGroupsModal
                                          openModal={openModal}
                                          onModalClose={handleCloseGroups}
                                          donationRequest={donationRequest}
                                        ></EmailContactGroupsModal>
                                      </Typography>
                                    )}

                                  {donationRequest &&
                                    donationRequest.request_type === "cash" &&
                                    (donationRequest.status === 0 ||
                                      donationRequest.status === 1) &&
                                    ((authUser &&
                                      authUser.unique_id ===
                                        donationRequest.user_info._id) ||
                                      isSuperAdmin ||
                                      isAdmin) && (
                                      <div className="spambtn">
                                        <Grid
                                          component="div"
                                          className="footer-sec"
                                          md={6}
                                        >
                                          <Button
                                            size="small"
                                            className="email-btn"
                                          >
                                            Report as Spam
                                          </Button>
                                        </Grid>
                                        <Grid
                                          component="div"
                                          className="footer-sec"
                                          md={6}
                                        >
                                          <Button
                                            size="small"
                                            className="email-btn"
                                          >
                                            Report as Inappropriate
                                          </Button>
                                        </Grid>
                                      </div>
                                    )}
                                </Box>
                                {donationRequest &&
                                  (donationRequest.request_type ===
                                    "internship" ||
                                    donationRequest.request_type ===
                                      "homework help" ||
                                    donationRequest.request_type ===
                                      "volunteering" ||
                                    donationRequest.request_type ===
                                      "scholarship") && (
                                    <Typography
                                      component="section"
                                      className="drop-box"
                                    >
                                      <Typography
                                        component="div"
                                        className="amount-text"
                                      >
                                        <CardHeader
                                          onClick={
                                            donationRequest &&
                                            donationRequest.orgId &&
                                            donationRequest.fundsRecipient !==
                                              "self"
                                              ? () =>
                                                  props.history.push(
                                                    `/organization/${donationRequest.orgId._id}`
                                                  )
                                              : () =>
                                                  props.history.push(
                                                    `${donationRequest.user_info.username}`
                                                  )
                                          }
                                          style={{
                                            pointerEvents: isPreviewMode
                                              ? "none"
                                              : "auto",
                                            cursor: "pointer",
                                            padding: "0 16px 16px 0",
                                          }}
                                          avatar={
                                            <Avatar
                                              aria-label="recipe"
                                              className="avatar-container"
                                            >
                                              <figure>
                                                <img
                                                  src={
                                                    donationRequest &&
                                                    donationRequest.orgId
                                                      ? (donationRequest &&
                                                          donationRequest.orgId &&
                                                          donationRequest.orgId
                                                            .defaultImageUrl) ||
                                                        "/images/orgLogo.png"
                                                      : (donationRequest &&
                                                          donationRequest.user_info &&
                                                          donationRequest
                                                            .user_info
                                                            .image_url) ||
                                                        "/images/donor.png"
                                                  }
                                                  alt="user-logo"
                                                  style={{
                                                    verticalAlign: "middle",
                                                    width: "100%",
                                                  }}
                                                />
                                              </figure>
                                            </Avatar>
                                          }
                                          title={
                                            <span
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <Tooltip title="View Public Profile">
                                                <Fragment>
                                                  {donationRequest &&
                                                  donationRequest.orgId &&
                                                  donationRequest.orgId.orgName
                                                    ? donationRequest.orgId
                                                        .orgName
                                                    : donationRequest &&
                                                      donationRequest.user_info &&
                                                      donationRequest.user_info
                                                        .name &&
                                                      donationRequest.user_info
                                                        .name.first_name +
                                                        " " +
                                                        donationRequest
                                                          .user_info.name
                                                          .last_name}
                                                  <VerifiedUser
                                                    style={{
                                                      color: green[500],
                                                      width: 34,
                                                      paddingTop: 6,
                                                    }}
                                                  />
                                                </Fragment>
                                              </Tooltip>
                                            </span>
                                          }
                                          subheader={
                                            <span>
                                              <Typography
                                                component="span"
                                                className="date"
                                                style={{ fontSize: 13 }}
                                              >
                                                {values &&
                                                  moment(
                                                    values.createdAt
                                                  ).format(DATE_FORMAT)}
                                                <span> | </span>
                                                <span>
                                                  {donationRequest &&
                                                    donationRequest.createdAt &&
                                                    moment(
                                                      donationRequest.createdAt
                                                    )
                                                      .startOf("seconds")
                                                      .fromNow()}
                                                </span>
                                              </Typography>
                                            </span>
                                          }
                                        />
                                        <Typography
                                          component="div"
                                          className="payment-desc-container"
                                        >
                                          <Typography
                                            component="p"
                                            className="payment-info"
                                          >
                                            {donationRequest.title}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            {donationRequest &&
                                              donationRequest.orgId &&
                                              donationRequest.orgId
                                                .orgAddress && (
                                                <>
                                                  <LocationOn
                                                    style={{
                                                      fontSize: 13,
                                                      color: green[500],
                                                    }}
                                                  />
                                                  {donationRequest &&
                                                  donationRequest.orgId &&
                                                  donationRequest.orgId
                                                    .orgAddress &&
                                                  donationRequest.orgId
                                                    .orgAddress.city
                                                    ? donationRequest.orgId
                                                        .orgAddress.city
                                                    : ""}
                                                  {donationRequest &&
                                                  donationRequest.orgId &&
                                                  donationRequest.orgId
                                                    .orgAddress &&
                                                  donationRequest.orgId
                                                    .orgAddress.country
                                                    ? "  , " +
                                                      donationRequest.orgId
                                                        .orgAddress.country
                                                    : ""}
                                                </>
                                              )}
                                            {donationRequest &&
                                              !donationRequest.orgId &&
                                              address &&
                                              address.city &&
                                              address.country && (
                                                <>
                                                  <LocationOn
                                                    style={{
                                                      fontSize: 13,
                                                      color: green[500],
                                                    }}
                                                  />
                                                  {address && address.city
                                                    ? address.city
                                                    : ""}
                                                  {address && address.country
                                                    ? ", " + address.country
                                                    : ""}
                                                </>
                                              )}
                                          </Typography>
                                        </Typography>
                                      </Typography>

                                      {!authUser ||
                                      (authUser &&
                                        authUser.unique_id !==
                                          donationRequest.user_info._id &&
                                        donationRequest.request_type !==
                                          "scholarship") ? (
                                        <Typography
                                          component="div"
                                          className="footer-sec"
                                        >
                                          <Button
                                            size="small"
                                            className="donate-btn"
                                            onClick={donateButtonHandler}
                                            disabled={
                                              (donationRequest.request_type ===
                                                "internship" ||
                                                donationRequest.request_type ===
                                                  "volunteering" ||
                                                donationRequest.request_type ===
                                                  "homework help") &&
                                              donationRequest.isScholarshipApplied
                                            }
                                            style={{
                                              opacity:
                                                donationRequest &&
                                                (donationRequest.request_type ===
                                                  "internship" ||
                                                  donationRequest.request_type ===
                                                    "volunteering" ||
                                                  donationRequest.request_type ===
                                                    "homework help") &&
                                                donationRequest.isScholarshipApplied
                                                  ? "0.5"
                                                  : 1,
                                            }}
                                          >
                                            {donationRequest &&
                                            (donationRequest.request_type ===
                                              "internship" ||
                                              donationRequest.request_type ===
                                                "volunteering") &&
                                            donationRequest.isScholarshipApplied
                                              ? "Already Applied"
                                              : (donationRequest.request_type ===
                                                  "internship" ||
                                                  donationRequest.request_type ===
                                                    "volunteering") &&
                                                !donationRequest.isScholarshipApplied
                                              ? "Apply"
                                              : donationRequest.request_type ===
                                                  "homework help" &&
                                                donationRequest.isScholarshipApplied
                                              ? "Already helped"
                                              : "I want to help"}
                                          </Button>
                                        </Typography>
                                      ) : (
                                        donationRequest &&
                                        (!authUser ||
                                          (authUser &&
                                            authUser.unique_id !==
                                              donationRequest.user_info._id)) &&
                                        donationRequest.request_type ===
                                          "scholarship" &&
                                        donationRequest.status ===
                                          Number(STATUS_APPROVED) && (
                                          <Typography
                                            component="div"
                                            className="footer-sec"
                                          >
                                            <Button
                                              size="small"
                                              className="donate-btn"
                                              onClick={donateButtonHandler}
                                              disabled={
                                                donationRequest.isScholarshipApplied
                                              }
                                              style={{
                                                opacity:
                                                  donationRequest.request_type ===
                                                    "scholarship" &&
                                                  donationRequest.isScholarshipApplied
                                                    ? "0.5"
                                                    : 1,
                                              }}
                                            >
                                              {donationRequest.isScholarshipApplied
                                                ? "Already Applied"
                                                : "Apply Now"}
                                            </Button>
                                          </Typography>
                                        )
                                      )}
                                      {donationRequest &&
                                        donationRequest.request_type &&
                                        authUser &&
                                        authUser.unique_id ===
                                          donationRequest.user_info._id &&
                                        (donationRequest.request_type ===
                                          "scholarship" ||
                                          donationRequest.request_type ===
                                            "internship" ||
                                          donationRequest.request_type ===
                                            "volunteering" ||
                                          donationRequest.request_type ===
                                            "homework help") &&
                                        (donationRequest.status ===
                                          Number(STATUS_APPROVED) ||
                                          donationRequest.status ===
                                            Number(STATUS_EXPRIED)) && (
                                          <>
                                            <Typography
                                              component="div"
                                              className="footer-sec"
                                            >
                                              <Button
                                                size="small"
                                                className="donate-btn"
                                                onClick={() =>
                                                  props.history.push(
                                                    `/viewApplicants/${donationRequest._id}`
                                                  )
                                                }
                                              >
                                                View Applicants
                                              </Button>
                                            </Typography>
                                          </>
                                        )}
                                    </Typography>
                                  )}
                              </Typography>
                            </Typography>
                          </Typography>
                        </StickyBox>
                      </Grid>
                    </Grid>
                  </div>
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      )}

      <Hidden mdUp>
        <Typography component="div" className="fixed_donate_now dntTable">
          <Box borderBottom="1px solid #f1f1f1">
            {donationRequest && donationRequest.request_type === "cash" && (
              <Typography component="div" className="amount-text">
                <Grid
                  container
                  spacing={2}
                  style={{ alignItems: "center", padding: "0 16px" }}
                >
                  <Grid
                    xs={4}
                    item
                    style={{
                      width: "30%",
                      position: "relative",
                    }}
                  >
                    <Box component="div" className="dntres">
                      {`${values && values.donatedPercentage}%`}
                    </Box>
                    <CircularProgressbar
                      value={values && values.donatedPercentage}
                      styles={buildStyles({
                        // This is in units relative to the 100x100px
                        // SVG viewbox.
                        textSize: "14px",
                        textColor: "red",
                        pathColor: "green",
                        trailColor: "#eee",
                      })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Raised</Typography>
                    <Typography>
                      {values && formatNumber(values.donatedQuantity)}{" "}
                      {values && values.requestType === "cash"
                        ? values.cashUnit
                        : values && values.requestType === "kind"
                        ? values && values.kindUnit
                        : values && values.timeUnit}{" "}
                      {values && values.requestType === "kind"
                        ? values.requested_for
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Goal</Typography>
                    <Typography>
                      {values && formatNumber(values.quantity)}{" "}
                      {values && values.requestType === "cash"
                        ? values.cashUnit
                        : values && values.requestType === "kind"
                        ? values && values.kindUnit
                        : values && values.timeUnit}{" "}
                      {values && values.requestType === "kind"
                        ? values.requested_for
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography component="p">
                  <div
                    className="progress-text"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 16px",
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={(e) => showDonorsList(e)}
                    >
                      {donorsList && donorsList.length}
                      {"  "}
                      {donorsList && donorsList.length === 1
                        ? "Supporter"
                        : "Supporters"}
                      {showDonors ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </p>

                    {donationRequest && donationRequest.due_date && (
                      <span
                        style={{
                          float: "right",
                          textTransform: "none",
                        }}
                      >
                        {moment(donationRequest.due_date).diff(
                          moment().toDate(),
                          "days"
                        )}{" "}
                        days to go
                      </span>
                    )}
                  </div>

                  {showDonors && (
                    <div>
                      {donorsList && donorsList.length > 0 && (
                        <div style={{ border: "1px solid #ccc" }}>
                          <TableContainer>
                            <Table>
                              <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={donorsList.length}
                              />
                              <TableBody>
                                {stableSort(
                                  donorsList,
                                  getComparator(order, orderBy)
                                )
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((data) => (
                                    <TableRow key={data.id}>
                                      <TableCell component="th" scope="row">
                                        {data.userName}
                                      </TableCell>
                                      <TableCell>
                                        {data.units} {data.amount}
                                      </TableCell>
                                      <TableCell>
                                        {moment(data.donatedDate).format(
                                          DATE_FORMAT
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            component="div"
                            className="page-grid"
                            count={donorsList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Typography>
              </Typography>
            )}
          </Box>

          {values && values.requestType === "cash" && (
            <Button
              size="small"
              className="donate-btn"
              onClick={donateButtonHandler}
              style={{
                pointerEvents: isPreviewMode ? "none" : "auto",
                opacity:
                  donationRequest &&
                  donationRequest &&
                  donationRequest.status !== STATUS_NEW &&
                  donationRequest.status !== STATUS_APPROVED
                    ? // donationRequest.donated_quantity >=
                      //   donationRequest.quantity
                      "1"
                    : 0.5,

                margin: "0 0 10px 0",
                // display: values && values.requestType !== "cash" ? "none" : "",
              }}
              disabled={
                donationRequest &&
                donationRequest.status !== 1 &&
                donationRequest.status !== 0
                  ? true
                  : false
              }
            >
              Donate Now
            </Button>
          )}
        </Typography>
      </Hidden>

      <DonationRequestNotLoggedInDialog
        open={openOptions}
        donationRequest={donationRequest}
        onClose={() => setOpenOptions(false)}
      />
      {donationRequest && (
        <ApplyPost
          post={donationRequest}
          setDonationRequest={setDonationRequest}
          open={openIntern}
          onClose={() => {
            setOpenIntern(false);
          }}
        />
      )}
      <Loader isOpen={isLoading} onClose={(e) => setIsLoading(false)} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAnalyticsModal}
        onClose={handleAnalyticsClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAnalyticsModal}>
          <div className={`${classes.modalAnalytic} report-modal`}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              textAlign="center"
              padding="10px"
              className="modal-clr"
              // style={{
              //   backgroundColor: "#eee",
              //   borderTopLeftRadius: "10px",
              //   borderTopRightRadius: "10px",
              // }}
            >
              <Box
                component="h2"
                id="transition-modal-title"
                fontSize="18px"
                color="#090909"
                marginBottom="0px"
                marginLeft="8px"
              >
                Insights{" "}
              </Box>
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginRight="8px"
                onClick={handleAnalyticsClose}
                style={{ cursor: "pointer" }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box style={{ padding: "8px" }}>
              <div style={{ display: "flex" }}>
                <Button style={{ cursor: "unset", background: "none" }}>
                  <img
                    className="insight-img"
                    src={
                      donationRequest &&
                      donationRequest.orgId &&
                      donationRequest.fundsRecipient !== "self"
                        ? (donationRequest &&
                            donationRequest.orgId &&
                            donationRequest.orgId.defaultImageUrl) ||
                          "/images/orgLogo.png"
                        : (donationRequest &&
                            donationRequest.user_info &&
                            donationRequest.user_info.image_url) ||
                          "/images/donor.png"
                    }
                    alt="user-logo"
                  />{" "}
                </Button>
                <div style={{ paddingLeft: "10px" }}>
                  <h6 className="title-h6">
                    {donationRequest && donationRequest.title}
                  </h6>
                  <Typography
                    className="title-p"
                    style={{ color: "#6E6E6E", fontSize: "10px" }}
                  >
                    {donationRequest &&
                      moment(donationRequest.createdAt).format(
                        DATE_FORMAT_FULL_MONTH
                      )}{" "}
                    at{" "}
                    {donationRequest &&
                      moment(donationRequest.createdAt).format(TIME_FORMAT)}
                  </Typography>
                </div>
              </div>
              <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
              <Box className="post-actions-left share-app share-apps">
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Tooltip title="Like">
                    <IconButton
                      aria-label="like"
                      style={{ cursor: "unset", paddingLeft: "9px" }}
                    >
                      <img src="/images/thumbs-up.svg" alt="Internship" />{" "}
                    </IconButton>
                  </Tooltip>
                  <div>
                    {" "}
                    <Typography className="divider-p">
                      {donationRequest && donationRequest.likesCount}
                    </Typography>
                  </div>
                </div>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Tooltip title="comment">
                    <IconButton
                      aria-label="share"
                      className="share-req-icon"
                      style={{ cursor: "unset", marginLeft: "4px" }}
                    >
                      <img src="/images/Vector.svg" alt="Internship" />{" "}
                    </IconButton>
                  </Tooltip>
                  <div>
                    {" "}
                    <Typography className="divider-p">
                      {commentsCount}
                    </Typography>
                  </div>
                </div>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Tooltip title="share">
                    <IconButton style={{ cursor: "unset", marginLeft: "4px" }}>
                      <img src="/images/share.svg" alt="Internship" />{" "}
                    </IconButton>
                  </Tooltip>
                  <div>
                    <Typography className="divider-p">{sharesCount}</Typography>
                  </div>
                </div>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Tooltip title="Favourite">
                    <IconButton
                      aria-label="like"
                      style={{ cursor: "unset", marginRight: "8px" }}
                    >
                      <img src="/images/favorite.svg" alt="Internship" />{" "}
                    </IconButton>
                  </Tooltip>
                  <Typography className="divider-p">
                    {favouritesCount}
                  </Typography>
                </div>
              </Box>
              <Divider style={{ marginTop: "70px" }} />
              <div style={{ margin: "8px" }}>
                <div>
                  <p className="personal-links-p" style={{ margin: "8px" }}>
                    Overview:
                  </p>
                </div>
                <div className="personal-links" style={{ margin: "8px" }}>
                  <ul>
                    <li>
                      {" "}
                      <span>Post reached</span>
                      <span>
                        {donationRequest && donationRequest.noOfViews}
                      </span>
                    </li>

                    <li>
                      {" "}
                      <span>Post engaged</span>
                      <span>
                        {donationRequest &&
                          donationRequest.likesCount +
                            commentsCount +
                            sharesCount +
                            buttonClicks +
                            favouritesCount}
                      </span>{" "}
                    </li>
                    <li>
                      <span>CTA Clicks </span>
                      <span>{buttonClicks}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default DonationRequest;
