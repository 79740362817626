import moment from "moment";
import React from "react";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import "./DonationReceipt.scss";
import { Box } from "@material-ui/core";

class DonationReceipt extends React.Component {
  render() {
    const { receiptData } = this.props;
    return (
      <div>
        <table cellpadding="0" cellspacing="0" class="hedear_bg">
          <tr>
            <td>
              <img src="/images/TAL-logo-black.png" alt="successful" />
            </td>
          </tr>
        </table>
        <table cellpadding="0" cellspacing="0" class="body_color ">
          <tr>
            <td>
              <Box p={2} borderBottom=" 1px solid #f5f5f5">
                <table cellpadding="0" cellspacing="0" class="text_center">
                  <tr>
                    <td>
                      <small>For</small>
                      <strong>{receiptData && receiptData.donatedTo}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h1>
                        {receiptData && receiptData.units}{" "}
                        {receiptData && receiptData.amount}
                      </h1>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Plus 3% bank transaction charges</small>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="/images/right-icon.png" />
                      <span>Donation Successful</span>
                    </td>
                  </tr>
                </table>
              </Box>
              <Box p={2} borderBottom=" 1px solid #f5f5f5">
                <table cellpadding="5" cellspacing="0">
                  <tr>
                    <td>Receipt No. </td>
                    <td>{receiptData && receiptData.receiptId}</td>
                  </tr>
                  <tr>
                    <td>Payment ID </td>
                    <td>{receiptData && receiptData.paymentId}</td>
                  </tr>
                  <tr>
                    <td>Donated on </td>
                    <td>
                      {receiptData &&
                        moment(receiptData.donatedDate).format(
                          DATE_TIME_FORMAT
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>Mode of payment </td>
                    <td>{receiptData && receiptData.paymentMethod}</td>
                  </tr>

                  <tr>
                    <td>Account of</td>
                    <td>{receiptData && receiptData.donationFor}</td>
                  </tr>
                </table>
              </Box>
              <Box p={2}>
                <table cellpadding="5" cellspacing="0">
                  <tr>
                    <td>Name </td>
                    <td>{receiptData && receiptData.name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number </td>
                    <td>{receiptData && receiptData.phone}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {receiptData.address &&
                        receiptData.address.line1 &&
                        receiptData.address.line1.length > 0 &&
                        receiptData.address.line1.trim() &&
                        receiptData.address.line1 + ", "}
                      {receiptData.address &&
                        receiptData.address.line2 &&
                        receiptData.address.line2.length > 0 &&
                        receiptData.address.line2.trim() &&
                        receiptData.address.line2 + ", "}
                      {receiptData.address &&
                        receiptData.address.city &&
                        receiptData.address.city.length > 0 &&
                        receiptData.address.city.trim() &&
                        receiptData.address.city + ", "}
                      {receiptData.address &&
                        receiptData.address.state &&
                        receiptData.address.state.length > 0 &&
                        receiptData.address.state.trim() &&
                        receiptData.address.state + ", "}
                      {receiptData.address &&
                        receiptData.address.country &&
                        receiptData.address.country.length > 0 &&
                        receiptData.address.country.trim() &&
                        receiptData.address.country + ","}
                      {receiptData.address &&
                        receiptData.address.zip_code &&
                        receiptData.address.zip_code.length > 0 &&
                        receiptData.address.zip_code.trim() &&
                        receiptData.address.zip_code}
                    </td>
                  </tr>
                </table>
              </Box>
              <div class="footer">
                <h2>Touch-A-Life Foundation</h2>
                {receiptData && receiptData.units === "USD" ? (
                  <>
                    <p>Tax ID: 46-1060432</p>
                    <p>4701 Patrick Henry Drive,#20 Santa Clara,CA 95054</p>
                  </>
                ) : (
                  <>
                    <p>PAN#: AAGCT7121A</p>
                    <p>
                      12-13-522, Nagarjuna Nagar, Habsiguda, Tarnaka,
                      Hyderabad(Telangana)-500017, India
                    </p>
                  </>
                )}
                <p>
                  Please do not share your password,OTP. Credit/Debit card
                  number and CW or any other confidential information with
                  anyone even if he/she claims to be from Touch-A-life
                  Foundation. We advise our users to completely ignore such
                  communications.
                </p>
                <p>
                  Sent with
                  <span>
                    <img src="/images/red.jpg" style={{ width: "19px" }} />
                  </span>
                  from Touch-A-life
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default DonationReceipt;
