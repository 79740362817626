import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import VisibleIcon from "@material-ui/icons/Visibility";
import Box from "@material-ui/core/Box";

import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { appContext } from "../../App";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    borderRadius: "0",
  },

  span: {
    background: "rgb(205, 38, 39)",
  },

  modelbox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& div": {
        "&:nth-child(2)": {
          order: "3",
          MuiTabsFixed: {
            minWidth: "80px",
          },
          MuiTabsIndicator: {
            width: "70px",
            left: "0px",
          },
        },
        "&:nth-child(3)": {
          order: "2",
          "& Button": {
            position: "absolute",
            right: "0",
            "&:nth-child(1)": {
              top: "50px",
              right: "10px",
              minWidth: "0px",
            },
            "&:nth-child(2)": {
              top: "0px",
            },
          },
        },
      },
    },
  },
}));

function SimpleDialog(props) {
  const { onClose, open, emailTemplate } = props;
  const { setSelectedTemplate } = useContext(appContext);
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleSelect = (emailTemplate) => {
    setSelectedTemplate(emailTemplate);
    props.history.push(
      `/startContactEmailCampaign/EmailEdit/${props.match.params.id}`
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="email">
        <Box className={classes.modelbox}>
          <Box>
            <DialogTitle
              id="simple-dialog-title"
              style={{ padding: "8px 10px", fontSize: "16px" }}
            >
              Template preview mode
            </DialogTitle>
          </Box>

          <Box className="tab-desktop-mobile">
            <Paper
              className={classes.root}
              style={{ borderBottom: "1px solid #f6f7fb" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="primary"
                aria-label="email"
              >
                <Tab
                  label="Desktop"
                  {...a11yProps(0)}
                  style={{ padding: "0" }}
                />
                <Tab label="Mobile" {...a11yProps(1)} />
              </Tabs>
            </Paper>
          </Box>

          <Box className="save-close-popup">
            <Button
              className="select-button"
              onClick={() => handleSelect(emailTemplate)}
              variant="contained"
              style={{ background: "#EF6C00", color: "white", width: "20%" }}
              aria-label="save"
            >
              Select
            </Button>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <div
          className="start-email"
          style={{ overflowX: "auto", marginBottom: "0" }}
        >
          {ReactHtmlParser(emailTemplate.htmlText)}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          class="mobil-temp"
          // style={{ overflowX: "auto", marginBottom: "0", marginLeft: "25%" }}
        >
          {/* <iframe id="forceMobile" class="force-mobile"
                        src={"data:text/html," + encodeURIComponent(ReactHtmlParser(emailTemplate.htmlText))}
                    >
                    </iframe>s */}
          {ReactHtmlParser(emailTemplate.htmlText)}
        </div>
      </TabPanel>
    </Dialog>
  );
}
const EmailDialog = withRouter(SimpleDialog);
function EmailCardHover(props) {
  const [hiddenPopupMenu, setHiddenPopupMenu] = useState(true);
  const [open, setOpen] = React.useState(false);
  const toggle = () => {
    setHiddenPopupMenu(!hiddenPopupMenu);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="whenhovered" onClick={toggle}>
      {hiddenPopupMenu && (
        <div>
          <div className="mt-5 pt-5" />
          <Button
            style={{ color: "white", background: "rgb(239, 108, 0)" }}
            backgroundColor="transparent"
            textColor="white"
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<VisibleIcon style={{ color: "white" }} />}
          >
            Preview
          </Button>
        </div>
      )}
      <EmailDialog
        open={open}
        onClose={handleClose}
        emailTemplate={props.emailTemplate}
      />
    </div>
  );
}

export default withRouter(EmailCardHover);
