import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid, IconButton, Hidden, Link } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Drawer from "@material-ui/core/Drawer";
import moment from "moment";
import toastr from "toastr";
import "./Notifications.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appContext } from "../../App";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../SideMenu/Sidemenu";
import StickyBox from "react-sticky-box";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {
  getUserNotifications,
  updateNotification,
  deleteUserNotification,
} from "../../utils/api";

const Notifications = (props) => {
  const { authUser, userNotifications, setUserNotifications } = useContext(appContext);
  const [hamburger, setHamburger] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setUserDetails(authUser);
      getUserNotifications(authUser.unique_id)
        .then((response) => {
          if (response) {
            setUserNotifications(response);
          }
        })
        .catch((error) => {
          toastr.error(error.data.message);
        });
    }
  }, [authUser]);
  const handleHamburger = () => {
    setHamburger(true);
  };
  const handleDrawerClose = () => {
    setHamburger(false);
  };

  const updateNotificationStatus = (data) => {
    if (data && data.status === 0) {
      updateNotification(userDetails.unique_id, data._id)
        .then((response) => {
          if (response) {
            getUserNotifications(userDetails.unique_id)
              .then((response) => {
                setUserNotifications(response);
              })
              .catch((error) => {
                toastr.error(error.data.message);
              });
          }
        })
        .catch((error) => {
          toastr.error(error.data.message);
        });
    }
  };
  const deleteNotification = (data) => {
    if (userDetails) {
      deleteUserNotification(data, userDetails.unique_id)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            getUserNotifications(userDetails.unique_id)
              .then((response) => {
                setUserNotifications(response);
              })
              .catch((error) => {
                toastr.error(error.data.message);
              });
          }
        })
        .catch((error) => {
          toastr.error(error.data.message);
        });
    }
  };
  return (
    <div className="notifications">
      <Hidden mdUp>
        {authUser && (
          <IconButton
            className={` hamburger-icon`}
            onClick={handleHamburger}
            aria-label="show no. of items in inbox"
            color="inherit"
          >
            <FontAwesomeIcon size="1x" icon={faAngleDoubleRight} />
          </IconButton>
        )}
      </Hidden>

      <Drawer
        className={`profile-drawer-container`}
        variant="persistent"
        anchor="left"
        open={hamburger}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
      >
        <IconButton
          style={{ textalign: "right" }}
          className="chevor-icon"
          onClick={handleDrawerClose}
        >
          <FontAwesomeIcon size="1x" icon={faAngleDoubleLeft} />
        </IconButton>
        <hr />
        <SideMenu />
      </Drawer>
      <Container MaxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <Hidden smDown>
            <div className="container-left">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <SideMenu />
              </StickyBox>
            </div>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={9}>
          <div className="container-center drop-box">
            <div display="flex">
              <h5>Your Notifications</h5>
              <hr />
              {userNotifications &&
                userNotifications.map((data) => {
                  return (
                    <div
                      style={{
                        "background-color":
                          data.status === 0 ? "#f1f1f1" : "transparent",
                      }}
                    >
                      <List
                        style={{
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                      >
                        <ListItem
                          alignItems="flex-start"
                          style={{ cursor: "pointer" }}
                          onClick={() => updateNotificationStatus(data)}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt="Remy Sharp"
                              src={data.sourceImageUrl}
                            />
                          </ListItemAvatar>
                          <ListItemText 
                            primary={
                              data && data.destinationUrlReference ? (
                                <Link
                                  href={`/donationRequest/${data.destinationUrlReference}`}
                                  target="blank"
                                >
                                  <div
                                    style={{
                                      "font-weight":
                                        data.status === 0 ? "bold" : "normal",
                                    }}
                                  >
                                    {data.message}
                                  </div>
                                </Link>
                              ) : (
                                <div
                                  style={{
                                    "font-weight":
                                      data.status === 0 ? "bold" : "normal",
                                  }}
                                >
                                  {data.message}
                                </div>
                              )
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {moment(data.createdAt).format(
                                    DATE_TIME_FORMAT
                                  )}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemAvatar>
                            <DeleteIcon
                              onClick={() => deleteNotification(data._id)}
                            />
                          </ListItemAvatar>
                        </ListItem>
                        <hr />
                      </List>
                    </div>
                  );
                })}
              {userNotifications && userNotifications.length === 0 ? (
                <div
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    margin: "20px 0",
                  }}
                >
                  You have no new notifications
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Notifications);
