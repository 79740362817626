import React, { useState, useContext, useEffect, Fragment } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReactHtmlParser from "react-html-parser";
import firebase from "../../firebase/firebase";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import LocationOn from "@material-ui/icons/LocationOn";
import { green } from "@material-ui/core/colors";
import { lighten, withStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import SideMenu from "../SideMenu/Sidemenu";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ShareIcon from "@material-ui/icons/Share";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

import {
  Button,
  TextareaAutosize,
  TextField,
  Tooltip,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import {
  getDonationRequest,
  updateDonationRequest,
  deleteDonationRequest,
  getDonorsList,
  CareerHelpShareDetails,
  getOrganizationList,
} from "../../utils/api";
import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import AddVideo from "../common/AddVideo";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { favourite, likeOrUnlike } from "../../utils/api";
import { appContext } from "../../App";
import Payments from "../donationDetails/PaymentsPopUp";
import "./PostDetails.scss";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  formatNumber,
  isImage,
  HOME_PAGE,
  DATE_FORMAT,
  DONATION_REQUEST_STATUS_MAP,
  STATUS_NEW,
  STATUS_INCOMPLETE,
  STATUS_SPAM,
  STATUS_REJECTED,
  STATUS_APPROVED,
  getObjectValuesInStringFormat,
} from "../../utils/utils";
import DocumentRow from "../common/DocumentRow";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import StickyBox from "react-sticky-box";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

//ALert
import { Alert, AlertTitle } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import Comments from "../donationRequest/Comments";
import DonationRequestNotLoggedInDialog from "../donationRequest/DonationRequestNotLoggedInDialog";
import DonationRequestForm from "../donationRequest/DonationRequestForm";
import DonationRequestUpdate from "../donationRequest/DonationRequestUpdate";
import EmailContactGroupsModal from "../donationRequest/EmailContactGroupsModal";
import ShareDonationRequest from "../donationRequest/ShareDonationRequest";
import KindTimeDonation from "../donationDetails/KindTimeDonation";
import OfflineDonationDetails from "../donationRequest/OfflineDonationDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div" className="details-child">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

// table columns sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "userName", numeric: false, label: "Name" },
  { id: "amount", numeric: true, label: "Amount" },
  { id: "donatedDate", numeric: true, label: "Date" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#ccc" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const PostDetails = (props) => {
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [commentsCount, setCommentsCount] = useState("");
  const [isKindTimeDonationOpen, setIsKindTimeDonationOpen] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const {
    authUser,
    isAdmin,
    isVolunteer,
    isSuperAdmin,
    setSelectedOrganization,
  } = useContext(appContext);
  const [id, setId] = useState("");
  const classes = useStyles();
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [, setStartDate] = useState(0);
  const [, setDueDate] = useState(0);
  const [address, setAddress] = useState("");
  const [values, setValues] = useState(null);
  const [, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [companyDescription, setCompanyDescription] = useState("");

  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [post, setPost] = useState(null);
  const [active25, setActive25] = useState(false);
  const [active50, setActive50] = useState(false);
  const [active100, setActive100] = useState(false);
  const [active250, setActive250] = useState(false);
  const [active500, setActive500] = useState(false);
  const [activeOther, setActiveOther] = useState(false);
  const [showDonors, setShowDonors] = useState(false);
  const [donatedperc, setDonatedperc] = useState(0);
  const [donAmt, setDonAmt] = useState(0);
  const [shareIcons, setShareIcons] = useState(false);
  const [showLiked, setShowLiked] = useState(false);
  const [showFavorited, setShowFavorited] = useState(false);
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false);
  const [donorsList, setDonorsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("amount");
  const [openOfflineDonationPopUp, setOpenOfflineDonationPopUp] =
    useState(false);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isOpenPostModal, setIsOpenPostModal] =
    useState(false);
  const [value, setValue] = React.useState(
    props.location.state && props.location.state.isUpdate ? 1 : 0
  );
  const [, setIsOrgOwnerOrAdmin] = useState(false);

  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const onClick = () => {
    setRefresh(!refresh);
  };

  const donateButtonHandler = async () => {
    if (isPreviewMode) {
      return;
    }
    if (authUser && authUser.unique_id) {
      if (post && post.request_type === "cash") {
        setIsPaymentsOpen(true);
      } else if (post && post.request_type === "career") {
        if (authUser.isMentor && post.creatorType === "mentee") {
          setIsLoading(true);
          setIsMailSent(1);
          let Obj = {
            mentorName:
              authUser.name.first_name + " " + authUser.name.last_name,
            mentorEmail: authUser.email,
            menteeName:
              post.user_info.name.first_name +
              " " +
              post.user_info.name.last_name,
            menteeEmail: post.user_info.email,
            menteePhone: post.user_info.phone,
          };
          await CareerHelpShareDetails(Obj)
            .then(() => {
              setIsMailSent(2);
            })
            .catch(() => {
              setIsLoading(false);
              setIsMailSent(false);
            });
        } else {
          props.history.push("/careerhelp-request");
        }
      } else {
        setIsKindTimeDonationOpen(true);
      }
    } else {
      if (post && post.request_type === "cash") {
        setOpenOptions(true);
      } else {
        localStorage.setItem(
          "redirectUri",
          `/post-details/${post._id}`
        );
        props.history.push("/login");
      }
    }
  };

  const saveDonationHandler = (donatedQuantity) => {
    setIsKindTimeDonationOpen(false);
    setIsPaymentsOpen(false);

    setValues((currentValues) => {
      return {
        ...currentValues,
        donatedQuantity:
          currentValues.donatedQuantity + Number(donatedQuantity),
      };
    });
    toastr.success("Thank you for making a contribution.");
  };

  const makeFavourite = () => {
    if (isPreviewMode) {
      return;
    }
    setShowFavorited((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = post["_id"];
      obj.userId = authUser.unique_id;
      if (showFavorited === false) {
        obj.type = "favourite"; // favourite or unfavourite
      } else if (showFavorited === true) {
        obj.type = "unfavourite";
      }
      favourite(obj);
    } else {
      props.history.push("/login");
      toastr.warning("Please signup or login to complete this action");
      setShowFavorited(post && post.isFavourite);
    }
  };

  const handleLikeDonation = () => {
    if (isPreviewMode) {
      return;
    }
    setShowLiked((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = post["_id"];
      obj.userId = authUser.unique_id;
      if (showLiked === false) {
        post.likesCount = post.likesCount + 1;
        obj.type = "like"; // like or unlike
      } else if (showLiked === true) {
        post.likesCount = post.likesCount - 1;
        obj.type = "unlike"; // like or unlike
      }
      likeOrUnlike(obj);
    } else {
      props.history.push("/login");
      toastr.warning("Please signup or login to complete this action");
      setShowLiked(post && post.isLike);
    }
  };

  const loadPost = (postId) => {
    const localStorageDescription = localStorage.getItem(
      "postDescription"
    );
    const localStorageBrief = localStorage.getItem("postBrief");
    const localStorageLocation = localStorage.getItem(
      "postLocation"
    );
    const localStorageJustifyToOthers = localStorage.getItem(
      "postJustifyToOthers"
    );
    const isPreview = localStorage.getItem("isPreviewMode");
    localStorage.removeItem("postDescription");
    localStorage.removeItem("postBrief");
    localStorage.removeItem("postLocation");
    localStorage.removeItem("postJustifyToOthers");
    localStorage.removeItem("isPreviewMode");
    if (localStorageDescription || isPreview) {
      setIsPreviewMode(true);
    }
    getDonationRequest(postId, authUser && authUser.unique_id)
      .then((response) => {
        setPost(response);
        setId(response._id);
        setDefaultImageUrl(response.defaultImageUrl);
        setStartDate(moment(response.start_date));
        setDueDate(moment(response.due_date));
        setTags(response.tags);
        setAddress({
          addressLine1: response.shipping_address.line1,
          addressLine2: response.shipping_address.line2,
          city: localStorageLocation
            ? localStorageLocation
            : response.shipping_address.city,
          region: response.shipping_address.state,
          zip: response.shipping_address.zip_code,
          country: response.shipping_address.country,
        });
        let donatedPercentage = 0;
        if ((response.donated_quantity * 100) / response.quantity > 100) {
          donatedPercentage = 100;
        } else {
          donatedPercentage = Math.round(
            (response.donated_quantity * 100) / response.quantity
          );
        }
        setDonatedperc(donatedPercentage);

        setValues((currentValues) => {
          return {
            ...currentValues,
            requestType: response.request_type,
            title: response.title,
            description: localStorageDescription
              ? localStorageDescription
              : response.description,
            brief: localStorageBrief ? localStorageBrief : response.brief,
            tags: response.tags,
            quantity: response.quantity,
            unit: response.units,
            donatedQuantity: response.donated_quantity,
            whyYouNeedHelp: localStorageJustifyToOthers
              ? localStorageJustifyToOthers
              : response.whyYouNeedHelp,
            donatedPercentage: donatedPercentage,
            userInfo: response.user_info,
            createdAt: response.createdAt,
            due_date: response.due_date,
          };
        });
        if (response.request_type === "cash") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              cashQuantity: response.quantity,
              cashUnit: response.units && response.units.toUpperCase(),
            };
          });
        } else if (response.request_type === "kind") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              kindQuantity: response.quantity,
              kindUnit: response.units && response.units.toUpperCase(),
            };
          });
        } else if (response.request_type === "help") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              requestedForDetails: response.requestedForDetails,
            };
          });
        } else if (response.request_type === "time") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              timeQuantity: response.quantity,
              timeUnit: response.units,
            };
          });
        }
      })
      .catch((error) => {
        if (error.data.statusCode === 422) {
          toastr.error(error.data.message);
          props.history.push(HOME_PAGE);
        }
      });
  };

  useEffect(() => {
    if (post && post._id) {
      loadDonorsList(post._id);
      setShowLiked(post.isLike);
      setShowFavorited(post.isFavourite);
      fetchImages(post._id);
      fetchVideos(post._id);
      fetchDocuments(post._id);
      // orgList.forEach((org) => {
      //   console.log("Org....", JSON.stringify(org));
      //   if (org.orgId._id === post.fundsRecipient) {
      //     console.log(org.orgId.description);
      //     setCompanyDescription(org.orgId.description);
      //   }
      // });
    }
  }, [post]);

  // useEffect(() => {
  //   if (authUser) {
  //     getOrganizationList(authUser.unique_id)
  //       .then((resp) => {
  //         setOrgList(resp);
  //       })
  //       .catch((error) => {
  //         toastr.error(error.message);
  //       });
  //   }
  // }, []);

  const savePost = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser.unique_id,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success(
          "Default profile Image is set for this donation request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the donation request. " +
            error.message
        );
      });
  };

  const submitForApprovalHandler = () => {
    const finalValues = {
      description: values.description,
      user_id: authUser.unique_id,
      status: 0,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        if (post.request_type === "help") {
          toastr.success("Covid help request created successfully");
        } else {
          toastr.success("Fundraiser created successfully");
        }
        props.history.push(HOME_PAGE);
      })
      .catch((error) => {
        toastr.error("Error while saving fundraiser: " + error.message);
      });
  };

  const loadDonorsList = (postId) => {
    getDonorsList(postId)
      .then((response) => {
        const detailsArray = [];
        response.data.data.map((details) => {
          const obj = {};
          obj.donationId = details["_id"];
          obj.units =
            details.units && details.units !== ""
              ? details.units.toUpperCase()
              : "";
          obj.amount = details.quantity;
          if (details.anonymous === true) {
            obj.userName = "Anonymous";
          } else {
            if (details.user_info) {
              obj.userName = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
              obj.profileImage = details.user_info.image_url;
            } else if (details.externalUserInfo) {
              obj.userName = details.externalUserInfo.name;
              obj.profileImage = "";
            }
          }
          obj.donatedDate = details.createdAt;
          detailsArray.push(obj);
          return null;
        });
        setDonorsList(detailsArray);
      })
      .catch(() => {
        //toastr.error(error);
      });
  };

  // Load Post Object
  useEffect(() => {
    if (props.match.params.id) {
      loadPost(props.match.params.id);
    }
  }, [props.match.params.id, refresh]);

  useEffect(() => {
    if (
      isSuperAdmin ||
      isAdmin ||
      isVolunteer ||
      (authUser &&
        post &&
        (post.status == STATUS_NEW ||
          post.status == STATUS_INCOMPLETE) &&
        post.user_info &&
        authUser.unique_id === post.user_info._id)
    ) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    if (authUser && post) {
      const donateNowButtonClicked = localStorage.getItem(
        "donateNowButtonClicked"
      );
      localStorage.removeItem("donateNowButtonClicked");
      if (donateNowButtonClicked && donateNowButtonClicked === "true") {
        donateButtonHandler();
      }
    }

    if (post) {
      //get comments count
      const ref = firebase.realtimeDb.ref(
        "/conversations/" + post._id
      );

      ref.on("value", (snapshot) => {
        const comments = snapshot.val();
        const commentsArray = comments ? comments : [];
        setCommentsCount(
          commentsArray
            ? commentsArray && Object.keys(commentsArray).length
            : "0"
        );
      });
    }
  }, [authUser, post]);

  useEffect(() => {
    if (authUser && post && post.orgId) {
      if (authUser.unique_id === post.orgId.user_info) {
        setIsOrgOwnerOrAdmin(true);
      } else if (orgList && orgList.length > 0) {
        orgList.forEach((org) => {
          if (org.orgId && org.orgId._id === post.orgId._id) {
            if (org.role === "owner" || org.role === "admin") {
              setIsOrgOwnerOrAdmin(true);
            }
          }
        });
      }
    }
  }, [authUser, post, orgList]);

  const BorderLinearProgress = withStyles({
    root: {
      height: 12,
      borderRadius: 10,
      backgroundColor: lighten("#E0E0E0", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor:
        donatedperc !== null && donatedperc < 26
          ? "red"
          : donatedperc < 76
          ? "orange"
          : "green",
    },
  })(LinearProgress);

  const fetchImages = (uid) => {
    // Fetch images
    const path = `DonationRequest/${uid}/images`;
    const storageRef = firebase.storageDonationRequests.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          setImages([]);
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: url,
                    thumbnail: url,
                  },
                ];
              });
            });
          });
        } else {
          setImages([
            {
              original: `${
                post && post.defaultImageUrl
                  ? post.defaultImageUrl
                  : "/images/default-donation-request.jpg"
              }`,
              thumbnail: `${
                post && post.defaultImageUrl
                  ? post.defaultImageUrl
                  : "/images/default-donation-request.jpg"
              }`,
            },
          ]);
        }
      })
      .catch(() => {});
  };

  const fetchVideos = (uid) => {
    // Fetch videos
    const path = `DonationRequest/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: "/images/default-thumbnail.jpeg",
                    thumbnail: "/images/default-thumbnail.jpeg",
                    embedUrl: url,
                    renderItem: _renderVideo.bind(this),
                  },
                ];
              });
            });
          });
        }
      })
      .catch(() => {});
  };

  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const fetchDocuments = (uid) => {
    const path = `DonationRequest/${uid}/documents`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      setImageDocuments([]);
      setNonImageDocuments([]);
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              if (isImage(metadata.contentType)) {
                setImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              } else {
                setNonImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              }
            })
            .catch(() => {
              //toastr.error(error.message);
            });
        });
      });
    });
  };

  const onCloseAddPhoto = () => {
    fetchImages(id);
    setShowingAddPhotoModal(false);
  };

  const onCloseAddDocument = () => {
    fetchDocuments(id);
    setShowingAddDocumentModal(false);
  };

  const onCloseAddVideo = () => {
    setShowingAddVideoModal(false);
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    savePost(url);
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success(message);
  };

  const userProfileHandler = () => {
    if (values && values.userInfo && values.userInfo.username) {
      props.history.push(`/${values.userInfo.username}`);
    }
  };

  const orgProfileHandler = (orgId) => {
    props.history.push(`/organization/${orgId}`);
  };

  const handleDonationAmount = (identifier, amt) => {
    if (identifier !== "other") {
      setDonAmt(amt);
    } else {
      setDonAmt();
    }
    if (identifier === "25") {
      setActive25(!active25);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "50") {
      setActive25(false);
      setActive50(!active50);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "100") {
      setActive25(false);
      setActive50(false);
      setActive100(!active100);
      setActive250(false);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "250") {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(!active250);
      setActive500(false);
      setActiveOther(false);
    } else if (identifier === "500") {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(!active500);
      setActiveOther(false);
    } else {
      setActive25(false);
      setActive50(false);
      setActive100(false);
      setActive250(false);
      setActive500(false);
      setActiveOther(!activeOther);
    }
  };

  const handleOtherAmt = (e) => {
    setDonAmt(Number(e.target.value));
  };

  const showDonorsList = () => {
    setShowDonors(!showDonors);
  };

  const openOfflineDonationDetails = () => {
    setOpenOfflineDonationPopUp(!openOfflineDonationPopUp);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateSavedPost = (updatedPost) => {
    setIsOpenPostModal(false);
    loadPost(updatedPost._id);
  };

  const handlePlasmaDonate = (req) => {
    const finalValues = {
      donated_quantity: 1,
      user_id: req.user_info._id,
      status: 2,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        toastr.success("Updated successfully.");
        setRefresh(!refresh);
      })
      .catch((error) => {
        toastr.error(
          "Error while updating the donation request. " + error.message
        );
      });
  };

  const handleDeleteRequest = () => {
    if (
      window.confirm("Are you sure you want to delete this donation request?")
    ) {
      deleteDonationRequest(post._id, authUser.unique_id)
        .then(() => {
          toastr.success("Donation Request is deleted successfully");
          props.history.push("/");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const launchEmailCampaign = () => {
    if (authUser && authUser.unique_id) {
      setSelectedOrganization(null);
      setOpenModal(true);
    } else {
      localStorage.setItem(
        "redirectUri",
        `/post-details/${props.match.params.id}`
      );
      toastr.warning("Please signup or login to complete this action.");
      props.history.push("/login");
    }
  };
  const handleCloseGroups = () => {
    setOpenModal(false);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div>
      {post && post.status === -3 ? (
        <div className="spam-text">
          <i>
            This Fundraiser is reported as <b>{post.flagReason}</b>.
            If you created this Fundraiser and think it is not spam, please
            email{" "}
            <a href="mailto:support@touchalife.org">support@touchalife.org</a>{" "}
            explaining why you think it is not spam.
          </i>
        </div>
      ) : (
        <div className="don-details-container">
          <div className="admin-statuse-requast-main">
            {post &&
              DONATION_REQUEST_STATUS_MAP[post.status] &&
              !isPreviewMode && (
                <Collapse in={openAlert}>
                  <Box
                    className="admin-statuse-requast"
                    bgcolor={
                      post &&
                      DONATION_REQUEST_STATUS_MAP[post.status]
                        .bgcolor
                    }
                  >
                    <Container maxWidth="lg">
                      <Alert
                        style={{ padding: "0" }}
                        variant="filled"
                        icon={true}
                        severity={" "}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <AlertTitle>
                          Status:{" "}
                          {post &&
                            DONATION_REQUEST_STATUS_MAP[
                              post.status
                            ] &&
                            DONATION_REQUEST_STATUS_MAP[post.status]
                              .label}
                        </AlertTitle>
                      </Alert>
                    </Container>
                  </Box>
                </Collapse>
              )}
          </div>

          <Container maxWidth="lg">
            <div className="menu-container">
              {closeSideMenu && (
                <Hidden mdUp>
                  {" "}
                  <div style={{ marginRight: "15px", width: "340px" }}>
                    <SideMenu />
                  </div>
                </Hidden>
              )}

              {/* <Hidden smDown> <div style={{marginRight:"15px", width:"340px"  }}><SideMenu /></div></Hidden> */}

              <div style={{ width: "100%" }}>
                <Typography component="div" className="head-container">
                  <div className="spacing_mobile">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8}>
                        <Typography component="div" className="image">
                          <div className="fill-color drop-box">
                            <Typography className="donat-title" component="h2">
                              {values && values.title}{" "}
                              <span className="postTitle-fav">
                                {showFavorited === false ? (
                                  <FavoriteBorderIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => makeFavourite()}
                                  />
                                ) : (
                                  <FavoriteIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => makeFavourite()}
                                  />
                                )}
                              </span>
                            </Typography>
                            <Typography
                              component="p"
                              variant="subtitle1"
                              gutterBottom
                              className="postSubTitle"
                            >
                              {post &&
                                post.shipping_address &&
                                post.shipping_address.city &&
                                post.shipping_address.country && (
                                  <span>
                                    <LocationOn
                                      style={{
                                        fontSize: 14,
                                        color: "green",
                                        justifyContent: "space-between",
                                        margin: "15px",
                                      }}
                                    />
                                    {post.shipping_address.city},
                                    {post.shipping_address.country}
                                  </span>
                                )}
                            </Typography>
                            <Typography
                              component="h4"
                              style={{ paddingLeft: 20 }}
                            >
                              {post &&
                                post.request_type === "help" &&
                                values &&
                                values.brief}
                            </Typography>
                            <ImageGallery
                              showBullets={true}
                              defaultImage={
                                post &&
                                post.request_type === "plasma"
                                  ? post.defaultImageUrl
                                  : "/images/default-donation-request.jpg"
                              }
                              showThumbnails={false}
                              lazyLoad={true}
                              showPlayButton={false}
                              items={images}
                              showFullscreenButton={false}
                            />

                            <div className="req-date">
                              {values && values.tags && values.tags.length > 0 && (
                                <div className="category">
                                  <b>Category: </b>

                                  <span className="req-name">
                                    {values &&
                                      values.tags &&
                                      values.tags.length > 0 &&
                                      values.tags.map((tag) => tag + " ")}
                                  </span>

                                  <div className="shear-btn">
                                    <div
                                      style={{
                                        margin: "17px 0",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {values &&
                                        moment(values.createdAt).format(
                                          DATE_FORMAT
                                        )}
                                    </div>

                                    <div className="icon-btn">
                                      <IconButton
                                        className="action-btn"
                                        aria-label="add to favorites"
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                        }}
                                        onClick={handleLikeDonation}
                                      >
                                        <ThumbUpOutlinedIcon
                                          style={{
                                            color: showLiked ? "blue" : "#666",
                                          }}
                                        />
                                        <span
                                          style={{
                                            font: "500 11px Roboto",
                                            marginLeft: 4,
                                            color: showLiked ? "blue" : "#666",
                                          }}
                                        >
                                          Like (
                                          {post &&
                                            post.likesCount}
                                          )
                                        </span>
                                      </IconButton>

                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                          padding: "6px 15px 0 0",
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                        }}
                                        onMouseOver={() => setShareIcons(true)}
                                        onMouseLeave={() =>
                                          setShareIcons(false)
                                        }
                                        className="action-btn"
                                      >
                                        <Tooltip title="Share request">
                                          <IconButton
                                            aria-label="share"
                                            className="share-req-icon"
                                          >
                                            <ShareIcon />
                                            <span
                                              style={{
                                                font: "500 11px Roboto",
                                                marginLeft: 4,
                                                color: "#000 !important",
                                                position: "relative",
                                                pointerEvents: isPreviewMode
                                                  ? "none"
                                                  : "auto",
                                              }}
                                            >
                                              Share (
                                              {post &&
                                                post.sharesCount}
                                              )
                                            </span>
                                          </IconButton>
                                        </Tooltip>

                                        {shareIcons && (
                                          <span
                                            style={{
                                              background: "white",
                                              padding: "8px",
                                              boxShadow:
                                                "rgb(233, 233, 233) 0px 0px 11px",
                                              borderRadius: "4px",
                                              display: "inline-block",
                                              position: "absolute",
                                              right: "5px",
                                              width: "200px",
                                              bottom: "40px",
                                              zIndex: "1",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                          >
                                            <ShareDonationRequest
                                              post={post}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <Typography
                              component="div"
                              className="details-child"
                              marginTop="10px"
                            >
                              <Typography component="div">
                                <Typography className="adding-container">
                                  <div className="donationrequst-edit">
                                    {canEdit && (
                                      <div>
                                        <Typography>
                                          <Tooltip title="Edit Request">
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                pointerEvents: isPreviewMode
                                                  ? "none"
                                                  : "auto",
                                              }}
                                              onClick={() =>
                                                setIsOpenPostModal(
                                                  true
                                                )
                                              }
                                            >
                                              <EditOutlinedIcon
                                                style={{ fontSize: 36 }}
                                              />
                                            </span>
                                          </Tooltip>
                                          <DonationRequestForm
                                            postId={
                                              post &&
                                              post._id
                                            }
                                            open={isOpenPostModal}
                                            close={() =>
                                              setIsOpenPostModal(
                                                false
                                              )
                                            }
                                            onSave={updateSavedPost}
                                          />
                                        </Typography>
                                      </div>
                                    )}

                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Images">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddPhotoModal(true)
                                            }
                                          >
                                            <PhotoCameraOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddPhotos
                                          title={
                                            canEdit
                                              ? "Add Images"
                                              : "View Images"
                                          }
                                          isOpen={showingAddPhotoModal}
                                          onClose={onCloseAddPhoto}
                                          uid={id}
                                          collection="Post"
                                          profilephotoURL={defaultImageUrl}
                                          onProfilephotoURLChange={
                                            updateDefaultImageUrl
                                          }
                                          canEdit={canEdit}
                                        />
                                      </Typography>
                                    </div>

                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Supporting Documents">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddDocumentModal(true)
                                            }
                                          >
                                            <DescriptionOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddDocument
                                          title={
                                            canEdit
                                              ? "Add Supporting Documents"
                                              : "View Supporting Documents"
                                          }
                                          isOpen={showingAddDocumentModal}
                                          onClose={onCloseAddDocument}
                                          uid={id}
                                          collection="Post"
                                          onSavingDocument={onSavingDocument}
                                          canEdit={canEdit}
                                        />
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography>
                                        <Tooltip title="View or Add Videos">
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={() =>
                                              setShowingAddVideoModal(true)
                                            }
                                          >
                                            <VideocamOutlinedIcon
                                              style={{ fontSize: 36 }}
                                            />{" "}
                                            {canEdit && (
                                              <AddCircleIcon className="add-circle" />
                                            )}
                                          </span>
                                        </Tooltip>
                                        <AddVideo
                                          title={
                                            canEdit
                                              ? "Add Videos"
                                              : "View Videos"
                                          }
                                          isOpen={showingAddVideoModal}
                                          onClose={onCloseAddVideo}
                                          uid={id}
                                          collection="Post"
                                          onSavingDocument={onSavingVideo}
                                          canEdit={canEdit}
                                        />
                                      </Typography>
                                    </div>
                                    {canEdit &&
                                      post &&
                                      post.donated_quantity ===
                                        0 && (
                                        <div>
                                          <Typography>
                                            <Tooltip title="Delete Request">
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  pointerEvents: isPreviewMode
                                                    ? "none"
                                                    : "auto",
                                                }}
                                                onClick={handleDeleteRequest}
                                              >
                                                <DeleteForeverIcon
                                                  style={{ fontSize: 36 }}
                                                />
                                              </span>
                                            </Tooltip>
                                          </Typography>
                                        </div>
                                      )}
                                  </div>
                                </Typography>
                                <Typography className="tab_space">
                                  <AppBar
                                    position="static"
                                    style={{
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <Tabs
                                      value={value}
                                      onChange={handleChange}
                                      aria-label="simple tabs example"
                                    >
                                      <Tab label="Details" {...a11yProps(0)} />
                                      <Tab
                                        label={`Updates ( ${
                                          post &&
                                          post.totalUpdatesCount
                                            ? post.totalUpdatesCount
                                            : 0
                                        } )`}
                                        {...a11yProps(1)}
                                      />
                                      <Tab
                                        // style={{ color: "#fff" }}
                                        label={`Comments ( ${
                                          commentsCount > 0 ? commentsCount : 0
                                        } )`}
                                        {...a11yProps(1)}
                                        onClick={() =>
                                          setIsCommentsDialogOpen(true)
                                        }
                                      />
                                    </Tabs>
                                  </AppBar>
                                  <TabPanel
                                    component="div"
                                    value={value}
                                    index={0}
                                    style={{ padding: 0 }}
                                  >
                                    {post &&
                                      post.request_type ===
                                        "internship" && (
                                        <>
                                          <Grid
                                            className={classes.root}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                            container
                                          >
                                            <Grid container spacing={3}>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Org. Type
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {post &&
                                                    post.orgId &&
                                                    post.orgId
                                                      .orgType}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Start Date
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {moment(
                                                    post.start_date
                                                  ).format(DATE_FORMAT)}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Duration
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {post
                                                    .additionalInfo.duration +
                                                    "  days"}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Apply By
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {moment(
                                                    post.due_date
                                                  ).format(DATE_FORMAT)}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Stipend
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {post
                                                    .additionalInfo
                                                    .monthlyStipend + " /month"}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  Internship Type
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {
                                                    post
                                                      .additionalInfo
                                                      .trainingMode
                                                  }
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    paddingTop: "10px",
                                                    fontSize: "16px",
                                                    fontWeight: "regular",
                                                  }}
                                                >
                                                  No. of. Openings
                                                </Typography>
                                                <Typography
                                                  className="donation-text"
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {
                                                    post
                                                      .additionalInfo
                                                      .numberOfOpenings
                                                  }
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                {post.description}
                                              </Typography>
                                            </Grid>
                                            <hr></hr>
                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "30px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Responsibilities
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "30px",
                                                }}
                                              >
                                                {ReactHtmlParser(
                                                  post.additionalInfo
                                                    .internResponsibilities
                                                )}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Skills Required
                                              </Typography>
                                            </Grid>

                                            {/* <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "30px",
                                                }}
                                              >
                                                {post.additionalInfo.skills.map(
                                                  (data) => {
                                                    return (
                                                      <Chip
                                                        label={data}
                                                        variant="outlined"
                                                      />
                                                    );
                                                  }
                                                )}
                                              </Typography>
                                            </Grid> */}

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Who can apply
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                {ReactHtmlParser(
                                                  post.additionalInfo
                                                    .internQualifications
                                                )}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Perks
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                {post.additionalInfo.perks.map(
                                                  (data) => {
                                                    return (
                                                      <Chip
                                                        style={{
                                                          padding: "2px",
                                                        }}
                                                        label={data}
                                                        variant="outlined"
                                                      />
                                                    );
                                                  }
                                                )}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Education Qualification
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Chip
                                                  label={
                                                    post
                                                      .additionalInfo
                                                      .educationalQualification
                                                  }
                                                  variant="outlined"
                                                />
                                              </Typography>
                                            </Grid>
                                            <hr></hr>
                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "20px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                About Company
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                className="donation-text"
                                                style={{
                                                  fontSize: "16px",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                {post &&
                                                  post.orgId &&
                                                  post.orgId
                                                    .description}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              {post &&
                                                post.orgId &&
                                                post.orgId
                                                  .websiteUrl && (
                                                  <Chip
                                                    variant="outlined"
                                                    label={
                                                      <a
                                                        className="donation-text"
                                                        style={{
                                                          fontSize: "16px",
                                                          paddingTop: "10px",
                                                        }}
                                                        href={
                                                          post.orgId
                                                            .websiteUrl
                                                        }
                                                      >
                                                        Website
                                                      </a>
                                                    }
                                                  ></Chip>
                                                )}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                  </TabPanel>
                                  <TabPanel value={value} index={1}>
                                    <DonationRequestUpdate
                                      post={post}
                                    />
                                  </TabPanel>
                                  <TabPanel value={value} index={2}>
                                    {isCommentsDialogOpen && (
                                      <Comments
                                        post={post}
                                        isOpen={isCommentsDialogOpen}
                                        onClick={onClick}
                                        onKeyPress={onClick}
                                        onClose={() =>
                                          setIsCommentsDialogOpen(false)
                                        }
                                      />
                                    )}
                                  </TabPanel>
                                </Typography>
                              </Typography>
                              {values && values.requestType === "cash" && (
                                <Payments
                                  isOpen={isPaymentsOpen}
                                  donationFor="post"
                                  postId={id}
                                  amt={donAmt}
                                  currency={values && values.unit}
                                  onClose={() => setIsPaymentsOpen(false)}
                                />
                              )}
                              {values && values.requestType !== "cash" && (
                                <KindTimeDonation
                                  isOpen={isKindTimeDonationOpen}
                                  post={post}
                                  requestType={values.requestType}
                                  donationFor="post"
                                  onClose={() =>
                                    setIsKindTimeDonationOpen(false)
                                  }
                                  onSavingDonation={saveDonationHandler}
                                />
                              )}
                              {values && openOfflineDonationPopUp && (
                                <OfflineDonationDetails
                                  isOpen={openOfflineDonationPopUp}
                                  donationFor="post"
                                  postId={id}
                                  donationDetails={values}
                                  onClose={() =>
                                    setOpenOfflineDonationPopUp(false)
                                  }
                                />
                              )}
                            </Typography>
                          </div>

                          <Typography
                            className="share-option-btns"
                            style={{
                              pointerEvents: isPreviewMode ? "none" : "auto",
                            }}
                          >
                            <WhatsappShareButton
                              url={`${
                                process.env.REACT_APP_API_BASE_URL
                              }/donationRequestShare/${
                                post && post.name
                              }`}
                              quote={post && post.title}
                              style={{ background: "#4baf4f", borderRadius: 4 }}
                            >
                              <Button className="whatsapp">
                                <WhatsAppIcon /> Share
                              </Button>
                            </WhatsappShareButton>

                            <FacebookShareButton
                              url={`${
                                process.env.REACT_APP_API_BASE_URL
                              }/donationRequestShare/${
                                post && post.name
                              }`}
                              quote={post && post.title}
                              style={{ background: "#3b5997", borderRadius: 4 }}
                            >
                              <Button className="facebook">
                                <FacebookIcon /> Share
                              </Button>
                            </FacebookShareButton>

                            <EmailContactGroupsModal
                              openModal={openModal}
                              onModalClose={handleCloseGroups}
                              post={post}
                            ></EmailContactGroupsModal>
                            {post &&
                              post.request_type === "cash" &&
                              (post.status === 0 ||
                                post.status === 1) && (
                                <Button
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  variant="contained"
                                  color="primary"
                                  onClick={launchEmailCampaign}
                                  startIcon={<MailOutlineIcon />}
                                  style={{ backgroundColor: "#4e342e" }}
                                >
                                  Email Campaign
                                </Button>
                              )}
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography className="fundraiser-container drop-box">
                                <Typography
                                  component="p"
                                  className="fundraiser-title"
                                >
                                  {post &&
                                  post.request_type === "cash"
                                    ? "Fundraiser"
                                    : post &&
                                      post.request_type ===
                                        "plasma" &&
                                      post.creatorType === "donee"
                                    ? "Requested By"
                                    : post &&
                                      post.request_type ===
                                        "plasma" &&
                                      post.creatorType === "donor" &&
                                      "Plasma Donor"}
                                </Typography>

                                <CardHeader
                                  onClick={userProfileHandler}
                                  style={{
                                    pointerEvents: isPreviewMode
                                      ? "none"
                                      : "auto",
                                    cursor: "pointer",
                                    padding: "16px 16px 16px 0",
                                  }}
                                  avatar={
                                    <Avatar
                                      aria-label="recipe"
                                      className="avatar-container"
                                    >
                                      <figure>
                                        <img
                                          src={
                                            (values &&
                                              values.userInfo &&
                                              values.userInfo.image_url &&
                                              values.userInfo.image_url) ||
                                            "/images/donor.png"
                                          }
                                          alt="user-logo"
                                          style={{
                                            verticalAlign: "middle",
                                            width: "100%",
                                          }}
                                        />
                                      </figure>
                                    </Avatar>
                                  }
                                  title={
                                    <span style={{ cursor: "pointer" }}>
                                      <Tooltip title="View Public Profile">
                                        <Fragment>
                                          {values &&
                                            values.userInfo &&
                                            values.userInfo.username}
                                          <VerifiedUser
                                            style={{
                                              color: green[500],
                                              width: 34,
                                              paddingTop: 6,
                                            }}
                                          />
                                        </Fragment>
                                      </Tooltip>
                                    </span>
                                  }
                                  subheader={
                                    <span>
                                      <Typography
                                        component="span"
                                        className="date"
                                        style={{ fontSize: 13 }}
                                      >
                                        {values &&
                                          moment(values.createdAt).format(
                                            DATE_FORMAT
                                          )}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        className="location"
                                      >
                                        <LocationOn style={{ fontSize: 13 }} />
                                        {address && address.city
                                          ? address.city
                                          : ""}
                                      </Typography>
                                    </span>
                                  }
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {post &&
                                post.request_type === "cash" &&
                                post.orgId &&
                                post.orgId._id && (
                                  <Typography className="fundraiser-container drop-box">
                                    <Typography
                                      component="p"
                                      className="fundraiser-title"
                                    >
                                      Beneficiary
                                    </Typography>

                                    <CardHeader
                                      onClick={() =>
                                        orgProfileHandler(
                                          post &&
                                            post.orgId &&
                                            post.orgId._id
                                        )
                                      }
                                      style={{
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                        padding: "16px 16px 16px 0",
                                      }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-container"
                                        >
                                          <figure>
                                            <img
                                              src={
                                                post &&
                                                post.orgId &&
                                                post.orgId
                                                  .defaultImageUrl
                                                  ? post.orgId
                                                      .defaultImageUrl
                                                  : "/images/orgLogo.png"
                                              }
                                              alt="user-logo"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "100%",
                                                height: "74.9px",
                                              }}
                                            />
                                          </figure>
                                        </Avatar>
                                      }
                                      title={
                                        <span style={{ cursor: "pointer" }}>
                                          <Tooltip title="View Organization Public Profile">
                                            <Fragment>
                                              {post &&
                                                post.orgId &&
                                                post.orgId.orgName}
                                              <VerifiedUser
                                                style={{
                                                  color: green[500],
                                                  width: 34,
                                                  paddingTop: 6,
                                                }}
                                              />
                                            </Fragment>
                                          </Tooltip>
                                        </span>
                                      }
                                      subheader={
                                        <span>
                                          <Typography
                                            component="span"
                                            className="date"
                                          >
                                            {moment(
                                              post.createdAt
                                            ).format(DATE_FORMAT)}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            <LocationOn
                                              style={{ fontSize: 14 }}
                                            />
                                            {post &&
                                            post.orgId &&
                                            post.orgId.orgAddress &&
                                            post.orgId.orgAddress
                                              .city
                                              ? post.orgId.orgAddress
                                                  .city
                                              : ""}
                                          </Typography>
                                        </span>
                                      }
                                    />
                                  </Typography>
                                )}
                              {post &&
                                post.request_type === "cash" &&
                                post.fundsRecipient &&
                                post.fundsRecipient.toLowerCase() !==
                                  "self" &&
                                post.fundsRecipientName && (
                                  <Typography className="fundraiser-container drop-box">
                                    <Typography
                                      component="p"
                                      className="fundraiser-title"
                                    >
                                      Beneficiary
                                    </Typography>

                                    <CardHeader
                                      style={{
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                        padding: "16px 16px 16px 0",
                                      }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-container"
                                        >
                                          <figure>
                                            <img
                                              src={"/images/orgLogo.png"}
                                              alt="Logo"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "100%",
                                              }}
                                            />
                                          </figure>
                                        </Avatar>
                                      }
                                      title={
                                        <span style={{ cursor: "pointer" }}>
                                          <Fragment>
                                            {post &&
                                              post.fundsRecipientName}
                                            <VerifiedUser
                                              style={{
                                                color: green[500],
                                                width: 34,
                                                paddingTop: 6,
                                              }}
                                            />
                                          </Fragment>
                                        </span>
                                      }
                                      subheader={
                                        <span>
                                          <Typography
                                            component="span"
                                            className="date"
                                          >
                                            {moment(
                                              post.createdAt
                                            ).format(DATE_FORMAT)}
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            <LocationOn
                                              style={{ fontSize: 14 }}
                                            />
                                            {address && address.city
                                              ? address.city
                                              : ""}
                                          </Typography>
                                        </span>
                                      }
                                    />
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        className="donation-left-bar"
                      >
                        <StickyBox
                          offsetTop={135}
                          offsetBottom={-150}
                          className="title-container"
                        >
                          <Typography component="div">
                            {isPreviewMode && (
                              <Fragment>
                                <Button
                                  className="submit-post"
                                  onClick={submitForApprovalHandler}
                                >
                                  <CheckBoxIcon /> Submit Request for Approval
                                </Button>
                                <Button className="facebook-post">
                                  <FacebookIcon /> Help Spread Awareness
                                </Button>
                              </Fragment>
                            )}
                            <Typography>
                              {post &&
                                post.request_type === "plasma" && (
                                  <Typography
                                    component="section"
                                    className="drop-box"
                                  >
                                    <Typography
                                      component="div"
                                      className="amount-text"
                                    >
                                      {post &&
                                      post.status &&
                                      post.status === 2 ? (
                                        <Typography
                                          component="h2"
                                          className="payment-header"
                                          style={{ color: "#000" }}
                                        >
                                          {post &&
                                          post.creatorType ===
                                            "employer" ? (
                                            post.user_info &&
                                            authUser &&
                                            post.user_info._id ===
                                              authUser.unique_id ? (
                                              <span>
                                                You marked the Plasma donation
                                                post as COMPLETED
                                              </span>
                                            ) : (
                                              <span>
                                                The Donor marked this Plasma
                                                Donation as COMPLETED
                                              </span>
                                            )
                                          ) : post.user_info &&
                                            authUser &&
                                            post.user_info._id ===
                                              authUser.unique_id ? (
                                            <span>
                                              You marked the Plasma Request post
                                              as COMPLETED
                                            </span>
                                          ) : (
                                            <span>
                                              The Donee marked this Plasma
                                              Request as COMPLETED
                                            </span>
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography component="div">
                                          <Typography className="donation-header">
                                            Contact Information
                                          </Typography>
                                          <Typography
                                            component="div"
                                            className="payment-desc-container"
                                          >
                                            {(post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .contactByPhone) ||
                                            (post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .contactByEmail) ||
                                            (address && address.city) ? (
                                              <div
                                                className="contact-information
                                                  "
                                              >
                                                {post &&
                                                  post.additionalInfo &&
                                                  post.additionalInfo
                                                    .contactByEmail && (
                                                    <p
                                                      style={{
                                                        color: "#555",
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      <MailOutlineIcon />{" "}
                                                      {post &&
                                                        post.user_info &&
                                                        post
                                                          .user_info.email}{" "}
                                                    </p>
                                                  )}
                                                {post &&
                                                  post.additionalInfo &&
                                                  post.additionalInfo
                                                    .contactByPhone && (
                                                    <p
                                                      style={{ color: "#555" }}
                                                    >
                                                      <PhoneIcon />{" "}
                                                      {post &&
                                                        post.user_info &&
                                                        post
                                                          .user_info.phone}{" "}
                                                    </p>
                                                  )}
                                                {address && address.city && (
                                                  <p style={{ color: "#555" }}>
                                                    <LocationOn />{" "}
                                                    {address && address.city
                                                      ? address.city
                                                      : ""}{" "}
                                                  </p>
                                                )}
                                              </div>
                                            ) : (
                                              <p>
                                                <i>No information available</i>
                                              </p>
                                            )}
                                            {authUser &&
                                              post &&
                                              authUser.unique_id ===
                                                post.user_info._id &&
                                              !(
                                                post &&
                                                (post.status ==
                                                  STATUS_INCOMPLETE ||
                                                  post.status ==
                                                    STATUS_SPAM ||
                                                  post.status ==
                                                    STATUS_REJECTED)
                                              ) && (
                                                <Button
                                                  className="plasma-btn"
                                                  style={{
                                                    pointerEvents: isPreviewMode
                                                      ? "none"
                                                      : "auto",
                                                  }}
                                                  onClick={() =>
                                                    handlePlasmaDonate(
                                                      post
                                                    )
                                                  }
                                                  disabled={
                                                    isPreviewMode ? true : false
                                                  }
                                                >
                                                  I{" "}
                                                  {post &&
                                                  post.creatorType ===
                                                    "donee"
                                                    ? "Received Plasma"
                                                    : "Donated Plasma"}
                                                </Button>
                                              )}
                                          </Typography>
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                )}
                              {post &&
                                post.request_type === "cash" && (
                                  <Hidden smDown>
                                    <Typography
                                      className="drop-box"
                                      component="section"
                                      style={{ padding: 10 }}
                                    >
                                      <Typography
                                        component="p"
                                        className="progress-text"
                                      >
                                        <div style={{ color: "#f34637" }}>
                                          {values &&
                                            formatNumber(
                                              values.donatedQuantity
                                            )}{" "}
                                          Raised of{" "}
                                          {values &&
                                            formatNumber(values.quantity)}{" "}
                                          {values &&
                                          values.requestType === "cash"
                                            ? values.cashUnit
                                            : values &&
                                              values.requestType === "kind"
                                            ? values && values.kindUnit
                                            : values && values.timeUnit}{" "}
                                          {values &&
                                          values.requestType === "kind"
                                            ? values.requested_for
                                            : ""}
                                        </div>
                                        <div>
                                          {values && values.donatedPercentage} %
                                        </div>
                                      </Typography>
                                      <BorderLinearProgress
                                        className="prgBar"
                                        variant="determinate"
                                        color="secondary"
                                        value={
                                          values && values.donatedPercentage
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                      <Typography component="p">
                                        <div className="progress-text">
                                          {values && values.requestType} Funding
                                          <p
                                            style={{
                                              float: "center",
                                              margin: "0",

                                              cursor: "pointer",
                                              // margin-left": "92px",
                                              pointerEvents: isPreviewMode
                                                ? "none"
                                                : "auto",
                                            }}
                                            onClick={(e) => showDonorsList(e)}
                                          >
                                            {donorsList && donorsList.length}
                                            {"  "}
                                            {donorsList &&
                                            donorsList.length === 1
                                              ? "Supporter"
                                              : "Supporters"}
                                            {showDonors ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )}
                                          </p>
                                          {(isSuperAdmin || isAdmin) && (
                                            <Tooltip title="Add off-line Donations">
                                              <div
                                                style={{
                                                  float: "right",
                                                  fontSize: 17,
                                                  color: "var(--tal_primary)",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  openOfflineDonationDetails(e)
                                                }
                                              >
                                                <AddCircleIcon className="add-circle" />
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                        {showDonors && (
                                          <div>
                                            {donorsList &&
                                              donorsList.length > 0 && (
                                                <div
                                                  style={{
                                                    border: "1px solid #ccc",
                                                  }}
                                                >
                                                  <TableContainer>
                                                    <Table>
                                                      <EnhancedTableHead
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={
                                                          handleRequestSort
                                                        }
                                                        rowCount={
                                                          donorsList.length
                                                        }
                                                      />
                                                      <TableBody>
                                                        {stableSort(
                                                          donorsList,
                                                          getComparator(
                                                            order,
                                                            orderBy
                                                          )
                                                        )
                                                          .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                              rowsPerPage
                                                          )
                                                          .map((data) => (
                                                            <TableRow
                                                              key={data.id}
                                                            >
                                                              <TableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {data.userName}
                                                              </TableCell>
                                                              <TableCell>
                                                                {data.units}{" "}
                                                                {data.amount}
                                                              </TableCell>
                                                              <TableCell>
                                                                {moment(
                                                                  data.donatedDate
                                                                ).format(
                                                                  DATE_FORMAT
                                                                )}
                                                              </TableCell>
                                                            </TableRow>
                                                          ))}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                  <TablePagination
                                                    rowsPerPageOptions={[
                                                      5, 10, 20, 50, 100,
                                                    ]}
                                                    component="div"
                                                    className="page-grid"
                                                    count={donorsList.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={
                                                      handleChangePage
                                                    }
                                                    onChangeRowsPerPage={
                                                      handleChangeRowsPerPage
                                                    }
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </Typography>
                                    </Typography>
                                  </Hidden>
                                )}

                              {values && values.requestType === "cash" && (
                                <Typography
                                  component="section"
                                  className="drop-box"
                                >
                                  <Typography
                                    component="div"
                                    className="amount-text"
                                  >
                                    <Typography
                                      component="h3"
                                      className="payment-header"
                                    >
                                      Payment Information
                                    </Typography>
                                    <Typography
                                      component="div"
                                      className="payment-desc-container"
                                    >
                                      <Typography
                                        component="p"
                                        className="payment-info"
                                      >
                                        Choose the amount you wish to donate
                                      </Typography>
                                      <Typography
                                        component="p"
                                        className="payment-sub-header"
                                      >
                                        Donation Amount
                                      </Typography>
                                      <Typography
                                        className="payment-btn-container"
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                        }}
                                      >
                                        <div className="amount-btn">
                                          <Button
                                            className={
                                              active25 && "activeColor"
                                            }
                                            onClick={() =>
                                              handleDonationAmount(
                                                "25",
                                                post &&
                                                  post.units &&
                                                  post.units.toUpperCase() ===
                                                    "INR"
                                                  ? 500
                                                  : 25
                                              )
                                            }
                                          >
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR" ? (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faRupeeSign}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faDollarSign}
                                              />
                                            )}{" "}
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR"
                                              ? 500
                                              : 25}
                                          </Button>
                                          <Button
                                            className={
                                              active50 && "activeColor"
                                            }
                                            onClick={() =>
                                              handleDonationAmount(
                                                "50",
                                                post &&
                                                  post.units &&
                                                  post.units.toUpperCase() ===
                                                    "INR"
                                                  ? 1000
                                                  : 50
                                              )
                                            }
                                          >
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR" ? (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faRupeeSign}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faDollarSign}
                                              />
                                            )}{" "}
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR"
                                              ? 1000
                                              : 50}
                                          </Button>
                                          <Button
                                            className={
                                              active100 && "activeColor"
                                            }
                                            onClick={() =>
                                              handleDonationAmount(
                                                "100",
                                                post &&
                                                  post.units &&
                                                  post.units.toUpperCase() ===
                                                    "INR"
                                                  ? 2000
                                                  : 100
                                              )
                                            }
                                          >
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR" ? (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faRupeeSign}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faDollarSign}
                                              />
                                            )}{" "}
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR"
                                              ? 2000
                                              : 100}
                                          </Button>
                                          <Button
                                            className={
                                              active250 && "activeColor"
                                            }
                                            onClick={() =>
                                              handleDonationAmount(
                                                "250",
                                                post &&
                                                  post.units &&
                                                  post.units.toUpperCase() ===
                                                    "INR"
                                                  ? 5000
                                                  : 250
                                              )
                                            }
                                          >
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR" ? (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faRupeeSign}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faDollarSign}
                                              />
                                            )}{" "}
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR"
                                              ? 5000
                                              : 250}
                                          </Button>
                                          <Button
                                            className={
                                              active500 && "activeColor"
                                            }
                                            onClick={() =>
                                              handleDonationAmount(
                                                "500",
                                                post &&
                                                  post.units &&
                                                  post.units.toUpperCase() ===
                                                    "INR"
                                                  ? 10000
                                                  : 500
                                              )
                                            }
                                          >
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR" ? (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faRupeeSign}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                color="#fff"
                                                size="1x"
                                                icon={faDollarSign}
                                              />
                                            )}{" "}
                                            {post &&
                                            post.units &&
                                            post.units.toUpperCase() ===
                                              "INR"
                                              ? 10000
                                              : 500}
                                          </Button>
                                        </div>

                                        <div>
                                          {!activeOther && (
                                            <Button
                                              className={`${
                                                activeOther && "activeColor"
                                              } other-btn`}
                                              onClick={() =>
                                                handleDonationAmount("other")
                                              }
                                            >
                                              Other
                                            </Button>
                                          )}{" "}
                                        </div>

                                        {activeOther && (
                                          <TextField
                                            type="number"
                                            style={{ width: "94%" }}
                                            variant="outlined"
                                            placeholder="Enter required amount"
                                            onChange={handleOtherAmt}
                                          ></TextField>
                                        )}
                                      </Typography>
                                    </Typography>
                                  </Typography>

                                  <Hidden smDown>
                                    <Typography
                                      component="div"
                                      className="footer-sec"
                                    >
                                      <Button
                                        size="small"
                                        className="donate-btn"
                                        onClick={donateButtonHandler}
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                          opacity:
                                            post &&
                                            post &&
                                            post.status !== 1 &&
                                            post.status !== 0
                                              ? // post.donated_quantity >=
                                                //   post.quantity
                                                "0.5"
                                              : 1,

                                          margin: "0 0 10px 0",
                                        }}
                                        disabled={
                                          post &&
                                          post.status !== 1 &&
                                          post.status !== 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Donate Now
                                      </Button>
                                    </Typography>
                                  </Hidden>
                                </Typography>
                              )}
                              {values && values.requestType === "internship" && (
                                <Typography
                                  component="section"
                                  className="drop-box"
                                >
                                  <Typography
                                    component="div"
                                    className="amount-text"
                                  >
                                    <CardHeader
                                      onClick={userProfileHandler}
                                      style={{
                                        pointerEvents: isPreviewMode
                                          ? "none"
                                          : "auto",
                                        cursor: "pointer",
                                        padding: "0 16px 16px 0",
                                      }}
                                      avatar={
                                        <Avatar
                                          aria-label="recipe"
                                          className="avatar-container"
                                        >
                                          <figure>
                                            <img
                                              src={
                                                (values &&
                                                  values.userInfo &&
                                                  values.userInfo.image_url &&
                                                  values.userInfo.image_url) ||
                                                "/images/donor.png"
                                              }
                                              alt="user-logo"
                                              style={{
                                                verticalAlign: "middle",
                                                width: "100%",
                                              }}
                                            />
                                          </figure>
                                        </Avatar>
                                      }
                                      title={
                                        <span style={{ cursor: "pointer" }}>
                                          <Tooltip title="View Public Profile">
                                            <Fragment>
                                              {values &&
                                                values.userInfo &&
                                                values.userInfo.username}
                                              <VerifiedUser
                                                style={{
                                                  color: green[500],
                                                  width: 34,
                                                  paddingTop: 6,
                                                }}
                                              />
                                            </Fragment>
                                          </Tooltip>
                                        </span>
                                      }
                                      subheader={
                                        <span>
                                          <Typography
                                            component="span"
                                            className="date"
                                            style={{ fontSize: 13 }}
                                          >
                                            {values &&
                                              moment(values.createdAt).format(
                                                DATE_FORMAT
                                              )}
                                            <span> | </span>
                                            <span>
                                              {post &&
                                                post.createdAt &&
                                                moment(
                                                  post.createdAt
                                                )
                                                  .startOf("seconds")
                                                  .fromNow()}
                                            </span>
                                          </Typography>
                                          <Typography
                                            component="span"
                                            className="location"
                                          >
                                            <LocationOn
                                              style={{ fontSize: 13 }}
                                            />
                                            {address && address.city
                                              ? address.city
                                              : ""}
                                          </Typography>
                                        </span>
                                      }
                                    />
                                    <Typography
                                      component="div"
                                      className="payment-desc-container"
                                    >
                                      <Typography
                                        component="p"
                                        className="payment-info"
                                      >
                                        {post.title}
                                      </Typography>
                                      <Typography>
                                        {isLoading && (
                                          <div>
                                            <br />
                                            <span style={{ color: "grey" }}>
                                              Contact Details
                                            </span>
                                            <p
                                              style={{
                                                color: "#5f83c0",
                                                fontWeight: "500",
                                                marginTop: "5px",
                                                lineHeight: "22px",
                                                marginBottom: "40px",
                                              }}
                                            >
                                              {post.user_info.phone}{" "}
                                              <br />
                                              {post.user_info.email}
                                            </p>
                                          </div>
                                        )}
                                      </Typography>
                                      <Typography
                                        className="payment-btn-container"
                                        style={{
                                          pointerEvents: isPreviewMode
                                            ? "none"
                                            : "auto",
                                        }}
                                      ></Typography>
                                    </Typography>
                                  </Typography>
                                  <Typography
                                    component="div"
                                    className="footer-sec"
                                  >
                                    {(!authUser ||
                                      (authUser &&
                                        authUser.unique_id !==
                                          post.user_info._id &&
                                        post.creatorType ===
                                          "employer")) && (
                                      <Button
                                        size="small"
                                        className="donate-btn"
                                        onClick={donateButtonHandler}
                                      >
                                        Apply
                                      </Button>
                                    )}
                                  </Typography>
                                </Typography>
                              )}
                              {post &&
                                post.request_type === "help" && (
                                  <Typography
                                    className="fundraiser-container drop-box"
                                    style={{ padding: 20 }}
                                  >
                                    <Typography component="div">
                                      <Typography
                                        component="h3"
                                        className="payment-header"
                                        style={{ color: "#000" }}
                                      >
                                        Contact Information
                                      </Typography>
                                      {post &&
                                        post.additionalInfo &&
                                        post.additionalInfo
                                          .fullname && (
                                          <Typography
                                            component="div"
                                            className="payment-desc-container"
                                          >
                                            Patient Name:{" "}
                                            {post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .fullname}
                                          </Typography>
                                        )}
                                      <Typography
                                        component="div"
                                        className="payment-desc-container"
                                      >
                                        {(post &&
                                          post.additionalInfo &&
                                          post.additionalInfo
                                            .contactByPhone) ||
                                        (post &&
                                          post.additionalInfo &&
                                          post.additionalInfo
                                            .contactByEmail) ||
                                        address ? (
                                          <div
                                            className="contact-information
                                                  "
                                          >
                                            {post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .contactByEmail && (
                                                <p
                                                  style={{
                                                    color: "#555",
                                                    textTransform: "lowercase",
                                                  }}
                                                >
                                                  <MailOutlineIcon />{" "}
                                                  {post &&
                                                    post.user_info &&
                                                    post.user_info
                                                      .email}{" "}
                                                </p>
                                              )}
                                            {post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .contactByPhone && (
                                                <p style={{ color: "#555" }}>
                                                  <PhoneIcon />{" "}
                                                  {post &&
                                                    post.user_info &&
                                                    post.user_info
                                                      .phone}{" "}
                                                </p>
                                              )}
                                            {post &&
                                              post.additionalInfo &&
                                              post.additionalInfo
                                                .alternateMobileNumber && (
                                                <p style={{ color: "#555" }}>
                                                  <PhoneIcon />{" "}
                                                  {post &&
                                                    post.additionalInfo &&
                                                    post
                                                      .additionalInfo
                                                      .alternateMobileNumber}{" "}
                                                </p>
                                              )}
                                            <p style={{ color: "#555" }}>
                                              <LocationOn />{" "}
                                              {address &&
                                                getObjectValuesInStringFormat(
                                                  address
                                                )}
                                            </p>
                                          </div>
                                        ) : (
                                          <p>
                                            <i>No information available</i>
                                          </p>
                                        )}
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                )}
                            </Typography>
                          </Typography>
                        </StickyBox>
                      </Grid>
                    </Grid>
                  </div>
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      )}

      <Hidden mdUp>
        <Typography component="div" className="fixed_donate_now">
          <Box borderBottom="1px solid #f1f1f1">
            {post && post.request_type === "cash" && (
              <Typography
                component="section"
                style={{ padding: 10, background: "#fff" }}
              >
                <Box
                  component="Typography"
                  display="flex"
                  justifyContent=" space-between"
                  className="progress-text"
                >
                  <div style={{ color: "#f34637" }}>
                    {values && formatNumber(values.donatedQuantity)} Raised of{" "}
                    {values && formatNumber(values.quantity)}{" "}
                    {values && values.requestType === "cash"
                      ? values.cashUnit
                      : values && values.requestType === "kind"
                      ? values && values.kindUnit
                      : values && values.timeUnit}{" "}
                    {values && values.requestType === "kind"
                      ? values.requested_for
                      : ""}
                  </div>
                  <div>{values && values.donatedPercentage} %</div>
                </Box>
                <BorderLinearProgress
                  className="prgBar"
                  variant="determinate"
                  color="secondary"
                  value={values && values.donatedPercentage}
                  style={{ cursor: "pointer" }}
                />
                <Typography component="p">
                  <Box
                    className="progress-text"
                    display="flex"
                    justifyContent=" space-between"
                  >
                    {values && values.requestType} Funding
                    <div
                      style={{
                        float: "center",
                        fontSize: 17,
                        color: "var(--tal_primary) ",
                        cursor: "pointer",
                        // margin-left": "92px",
                        display: "flex",
                        whiteSpace: "nowrap",
                        pointerEvents: isPreviewMode ? "none" : "auto",
                      }}
                      onClick={(e) => showDonorsList(e)}
                    >
                      {donorsList && donorsList.length}
                      {"  "}
                      {donorsList && donorsList.length === 1
                        ? "Supporter"
                        : "Supporters"}
                      {showDonors ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                    {(isSuperAdmin || isAdmin) && (
                      <Tooltip title="Add off-line Donations">
                        <div
                          style={{
                            float: "right",
                            fontSize: 17,
                            color: "var(--tal_primary) ",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openOfflineDonationDetails(e)}
                        >
                          <AddCircleIcon className="add-circle" />
                        </div>
                      </Tooltip>
                    )}
                  </Box>
                  {showDonors && (
                    <div>
                      {donorsList && donorsList.length > 0 && (
                        <div style={{ border: "1px solid #ccc" }}>
                          <TableContainer>
                            <Table>
                              <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={donorsList.length}
                              />
                              <TableBody>
                                {stableSort(
                                  donorsList,
                                  getComparator(order, orderBy)
                                )
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((data) => (
                                    <TableRow key={data.id}>
                                      <TableCell component="th" scope="row">
                                        {data.userName}
                                      </TableCell>
                                      <TableCell>
                                        {data.units} {data.amount}
                                      </TableCell>
                                      <TableCell>
                                        {moment(data.donatedDate).format(
                                          DATE_FORMAT
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            component="div"
                            className="page-grid"
                            count={donorsList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Typography>
              </Typography>
            )}
          </Box>

          {values && values.requestType === "cash" && (
            <Button
              size="small"
              className="donate-btn"
              onClick={donateButtonHandler}
              style={{
                pointerEvents: isPreviewMode ? "none" : "auto",
                opacity:
                  post &&
                  post &&
                  post.status !== STATUS_APPROVED &&
                  post.status !== STATUS_NEW
                    ? // post.donated_quantity >=
                      //   post.quantity
                      "0.5"
                    : 1,

                margin: "0 0 10px 0",
                // display: values && values.requestType !== "cash" ? "none" : "",
              }}
              disabled={
                post &&
                post.status !== 1 &&
                post.status !== 0
                  ? true
                  : false
              }
            >
              Donate Now
            </Button>
          )}
        </Typography>
      </Hidden>

      <DonationRequestNotLoggedInDialog
        open={openOptions}
        post={post}
        onClose={() => setOpenOptions(false)}
      />
    </div>
  );
};

export default PostDetails;
