import React, { useContext } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { appContext } from "../../App";

function ChannelSelectionBox(props) {
  const { setPlaylists, channel, setChannel, channels } =
    useContext(appContext);

  function handleChangeChannel(event) {
    if (props.onChannelChange) {
      props.onChannelChange(channel, event.target.value);
    }
    setChannel(event.target.value);
    if (props.setOffset) props.setOffset(0);
    if (props.setRows) props.setRows([]);
    if (props.setPlaylists) setPlaylists([]);
  }
  return (
    <Select
      fullWidth
      variant="outlined"
      value={channel}
      onChange={handleChangeChannel}
      label="channel"
      inputProps={{
        name: "channel",
        id: "channel",
      }}
    >
      {channels &&
        channels.length > 0 &&
        channels.map((channel) => (
          <MenuItem key={channel._id} value={channel.name}>
            {channel.label}
          </MenuItem>
        ))}
    </Select>
  );
}
export default ChannelSelectionBox;
