const ValidateProfile = (values) => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter First Name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter Last Name";
  }
  if (!values.userName) {
    errors.userName = "Please enter User Name";
  }
  if (values.summary && values.summary.trim().length >= 100) {
    errors.summary = "Please enter less than 100 characters.";
  }
  if (values.aboutMe && values.aboutMe.trim().length >= 1000) {
    errors.aboutMe = "Please enter less than 1000 characters.";
  }
  return errors;
};

export default ValidateProfile;
