import validator from "validator";
const validateAddMediaFile = (values) => {
  let errors = {};
  if (!values.description) {
    errors.description = "Please Enter the description";
  }
  if (!values.type) {
    errors.type = "Please select the type";
  }
  if (!values.title) {
    errors.title = "Please select the title";
  }

  if (!values.mediaUrl || !validator.isURL(values.mediaUrl.trim())) {
    errors.mediaUrl = "Please Enter a valid mediaUrl ";
  }
  return errors;
};

export default validateAddMediaFile;
