import React, { useEffect, useState, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";
import useFormValidation from "../../hooks/useFormValidation";
import "../Organization/Organization.scss";
import CloseIcon from "@material-ui/icons/Close";
import validateContact from "./validateContact";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import {
  addContact,
  getMyContactGroups,
  deleteContact,
  updateContact,
  assignContactToGroup,
  getGroupsByContactId,
  unAssignGroupToContact,
} from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    heigth: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
    marginTop: "40px",
  },
  deleteBtn: {
    marginTop: "40px",
    float: "right",
  },
  assignContact: {
    width: "100%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ContactModal = (props) => {
  const {
    authUser,
    contacts,
    setContacts,
    contactGroups,
    setContactGroups,
  } = useContext(appContext);
  const classes = useStyles();

  const initialState = {
    firstName: "",
    email: "",
    phoneNumber: "",
  };

  const [lastName, setLastName] = useState("");
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [prevContactGroups, setPrevContactGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

  const getContactFormData = () => {
    return {
      lastName,
      firstName: values.firstName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      userId: authUser.unique_id,
    };
  };

  const handleAddContact = async () => {
    try {
      const formData = getContactFormData();
      let data = null;
      let contactCopy = [...contacts];
      if (props.currentContact) {
        data = await updateContact(
          authUser.unique_id,
          props.currentContact._id,
          formData
        );
        const index = contactCopy.findIndex(
          (item) => item._id === props.currentContact._id
        );
        contactCopy[index] = data.data;
      } else {
        data = await addContact(authUser.unique_id, formData);
        contactCopy.push(data.data);
      }

      setContacts(contactCopy);

      if (selectedContactGroups.length || prevContactGroups.length) {
        let newGroups = [];
        let deleteGroups = [];
        if (prevContactGroups.length) {
          prevContactGroups.forEach((item) => {
            if (
              selectedContactGroups.findIndex(
                (sl) => sl._id === item.groupId._id
              ) === -1
            ) {
              deleteGroups.push(item.groupId._id);
            }
          });
          newGroups = selectedContactGroups.filter(
            (item) =>
              prevContactGroups.findIndex((p) => p.groupId._id === item._id) ===
              -1
          );
        } else {
          newGroups = selectedContactGroups;
        }
        const newGroupPromise = newGroups.map((item) =>
          assignContactToGroup(authUser.unique_id, data.data._id, item._id)
        );
        const deletGroupPromise = deleteGroups.map((item) =>
          unAssignGroupToContact(authUser.unique_id, data.data._id, item)
        );

        if (
          (newGroupPromise && newGroupPromise.length) ||
          (deletGroupPromise && deletGroupPromise.length)
        ) {
          await Promise.all(newGroupPromise.concat(deletGroupPromise));
        }
      }
      const groups = await getMyContactGroups(authUser.unique_id);
      setContactGroups(groups);
      toastr.success(data.message);
      props.onContactSave();
    } catch (error) {
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        toastr.error(error.message);
      }
    }
  };
  const {
    values,
    setValues,
    errors,
    changeHandler,
    submitHandler,
  } = useFormValidation(initialState, validateContact, handleAddContact);
  const deleteHandler = async () => {
    if (!isLoading) {
      setIsLoading(true);
    if (props.currentContact) {
      try {
        const data = await deleteContact(
          authUser.unique_id,
          props.currentContact._id
        );
        const modifiedContacts = [...contacts].filter(
          (item) => item._id !== props.currentContact._id
        );
        setContacts(modifiedContacts);
        const groups = await getMyContactGroups(authUser.unique_id);
        setContactGroups(groups);
        toastr.success(data.message);
        props.onContactSave();
      } catch (error) {
        toastr.error(error.message);
      }
    }
    }
  };
  useEffect(() => {
    if (props.currentContact) {
      setValues({
        firstName: props.currentContact.firstName,
        email: props.currentContact.email,
        phoneNumber: props.currentContact.phoneNumber,
      });
      setLastName(props.currentContact.lastName);

      getGroupsByContactId(authUser.unique_id, props.currentContact._id)
        .then((data) => {
          if (data.length) {
            setPrevContactGroups(data);
            const groups = [];
            data.forEach((item) => {
              const group = contactGroups.find(
                (g) => g._id === item.groupId._id
              );
              groups.push(group);
            });
            setSelectedContactGroups(groups);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      setValues({
        firstName: "",
        email: "",
        phoneNumber: "",
      });
      setLastName("");
      setSelectedContactGroups([]);
      setPrevContactGroups([]);
    }
  }, [props.openModal]);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>
                {" "}
                {`${props.currentContact ? "Edit" : "Add"} `}Contact
              </h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onModalClose} />
              </span>
            </div>
            <div className={classes.plasma}>
              <form noValidate autoComplete="off">
                <TextField
                  type="text"
                  required
                  className={`input-field request-title`}
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={changeHandler}
                />
                {errors && errors.firstName && (
                  <div className="custom-error">{errors.firstName}</div>
                )}

                <TextField
                  type="text"
                  className={`input-field request-title`}
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />

                <TextField
                  type="email"
                  className={`input-field request-title`}
                  variant="outlined"
                  label="Email * "
                  name="email"
                  value={values.email}
                  onChange={changeHandler}
                />
                {errors && errors.email && (
                  <div className="custom-error">{errors.email}</div>
                )}

                <TextField
                  type="number"
                  className={`input-field request-title`}
                  variant="outlined"
                  label="Phone Number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={changeHandler}
                />
                {errors && errors.phoneNumber && (
                  <div className="custom-error">{errors.phoneNumber}</div>
                )}
                {contactGroups && contactGroups.length > 1 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Assign to Groups
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={selectedContactGroups}
                      onChange={(e) => {
                        setSelectedContactGroups(e.target.value);
                      }}
                      input={<Input />}
                      renderValue={(selected) =>
                        selected.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {contactGroups.map(
                        (group) =>
                          group._id.toLowerCase() !== "none" && (
                            <MenuItem key={group._id} value={group}>
                              <Checkbox
                                checked={
                                  selectedContactGroups.findIndex(
                                    (item) => item._id === group._id
                                  ) > -1
                                }
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                )}
                {contactGroups && contactGroups.length === 1 && (
                  <p>No Groups</p>
                )}
              </form>
              <Button className={classes.saveBtn} onClick={submitHandler}>
                {props.currentContact ? "Update" : "Save"}
              </Button>
              {props.currentContact !== null && (
                <Button
                  variant="contained"
                  className={classes.deleteBtn}
                  onClick={deleteHandler}
                >
                  Delete Contact
                </Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ContactModal;
