import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import Box from "@material-ui/core/Box";
import validator from "validator";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useFormValidation from "../../../hooks/useFormValidation";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LogDesign from "../Logdesign";
import {
  forgotPassword,
  validateOtp,
  changePassword,
  resendOTP,
} from "../../../utils/api";
import "./ForgotPassword.scss";

const useStyles = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },

  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p": {
      color: "#8692A6",
    },

    "& p:nth-child(1)": {
      textAlign: "right",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        // marginBottom:"20px",
      },

      "& small": {
        color: "#BABABA",
        display: "block",
        position: "relative",
        backgroundColor: "white",
        padding: "0 5px 0 5px",
        left: "50%",
        top: "-4px",
        width: "20px",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [responseStatus, setStatus] = useState("");
  const [otpValues, setOtp] = useState("");
  const [error, setError] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const [responseDate, setResponseData] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordView, setViewPassword] = useState(false);
  const [smallLetter, setSmallLetterCondition] = useState(false);
  const [capitalLetter, setCapitalLetterCondition] = useState(false);
  const [length, setPasswordLength] = useState(false);
  const [numberCondition, setNumberCondition] = useState(false);
  const [specialLetter, setSpecialLetter] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [timer, setTimer] = useState(60);

  const postOtpValues = (otp) => {
    const otpDetails = {};
    otpDetails.otp = otp;
    otpDetails.email = values.email;
    otpDetails.type = "forgot password";
    validateOtp(otpDetails)
      .then((response) => {
        if (response && response.statusCode === 200) {
          setResponseData(response);
          toastr.success(response.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const showTime = () => {
    let sec = 60;
    setInterval(function () {
      if (sec > 0) {
        sec--;
        setTimer(sec);
      }
      if (sec === 0) {
        setShowResendButton(true);
      }
    }, 1000);
  };

  const handleOtpCode = (e) => {
    setOtp(e);
    if (e.length === 6) {
      postOtpValues(e);
    }
  };

  const initialState = {
    email: "",
  };

  const validateEmail = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Please enter a valid email address";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Please enter a valid email address";
    }
    return errors;
  };

  const resendOtp = () => {
    const obj = {};
    obj.email = values.email;
    obj.type = "forgot password";
    resendOTP(obj)
      .then((response) => {
        showTime();
        toastr.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const sendResetEmail = () => {
    forgotPassword(values)
      .then((response) => {
        if (response && response.statusCode === 200) {
          setStatus(response);
          showTime();
        }
      })
      .catch((error) => {
        if (error.data.statusCode === 401) {
          toastr.error(
            "No account found with the given email address. Create a new account"
          );
        } else if (error.data.statusCode === 404) {
          toastr.error(error.data.message);
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeHandlerPassword = (event) => {
    showPasswordRules();
    setError("");
    if (event.target.value.match(/[a-z]/)) {
      setSmallLetterCondition(true);
    } else {
      setSmallLetterCondition(false);
    }
    if (event.target.value.match(/[A-Z]+/)) {
      setCapitalLetterCondition(true);
    } else {
      setCapitalLetterCondition(false);
    }
    if (event.target.value.match(/\d/)) {
      setNumberCondition(true);
    } else {
      setNumberCondition(false);
    }
    if (event.target.value.match(/[^a-zA-Z0-9\-/]/)) {
      setSpecialLetter(true);
    } else {
      setSpecialLetter(false);
    }
    if (event.target.value.length >= 6) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    referPasswordRules(event.target.value);
  };
  const referPasswordRules = (pswd) => {
    setPassword(pswd);
    setPasswordValidation(true);
  };

  const changePasswordHandler = () => {
    if (password !== "") {
      if (
        smallLetter !== false &&
        capitalLetter !== false &&
        numberCondition !== false &&
        specialLetter !== false &&
        length !== false
      ) {
        const obj = {};
        obj.email = values.email;
        obj.otp = otpValues;
        obj.password = password;
        changePassword(obj)
          .then((response) => {
            if (response && response.statusCode === 200) {
              toastr.success(response.message);
              if (
                response.status === "success" &&
                response.message === "Successfully changed the user password"
              ) {
                props.history.push("/login");
              }
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
      } else {
        setViewPassword(true);
      }
    } else {
      setError("Password is Required");
    }
  };

  const showPasswordRules = () => {
    setViewPassword(true);
  };

  const viewPasswordRules = () => {
    if (
      smallLetter === true &&
      capitalLetter === true &&
      length === true &&
      specialLetter === true &&
      numberCondition === true
    ) {
      setViewPassword(false);
    }
    return (
      <Box component="main">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <div className="password-strength">
                <h4>Password must be requirements</h4>
                <ul>
                  <li
                    className={smallLetter === true ? "valid" : "invalid"}
                    style={{ "text-align": "start" }}
                  >
                    Contains at least <strong>one letter</strong>
                  </li>
                  <li
                    className={capitalLetter === true ? "valid" : "invalid"}
                    style={{ "text-align": "start" }}
                  >
                    Contains at least <strong>one capital letter</strong>
                  </li>
                  <li
                    className={numberCondition === true ? "valid" : "invalid"}
                    style={{ "text-align": "start" }}
                  >
                    Contains at least <strong>one digit</strong>
                  </li>
                  <li
                    className={specialLetter === true ? "valid" : "invalid"}
                    style={{ "text-align": "start" }}
                  >
                    Contains at least <strong>one special character</strong>
                  </li>
                  <li
                    className={length === true ? "valid" : "invalid"}
                    style={{ "text-align": "start" }}
                  >
                    Contains at least <strong>6 characters</strong>
                  </li>
                </ul>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  const handleEnter = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      changePasswordHandler(event);
    }
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    validateEmail,
    sendResetEmail
  );

  return (
    <Box component="main" mt={-4}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <LogDesign />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Paper elevation={0}>
            <Box component="aside" className={classes.sign_right}>
              <Box component="section">
                <div>
                  {responseDate && responseDate.statusCode === 200 ? (
                    <div>
                      <Typography
                        component="div"
                        className="change-password-container"
                      >
                        <Typography
                          component="div"
                          className="forgot-reset-password"
                        >
                          <Typography component="div" className="headername">
                            <Box component="h4">Login to TALTransformers</Box>
                          </Typography>
                          <FormControl
                            className="full-width border-radius"
                            style={{ width: "100%" }}
                          >
                            <TextField
                              className={`${error && "error-text"}`}
                              id="outlined-adornment-confirm-password"
                              name="confirmPassword"
                              style={{ width: "100%", marginTop: 15 }}
                              variant="outlined"
                              label="Confirm Password"
                              type={showPassword ? "password" : "text"}
                              value={password.newPassword}
                              onChange={changeHandlerPassword}
                              onKeyPress={(e) => handleEnter(e)}
                            />
                            <InputAdornment
                              position="end"
                              className="showpassword"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{ "margin-top": "140%" }}
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Tooltip title="Click to Show the Password">
                                    <VisibilityOff />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Click to hide the Password">
                                    <Visibility />
                                  </Tooltip>
                                )}
                              </IconButton>
                            </InputAdornment>
                            {passwordView === true ? viewPasswordRules() : ""}
                          </FormControl>
                          {error && (
                            <spam style={{ color: "red", "font-size": "12px" }}>
                              {error}
                            </spam>
                          )}
                          <br />
                          <Button
                            style={{
                              background: "var(--tal_primary) ",
                              width: "100%",
                              margin: "15px 0",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={changePasswordHandler}
                            disable={!passwordView}
                          >
                            confirm
                          </Button>
                          <br />
                        </Typography>
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {responseStatus && responseStatus.status === "success" ? (
                        <div className="otp-container">
                          <div className="reset-password password-reset-container">
                            <figure>
                              <img
                                src="/images/otp.svg"
                                alt="google"
                                width="70px"
                              />
                            </figure>

                            <p style={{ fontSize: "26px", fontWeight: "bold" }}>
                              Verify Account
                            </p>

                            <div style={{ color: "red" }}>{error && error}</div>

                            <Box component="span" color="#8692A6" my={1}>
                              Enter a code we just sent to your registered mail
                            </Box>

                            <div>
                              <div className="otp-boxes">
                                <OtpInput
                                  onChange={(e) => handleOtpCode(e)}
                                  numInputs={6}
                                  value={otpValues}
                                  separator={<span> &nbsp; </span>}
                                  shouldAutoFocus={true}
                                />
                              </div>
                              <div className="timerOTP">
                                <span
                                  style={{ color: "blue", "font-size": "12px" }}
                                >
                                  Remaining Time:{"  "}
                                  <span style={{ color: "red" }}>{timer}</span>
                                </span>
                                {/* id="timer" */}

                                <div>
                                  {showResendButton &&
                                  showResendButton === true ? (
                                    <p
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "var(--tal_primary)",
                                      }}
                                      onClick={resendOtp}
                                    >
                                      Resend OTP
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="password-reset-container">
                          <div className="headername">
                            <h4>Reset Password</h4>
                          </div>
                          <div style={{ width: "100%" }}>
                            <TextField
                              className={`${error.email && "error-text"}`}
                              name="email"
                              style={{ width: "100%" }}
                              placeholder="Enter registered email address"
                              value={values.email}
                              onChange={changeHandler}
                              label="Email Address"
                              variant="outlined"
                              onKeyPress={(e) => handleEnterClick(e)}
                              id="email"
                            />
                            {errors && (
                              <p
                                style={{
                                  color: "var(--tal_primary)",
                                  "text-align": "center",
                                  margin: "5px 0",
                                  "font-size": "12px",
                                }}
                              >
                                {errors.email}
                              </p>
                            )}
                          </div>
                          <Button
                            style={{
                              background: "var(--tal_primary) ",
                              width: "100%",
                              margin: "10px 0",
                            }}
                            className="otp-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={submitHandler}
                          >
                            Get OTP
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
