import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import {
  Button,
  Divider,
  Grid,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SideMenu from "./SideMenu";
import Rating from "@material-ui/lab/Rating";
import { appContext } from "../../App";
import firebase from "../../firebase/firebase";
import TextField from "@material-ui/core/TextField";
import "./Transformers.scss";
import { Fragment } from "react";
import toastr from "toastr";
import { isImage, roundToTwoDecimalPlaces } from "../../utils/utils";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import moment from "moment";
import DocumentRow from "../common/DocumentRow";
import { getDonationRequest, updateJudge, getJudges } from "../../utils/api";
import { MAX_RATING_PER_FIELD } from "../../utils/utils";
import Link from "@material-ui/core/Link";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
const StatusFile = (props) => {
  const { authUser, isJudge, isAdmin, isStudent, isMentor } =
    useContext(appContext);
  const [images, setImages] = useState([]);
  const [document, setDocument] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialAdvocacy, setIsSocialAdvocacy] = useState(false);
  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [id, setId] = useState("");
  const [isSocialEntrepreneur, setIsSocialEntrepreneur] = useState(false);
  const [isSocialPhilanthropy, setIsSocialPhilanthropy] = useState(false);
  const [comments, setComments] = useState("");
  const [judges, setJudges] = useState([]);
  const [assignedJudgeIdeaData, setAssignedJudgeIdeaData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableRating, setDisableRating] = useState(false);
  const [totalFieldsRatingsOfJudge, setTotalFieldsRatingsOfJudge] = useState(0);

  const [philonthophyRating, setPhilonthophyRating] = React.useState({
    title: "0",
    purposeOfFundraiser: "0",
    goalOfFundraiserAmount: "0",
    goalOfFundRaiser: "0",
    beneficiaryOfFundraiser: "0",
    socialImpactSolution: "0",
    keyTargetMetrics: "0",
    pitchDeck: "0",
  });
  const [advocasyRating, setAdvocasyRating] = React.useState({
    title: "0",
    socialProblem: "0",
    socialChangeImpact: "0",
    proposedSolution: "0",
    supportNeeded: "0",
    externalSupportNeeded: "0",
    pitchDeck: "0",
  });
  const [entrePrenuerShipRating, setentrePrenuerShipRating] = React.useState({
    title: "0",
    communityProblemAndSolution: "0",
    socialProblem: "0",
    solutionProposing: "0",
    targetImpact: "0",
    targetLives: "0",
    describeUVP: "0",
    targetPartners: "0",
    costStructure: "0",
    revenueStreams: "0",
    potentialRisks: "0",
    pitchDeck: "0",
  });

  const loadDonationRequests = () => {
    setIsLoading(true);
    getDonationRequest(
      props.donationRequestId ? props.donationRequestId : props.match.params.id
    )
      .then((response) => {
        if (response) {
          const userAge =
            response && response.user_info && response.user_info.dob;
          if (userAge) {
            response.user_info.dob = moment().diff(
              response.user_info.dob,
              "years"
            );
          }
          const linkDocument = response && response.pitchDeckVideoUrl;
          if (linkDocument) {
            response.pitchDeckVideoUrl = response.pitchDeckVideoUrl =
              !/^https?:\/\//i.test(response.pitchDeckVideoUrl)
                ? `http://${response.pitchDeckVideoUrl}`
                : response.pitchDeckVideoUrl;
          }
          setDocument(response);
          setId(response && response._id);
          if (response.request_type == "social advocacy")
            setIsSocialAdvocacy(true);
          else if (response.request_type == "social entrepreneurship")
            setIsSocialEntrepreneur(true);
          else if (response.request_type == "cash")
            setIsSocialPhilanthropy(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching DonationRequest:" + error);
      });
  };

  const loadJudges = () => {
    if (authUser && authUser.unique_id && document && document._id) {
      getJudges(document._id, isJudge ? authUser.unique_id : "")
        .then((response) => {
          const respData = response.data;
          respData && setJudges([...respData]);
          if (response && response.data[0] && response.data[0]) {
            setAssignedJudgeIdeaData(response.data[0]);
            const judgeRatingObject = response.data[0].ratings;
            if (
              response.data[0].ratings &&
              Object.values(judgeRatingObject).every((v) => v > 0)
            ) {
              setPhilonthophyRating(response.data[0].ratings);
              setAdvocasyRating(response.data[0].ratings);
              setentrePrenuerShipRating(response.data[0].ratings);
              setDisableRating(true);
            }
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching Judges: " + error.message);
        });
    }
  };

  useEffect(() => {
    document._id && loadJudges();
  }, [document._id]);

  const onCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleEntrePrenuerShipRating = (e) => {
    const currentSelected = {
      ...entrePrenuerShipRating,
      [e.target.name]: e.target.value,
    };
    setentrePrenuerShipRating(currentSelected);
  };

  const handlePhilonthrophyRating = (e) => {
    const currentSelected = {
      ...philonthophyRating,
      [e.target.name]: e.target.value,
    };
    setPhilonthophyRating(currentSelected);
  };
  const handleAdvocasyRating = (e) => {
    const currentSelected = {
      ...advocasyRating,
      [e.target.name]: e.target.value,
    };
    setAdvocasyRating(currentSelected);
  };

  useEffect(() => {
    const totalFieldsRatings = Object.values(
      isSocialAdvocacy
        ? advocasyRating
        : isSocialPhilanthropy
        ? philonthophyRating
        : isSocialEntrepreneur
        ? entrePrenuerShipRating
        : ""
    );
    let totalFieldsObject = totalFieldsRatings.map((each) => {
      return JSON.parse(each);
    });
    const totalFieldsRatingSum = totalFieldsObject.reduce(
      (accumulator, value) => {
        return accumulator + value;
      },
      0
    );
    setTotalFieldsRatingsOfJudge(totalFieldsRatingSum);
  }, [advocasyRating, philonthophyRating, entrePrenuerShipRating]);

  const onSubmit = () => {
    const finalValues = {};
    const errors = {};
    if (isSocialAdvocacy) {
      finalValues.ratings = advocasyRating;
    } else if (isSocialPhilanthropy) {
      finalValues.ratings = philonthophyRating;
    } else {
      finalValues.ratings = entrePrenuerShipRating;
    }

    finalValues.note = comments;
    finalValues.status = assignedJudgeIdeaData && assignedJudgeIdeaData.status;

    if (
      !Object.values(
        isSocialAdvocacy
          ? advocasyRating
          : isSocialEntrepreneur
          ? entrePrenuerShipRating
          : isSocialPhilanthropy
          ? philonthophyRating
          : " "
      ).every((v) => v > 0)
    ) {
      errors.ratingError = "Please give your Ratings";
    }
    if (!comments) {
      errors.comments = "Please enter your Comments";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      toastr.error(
        "Please provide Ratings and Reviews for all required fields."
      );
      return;
    }
    const submitRatingandReviews = window.confirm(
      "Are you sure you want to submit? Once submitted can't be edited."
    );
    if (submitRatingandReviews) {
      assignedJudgeIdeaData &&
        assignedJudgeIdeaData._id &&
        updateJudge(assignedJudgeIdeaData._id, finalValues)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toastr.success("Ratings and Reviews are Submitted  Successfully");
            }
            setErrors({});
            setComments(" ");
            loadJudges();
            loadDonationRequests();
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              setErrors((currentValues) => {
                return {
                  ...currentValues,
                  title: "Ratings and Reviews are not Submitted",
                };
              });
            } else {
              toastr.error(
                error.response &&
                  error.response.data &&
                  error.response.data.message
              );
            }
          });
    }
  };
  const fetchImages = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch images
      const path = `DonationRequest/${uid}/images`;
      const storageRef = firebase.storageDonationRequests.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            setImages([]);
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: url,
                      thumbnail: url,
                    },
                  ];
                });
              });
            });
            resolve();
          } else if (
            document.request_type !== "cash" &&
            document.request_type !== "social entrepreneurship" &&
            document.request_type !== "social advocacy"
          ) {
            setImages([
              {
                original: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
                thumbnail: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
              },
            ]);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const fetchDocuments = (uid) => {
    return new Promise((resolve, reject) => {
      const path = `DonationRequest/${uid}/documents`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        setImageDocuments([]);
        setNonImageDocuments([]);
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                if (isImage(metadata.contentType)) {
                  setImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                } else {
                  setNonImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                }
              })
              .catch(() => {
                //toastr.error(error.message);
              });
          });
        });
        resolve();
      });
    });
  };

  const fetchVideos = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch videos
      const path = `DonationRequest/${uid}/videos`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: "/images/default-thumbnail.jpeg",
                      thumbnail: "/images/default-thumbnail.jpeg",
                      embedUrl: url,
                      renderItem: _renderVideo.bind(this),
                    },
                  ];
                });
              });
            });
            resolve();
          }
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    authUser &&
      authUser.unique_id &&
      (props.donationRequestId
        ? props.donationRequestId
        : props.match.params.id) &&
      loadDonationRequests();
  }, [
    authUser,
    props.donationRequestId ? props.donationRequestId : props.match.params.id,
  ]);

  useEffect(() => {
    if (document && document._id) {
      fetchVideos(document._id);
      fetchImages(document._id);
      fetchDocuments(document._id);
    }
  }, [document]);

  return (
    <div>
      {isStudent && <Header />}
      <div className="desktop-menu">
        {!isJudge && !isAdmin && !isMentor && <SideMenu />}
      </div>

      <Container maxWidth="xl" className="tal-container">
        <Grid container>
          <Grid item sm={12}>
            <article class="entry entry-single">
              <div class="entry-img">
                <img
                  src="../../images/Design-Thinking.png"
                  alt="Design Thinking"
                  style={{ width: "100%" }}
                />
              </div>
            </article>
          </Grid>

          <Grid
            item
            sm={12}
            component="h5"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            {isLoading && "Loading..."}
          </Grid>

          {!isLoading && (
            <Grid
              item
              xl={6}
              lg={9}
              md={12}
              xs={12}
              className="m20"
              style={{ margin: "0 auto" }}
            >
              <Grid container>
                <Grid item md={4} xs={12}>
                  <p className="p100">Idea Title:</p>
                </Grid>
                <Grid item md={5} xs={12}>
                  <p>{document.title}</p>
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Submitted for:</p>
                </Grid>
                <Grid item md={5} xs={12}>
                  <p className="text-ucase">
                    {document.requested_for ===
                    "ou-social-entrepreneurship-pitch-fest-2023"
                      ? "Social Entrepreneurship Pitchfest"
                      : document.requested_for === "talkindnessday"
                      ? "TAL Kindness Day"
                      : document.requested_for}
                  </p>
                </Grid>
                <Grid item md={3} xs={12}>
                  {isJudge && assignedJudgeIdeaData._id && (
                    <Rating
                      name="title"
                      size="large"
                      value={
                        (isSocialAdvocacy && advocasyRating.title) ||
                        (isSocialPhilanthropy && philonthophyRating.title) ||
                        (isSocialEntrepreneur && entrePrenuerShipRating.title)
                      }
                      onChange={
                        isSocialAdvocacy
                          ? (e) => handleAdvocasyRating(e)
                          : isSocialPhilanthropy
                          ? (e) => handlePhilonthrophyRating(e)
                          : isSocialEntrepreneur
                          ? (e) => handleEntrePrenuerShipRating(e)
                          : ""
                      }
                      precision={1}
                      disabled={disableRating}
                      className="rating_ideas"
                    />
                  )}
                  {errors &&
                    errors.ratingError &&
                    (!philonthophyRating.title ||
                      philonthophyRating.title <= 0) &&
                    (!advocasyRating.title || advocasyRating.title <= 0) &&
                    (!entrePrenuerShipRating.title ||
                      entrePrenuerShipRating.title <= 0) && (
                      <Typography className="custom-error">
                        {errors.ratingError}
                      </Typography>
                    )}
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Idea Track</p>
                </Grid>
                <Grid item md={8} xs={12}>
                  <p className="text-ucase">
                    {isSocialPhilanthropy && (
                      <img
                        src="../../images/socialPhilanthropy.svg"
                        class="me-2"
                        style={{ height: "50px" }}
                      />
                    )}
                    {isSocialEntrepreneur && (
                      <img
                        src="../../images/SocialEntrepreneurship.svg"
                        class="me-2"
                        style={{ height: "50px" }}
                      />
                    )}
                    {isSocialAdvocacy && (
                      <img
                        src="../../images/SocialAdvocacy.png"
                        class="me-2"
                        style={{ height: "50px" }}
                      />
                    )}
                    {document.request_type}
                  </p>
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Idea Category</p>
                </Grid>
                <Grid item md={8} xs={12} className="text-ucase">
                  {document.supportedSDGs && (
                    <>
                      <Grid
                        container
                        style={{ gap: "5px", marginBottom: "15px" }}
                      >
                        {document.supportedSDGs.map((data) => {
                          return (
                            <Chip
                              className=" bg-secondary"
                              label={data && data.toString()}
                              style={{ color: "#fff" }}
                              variant="outlined"
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </Grid>
                {/* <Divider style={{ width: "100%", marginBottom: "15px" }} /> */}
                {isSocialAdvocacy && (
                  <>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Addressed Social Problem </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.description}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="socialProblem"
                          value={advocasyRating.socialProblem}
                          size="large"
                          onChange={(e) => handleAdvocasyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!advocasyRating.socialProblem ||
                          advocasyRating.socialProblem <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Social Change impact </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.addressSocialChange}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="socialChangeImpact"
                          value={advocasyRating.socialChangeImpact}
                          size="large"
                          onChange={(e) => handleAdvocasyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!advocasyRating.socialChangeImpact ||
                          advocasyRating.socialChangeImpact <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Proposed Solution </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.solutionSustainableSocialImpact}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="proposedSolution"
                          value={advocasyRating.proposedSolution}
                          size="large"
                          onChange={(e) => handleAdvocasyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!advocasyRating.proposedSolution ||
                          advocasyRating.proposedSolution <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Support Needed from TALTransformers{" "}
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.supportFromTALScouts}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="supportNeeded"
                          value={advocasyRating.supportNeeded}
                          size="large"
                          onChange={(e) => handleAdvocasyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!advocasyRating.supportNeeded ||
                          advocasyRating.supportNeeded <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">External Support Needed </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.externalSupport}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="externalSupportNeeded"
                          value={advocasyRating.externalSupportNeeded}
                          size="large"
                          onChange={(e) => handleAdvocasyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!advocasyRating.externalSupportNeeded ||
                          advocasyRating.externalSupportNeeded <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
                {isSocialEntrepreneur && (
                  <>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Which community has a problem and will benefit from your
                        solution
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.description}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="communityProblemAndSolution"
                          value={
                            entrePrenuerShipRating.communityProblemAndSolution
                          }
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.communityProblemAndSolution ||
                          entrePrenuerShipRating.communityProblemAndSolution <=
                            0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Please describe the social problem you have identified
                        on a local or a global issue
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.socialProblem}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="socialProblem"
                          value={entrePrenuerShipRating.socialProblem}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.socialProblem ||
                          entrePrenuerShipRating.socialProblem <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Please describe the solution you are proposing?
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.solutionProposing}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="solutionProposing"
                          value={entrePrenuerShipRating.solutionProposing}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.solutionProposing ||
                          entrePrenuerShipRating.solutionProposing <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        select the SDGs your solution supports
                      </p>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.supportedSDGs &&
                          Array.isArray(document.supportedSDGs) && (
                            <>
                              <Grid container style={{ gap: "5px" }}>
                                {document.supportedSDGs.map((data) => {
                                  return (
                                    <Chip
                                      className=" bg-secondary"
                                      label={data && data.toString()}
                                      style={{ color: "#fff" }}
                                      variant="outlined"
                                    />
                                  );
                                })}
                              </Grid>
                            </>
                          )}
                      </p>
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Target Impact </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.targetImpact}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="targetImpact"
                          value={entrePrenuerShipRating.targetImpact}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}{" "}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.targetImpact ||
                          entrePrenuerShipRating.targetImpact <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">Target Lives </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.howManyLivesWillItChange}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="targetLives"
                          value={entrePrenuerShipRating.targetLives}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.targetLives ||
                          entrePrenuerShipRating.targetLives <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Please describe your Unique Value Proposition
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.uniqueValueProposition}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="describeUVP"
                          value={entrePrenuerShipRating.describeUVP}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.describeUVP ||
                          entrePrenuerShipRating.describeUVP <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Target Partners </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.targetPartners}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="targetPartners"
                          value={entrePrenuerShipRating.targetPartners}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.targetPartners ||
                          entrePrenuerShipRating.targetPartners <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Cost Structure </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.costStructure}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="costStructure"
                          value={entrePrenuerShipRating.costStructure}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}{" "}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.costStructure ||
                          entrePrenuerShipRating.costStructure <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Revenue Streams</p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.revenueStreams}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="revenueStreams"
                          value={entrePrenuerShipRating.revenueStreams}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}{" "}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.revenueStreams ||
                          entrePrenuerShipRating.revenueStreams <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>

                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Potential Risks </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.potentialRisks}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="potentialRisks"
                          value={entrePrenuerShipRating.potentialRisks}
                          size="large"
                          onChange={(e) => handleEntrePrenuerShipRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!entrePrenuerShipRating.potentialRisks ||
                          entrePrenuerShipRating.potentialRisks <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
                {isSocialPhilanthropy && (
                  <>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Purpose of Your Fundraiser ( Describe the social cause
                        you want to address)
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.description}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="purposeOfFundraiser"
                          value={philonthophyRating.purposeOfFundraiser}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}{" "}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.purposeOfFundraiser ||
                          philonthophyRating.purposeOfFundraiser <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />

                    <Grid item md={4} xs={12}>
                      <p className="p100">Goal of your fundraiser (Amount)</p>
                    </Grid>

                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document &&
                        document.units &&
                        document.units.toUpperCase() === "INR" ? (
                          <FontAwesomeIcon
                            color="#000"
                            size="1x"
                            icon={faRupeeSign}
                          />
                        ) : (
                          <FontAwesomeIcon
                            color="#000"
                            size="1x"
                            icon={faDollarSign}
                          />
                        )}{" "}
                        {document.quantity}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="goalOfFundraiserAmount"
                          value={philonthophyRating.goalOfFundraiserAmount}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.goalOfFundraiserAmount ||
                          philonthophyRating.goalOfFundraiserAmount <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">Goal of your fundraiser </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.brief}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="goalOfFundRaiser"
                          value={philonthophyRating.goalOfFundRaiser}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.goalOfFundRaiser ||
                          philonthophyRating.goalOfFundRaiser <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">Benificiary of your fundraiser </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.fundsRecipientName}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="beneficiaryOfFundraiser"
                          value={philonthophyRating.beneficiaryOfFundraiser}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.beneficiaryOfFundraiser ||
                          philonthophyRating.beneficiaryOfFundraiser <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Beneficiary of your fundraiser (Address)
                      </p>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document &&
                          document.shipping_address &&
                          document.shipping_address.line1}
                        {"  "}
                        {document &&
                          document.shipping_address &&
                          document.shipping_address.line2}
                        {"  "}
                        {document &&
                          document.shipping_address &&
                          document.shipping_address.city}
                        {"  "}
                        {document &&
                          document.shipping_address &&
                          document.shipping_address.state}
                        {"  "}
                        {document &&
                          document.shipping_address &&
                          document.shipping_address.country}
                        {"  "}
                      </p>
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        Describe how your fundraiser creates a sustainable
                        social impact solution
                      </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.solutionSustainableSocialImpact}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="socialImpactSolution"
                          value={philonthophyRating.socialImpactSolution}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.socialImpactSolution ||
                          philonthophyRating.socialImpactSolution <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        select the SDGs your solution supports
                      </p>
                    </Grid>
                    <Grid item md={8} xs={12} className="text-ucase">
                      {document.supportedSDGs &&
                        Array.isArray(document.supportedSDGs) && (
                          <>
                            <Grid container style={{ gap: "5px" }}>
                              {document.supportedSDGs.map((data) => {
                                return (
                                  <Chip
                                    className=" bg-secondary"
                                    label={data && data.toString()}
                                    style={{ color: "#fff" }}
                                    variant="outlined"
                                  />
                                );
                              })}
                            </Grid>
                          </>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">Key Target Metrics </p>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.keyTargetMetrics}
                      </p>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      {isJudge && assignedJudgeIdeaData._id && (
                        <Rating
                          name="keyTargetMetrics"
                          value={philonthophyRating.keyTargetMetrics}
                          size="large"
                          onChange={(e) => handlePhilonthrophyRating(e)}
                          precision={1}
                          disabled={disableRating}
                          className="rating_ideas"
                        />
                      )}
                      {errors &&
                        errors.ratingError &&
                        (!philonthophyRating.keyTargetMetrics ||
                          philonthophyRating.keyTargetMetrics <= 0) && (
                          <Typography className="custom-error">
                            {errors.ratingError}
                          </Typography>
                        )}
                    </Grid>

                    {/* <Grid item md={4} xs={12}>
                              <p className="p100">Video Pitch </p>
                            </Grid>
                            <Grid item md={8} xs={12}>
                              <p
                                className="p100"
                                style={{
                                  whiteSpace: "pre-line",
                                  lineHeight: "1.5",
                                }}
                              >
                                {document.pitchDeckVideoUrl}
                              </p>
                            </Grid> */}
                    <Divider style={{ width: "100%", marginBottom: "15px" }} />
                    <Grid item md={4} xs={12}>
                      <p className="p100">
                        what support do you need from TALTransformers?
                      </p>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "1.5",
                        }}
                      >
                        {document.supportFromTALScouts}
                      </p>
                    </Grid>
                  </>
                )}
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Pitchdeck </p>
                </Grid>
                <Grid item md={5} xs={12} style={{ marginBottom: "15px" }}>
                  <ImageGallery
                    showBullets={false}
                    defaultImage={
                      document && document.request_type === "cash"
                        ? document.defaultImageUrl
                        : "/images/default-donation-request.jpg"
                    }
                    showThumbnails={false}
                    lazyLoad={true}
                    showPlayButton={false}
                    items={images}
                    showFullscreenButton={false}
                  />

                  {/* {document.defaultImageUrl} */}
                </Grid>

                <Grid item md={3} xs={12}>
                  {isJudge && assignedJudgeIdeaData._id && (
                    <Rating
                      name="pitchDeck"
                      value={
                        isSocialAdvocacy
                          ? advocasyRating.pitchDeck
                          : isSocialPhilanthropy
                          ? philonthophyRating.pitchDeck
                          : isSocialEntrepreneur
                          ? entrePrenuerShipRating.pitchDeck
                          : ""
                      }
                      size="large"
                      onChange={
                        isSocialAdvocacy
                          ? (e) => handleAdvocasyRating(e)
                          : isSocialPhilanthropy
                          ? (e) => handlePhilonthrophyRating(e)
                          : isSocialEntrepreneur
                          ? (e) => handleEntrePrenuerShipRating(e)
                          : ""
                      }
                      precision={1}
                      disabled={disableRating}
                      className="rating_ideas"
                    />
                  )}
                  {errors &&
                    errors.ratingError &&
                    (!philonthophyRating.pitchDeck ||
                      philonthophyRating.pitchDeck <= 0) &&
                    (!advocasyRating.pitchDeck ||
                      advocasyRating.pitchDeck <= 0) &&
                    (!entrePrenuerShipRating.pitchDeck ||
                      entrePrenuerShipRating.pitchDeck <= 0) && (
                      <Typography className="custom-error">
                        {errors.ratingError}
                      </Typography>
                    )}
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100"> Documents </p>
                </Grid>
                <Grid item md={8} xs={12} className="text-ucase">
                  {((imageDocuments && imageDocuments.length > 0) ||
                    (nonImageDocuments && nonImageDocuments.length > 0)) && (
                    <Box>
                      <div class="inter-links">
                        <span id="documents"></span>
                      </div>

                      {imageDocuments &&
                        (imageDocuments.length > 0 ||
                          nonImageDocuments.length > 0) && (
                          <Fragment>
                            <Typography className="donation-header">
                              Supporting Documents:
                            </Typography>
                            {imageDocuments.map((document, index) => {
                              return (
                                <Fragment key={index}>
                                  <figure
                                    style={{
                                      display: "grid",
                                      placeContent: "center",
                                      background: " #fafafa",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <a
                                      target="_blank"
                                      href={document && document.url}
                                    >
                                      <img
                                        style={{
                                          maxHeight: "100px",
                                        }}
                                        alt={document && document.userGivenName}
                                        src={document && document.url}
                                      ></img>
                                    </a>
                                  </figure>
                                </Fragment>
                              );
                            })}
                            {nonImageDocuments.length > 0 && (
                              <Grid container>
                                <Table celled striped>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Document Name</TableCell>
                                      <TableCell>Note</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {nonImageDocuments.map(
                                      (document, index) => {
                                        return (
                                          <Fragment key={index}>
                                            <DocumentRow
                                              key={index}
                                              document={document}
                                              canEdit={false}
                                            />
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </Grid>
                            )}
                          </Fragment>
                        )}
                    </Box>
                  )}
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Team Details </p>
                </Grid>
                <Grid item className="text-ucase" md={8} xs={12}>
                  {document && document.teamId && document.teamId.name}
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Video Pitch </p>
                </Grid>
                <Grid item md={8} xs={12} className="linktag text-ucase">
                  <Link target="_blank" href={document.pitchDeckVideoUrl}>
                    {document.pitchDeckVideoUrl}
                  </Link>
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                <Grid item md={4} xs={12}>
                  <p className="p100">Participant Profile</p>
                </Grid>
                <Grid item md={8} xs={12}>
                  <p className="text-ucase">
                    <strong>
                      Name:{" "}
                      {document &&
                        document.user_info &&
                        document.user_info.name &&
                        document.user_info.name.first_name}{" "}
                      {document &&
                        document.user_info &&
                        document.user_info.name &&
                        document.user_info.name.last_name}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Age:{" "}
                      {document && document.user_info && document.user_info.dob}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Institution:{" "}
                      {document &&
                        document.user_info &&
                        document.user_info.institutionName}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Class:
                      {/* {document &&
                        document.user_info &&
                        document.user_info.educationalQualification} */}
                      {document?.user_info?.educationalQualification ===
                      "Others"
                        ? document?.user_info?.educationalQualificationOthers
                        : document?.user_info?.educationalQualification}
                    </strong>
                  </p>
                  <p>
                    <strong>Location: </strong>
                    {document?.user_info?.address?.line1
                      ? document?.user_info?.address?.line1
                      : ""}
                    {document?.user_info?.address?.line2
                      ? document?.user_info?.address?.line1
                        ? ", " + document?.user_info?.address?.line2
                        : document?.user_info?.address?.line2
                      : ""}
                    {document?.user_info?.address?.city
                      ? document?.user_info?.address?.line2
                        ? ", " + document?.user_info?.address?.city
                        : document?.user_info?.address?.city
                      : ""}
                    {document?.user_info?.address?.state
                      ? document?.user_info?.address?.city
                        ? ", " + document?.user_info?.address?.state
                        : document?.user_info?.address?.state
                      : ""}
                    {document?.user_info?.address?.country
                      ? document?.user_info?.address?.state
                        ? ", " + document?.user_info?.address?.country
                        : document?.user_info?.address?.country
                      : ""}
                  </p>
                </Grid>
                <Divider style={{ width: "100%", marginBottom: "15px" }} />
                {!isStudent && !isMentor && (
                  <>
                    <Grid className="p100">
                      Average Rating :
                      {document && roundToTwoDecimalPlaces(document.avgRating)}
                    </Grid>

                    <Grid item xs={12}>
                      <TableContainer
                        component={Paper}
                        style={{ marginTop: "10px" }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ color: "#f35a57" }}
                                colspan="6"
                              >
                                Rating & Notes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableRow>
                            {/* <TableCell
                            component="th"
                            scope="col"
                            style={{ color: "#f35a57" }}
                          >
                            Idea Title
                          </TableCell> */}
                            <TableCell
                              component="th"
                              scope="col"
                              style={{ color: "#f35a57" }}
                            >
                              Judges
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="col"
                              style={{ color: "#f35a57" }}
                            >
                              Ratings
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="col"
                              style={{ color: "#f35a57" }}
                            >
                              Notes
                            </TableCell>
                          </TableRow>
                          {judges && judges.length > 0 ? (
                            <TableBody>
                              {judges &&
                                judges.length > 0 &&
                                judges.map((row, index) => {
                                  return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      {/* <TableCell>
                                      {row.ideaId && row.ideaId.title}
                                    </TableCell> */}
                                      <TableCell>
                                        {row &&
                                          row.judgeId &&
                                          row.judgeId.name &&
                                          row.judgeId.name.first_name}
                                        {""}
                                        {row &&
                                          row.judgeId &&
                                          row.judgeId.name &&
                                          row.judgeId.name.last_name}
                                      </TableCell>
                                      <TableCell>
                                        {row.ratings &&
                                          Object.keys(row.ratings).length > 0 &&
                                          Object.values(row.ratings).reduce(
                                            (accumulator, value) => {
                                              return (
                                                Number(accumulator) +
                                                Number(value)
                                              );
                                            },
                                            0
                                          )}
                                        {(!row.ratings ||
                                          row.ratings.length == 0) &&
                                          "-"}
                                        {"/"}
                                        {Object.keys(
                                          isSocialAdvocacy
                                            ? advocasyRating
                                            : isSocialEntrepreneur
                                            ? entrePrenuerShipRating
                                            : isSocialPhilanthropy
                                            ? philonthophyRating
                                            : {}
                                        ).length * MAX_RATING_PER_FIELD}
                                      </TableCell>
                                      <TableCell> {row.note}</TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={20}>
                                <Typography className="no-req-text">
                                  <h6>Judges are yet to be assigned.</h6>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                )}
                <Grid item md={4} xs={12} className="edit-btn">
                  {!isJudge &&
                    !isAdmin &&
                    !isMentor &&
                    document &&
                    document.status === -2 &&
                    document.assignedJudges &&
                    document.assignedJudges.length == 0 &&
                    authUser?.unique_id === document?.user_info?._id &&
                    new Date().getTime() <
                      moment(
                        Date.parse(process.env.REACT_APP_FINAL_SUBMISSION_DATE)
                      )
                        .endOf("day")
                        .valueOf() && (
                      <Button
                        onClick={() =>
                          props.history.push(
                            `/transformers/SubmitIdea/${
                              props.donationRequestId
                                ? props.donationRequestId
                                : props.match.params.id
                            }`
                          )
                        }
                        className="edit-button"
                        style={{ textDecoration: "none", marginTop: "16px" }}
                      >
                        Edit Application
                      </Button>
                    )}
                </Grid>
                <Grid container style={{ paddingTop: "20px" }}>
                  {assignedJudgeIdeaData &&
                    !assignedJudgeIdeaData.note &&
                    !isMentor &&
                    !isAdmin &&
                    authUser &&
                    authUser.institutionalRole == "judge" &&
                    assignedJudgeIdeaData._id && (
                      <>
                        <Grid item xs={12}>
                          <div>
                            <h6>
                              {" "}
                              Your Overall Rating:{totalFieldsRatingsOfJudge}
                              {"/"}
                              {Object.keys(
                                isSocialAdvocacy
                                  ? advocasyRating
                                  : isSocialEntrepreneur
                                  ? entrePrenuerShipRating
                                  : isSocialPhilanthropy
                                  ? philonthophyRating
                                  : {}
                              ).length * MAX_RATING_PER_FIELD}
                            </h6>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <h4 style={{ marginTop: "20px" }}>
                            Add your comments
                          </h4>
                          <TextField
                            variant="outlined"
                            className="f100"
                            placeholder="Give  your Comments"
                            type="text"
                            name="title"
                            value={comments}
                            onChange={onCommentsChange}
                            required
                          />
                          {errors && errors.comments && (
                            <Typography className="custom-error">
                              {errors.comments}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                  <Grid item>
                    {(isJudge || isAdmin || isMentor) &&
                      window.location.pathname.indexOf(
                        "/transformers/viewapplication"
                      ) < 0 && (
                        <button
                          style={{
                            marginTop: "20px",
                            height: "49px",
                          }}
                          className="labsbutton"
                          type="submit"
                          onClick={props.onBackClick}
                        >
                          Back
                        </button>
                      )}
                  </Grid>
                  {assignedJudgeIdeaData &&
                    !assignedJudgeIdeaData.note &&
                    !isMentor &&
                    !isAdmin &&
                    authUser &&
                    authUser.institutionalRole == "judge" &&
                    assignedJudgeIdeaData._id && (
                      <Grid item style={{ marginLeft: "10px" }}>
                        <button
                          className="labsbutton"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Submit
                        </button>
                      </Grid>
                    )}
                </Grid>
                {/* </div> */}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default StatusFile;
