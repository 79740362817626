import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./stripe/components/CheckoutForm.scss";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { appContext } from "../../App";
import useFormValidation from "../../hooks/useFormValidation";

import "./stripe/components/CheckoutForm.scss";
import { useEffect } from "react";

const OrgBankDetails = (props) => {
  const [shouldUpdateValues, setShouldUpdateValues] = useState(true);

  const initialState = {
    routingNumber: props.value && props.value.routingNumber,
    accountNumber: props.value && props.value.accountNumber,
    accountHolderType: props.value && props.value.accountHolderType,
    accountHolderName: props.value && props.value.accountHolderName,
    country: props.value && props.value.country,
    label: props.value && props.value.label,
  };

  const { regions, region } = useContext(appContext);

  const handleChangeValues = (event) => {
    if (event.target.name === "country") {
      values.country = event.target.value;
    }
    if (event.target.name === "bankName") {
      values.label = event.target.value;
    }
    if (event.target.name === "accountHolderName") {
      values.accountHolderName = event.target.value;
    }
    if (event.target.name === "accountHolderType") {
      values.accountHolderType = event.target.value;
    }
    if (event.target.name === "accountNumber") {
      values.accountNumber = event.target.value;
    }
    if (event.target.name === "routingNumber") {
      values.routingNumber = event.target.value;
    }
    onConfirm();
  };

  useEffect(() => {
    if (
      props &&
      props.value &&
      props.value.routingNumber &&
      shouldUpdateValues
    ) {
      setValues({
        country: props.value.country,
        routingNumber: props.value.routingNumber,
        accountNumber: props.value.accountNumber,
        accountHolderName: props.value.accountHolderName,
        accountHolderType: props.value.accountHolderType,
        label: props.value.label,
      });
      setShouldUpdateValues(false);
    }
  }, [props.value]);

  const onConfirm = () => {
    let bankValues = {
      country: values.country,
      routingNumber: values.routingNumber,
      accountNumber: values.accountNumber,
      accountHolderName: values.accountHolderName,
      accountHolderType: values.accountHolderType,
      label: values.label,
      currency: values.country === "IN" ? "inr" : "usd",
    };
    props.onChange(bankValues);
  };

  const { values, setValues } = useFormValidation(initialState, onConfirm);

  return (
    <div>
      <Grid container spacing={3} className="donation-payment-container">
        <Grid item xs={12} sm={6} md={6}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            className="bnk_style"
          >
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              name="country"
              value={values.country}
              onChange={handleChangeValues}
              label="Country"
              style={{ width: "100%" }}
            >
              {regions &&
                regions.map((region) => (
                  <MenuItem value={region._id}>{region.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            type="text"
            label="Bank Name"
            variant="outlined"
            style={{ width: "100%" }}
            name="bankName"
            value={`${values.label}`}
            onChange={handleChangeValues}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            type="text"
            label="Account Holder Name"
            variant="outlined"
            style={{ width: "100%" }}
            name="accountHolderName"
            value={`${values.accountHolderName}`}
            onChange={handleChangeValues}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl
            style={{ width: "100%" }}
            variant="outlined"
            className="bnk_style"
          >
            <InputLabel id="account-type">Account Type</InputLabel>
            <Select
              labelId="account-type"
              name="accountHolderType"
              value={values.accountHolderType}
              onChange={handleChangeValues}
              label="Account Type"
              style={{ width: "100%" }}
            >
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="company">Company</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Account Number"
            variant="outlined"
            style={{ width: "100%" }}
            name="accountNumber"
            onChange={handleChangeValues}
            type="number"
            value={values.accountNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            type="text"
            name="routingNumber"
            id="routingNumber"
            autoComplete="off"
            label={
              values && values.country
                ? values.country === "US"
                  ? "Routing Number"
                  : "IFSC Code"
                : region && region._id === "IN"
                ? "IFSC Code"
                : region && region._id === "US" && "Routing Number"
            }
            variant="outlined"
            style={{ width: "100%" }}
            onChange={handleChangeValues}
            value={values.routingNumber}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(OrgBankDetails);
