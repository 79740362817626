import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Hidden } from "@material-ui/core";
import "./ContactEmailCampaign.scss";
import SideMenu from "../SideMenu/Sidemenu";
import ContactEmailCampaignTemplate from "./ContactEmailCampaignTemplate";
import { appContext } from "../../App";

const ContactEmailCampaign = (props) => {
  const { authUser, currentDonationRequestName } = useContext(appContext);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const { selectedOrganization, setSelectedOrganization } = useContext(
    appContext
  );

  const handleEdit = () => {
    setSelectedOrganization(null);
    props.history.push(`/donationRequest/${currentDonationRequestName}`);
  };
  return (
    <div className="make-new-req-container">
      <Hidden mdUp>
        {" "}
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          </Hidden>
          <div className="drop-box" style={{ width: "100%" }}>
            <div className="email-hedar">
              <h4>Send As</h4>
              <Button onClick={handleEdit} aria-label="edit">
                Edit
              </Button>
            </div>
            <div className="template-slot">
              {selectedOrganization === null ? (
                <p>
                  <img
                    src={"/images/individual.png"}
                    alt="individual"
                    width="50px"
                    style={{ margin: 5 }}
                  />{" "}
                  Individual
                </p>
              ) : (
                <p>
                  <img
                    src={
                      selectedOrganization &&
                      selectedOrganization.defaultImageUrl
                        ? selectedOrganization.defaultImageUrl
                        : "/images/orgLogo.png"
                    }
                    alt="orgLogo"
                    width="50px"
                    style={{ margin: 5 }}
                  />{" "}
                  {selectedOrganization.orgName}
                </p>
              )}
              <div>
                <h5>Select a Template</h5>
              </div>
              <div className="email-body">
                <ContactEmailCampaignTemplate
                  orgainization={selectedOrganization}
                />
              </div>
            </div>

            {/* {emailTypeSelected ? (
            <div className="drop-box" style={{ width: "100%" }}>
              <div className="email-hedar">
                <h4>Send as</h4>
                <Button onClick={handleEdit} aria-label="edit">
                  Edit
                </Button>
              </div>
              {selectedOrganization === null ? (
                <p>
                  <img
                    src={"/images/individual.png"}
                    alt="individual"
                    width="50px"
                    style={{ margin: 5 }}
                  />{" "}
                  Individual
                </p>
              ) : (
                <p>
                  <img
                    src={
                      selectedOrganization &&
                      selectedOrganization.defaultImageUrl
                        ? selectedOrganization.defaultImageUrl
                        : "/images/orgLogo.png"
                    }
                    alt="orgLogo"
                    width="50px"
                    style={{ margin: 5 }}
                  />{" "}
                  {selectedOrganization.orgName}
                </p>
              )}
              <div className="email-hedar">
                <h4>Select a Template</h4>
              </div>
              <div className="email-body">
                <ContactEmailCampaignTemplate
                  orgainization={selectedOrganization}
                />
              </div>
            </div>
          ) : (
            <div className="drop-box" style={{ width: "100%" }}>
              <div className="email-hedar">
                <h4>Send as</h4>
              </div>
              <div className="email-body">
                <div className="email-groups">
                  <Button onClick={handleIndividual}>
                    <EmailTypeCard
                      icon="/images/individual.png"
                      title="Individual"
                      subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                    />
                  </Button>
                  {!value ? (
                    <Button onClick={handleSelect}>
                      <EmailTypeCard
                        icon="/images/orgLogo.png"
                        title="Organization"
                        subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                      />
                    </Button>
                  ) : (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="organization-select-label">
                        Select Organization
                      </InputLabel>
                      <Select
                        labelId="organization-select-label"
                        id="organization-select"
                        value={selectedOrganization}
                        onChange={handleSelectOrganization}
                      >
                        {orgList &&
                          orgList.length > 0 &&
                          orgList.map((org) => {
                            return (
                              <MenuItem
                                key={org && org.orgId && org.orgId._id}
                                value={org && org.orgId}
                              >
                                <img
                                  src={
                                    org &&
                                    org.orgId &&
                                    org.orgId.defaultImageUrl
                                      ? org.orgId.defaultImageUrl
                                      : "/images/orgLogo.png"
                                  }
                                  alt="orgLogo"
                                  width="20px"
                                  style={{ marginRight: 8 }}
                                />{" "}
                                {org.orgId.orgName}{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "100%",
                                    textAlign: "end",
                                  }}
                                >
                                  {org.orgId.status === 1 ? (
                                    <CheckCircleOutlineIcon
                                      style={{ color: "green" }}
                                    />
                                  ) : (
                                    <ErrorOutlineIcon
                                      style={{ color: "red" }}
                                    />
                                  )}
                                </span>
                              </MenuItem>
                            );
                          })}
                        <MenuItem value="add" onClick={handleOrganization}>
                          Add Organization
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>
                <Organization
                  openOrgModal={openOrgModal}
                  onCloseModal={() => setOpenOrgModal(false)}
                  onSavingOrg={onSavingOrg}
                />
              </div>
            </div>
          )} */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactEmailCampaign;
