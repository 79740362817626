import React, { useEffect, useState, useContext } from "react";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import SpeedIcon from "@material-ui/icons/Speed";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PaymentIcon from "@material-ui/icons/Payment";
import HelpIcon from "@material-ui/icons/Help";
import Link from "@material-ui/core/Link";
import { appContext } from "../../App";
import { getUserData } from "../../utils/api";
import { getQueryStringValue } from "../../utils/utils";
import jwt_decode from "jwt-decode";
import moment from "moment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";

const SideMenu = (props) => {
  const [currentPage, setCurrentPage] = useState("");
  const { authUser, setAuthUser, configuration } = useContext(appContext);

  useEffect(() => {
    setCurrentPage(window.location.pathname.split("/").slice(-1).pop());
  }, []);

  const loadUserData = (userId) => {
    console.log("in loadUserData");
    getUserData(userId)
      .then((response) => {
        console.log("then of getUserData");
        setAuthUser(response);
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem(
          "stripeCustomerId",
          response.stripeCustomerId !== "" ? response.stripeCustomerId : "null"
        );
      })
      .catch((error) => {
        console.log("catch of getUserData");
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    if (!authUser) {
      //If token is available then call the API and set localstorage data & authUser data

      const userTokenParam = getQueryStringValue("token");

      if (userTokenParam) {
        //decrypt jwt token and set local storage
        try {
          const decodedToken = jwt_decode(userTokenParam);
          console.log("decodedToken", decodedToken);
          if (!decodedToken) {
            toastr.error("Invalid token");
            props.history.push("/");
          } else {
            loadUserData(decodedToken && decodedToken.user);
          }
        } catch (error) {
          toastr.error("Failed to validate the user data" + error.message);
          props.history.push("/");
        }
      }
    }
  }, [authUser]);

  const checkProfileCompletionAndRedirect = () => {
    if (authUser && authUser.unique_id) {
      if (authUser.isProfileCompleted) {
        props.history.push("/transformers/SubmitIdea");
        setCurrentPage(window.location.pathname.split("/").slice(-1).pop());
      } else {
        toastr.error(
          "Please complete participant profile, before submitting an idea."
        );
        props.history.push("/transformers/ParticipantProfile");
      }
    }
  };

  const checkProfileCompletionAndRedirectToBlog = () => {
    if (authUser && authUser.unique_id) {
      if (authUser.isProfileCompleted) {
        props.history.push("/transformers/StudentBlog");
      } else {
        toastr.error(
          "Please complete participant profile, before submitting an idea."
        );
        props.history.push("/transformers/ParticipantProfile");
      }
    }
  };

  return (
    <>
      <Paper className="sidebar-container">
        <MenuList>
          <MenuItem
            style={{
              backgroundColor: currentPage == "ParticipantProfile" && "#d9232d",
            }}
          >
            <Link
              href="/transformers/ParticipantProfile"
              style={{ color: currentPage == "ParticipantProfile" && "#fff" }}
            >
              <ListItemIcon>
                <HomeIcon
                  fontSize="small"
                  style={{
                    color: currentPage == "ParticipantProfile" && "#fff",
                  }}
                />
              </ListItemIcon>
              Participant Profile
            </Link>
          </MenuItem>
          <MenuItem
            style={{
              backgroundColor: currentPage == "Challenges" && "#d9232d",
            }}
          >
            <Link
              href="/transformers/Challenges"
              selected={"true"}
              style={{ color: currentPage == "Challenges" && "#fff" }}
            >
              <ListItemIcon>
                <SpeedIcon
                  fontSize="small"
                  style={{ color: currentPage == "Challenges" && "#fff" }}
                />
              </ListItemIcon>
              Challenge
            </Link>
          </MenuItem>
          <MenuItem
            style={{
              backgroundColor: currentPage == "LearningLabs" && "#d9232d",
            }}
          >
            <Link
              href="/transformers/LearningLabs"
              style={{ color: currentPage == "LearningLabs" && "#fff" }}
            >
              <ListItemIcon>
                <GroupIcon
                  fontSize="small"
                  style={{ color: currentPage == "LearningLabs" && "#fff" }}
                />
              </ListItemIcon>
              Learning Labs
            </Link>
          </MenuItem>
          {/* <MenuItem
            // disabled={!configuration.enableIdeaSubmission}
            style={{
              backgroundColor: currentPage === "StudentBlog" && "#d9232d",
            }}
          >
            <Link
              style={{ color: currentPage === "StudentBlog" && "#fff" }}
              onClick={checkProfileCompletionAndRedirectToBlog}
            >
              <ListItemIcon>
                <LibraryBooksIcon
                  fontSize="small"
                  style={{ color: currentPage == "StudentBlog" && "#fff" }}
                />
              </ListItemIcon>
              Submit Blog
            </Link>
          </MenuItem> */}
          <MenuItem
            disabled={!configuration.enableIdeaSubmission}
            style={{
              backgroundColor: currentPage === "SubmitIdea" && "#d9232d",
            }}
          >
            <Link
              style={{ color: currentPage === "SubmitIdea" && "#fff" }}
              onClick={checkProfileCompletionAndRedirect}
            >
              <ListItemIcon>
                <EmojiObjectsIcon
                  fontSize="small"
                  style={{ color: currentPage == "SubmitIdea" && "#fff" }}
                />
              </ListItemIcon>
              Submit Idea
            </Link>
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: currentPage == "Status" && "#d9232d" }}
            className={currentPage == "Status" && "activeClassName"}
          >
            <Link
              href="/transformers/Status"
              style={{ color: currentPage == "Status" && "#fff" }}
            >
              <ListItemIcon>
                <CheckCircleIcon
                  fontSize="small"
                  style={{ color: currentPage == "Status" && "#fff" }}
                />
              </ListItemIcon>
              Status
            </Link>
          </MenuItem>
          <MenuItem
            disabled={
              new Date().getTime() <
              moment(Date.parse(process.env.REACT_APP_CERTIFICATE_ENABLE_DATE))
                .startOf("day")
                .valueOf()
            }
            style={{
              backgroundColor: currentPage === "myCertificates" && "#d9232d",
            }}
            className={currentPage === "myCertificates" && "activeClassName"}
          >
            <Link
              href="/transformers/downloads/myCertificates"
              style={{ color: currentPage == "myCertificates" && "#fff" }}
            >
              <ListItemIcon>
                <CardMembershipIcon
                  fontSize="small"
                  style={{ color: currentPage === "myCertificates" && "#fff" }}
                />
              </ListItemIcon>
              Certificate
            </Link>
          </MenuItem>
          <MenuItem
            style={{ backgroundColor: currentPage == "HelpDesk" && "#d9232d" }}
          >
            <Link
              href="/transformers/HelpDesk"
              style={{ color: currentPage == "HelpDesk" && "#fff" }}
            >
              <ListItemIcon>
                <HelpIcon
                  fontSize="small"
                  style={{ color: currentPage == "HelpDesk" && "#fff" }}
                />
              </ListItemIcon>
              Help Desk
            </Link>
          </MenuItem>
        </MenuList>
      </Paper>
    </>
  );
};
export default withRouter(SideMenu);
