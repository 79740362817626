import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { getDonationDetails, getUserData } from "../../utils/api";
import toastr from "toastr";
import {
  Grid,
  InputLabel,
  lighten,
  LinearProgress,
  TextField,
  withStyles,
} from "@material-ui/core";
import { DATE_FORMAT, formatNumber } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { appContext } from "../../App";

const useStyles = makeStyles({
  root: {
    padding: 10,
    "& img": {
      backgroundColor: "rgb(246, 247, 251)",
      objectFit: "contain",
    },
  },
});
const BorderLinearProgress = withStyles({
  root: {
    height: 8,
    backgroundColor: lighten("#aaa", 0.2),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#5BCA7A",
  },
})(LinearProgress);

export default function WithdrawalCard(props) {
  const classes = useStyles();
  const [donationRequest, setDonationRequest] = useState({});
  const [address, setAddress] = useState(null);
  const { authUser } = useContext(appContext);

  const loadDonationRequest = (donationRequestId) => {
    getDonationDetails(donationRequestId)
      .then((response) => {
        setDonationRequest(response.data);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  useEffect(() => {
    if (props.donationRequestId) {
      loadDonationRequest(props.donationRequestId);
    }
  }, [props.donationRequestId]);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      getUserData(authUser.unique_id)
        .then((response) => {
          const userData = response;

          if (userData.address) {
            setAddress({
              line1: userData.address.hasOwnProperty("line1")
                ? userData.address.line1
                : "",
              line2: userData.address.hasOwnProperty("line2")
                ? userData.address.line2
                : "",
              city: userData.address.hasOwnProperty("city")
                ? userData.address.city
                : "",
              state: userData.address.hasOwnProperty("state")
                ? userData.address.state
                : "",
              zip: userData.address.hasOwnProperty("zip")
                ? userData.address.zip
                : "",
              country: userData.address.hasOwnProperty("country")
                ? userData.address.country
                : "",
            });
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  }, [authUser]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: "0" }}>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={donationRequest.defaultImageUrl}
          title="Contemplative Reptile"
        />
        <CardContent style={{ padding: "10px 0" }}>
          <Typography gutterBottom variant="h5" component="h2">
            {donationRequest.title}
          </Typography>
          <div style={{ color: "#aaa", font: "400 12px Roboto" }}>
            <span>
              {donationRequest &&
                moment(donationRequest.createdAt).format(DATE_FORMAT)}
            </span>
            <span> | </span>
            <span>
              {donationRequest &&
                donationRequest.createdAt &&
                moment(donationRequest.createdAt).startOf("seconds").fromNow()}
            </span>
          </div>
          <Typography variant="body2" color="textSecondary" component="p">
            {donationRequest.brief}
          </Typography>
        </CardContent>
        <b>
          {`${formatNumber(donationRequest.donated_quantity)}  raised of `}
          {donationRequest.units &&
          donationRequest.units.toUpperCase() === "INR" ? (
            <FontAwesomeIcon color="#000" size="1x" icon={faRupeeSign} />
          ) : (
            <FontAwesomeIcon color="#000" size="1x" icon={faDollarSign} />
          )}
          {donationRequest && formatNumber(donationRequest.quantity)}
        </b>
        <span style={{ float: "right" }}>
          {(donationRequest.donated_quantity * 100) /
            donationRequest.quantity >=
          100
            ? 100
            : Math.round(
                (donationRequest.donated_quantity * 100) /
                  donationRequest.quantity
              )}
          %
        </span>
        <BorderLinearProgress
          className="prgBar"
          variant="determinate"
          color="secondary"
          value={
            donationRequest &&
            donationRequest.quantity &&
            donationRequest.donated_quantity &&
            (donationRequest.donated_quantity * 100) /
              donationRequest.quantity >
              100
              ? 100
              : (donationRequest.donated_quantity * 100) /
                donationRequest.quantity
          }
          style={{ cursor: "pointer", margin: "5px 0" }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className="secFormLabel">Name</InputLabel>
            <TextField
              type="text"
              className="profile-fields user name"
              variant="outlined"
              style={{ width: "100%" }}
              name="userName"
              disabled
              value={
                donationRequest.user_info && donationRequest.user_info.username
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="secFormLabel">Number</InputLabel>
            <TextField
              type="text"
              className="profile-fields user name"
              variant="outlined"
              style={{ width: "100%" }}
              name="userName"
              disabled
              value={
                donationRequest.user_info && donationRequest.user_info.phone
              }
            />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <InputLabel
                style={{ margin: "10px 0 0 0" }}
                className="secFormLabel"
              >
                Address
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field addr-line1"
                placeholder="Address Line1"
                name="line1"
                variant="outlined"
                disabled
                value={address && address.line1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field addr-line2"
                placeholder="Address Line2"
                name="line2"
                variant="outlined"
                disabled
                value={address && address.line2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field city"
                placeholder="City"
                name="city"
                variant="outlined"
                disabled
                value={address && address.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field state"
                placeholder="State"
                name="state"
                variant="outlined"
                disabled
                value={address && address.state}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field country"
                placeholder="Country"
                name="country"
                variant="outlined"
                disabled
                value={address && address.country}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                className="input-field zip"
                placeholder="Zip"
                name="zip"
                variant="outlined"
                disabled
                value={address && address.zip}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
