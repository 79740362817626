import React, { useContext, Fragment, useState, useEffect } from "react";
import DonationRequestCard from "../donationRequest/DonationRequestCard";
import { appContext } from "../../App";
import {
  Container,
  Grid,
  Hidden,
  MenuList,
  MenuItem,
  Link,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import StickyBox from "react-sticky-box";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core/styles";
import { TALMEDIA_PAGE } from "../../utils/utils";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import toastr from "toastr";
import { getDonationRequestResponse } from "../../utils/api";
import Loader from "../common/Loader";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },

  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    paddingTop: "8px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    background: "rgb(246, 247, 251)",
    fontWeight: "400",
  },
  alert: {
    width: "100%",
    backgroundColor: "rgb(243, 90, 87)",
    maxHeight: "-10px !important",
  },
  ptag: {
    color: "#fff",
    padding: "8px",
    textAlign: "center",
    marginTop: "-25px",
  },
}));
const MyApplications = (props) => {
  const classes = useStyles();
  const { authUser, currentYear } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userScholarshipRequests, setUserScholarshipRequests] = useState([]);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setIsLoading(true);
      getDonationRequestResponse("", authUser && authUser.unique_id)
        .then((response) => {
          setIsLoading(false);
          setUserScholarshipRequests(
            response.filter(
              (res) =>
                res &&
                res.donation_request_info &&
                (res.donation_request_info.request_type === "internship" ||
                  res.donation_request_info.request_type === "volunteering" ||
                  res.donation_request_info.request_type === "scholarship" ||
                  res.donation_request_info.request_type === "career" ||
                  res.donation_request_info.request_type === "homework help")
            )
          );
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Error getting donation requests:" + error.message);
        });
    }
  }, [authUser && authUser.unique_id]);
  const handleDonationType = (type) => {
    if (type === "plasma") {
      props.history.push("/plasma-donee-donor");
    }
    if (type === "careerhelp") {
      props.history.push("/careerhelp-request");
    }
    if (type === "volunteering") {
      props.history.push("/volunteer-request");
    }
    if (type === "homework help") {
      props.history.push("/homework-request");
    }
  };
  const settingsslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [expanded] = React.useState(false);
  return (
    <div className="home2-container">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <Hidden smDown>
              <div className="container-left">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <SideMenu />
                </StickyBox>
              </div>
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {userScholarshipRequests && userScholarshipRequests.length > 0 && (
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                Total Applications({userScholarshipRequests.length})
              </Typography>
            )}

            <Fragment>
              {userScholarshipRequests && userScholarshipRequests.length > 0
                ? userScholarshipRequests.map((donationRequest, index) => {
                    return (
                      <DonationRequestCard
                        donationRequest={donationRequest.donation_request_info}
                        appliedScholarship={true}
                        key={index}
                      />
                    );
                  })
                : !isLoading &&
                  userScholarshipRequests.length === 0 && (
                    <p className="no-req-text">
                      You haven't applied for any requests
                    </p>
                  )}
            </Fragment>
          </Grid>
          <Grid item xs={12} sm={12} md={3} className="order-col">
            <div className="container-right">
              <StickyBox offsetTop={20}>
                <Hidden smDown>
                  <div className="quick-menu">
                    <p>Add Your Request</p>
                    <MenuList>
                      <MenuItem
                        onClick={() => handleDonationType("volunteering")}
                      >
                        <div>
                          <img
                            src="/images/volunteering.png"
                            alt="Volunteering"
                          />
                        </div>
                        <p> Volunteering</p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDonationType("careerhelp")}
                      >
                        <div>
                          <img
                            src="/images/career-help.svg"
                            alt="Career Help"
                          />
                        </div>
                        <p> Career Help</p>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDonationType("homework help")}
                      >
                        <div>
                          <img
                            src="/images/homework.png"
                            alt="Home Work Help"
                          />
                        </div>
                        <p> Home Work Help</p>
                      </MenuItem>
                    </MenuList>
                  </div>

                  <div className=" no-pad promoBlk" style={{ padding: "0" }}>
                    <div className="promotion  drop-box">
                      <div className="mobileSlider">
                        <div>
                          <Slider {...settingsslider}>
                            <Link
                              href={process.env.REACT_APP_TALRADIO_URL}
                              target="blank"
                            >
                              <img src="/images/radio.png" alt="TALRadio" />
                            </Link>
                            <Link
                              href={process.env.REACT_APP_TALSCOUTS_URL}
                              target="blank"
                            >
                              <img
                                src="/images/home-page-transformers.jpg"
                                alt="TALTransformers"
                              />
                            </Link>
                            <Link
                              href={process.env.REACT_APP_BASE_URL}
                              target="blank"
                            >
                              <img src="/images/group-2.png" alt="TALGiving" />
                            </Link>
                            <Link href={TALMEDIA_PAGE} target="blank">
                              <img src="/images/group-4.png" alt="TALMedia" />
                            </Link>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sidemenu-container">
                    <div className="store-block drop-box">
                      <p>Download The TALGiving App Now</p>
                      <div className="scan-img">
                        <img
                          src="/images/android-ios-store.png"
                          alt="Scan to download the app"
                        />
                      </div>
                      <div className=" scan">
                        <PhoneAndroidIcon />
                        Scan to Download
                      </div>
                    </div>
                  </div>
                  <section
                    className=" no-pad latestNewsBlk drop-box"
                    style={{ padding: "0" }}
                  >
                    <div className="newsList">
                      <Card className={classes.root} elevation={0}>
                        <CardContent>
                          <div style={{ display: "flex" }}>
                            <div class="newlist-icon"></div>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className="newsList-title"
                            >
                              TAL Headlines
                            </Typography>
                          </div>
                          <ul>
                            <li>
                              Touch-A-Life Foundation adds accelero as charter
                              member
                            </li>
                            <li>
                              Touch-A-Life Foundation adds PulpPR as TAL charter
                              member
                            </li>
                            <li>
                              Touch-A-Life Foundation adds Royse Law Firm as
                              charter member
                            </li>
                            <li>
                              Touch-A-Life Foundation announces Hero award
                              winners for TAL Annual Day 2019
                            </li>
                            <li>
                              Touch-A-Life Foundation partners with Minds to
                              enable Cryptocurrency-based giving
                            </li>
                          </ul>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <CardContent>
                            <ul>
                              <li>
                                Nearly half of the U.S.’s homeless people live
                                in one state: California
                              </li>
                              <li>
                                Sheltering the homeless: These 48 major US
                                cities face growing homeless populations
                              </li>
                              <li>
                                As Homelessness Surges in California, So Does a
                                Backlash
                              </li>
                              <li>
                                No Address, No Next of Kin: Homeless in Life,
                                Anonymous in Death
                              </li>
                              <li>
                                E.P.A. Bypassed Its West Coast Team as a Feud
                                With California Escalated
                              </li>
                            </ul>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </section>
                  <div className="home-footer drop-box">
                    <p>
                      <a
                        href="https://www.touchalife.org/about/"
                        target="blank"
                      >
                        About .
                      </a>{" "}
                      <a href="/users/help-desk" target="blank">
                        Help Desk .
                      </a>{" "}
                      <a href="/users/Privacy" target="blank">
                        Privacy .{" "}
                      </a>{" "}
                      <a href="/users/Terms" target="blank">
                        Terms
                      </a>
                      {". "}
                      <a href="/users/ContactUs" target="blank">
                        Contact Us
                      </a>{" "}
                    </p>
                    <p>Touch-A-Life Foundation INC &copy; {currentYear}</p>
                  </div>
                </Hidden>
              </StickyBox>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Loader isOpen={isLoading} onClose={(e) => setIsLoading(false)} />
    </div>
  );
};

export default MyApplications;
