import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select, MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./Payment.scss";
import { appContext } from "../../App";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

const PublicPayment = (props) => {
  const { regions, region, setDonationDetails, setRegion } =
    useContext(appContext);
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAnonymous, setAnonymous] = useState(false);
  const [donationFor, setDonationFor] = useState("tal");
  const [disableCurrency, setDisableCurrency] = useState(false);
  const [errorAmount, setErrorAmount] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [shouldChargeProcessingFees, setShouldChargeProcessingFees] =
    useState(false);
  const [open, setOpen] = React.useState(false);

  const changeHandler = (event, type) => {
    if (type === "amount") {
      setAmount(event.target.value);
      setErrorAmount("");
    } else if (type === "name") {
      setName(event.target.value);
      setErrorName("");
    } else if (type === "email") {
      setEmail(event.target.value);
      setErrorEmail("");
    }
  };

  const restrictCharacters = (event) => {
    const allowedCharacters = /^[0-9]\d*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  };

  const getParams = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params;
  };
  const handleChange = (event) => {
    setAnonymous(event.target.checked);
    if (event.target.checked === true) {
      setOpen(true);
      setErrorName("");
      setErrorEmail("");
    }
  };

  useEffect(() => {
    let params = getParams();
    setCurrency(params.get("currency"));
    let currency = params.get("currency");
    if (currency) {
      setCurrency(currency);
      setRegion(
        regions.find((region) => currency.toUpperCase() === region.currency)
      );
    } else if (region) {
      setCurrency(region.currency);
    }
    if (params.get("email")) {
      setEmail(params.get("email"));
    }
    if (params.get("name")) {
      setName(params.get("name"));
    }
    if (props.amt) {
      setAmount(props.amt);
    }
    if (params.get("donationfor")) {
      setDonationFor(params.get("donationfor"));
      if (params.get("donationfor") === "donationRequest") {
        setDisableCurrency(true);
      }
    }
  }, [props, region, regions, setRegion]);

  const submitHandler = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (
      !isAnonymous &&
      name === "" &&
      email === "" &&
      (!amount || isNaN(amount) || Number(amount) < 1)
    ) {
      setErrorName("Please enter Name");
      setErrorEmail("Please enter Email");
      setErrorAmount("Please enter valid amount");
    } else if (!amount || isNaN(amount) || Number(amount) < 1) {
      setErrorAmount("Please enter valid amount");
    } else if (name === "" && isAnonymous === false) {
      setErrorName("Please enter Name");
    } else if (
      (email === "" || pattern.test(email) === false) &&
      isAnonymous !== true
    ) {
      setErrorEmail("Please enter a valid email address");
    } else {
      let params = getParams();
      var paymentObj = {};
      paymentObj.donationFor = donationFor;
      paymentObj.userId = params.get("userid") || "";
      paymentObj.toUserInfo = params.get("touserinfo") || "";
      paymentObj.donationRequestId = params.get("donationid") || "";
      paymentObj.customer_id = params.get("customerid") || "";
      if (currency) {
        paymentObj.currency = currency;
      } else {
        paymentObj.currency = params.get("currency");
      }
      paymentObj.region = region;
      paymentObj.isAnonymous = isAnonymous;
      paymentObj.externalUserInfo = {
        name: name,
        email: email,
      };
      if (shouldChargeProcessingFees) {
        paymentObj.donatedAmount = Number(amount);
        paymentObj.processingFees = Number(
          (
            (paymentObj.region.processingFees / 100.0) *
            paymentObj.donatedAmount
          ).toFixed(2)
        );
        paymentObj.amount = Math.round(
          100 * (paymentObj.donatedAmount + paymentObj.processingFees)
        );
      } else {
        paymentObj.amount = 100 * Number(amount);
        paymentObj.donatedAmount = Number(
          (amount / (1 + paymentObj.region.processingFees / 100.0)).toFixed(2)
        );
        paymentObj.processingFees = amount - paymentObj.donatedAmount;
      }
      setDonationDetails(paymentObj);
      setAmount("");
      setName("");
      setEmail("");
      props.history.push("/billingInfo");
    }
  };

  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
  };

  const processingFeesChangeHandler = (event) => {
    setShouldChargeProcessingFees(event.target.checked);
  };

  return (
    <div>
      <div className="public-payment-container drop-box publicPayment ">
        <div className="payment-page">
          <div>
            <div className="pay-headername">
              <h2>Donate Now!</h2>
              <div className="avatar-cont">
                <Avatar
                  alt="Remy Sharp"
                  className="avatar"
                  src="/images/donate-icon.svg"
                />
              </div>
              <p className="payment-text">
                One step away to Spread kindness :)
              </p>
              <p className="payment-text">Make a contribution now!</p>
            </div>
            <div style={{ width: "70%", margin: "0 auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAnonymous}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Donate as anonymous"
              />
            </div>
            <div className="payment-textfield-container">
              <TextField
                type="text"
                className="full-width border-radius input-field payment-input"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => changeHandler(e, "name")}
              />
              <span>
                {errorName && (
                  <div
                    style={{
                      color: "red",
                      "font-size": "12px",
                      textAlign: "left",
                    }}
                  >
                    {errorName}
                  </div>
                )}
              </span>

              <TextField
                type="text"
                className="full-width border-radius input-field payment-input"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => changeHandler(e, "email")}
              />
              <span>
                {errorEmail && (
                  <div
                    style={{
                      color: "red",
                      "font-size": "12px",
                      textAlign: "left",
                    }}
                  >
                    {errorEmail}
                  </div>
                )}
              </span>

              <div className="flex-input payment-input ">
                <TextField
                  type="number"
                  className="full-width border-radius payment-input "
                  placeholder="Donation Amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => changeHandler(e, "amount")}
                  onKeyPress={restrictCharacters}
                />
                <FormControl className="payment-input-type">
                  <Select
                    disabled={disableCurrency}
                    name="currency"
                    value={currency}
                    className="currency"
                    onChange={currencyChangeHandler}
                  >
                    {regions &&
                      regions.length > 0 &&
                      regions.map((region, index) => {
                        return (
                          <MenuItem value={region.currency}>
                            {region.currency}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>{" "}
              </div>

              <span>
                {errorAmount && (
                  <div
                    style={{
                      color: "red",
                      "font-size": "12px",
                      textAlign: "left",
                    }}
                  >
                    {errorAmount}
                  </div>
                )}
              </span>
            </div>
          </div>

          <div className="donate-now" style={{ margin: "20px 30px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldChargeProcessingFees}
                  onChange={processingFeesChangeHandler}
                  name="processingFees"
                  color="primary"
                  style={{
                    position: "relative",
                    top: "-10px",
                    display: "flex",
                    alignSelf: "baseline",
                  }}
                />
              }
              label={`I would like to pay the extra bank transaction charges of ${
                region && region.processingFees
              }%  so that the beneficiary receives the full amount that I am donating.`}
            />
          </div>

          <div
            className="payment-btn"
            style={{ width: "200px", margin: "0 auto" }}
          >
            <Button
              color="primary"
              className="pay-btn"
              variant="contained"
              type="submit"
              onClick={submitHandler}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Dear Donor</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We still need your name and email address so that we can send a
            donation receipt to you. The donation will appear as anonymous to
            the public.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default withRouter(PublicPayment);
