import React, { useEffect, useState, useContext } from "react";
import SideMenu from "./SideMenu";
import { appContext } from "../../App";
import Header from "./Header";
import "./Transformers.scss";
import { Box, Grid } from "@material-ui/core";
import StatusMedia from "./StatusMedia";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { getTeamIdeas } from "../../utils/api";
import Link from "@material-ui/core/Link";

const Status = (props) => {
  const { authUser, configuration } = useContext(appContext);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ideasStartDate = process.env.REACT_APP_IDEAS_DISPLAYING_DATE;

  const loadDonationRequests = () => {
    const userData = authUser;
    getTeamIdeas(10, 0, userData?.email)
      .then((response) => {
        if (response) {
          setDocuments(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching DonationRequest:" + error);
      });
  };
  useEffect(() => {
    authUser && authUser.unique_id && loadDonationRequests();
  }, [authUser]);

  const checkProfileCompletionAndRedirect = () => {
    if (authUser && authUser.unique_id) {
      if (authUser.isProfileCompleted) {
        props.history.push("/transformers/SubmitIdea");
      } else {
        toastr.error(
          "Please complete participant profile, before submitting an idea."
        );
        props.history.push("/transformers/ParticipantProfile");
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Box component="h3" mt={2} mb={2}>
          Status
        </Box>
        {isLoading && documents && documents.length === 0 && (
          <Box component="h5" align="center" mt={4} mb={4}>
            Loading...
          </Box>
        )}
        {!isLoading && documents && documents.length === 0 && (
          <>
            <Box component="div" align="center" mt={2}>
              <Box component="h5" mt={2} mb={2}>
                You did not submit any ideas until now.{" "}
              </Box>
              <Grid className="linktag">
                {configuration && configuration.enableIdeaSubmission && (
                  <Link onClick={checkProfileCompletionAndRedirect}>
                    Click here to submit an idea
                  </Link>
                )}
                {configuration && !configuration.enableIdeaSubmission && (
                  <Box component="h5" mt={2} mb={2}>
                    Idea Submission is disabled
                  </Box>
                )}
              </Grid>
            </Box>
          </>
        )}

        {documents &&
          documents.length > 0 &&
          documents.map((document, index) => {
            return (
              <StatusMedia
                key={index}
                requestType={document.request_type}
                team={document && document.teamId && document.teamId.name}
                donationRequestId={document._id}
                thumbnailUrl={document.defaultImageUrl}
                document={document}
              />
            );
          })}
      </Container>
    </div>
  );
};

export default Status;
