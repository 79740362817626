import React from "react";
import { Tooltip } from "@material-ui/core";

function Song(props) {
  const { songName, songArtist, songProducer } = props;

  return (
    <div className="song">
      <Tooltip
        title={songName}
        placement="bottom-start"
      >
        <h1 className="song__title">{songName}</h1>
      </Tooltip>
      <Tooltip
        title={songArtist}
        placement="bottom-start"
      >
        <h3 className="song__artist">{songArtist}</h3>
      </Tooltip>
      <Tooltip
        title={songProducer}
        placement="bottom-start"
      >
        <h3 className="song__artist">{songProducer}</h3>
      </Tooltip>
    </div>
  );
}

export default Song;
