import React, { useContext, useEffect, useState } from "react";
import toastr from "toastr";
import firebase from "../../firebase/firebase";
import { DATE_TIME_TIMEZONE_FORMAT_FULL } from "../../utils/utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  getStreamings,
  deleteStreaming,
  updateStreaming,
} from "../../utils/api";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { appContext } from "../../App";
import { withRouter } from "react-router";

import VideoPlayer from "../common/VideoPlayer";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import StreamingForm from "./StreamingForm";
import ChannelSelectionBox from "./ChannelSelectBox";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  livestreamings: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#31A93D",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "16px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      border: "var(--tal_primary) 2px solid",
      backgroundColor: "white",
      color: "var(--tal_primary)",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "#CD2729",
      color: "white",
    },
  },
}));

function Streamings(props) {
  const { playlists, channel, setChannel } = props;
  const { authUser } = useContext(appContext);
  const [isDisplayStreamings, setIsDisplayStreamings] = useState(true);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedChannelItems, setSelectedChannelItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [listenersCount, setListenersCount] = useState(0);
  const listenersCountRef = firebase.realtimeDb.ref(
    "currentStreaming/listenersCount"
  );

  const getListenersCount = (snapshot) => {
    setListenersCount(Number(snapshot.val()));
  };

  useEffect(() => {
    listenersCountRef.on("value", getListenersCount);
  }, []);

  const handleStreamings = (id) => {
    setIsDisplayStreamings(false);
    if (id) {
      setIsEnableEdit(true);
      setSelectedId(id);
    }
  };

  const confirmDelete = (recordObj) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const recordId = recordObj.id;
      deleteStreaming(recordId)
        .then((delResp) => {
          if (delResp.statusCode === 200) {
            var oldRows = rows.filter((item) => {
              return item.id !== recordId;
            });
            setRows([...oldRows]);
            toastr.success(delResp.message);
          } else {
            toastr.error("Error while deleting the streaming");
          }
        })
        .catch((e) => {
          toastr.error("Failed to delete the streaming", e.message);
        });
    }
  };

  const loadStreamings = () => {
    setIsLoading(true);
    getStreamings()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          response.data.map((details) => {
            setRows((prevState) => {
              return [
                {
                  id: details._id,
                  title: details.title,
                  channel: details.channel,
                  startTime: details.startTime,
                  endTime: details.endTime,
                  playlist: details.playlist,
                  downloadUrl: details.downloadUrl,
                },
                ...prevState,
              ];
            });
            return rows;
          });
        }
        setIsLoading(false);
        return rows;
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Streamings:" + error);
      });
  };

  useEffect(() => {
    if (rows) {
      setSelectedChannelItems(rows.filter((item) => item.channel === channel));
    }
    setPage(0);
  }, [rows, channel]);

  useEffect(() => {
    if (authUser) {
      loadStreamings(authUser.unique_id);
    }
  }, [authUser]);

  const onPlayAudio = (row) => {
    setSelectedRow(row);
    setIsPlayingAudio(true);
  };
  const handlePlayerClose = () => {
    setSelectedRow({});
    setIsPlayingAudio(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classes = useStyles();
  if (!isDisplayStreamings) {
    return (
      <StreamingForm
        setIsDisplayStreamings={setIsDisplayStreamings}
        playlists={playlists}
        isEnableEdit={isEnableEdit}
        setIsEnableEdit={setIsEnableEdit}
        selectedId={selectedId}
        rows={rows}
        setRows={setRows}
        channel={props.channel}
        channels={props.channels}
      />
    );
  } else
    return (
      <Box>
        <Box className="drop-box" p={1} mb={1}>
          <Box className={classes.livestreamings}>
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Box display="flex" alignItems="center">
                  <h2>Live Streamings</h2>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box textAlign="center">
                  <h4>
                    Number of Listeners: {listenersCount} | Current Date:{" "}
                    {moment().format(DATE_TIME_TIMEZONE_FORMAT_FULL)}{" "}
                  </h4>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box className={classes.rightmenu}>
                  <Button
                    variant="contained"
                    onClick={() => handleStreamings()}
                  >
                    {" "}
                    + Add Streaming{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="drop-box" mb={1} p={1}>
          <ChannelSelectionBox
            channel={channel}
            channels={props.channels}
            setChannel={setChannel}
          />
        </Box>
        <Box className={classes.table_tab}>
          <TableContainer component={Paper}>
            <Table aria-label="all streamings table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedChannelItems &&
                  selectedChannelItems.length > 0 &&
                  selectedChannelItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Button onClick={() => onPlayAudio(row)}>
                            {row.title}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {moment(row.startTime).format("DD,MMM YYYY h:mm A")}
                        </TableCell>
                        <TableCell>
                          {moment(row.endTime).format("DD,MMM YYYY h:mm A")}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleStreamings(row.id)}
                          >
                            <EditIcon
                              style={{
                                color: "green",
                                padding: "5px",
                                margin: "5px",
                                borderRadius: "50px",
                                fontSize: "30px",
                                boxShadow: "0 0 9px #e8eaf5",
                                backgroundColor: "#ffffff",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => confirmDelete(row)}
                          >
                            <DeleteIcon
                              style={{
                                color: "red",
                                padding: "5px",
                                margin: "5px",
                                borderRadius: "50px",
                                fontSize: "30px",
                                boxShadow: "0 0 9px #e8eaf5",
                                backgroundColor: "#ffffff",
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                {isLoading && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          padding: "10px 0",
                        }}
                      >
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {selectedChannelItems &&
                  selectedChannelItems.length === 0 &&
                  !isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={selectedChannelItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <VideoPlayer
            isOpen={isPlayingAudio}
            onClose={handlePlayerClose}
            name={selectedRow.title}
            url={selectedRow.downloadUrl}
            note={selectedRow.description}
          />
        </Box>
      </Box>
    );
}
export default withRouter(Streamings);
