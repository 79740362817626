import React, { useState, useContext } from "react";
import { Hidden, Button } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { withRouter } from "react-router-dom";
import "./EmailEdit.scss";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { launchContactEmailCampaign } from "../../utils/api";
import toastr from "toastr";
import ReactHtmlParser from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const EmailEditConfirmation = (props) => {
  const {
    authUser,
    selectedTemplate,
    selectedOrganization,
    selectedContactsForEmailCampaign,
    contactsByGroupName,
    contactsCountByGroupName,
  } = useContext(appContext);

  let totalcontacts = 0;
  for (let group in contactsCountByGroupName) {
    totalcontacts += contactsCountByGroupName[group];
  }

  let contactsgroups = "";
  for (let group in contactsCountByGroupName) {
    if (contactsCountByGroupName[group] > 0) {
      contactsgroups += group + "(" + contactsCountByGroupName[group] + ") ";
    }
  }

  const myContactList = [];
  for (let group in contactsCountByGroupName) {
    if (contactsCountByGroupName[group] > 0) {
      if (group !== "Previous Donors") {
        myContactList.push(
          ...myContactList,
          ...contactsByGroupName[group]
            .filter((contact) =>
              selectedContactsForEmailCampaign.includes(contact._id)
            )
            .map((contact) => {
              return {
                email: contact.email,
                firstName:
                  contact.name && contact.name.first_name
                    ? contact.name.first_name
                    : "",
                lastName:
                  contact.name && contact.name.last_name
                    ? contact.name.last_name
                    : "",
              };
            })
        );
      }
    }
  }
  const selPrevContactList = [];
  if (contactsCountByGroupName["Previous Donors"] > 0) {
    selPrevContactList.push(
      ...contactsByGroupName["Previous Donors"]
        .filter((contact) =>
          selectedContactsForEmailCampaign.includes(contact.donationId)
        )
        .map((contact) => {
          return {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            donationId: contact.donationId,
          };
        })
    );
  }
  const handleBack = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailContactGroups/${props.match.params.id}`
    );
  };

  const emailCampaignSubmitHandler = () => {
    if (myContactList.length > 0) {
      const finalValues = {};
      finalValues.donationRequestId = props.match.params.id;
      finalValues.emailTemplateId = selectedTemplate._id;
      finalValues.subject = selectedTemplate.subject;
      finalValues.fromName = selectedTemplate.senderName;
      finalValues.contactList = myContactList;
      //finalValues.contactIds = selectedContactsForEmailCampaign;
      launchContactEmailCampaign(finalValues).then(() => {
        props.history.push(`/donationRequest/${props.match.params.id}`);
        toastr.success(
          "Email campaign for my contacts is launched successfully"
        );
      });
    }
    if (selPrevContactList.length > 0) {
      const finalValues = {};
      finalValues.donationRequestId = props.match.params.id;
      finalValues.emailTemplateId = selectedTemplate._id;
      finalValues.subject = selectedTemplate.subject;
      finalValues.orgId = selectedOrganization;
      finalValues.contactList = selPrevContactList;
      // finalValues.contactIds = selectedContactsForEmailCampaign;
      launchContactEmailCampaign(finalValues).then(() => {
        props.history.push(`/donationRequest/${props.match.params.id}`);
        toastr.success(
          "Email campaign for Previous Donors is launched successfully"
        );
      });
    }
  };
  const handleEditRecipients = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailContactGroups/${props.match.params.id}`
    );
  };
  const handleEditEmail = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailEdit/${props.match.params.id}`
    );
  };

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div className="edit-container">
      <Hidden mdUp>
        {" "}
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden>

      <Container MaxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          </Hidden>

          <div className="drop-box" style={{ width: "100%" }}>
            <div className="email-header">
              <Button
                className="back-button"
                onClick={handleBack}
                aria-label="back"
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </Button>

              <h4>Confirmation</h4>

              <Button
                className="save-button"
                onClick={emailCampaignSubmitHandler}
                variant="contained"
                style={{ background: "#EF6C00", color: "white" }}
                endIcon={<ArrowRightIcon />}
                aria-label="save"
                disabled={totalcontacts === 0}
              >
                Send
              </Button>
            </div>
            <div className="email-body">
              <div className="user-name-form">
                <b>The Campaign is ready to send. </b>
              </div>
              <div className="user-name-form">
                Review the campaign below before sending your campaign
              </div>

              <div className="box-grid">
                <header>
                  <Typography gutterBottom variant="body1" component="span">
                    Recipients
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={handleEditRecipients}
                  >
                    Edit
                  </Button>
                </header>
                <section>
                  <div>
                    <Typography gutterBottom variant="body1" component="span">
                      Total number of recipients :
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="span"
                    >
                      {totalcontacts}
                    </Typography>
                  </div>
                  <div>
                    <Typography gutterBottom variant="body1" component="span">
                      Contacts groups :
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      color="textSecondary"
                    >
                      {contactsgroups}
                    </Typography>
                  </div>
                </section>
              </div>

              <div className="box-grid">
                <header>
                  <Typography gutterBottom variant="body1" component="span">
                    Email Design
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={handleEditEmail}
                  >
                    Edit
                  </Button>
                </header>

                <section>
                  <Box className="email-design">
                    <div>
                      <Typography gutterBottom variant="body1" component="span">
                        User Name :
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {selectedTemplate.senderName}
                      </Typography>
                    </div>

                    <div>
                      <Typography gutterBottom variant="body1" component="span">
                        From :
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {selectedTemplate.previewGlossaryValues.fromEmail}
                      </Typography>
                    </div>
                  </Box>

                  <div>
                    <Typography gutterBottom variant="body1" component="span">
                      Subj :
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="span"
                    >
                      {selectedTemplate.subject}
                    </Typography>
                  </div>

                  <div>
                    <div>{ReactHtmlParser(selectedTemplate.htmlText)}</div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(EmailEditConfirmation);
