import React from "react";
import Box from "@material-ui/core/Box";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Typography from "@material-ui/core/Typography";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";

export default function EmailContactTypeBox(props) {
  return (
    <div style={{ width: "100%" }}>
      <Box
        display="flex"
        borderColor="grey.500"
        flexDirection="row"
        borderRadius="borderRadius"
        border={1}
        p={1}
        m={1}
        bgcolor="background.paper"
        textAlign="left"
      >
        <MailOutlineIcon
          fontSize="medium"
          color="disabled"
          style={{ marginRight: "5px" }}
        />
        <Typography component="div" style={{ width: "100%" }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ position: "relative" }}
          >
            {props.type}({props.count})
            {props.value && (
              <CircleCheckedFilled
                style={{ color: "green", position: "absolute", right: "0" }}
              />
            )}
          </Typography>
          <Typography variant="body2" gutterBottom color="textSecondary">
            {props.text}
          </Typography>
        </Typography>
      </Box>
    </div>
  );
}
