import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../liveStreaming/styles.scss";
import BootCampMedia from "./BootCampMedia";
import { appContext } from "../../App";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import styles from "../Admin/SubmittedIdeas.module.scss";
import { HOME_PAGE } from "../../utils/utils";
import toastr from "toastr";
import VideoPlayer from "../common/VideoPlayer";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function BootCamp(props) {
  const classes = useStyles();
  const { authUser } = useContext(appContext);
  const [value, setValue] = useState(0);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [document, setDocument] = useState({});
  const onPlayVideo = (document) => {
    setDocument(document);
    setIsPlayingVideo(true);
  };
  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  // useEffect(() => {
  //   if (authUser) {
  //     if (authUser.isPaidBootcamp) {
  //       //Stays within the page
  //     } else {
  //       toastr.warning(
  //         "You do not have the privilege to access the requested page!"
  //       );
  //       props.history.push(HOME_PAGE);
  //     }
  //   }
  // }, [authUser]);

  const SDGsMasterClass = [
    {
      _id: "1",
      description: "What are the 17 SDGs - Master Class",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2F17_SDG_Thumbnail_19042024.png?alt=media&token=82244e38-c0fd-466e-b143-ad66d6e16b1b",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fdocuments%2F17_SDGS_Master_Class.pdf?alt=media&token=b6b79861-e563-4d62-b006-4aa4db78a168",
      title: "What are the 17 SDGs - Master Class",
      type: 3,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
  ];

  const businessCanvas = [
    {
      _id: "1",
      description: "Social Entrepreneurship Business Canvas",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FPDF_Social_Ent_Thumbnail.png?alt=media&token=13c396ce-6c8b-46e8-ae75-62ff703e316e",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fdocuments%2FSDGsSampleIdeas_25042024.pdf?alt=media&token=23ae7597-14c4-4242-b63d-28b72b21f681",
      title: "Social Entrepreneurship Business Canvas",
      type: 3,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
  ];

  const SDGs = [
    {
      _id: "1",
      description: "No Poverty",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u368gf000d357cnyyzs2lh-No%20Poverty?alt=media&token=80a8de43-55bd-40f4-b476-65f7f03976ee",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%201%20-%20No%20Poverty.pdf?alt=media&token=4b6c4f5c-b9c5-4b47-a0d8-2b350efeb096&_gl=1*1c80fba*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0MTMuMTQuMC4w",
      title: "No Poverty",
      type: 3,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "2",
      description: "Zero Hunger",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u376am000e357cuxiige79-Zero%20Hunger?alt=media&token=0de91603-2e4d-4b8a-88cf-e2c8005bd91f",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%202%20-%20Zero%20Hunger.pdf?alt=media&token=7b664903-209f-4fe4-9bd3-9e71b154c7ea&_gl=1*1i58o60*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MDUuNi4wLjA",
      title: "Zero Hunger",
      type: 3,
      createdAt: "2022-07-20T10:28:22.360Z",
      updatedAt: "2022-07-20T10:28:22.360Z",
    },
    {
      _id: "3",
      description: "Good Health and Well Being",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u37ova000f357cc4f26rng-Good%20Health%20and%20Well%20Being?alt=media&token=47b0563f-e424-48b2-be3b-67e16e77ded6",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%203%20-%20Good%20Health%20_%20Well-Being_.pdf?alt=media&token=9f84a87d-deca-40d9-b9c0-7bf01be3fe21&_gl=1*gj084w*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MDkuMi4wLjA",
      title: "Good Health and Well Being",
      type: 3,
      createdAt: "2022-07-20T10:28:46.807Z",
      updatedAt: "2022-07-20T10:28:46.807Z",
    },
    {
      _id: "4",
      description: "Quality Education",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u38bji000g357cmb14w5mq-Quality%20Education?alt=media&token=633f43c5-1f6a-4183-b0c1-ff2bf15ee496",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%204%20-%20Quality%20Education.pdf?alt=media&token=bce83201-32b2-4e84-a605-689dbff17b3e&_gl=1*1m1my8c*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MTIuNTkuMC4w",
      title: "Quality Education",
      type: 3,
      createdAt: "2022-07-20T10:29:16.159Z",
      updatedAt: "2022-07-20T10:29:16.159Z",
    },
    {
      _id: "5",
      description: "Gender Equality",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u38tx2000h357cm0fk3ji6-Gender%20Equality?alt=media&token=fd232d66-f817-415f-a53a-d10f02780e7a",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%205%20-%20Gender%20Equality.pdf?alt=media&token=7754fcd1-8d48-4340-9b95-0cd9f16a5742&_gl=1*cea2am*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MTcuNTQuMC4w",
      title: "Gender Equality",
      type: 3,
      createdAt: "2022-07-20T10:29:39.506Z",
      updatedAt: "2022-07-20T10:29:39.506Z",
    },
    {
      _id: "6",
      description: "Clean Water and Sanitation",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u39n72000i357cy9wau6ga-Clean%20Water%20and%20Sanitation?alt=media&token=e1d914a5-89a4-40ad-90fa-20454e1b440b",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%206%20-%20Clean%20Water%20and%20Sanitation.pdf?alt=media&token=b391a4ab-2936-4078-8988-b09a477004d5&_gl=1*1dmfuop*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MjMuNDguMC4w",
      title: "Clean Water and Sanitation",
      type: 3,
      createdAt: "2022-07-20T10:30:17.456Z",
      updatedAt: "2022-07-20T10:30:17.456Z",
    },
    {
      _id: "7",
      description: "Affordable and Clean Energy",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3a62e000j357c47ygegw2-Affordable%20and%20Clean%20Energy?alt=media&token=3803de41-3b59-4f48-b72f-11836b4e52c4",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%207%20-%20Affordable%20and%20Clean%20Energy.pdf?alt=media&token=eb4f658b-a480-4d15-bde5-7c9b332cad70&_gl=1*dpshje*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MjcuNDQuMC4w",
      title: "Affordable and Clean Energy",
      type: 3,
      createdAt: "2022-07-20T10:30:42.104Z",
      updatedAt: "2022-07-20T10:30:42.104Z",
    },
    {
      _id: "8",
      description: "Decent Work and Economic Growth",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3asum000k357c24oepdmp-Decent%20Work%20and%20Economic%20Growth?alt=media&token=e12c78ed-e3ae-49a9-81c4-f0697c28b489",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%208%20-%20Decent%20Work%20_%20Economic%20Growth.pdf?alt=media&token=5496083d-2e3e-4f23-bb60-9df891c04b4c&_gl=1*1plzy8d*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MzEuNDAuMC4w",
      title: "Decent Work and Economic Growth",
      type: 3,
      createdAt: "2022-07-20T10:31:11.961Z",
      updatedAt: "2022-07-20T10:31:11.961Z",
    },
    {
      _id: "9",
      description: "Industry Innovation and Infrastructure",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3be8u000l357cr99yj9lc-Industry%20Innovation%20and%20Infrastructure?alt=media&token=6b65f778-7e7b-4f57-97eb-98448c2e4bc9",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%209%20-%20Industry%20Innovation%20and%20Infrastructure.pdf?alt=media&token=460e650c-60cb-4ff3-beb4-653c4d050ab6&_gl=1*qwg2rj*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MzUuMzYuMC4w",
      title: "Industry Innovation and Infrastructure",
      type: 3,
      createdAt: "2022-07-20T10:31:39.011Z",
      updatedAt: "2022-07-20T10:31:39.011Z",
    },
    {
      _id: "10",
      description: "Reduced Inequalities",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3by86000m357cj1kjlz9m-Reduced%20Inequalities?alt=media&token=7f27584f-f730-456a-8e73-8d353c9b5253",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2010-%20Reduced%20Inequalities.pdf?alt=media&token=c65bd0c4-dbf8-4fdf-bea4-e9b1908eaf93&_gl=1*pccfqq*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0NTEuNjAuMC4w",
      title: "Reduced Inequalities",
      type: 3,
      createdAt: "2022-07-20T10:32:05.081Z",
      updatedAt: "2022-07-20T10:32:05.081Z",
    },
    {
      _id: "11",
      description: "Sustainable Cities and Communities",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3cixq000n357cjgebayzq-Sustainable%20Cities%20and%20Communities?alt=media&token=b960dfc4-f203-414b-adaa-ff18ce620e53",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2011%20-%20Sustainable%20Cities%20and%20Communities.pdf?alt=media&token=18862cbd-d532-425f-9c30-4532398eb52d&_gl=1*po01ez*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0ODIuMjkuMC4w",
      title: "Sustainable Cities and Communities",
      type: 3,
      createdAt: "2022-07-20T10:32:32.637Z",
      updatedAt: "2022-07-20T10:32:32.637Z",
    },
    {
      _id: "12",
      description: "Responsible Consumption and Production",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3d6md000o357c5us0u3hp-Responsible%20Consumption%20and%20Production?alt=media&token=51b65766-1355-4c29-931c-d1d364288411",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2012-%20Responsible%20Consumption%20_%20Production.pdf?alt=media&token=5d7b180e-57ef-4942-a775-cd8aadc63f18&_gl=1*1p1s66q*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0OTAuMjEuMC4w",
      title: "Responsible Consumption and Productionn",
      type: 3,
      createdAt: "2022-07-20T10:33:02.907Z",
      updatedAt: "2022-07-20T10:33:02.907Z",
    },
    {
      _id: "13",
      description: "Climate Action",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3dyml000p357c0t0ozyq1-Climate%20Action?alt=media&token=2bed9136-c518-4cbe-a532-12a8efa031e8",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2013-%20Climate%20Action.pdf?alt=media&token=525ff095-6d73-4bd4-acf3-3a397556bd7e&_gl=1*2tknov*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0OTIuMTkuMC4w",
      title: "Climate Action",
      type: 3,
      createdAt: "2022-07-20T10:33:39.302Z",
      updatedAt: "2022-07-20T10:33:39.302Z",
    },
    {
      _id: "14",
      description: "Life Below Water",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3eg0m000q357c4zdhfmr5-Life%20Below%20Water?alt=media&token=b915e77a-6196-4f4e-b280-425ea5b2a3d9",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2014-%20Life%20Below%20Water.pdf?alt=media&token=43130132-3241-44c8-bebd-a1d1d8d70ae4&_gl=1*1m9ot5q*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0OTQuMTcuMC4w",
      title: "Life Below Water",
      type: 3,
      createdAt: "2022-07-20T10:34:01.783Z",
      updatedAt: "2022-07-20T10:34:01.783Z",
    },
    {
      _id: "15",
      description: "Life On Land",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u3f10m000r357cjwk62nit-Life%20On%20Land?alt=media&token=064b17e2-9fca-4d86-bc2a-c5534f2f8ee4",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2015-%20Life%20on%20Land.pdf?alt=media&token=b474362f-54f2-4681-ad3a-5ccdcbb54367&_gl=1*5jdnfe*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI0OTYuMTUuMC4w",
      title: "Life On Land",
      type: 3,
      createdAt: "2022-07-20T10:34:28.913Z",
      updatedAt: "2022-07-20T10:34:28.913Z",
    },
    {
      _id: "16",
      description: "Peace Justice and Strong Institutions",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u429na000s357cimbowinv-Peace%20Justice%20and%20Strong%20Institutions?alt=media&token=de0a0b8e-f258-4d24-abae-7403327f4197",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2016-%20Peace%2C%20Justic%20and%20Strong%20Institutions.pdf?alt=media&token=739d3f2a-54dd-4e2b-879b-70e4b4c0ec69&_gl=1*1pxtowm*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MDAuMTEuMC4w",
      title: "Peace Justice and Strong Institutions",
      type: 3,
      createdAt: "2022-07-20T10:52:33.274Z",
      updatedAt: "2022-07-20T10:52:33.274Z",
    },
    {
      _id: "17",
      description: "Partnerships for the goals",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/learninglabs%2Fthumbnails%2Fcl5u43ium000t357cdr60axj9-Partnerships%20for%20the%20goals?alt=media&token=55e68c18-cb20-46ae-95af-e201b763e8aa",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/Bootcamp2023%2FSDG%2017-%20Partnerships%20for%20the%20Goals.pdf?alt=media&token=37639142-1152-4a15-ba36-e66c557271b9&_gl=1*h7wiws*_ga*MTkzODMwMzc1LjE2ODcxNDgwMTQ.*_ga_CW55HF8NVT*MTY5NzYzMjEwMy42OS4xLjE2OTc2MzI1MDIuOS4wLjA",
      title: "Partnerships for the goals",
      type: 3,
      createdAt: "2022-07-20T10:53:31.520Z",
      updatedAt: "2022-07-20T10:53:31.520Z",
    },
  ];

  const Videos = [
    {
      _id: "1",
      // description: "Social Enterprise Fundamentals & Awareness on SDGs",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession1Tanvi.jpeg?alt=media&token=a61adf3d-1418-446a-aa34-5628de9e6b70",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2FSession-1-Social%20Entrepreneurship%20%26%20SDGs-Recording.mp4?alt=media&token=b5bdcee1-0ad8-43de-a947-74cdbdafa45c",
      title: "Social Enterprise Fundamentals & Awareness on SDGs",
      type: 4,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "2",
      // description: "Principals of Business Planning - Part - 1",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession2Part1.jpeg?alt=media&token=e343fb52-143f-46dc-9686-91e56e78747c",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2FSession-2-Business-Canvas%20Part-1.mp4?alt=media&token=d221de0c-aca7-48ff-85cc-4f16dd336371",
      title: "Principals of Business Planning - Part - 1",
      type: 4,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "3",
      // description: "Principals of Business Planning - Part - 2",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession3Part2.jpeg?alt=media&token=e7aeed2b-9353-481b-b47d-d46962b9df4a",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2FSession-3-Buniness-Canvas%20Part-II.mp4?alt=media&token=6adb369f-b9a8-42c1-bbb1-3571c8fb5dca",
      title: "Principals of Business Planning - Part - 2",
      type: 4,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "4",
      // description: "Principals of Business Planning - Part - 2",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession4Finance.png?alt=media&token=9f523f8d-ca0c-4d56-b227-65f11bb0ad7b",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession-4-IntroToFundamentals%5COfFinance.mp4?alt=media&token=a68b37d0-8447-4f50-86a4-29a72a14e6aa",
      title: "Introduction to Fundamentals of Finance",
      type: 4,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "5",
      // description: "Principals of Business Planning - Part - 2",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession5Veena.jpeg?alt=media&token=d2290b7c-de4b-445f-aefd-0c47ad793479",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fdocuments%2FSession-5-MarketingBasics.mp4?alt=media&token=3553bdd4-099b-4645-b4e3-501e4e75e1d4",
      title: "Marketing Basics",
      type: 4,
      createdAt: "2022-07-20T10:27:38.505Z",
      updatedAt: "2022-07-20T10:27:38.505Z",
    },
    {
      _id: "6",
      // description: "Intro to Leadership and Stress Management",
      thumbnailImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2Fthumbnails%2FSession6_Aparna.jpeg?alt=media&token=f18f7ac9-a5d8-4e34-ba4d-8bcd8b8bcef7",
      mediaUrl:
        "https://firebasestorage.googleapis.com/v0/b/touchalife-af350.appspot.com/o/RecordedVideos%2F2024%2FSession6_Leadership_StressManagement.mp4?alt=media&token=92c2a2ca-1d53-498d-9064-d855e49d8c77",
      title: "Intro to Leadership and Stress Management",
      type: 4,
      createdAt: "2024-04-14T10:27:38.505Z",
      updatedAt: "2024-04-14T10:27:38.505Z",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.barnav}>
        <Container MaxWidth="lg">
          <div className="search-right">
            <div className={styles.admin_head}>
              {authUser && (
                <Button
                  onClick={handleCloseSideMenu}
                  className={styles.managebtn}
                >
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4>Boot Camp Resources</h4>
            </div>
          </div>
        </Container>
      </div>
      <Container MaxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Grid>
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12} sm={12}>
            <div style={{ padding: "0 10px" }}>
              <AppBar
                position="static"
                color="default"
                className="learning-labs"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="scrollable auto tabs example"
                  style={{ overflowX: "auto" }}
                >
                  <Tab label="SDGs Master Class" {...a11yProps(0)} />
                  <Tab
                    label="Social Entrepreneurship Business Canvas"
                    {...a11yProps(1)}
                  />
                  <Tab label="Recordings" {...a11yProps(2)} />
                  {/* <Tab label="SDGs" {...a11yProps(2)} /> */}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid container className="le-tabs " spacing={3}>
                  {SDGsMasterClass &&
                    SDGsMasterClass.length > 0 &&
                    SDGsMasterClass.map((document, index) => {
                      return (
                        <BootCampMedia
                          key={index}
                          title={document.title}
                          document={document}
                          media={document.mediaUrl}
                          thumbnailUrl={document.thumbnailImageUrl}
                          note={document.description}
                        />
                      );
                    })}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container className="le-tabs " spacing={3}>
                  {businessCanvas &&
                    businessCanvas.length > 0 &&
                    businessCanvas.map((document, index) => {
                      return (
                        <BootCampMedia
                          key={index}
                          document={document}
                          title={document.title}
                          media={document.mediaUrl}
                          thumbnailUrl={document.thumbnailImageUrl}
                          note={document.description}
                        />
                      );
                    })}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container className="le-tabs" spacing={3}>
                  {Videos &&
                    Videos.length > 0 &&
                    Videos.map((document, index) => {
                      return (
                        <>
                          <BootCampMedia
                            key={index}
                            document={document}
                            onPlayHandler={() => onPlayVideo(document)}
                            media={document.mediaUrl}
                            title={document.title}
                            thumbnailUrl={document.thumbnailImageUrl}
                            note={document.description}
                            isPlayingVideo={isPlayingVideo}
                            onClosePlayVideo={() => setIsPlayingVideo(false)}
                          />
                        </>
                      );
                    })}
                </Grid>
              </TabPanel>
              {/* <TabPanel value={value} index={2}>
                <Grid container className="le-tabs" spacing={3}>
                  {SDGs &&
                    SDGs.length > 0 &&
                    SDGs.map((document, index) => {
                      return (
                        <BootCampMedia
                          key={index}
                          document={document}
                          media={document.mediaUrl}
                          title={document.title}
                          thumbnailUrl={document.thumbnailImageUrl}
                          note={document.description}
                        />
                      );
                    })}
                </Grid>
              </TabPanel> */}
            </div>
            <VideoPlayer
              isOpen={isPlayingVideo}
              onClose={onClosePlayVideo}
              name={document && document.title}
              url={document && document.mediaUrl}
              note={document && document.description}
              document={document}
            />
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default BootCamp;
