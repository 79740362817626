const ValidateTransformersDonationRequestState = (values) => {
  let errors = {};

  if (values.trainingMode === "advocacy") {
    if (!values.addressSocialChange) {
      errors.addressSocialChange = "Please enter text";
    }
    if (!values.solutionSustainableSocialImpact) {
      errors.solutionSustainableSocialImpact = "Please enter text";
    }
  } else if (values.trainingMode === "philanthropy") {
    if (!values.quantity) {
      errors.quantity = "Please enter amount";
    }
    if (!values.brief) {
      errors.brief = "Please enter text";
    }
    if (
      values &&
      values.fundsRecipient &&
      values.fundsRecipient.toLowerCase() === "self"
    ) {
      if (values.fundsRecipientName === "") {
        errors.fundsRecipientName = "Please enter Funds Recipient Name";
      }
    }
    if (!values.solutionSustainableSocialImpact) {
      errors.solutionSustainableSocialImpact = "Please enter text";
    }
    if (!values.keyTargetMetrics) {
      errors.keyTargetMetrics = "Please enter text";
    }
  } else if (values.trainingMode === "entrepreneurship") {
    if (!values.socialProblem) {
      errors.socialProblem = "Please enter text";
    }
    if (!values.solutionProposing) {
      errors.solutionProposing = "Please enter text";
    }
    if (!values.targetImpact) {
      errors.targetImpact = "Please enter text";
    }
    if (!values.uniqueValueProposition) {
      errors.uniqueValueProposition = "Please enter text";
    }
    if (!values.minimumViableProduct) {
      errors.minimumViableProduct = "Please enter text";
    }
    if (!values.targetPartners) {
      errors.targetPartners = "Please enter text";
    }
    if (!values.costStructure) {
      errors.costStructure = "Please enter text";
    }
    if (!values.revenueStreams) {
      errors.revenueStreams = "Please enter text";
    }
    if (!values.potentialRisks) {
      errors.potentialRisks = "Please enter text";
    }
  }
  if (
    values.trainingMode === "advocacy" ||
    values.trainingMode === "philanthropy" ||
    values.trainingMode === "entrepreneurship"
  ) {
    if (!values.title) {
      errors.title = "Please enter title of your startup";
    }
    if (!values.description) {
      errors.description = "Please enter text";
    }
  }
  return errors;
};
export default ValidateTransformersDonationRequestState;
