const noValidate = (orgDetails) => {
  let errors = {};
  //   if (!values.name) {
  //     errors.name = "Please enter organization name";
  //   }
  //   if (!values.type) {
  //     errors.type = "Please select type";
  //   }
  //   if (!values.isCSRWing) {
  //     errors.isCSRWing = "Please select Yes/No";
  //   }
  return errors;
};
export default noValidate;
