import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Paypal from "../../paypal";
import Container from "@material-ui/core/Container";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { saveDonation } from "../../../../utils/api";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";

import "./CheckoutForm.scss";
import api from "../api";
import { formatNumber } from "../../../../utils/utils";

const CheckoutForm = (props) => {
  const [paymentData, setPaymentData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [settlementCurrency, setSettlementCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [userCustomerId, setUserCustomerId] = useState("");
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [userId, setUserId] = useState("");
  const [donationRequestId, setDonationRequestId] = useState("");
  const [donationFor, setDonationFor] = useState("");
  const [toUserInfo, setToUserInfo] = useState("");
  const [externalUserInfo, setexternalUserInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [sendAmount, setSendAmount] = useState(0);
  const [isAnonymous, setAnonymous] = useState(false);
  const [remember, setRemember] = useState(true);

  const stripe = useStripe();
  const elements = useElements();
  const [selectedPayment, setSelectedPayment] = useState("stripe");

  const handleStripePayment = () => {
    setSelectedPayment("stripe");
  };

  const handlePaypalPayment = () => {
    setSelectedPayment("paypal");
  };

  useEffect(() => {
    if (props.amountDetails) {
      setPaymentData(props.amountDetails);
      setAmount(props.amountDetails.amount / 100);
      setSendAmount(props.amountDetails.amount);
      setCurrency(props.amountDetails.currency);
      setSettlementCurrency(props.amountDetails.settlementCurrency);
      setUserCustomerId(props.amountDetails.customer_id);
      setUserId(props.amountDetails.userId);
      setDonationFor(props.amountDetails.donationFor);
      if (props.amountDetails.donationFor === "donationRequest") {
        setDonationRequestId(props.amountDetails.donationRequestId);
      } else if (props.amountDetails.donationFor === "individual") {
        setToUserInfo(props.amountDetails.toUserInfo);
      }
      if (props.amountDetails.hasOwnProperty("isAnonymous")) {
        setAnonymous(props.amountDetails.isAnonymous);
      }
      if (props.amountDetails.hasOwnProperty("externalUserInfo")) {
        setexternalUserInfo(props.amountDetails.externalUserInfo);
      }
      if (props.amountDetails.userId === "") {
        setRemember(false);
      }
    }
  }, []);

  const handleError = (err) => {
    setError(false);
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    var customer_id;
    function paymentComplete(clientSecret, cardData = null) {
      stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        var paymentIntent = result.paymentIntent;
        paymentIntent.customer_id = customer_id;
        if (paymentIntent.last_payment_error != null) {
          var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(
            "Some problem while processing the card. Please check the card information again."
          );
          //setError(paymentIntent.last_payment_error);
          setSucceeded(false);
          setProcessing(false);
          setMetadata(paymentIntent);
          return console.log("[PaymentIntent]", paymentIntentJson);
        } else {
          paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(null);
          setSucceeded(true);
          setProcessing(false);
          setMetadata(paymentIntent);
          var payload = {};
          payload.customerId = paymentIntent.customer_id;
          payload.clientSecret = paymentIntent.client_secret;
          payload.paymentMethod = "stripe";
          payload.units = paymentIntent.currency;
          payload.user_id = userId;
          payload.donationFor = donationFor;
          if (donationFor === "donationRequest") {
            payload.donation_request_id = donationRequestId;
          } else if (donationFor === "individual") {
            payload.toUserInfo = toUserInfo;
          }
          payload.externalUserInfo = externalUserInfo;
          payload.quantity = paymentData.donatedAmount;
          payload.transactionId = paymentIntent.id;
          payload.type = "cash";
          payload.anonymous = isAnonymous;
          payload.cardInfo = cardData.paymentMethod.card;
          payload.billingAddress = paymentData.billingAddress;

          saveDonation(payload)
            .then((response) => {
              if (customer_id !== "") {
                localStorage.setItem("stripeCustomerId", customer_id);
              }
              response.data.currency = payload.units;
              var thankyouResponse = response.data;
              props.history.push("/thankyou", { thankyouResponse });
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        }
      });
    }
    var cardholderName = ev.target.name.value;
    var data = {
      billing_details: {},
    };

    if (cardholderName) {
      data["billing_details"]["name"] = cardholderName;
      data.billing_details.address = {
        line1: paymentData.billingAddress.line1,
        postal_code: paymentData.billingAddress.zip_code,
        city: paymentData.billingAddress.city,
        state: paymentData.billingAddress.state,
        //country: paymentData.billingAddress.country,
      };
    }

    stripe
      .createPaymentMethod(
        "card",
        elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        ),
        data
      )
      .then(function (card_response) {
        if (card_response.error) {
          setProcessing(false);
          setError(card_response.error.message);
          return null;
        }
        api
          .createPaymentIntent({
            payment_method_types: ["card"],
            paymentMethodId: card_response.paymentMethod.id,
            amount: sendAmount,
            currency: currency,
            settlementCurrency: settlementCurrency,
            customer_id: userCustomerId === "null" ? "" : userCustomerId,
            saveCard: false,
          })
          .then(function (paymentData) {
            if (paymentData.requiresAction) {
              customer_id = paymentData.customer_id;
              stripe
                .handleCardAction(paymentData.clientSecret)
                .then(function (actionData) {
                  if (actionData.error) {
                    setProcessing(false);
                    setError(
                      "Your card was not authenticated, please try again"
                    );
                  } else if (
                    actionData.paymentIntent.status === "requires_confirmation"
                  ) {
                    // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent and adding currency
                    api
                      .createPaymentIntent({
                        paymentIntentId: actionData.paymentIntent.id,
                        currency: currency,
                        settlementCurrency: settlementCurrency,
                      })
                      .then(function (paymentSuccess) {
                        if (paymentSuccess.error) {
                          console.log(paymentSuccess.error);
                          setProcessing(false);
                          setError(paymentSuccess.error);
                        } else {
                          paymentComplete(
                            paymentData.clientSecret,
                            card_response
                          );
                        }
                      });
                  }
                });
            } else if (paymentData.error) {
              setProcessing(false);
              setError("Your card was not authenticated, please try again");
            } else if (paymentData.statusCode === 400) {
              setProcessing(false);
              setError(paymentData.raw.message);
            } else {
              customer_id = paymentData.customer_id;
              paymentComplete(paymentData.clientSecret, card_response);
            }
          })
          .catch((err) => {
            setProcessing(false);
            setError(
              "Some problem while processing the card. Please check the card information again."
            );
          });
      })
      .catch((err) => {
        setProcessing(false);
        setError(
          "Some problem while processing the card. Please check the card information again."
        );
      });
  };

  const renderSuccess = () => {
    return <div className="sr-field-success message"></div>;
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
    let gridStyle = {};
    if (userCustomerId === "null") {
      gridStyle = {
        margin: "auto",
      };
    }

    return (
      <Container maxWidth="lg">
        <div className="donation-payment-container drop-box">
          <h4>Payment Method</h4>

          <div className="payment-btns">
            <p>Choose your best payment method</p>
            {paymentData && paymentData.region && paymentData.region.stripePk && (
              <Button
                className="stripe-btn"
                variant="contained"
                color="#fff"
                onClick={handleStripePayment}
              >
                <img src="/images/stripe.png" width="70%" alt="stripe" />
                {selectedPayment === "stripe" && (
                  <CheckCircleIcon className="check-icon" />
                )}
              </Button>
            )}
            {paymentData && paymentData.region && paymentData.region.paypalKey && (
              <Button
                className="stripe-btn"
                variant="contained"
                color="#fff"
                onClick={handlePaypalPayment}
              >
                <img
                  src="/images/paypal.png"
                  width="70%"
                  height="50px"
                  alt="paypal"
                />
                {selectedPayment === "paypal" && (
                  <CheckCircleIcon className="check-icon" />
                )}
              </Button>
            )}
          </div>

          <div className="container-center ">
            {selectedPayment === "stripe" ? (
              <div style={gridStyle}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <div className="card-grid">
                        <CardNumberElement
                          className="sr-input sr-card-element"
                          options={options}
                          onChange={handleError}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="card-grid">
                        <CardExpiryElement
                          className="sr-input sr-card-element"
                          options={options}
                          onChange={handleError}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="card-cvv card-grid">
                        <CardCvcElement
                          className="sr-input sr-card-element "
                          options={options}
                          onChange={handleError}
                        />
                        <img src="/images/cvc.png" alt="card-cvv" />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="card-grid last-name">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name on card"
                          autoComplete="cardholder"
                          className="sr-input sr-card-element StripeElement StripeElement--empty"
                          style={{ "z-index": "1" }}
                        />
                      </div>
                    </Grid>

                    {error && (
                      <div className="message sr-field-error">{error}</div>
                    )}

                    <Grid item xs={12}>
                      <button
                        className="pay-mian"
                        variant="contained"
                        color="#fff"
                        disabled={processing || !stripe}
                      >
                        <LockIcon style={{ marginRight: 6 }} />{" "}
                        {processing
                          ? "Processing... " +
                            currency +
                            " " +
                            formatNumber(amount)
                          : "Pay " + currency + " " + formatNumber(amount)}
                      </button>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="card-adv">
                        <img src="/images/amex.png" alt="amex" />
                        <img src="/images/vvisa.png" alt="visa" />
                        <img src="/images/mastercard.png" alt="mastercard" />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            ) : (
              <div style={{ margin: 33, width: "37%" }}>
                <Paypal donationDetails={paymentData} />
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  };

  return (
    <div className="checkout-form">
      <div>
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
};

export default withRouter(CheckoutForm);
