import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { appContext } from "../../App";
import "./Footer.scss";

const Footer = (props) => {
  const { currentYear } = useContext(appContext);
  const handleFaqPage = () => {
    props.history.push("/user/help-desk");
  };
  return (
    <div className="footer-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <p className="left" href="javascript:void(0)">
              Touch-A-Life Foundation Inc&copy; {currentYear}
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p className="right">
              <a href="https://www.touchalife.org/about/" target="blank">
                About
              </a>
              {" . "}

              <a  href= {`${process.env.REACT_APP_TALGIVING_URL}/users/help-desk`} target="blank">
                Help Desk
              </a>

              {" | "}
              <a
               href= {`${process.env.REACT_APP_TALGIVING_URL}/users/Privacy`} target="blank">
                
              
                Privacy
              </a>
              {" . "}
              <a
                href= {`${process.env.REACT_APP_TALGIVING_URL}/users/Terms`} 
                target="blank"
              >
                Terms
              </a>
              {/* {" . "}

               <a
                href="https://www.touchalife.org/terms-and-conditions/"
                target="blank"
              >
                Security
              </a>  */}
              {" . "}
              <a href={`${process.env.REACT_APP_TALGIVING_URL}/users/ContactUs`}  target="blank">
                          Contact Us
                        </a>{" "}
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Footer);
