const validateEmailCampaign = (values) => {
  let errors = {};
  if (!values.amountDonatedMin) {
    errors.amountDonatedMin = "Please enter Minimum Donated Amount";
  }
  if (!values.amountDonatedMax) {
    errors.amountDonatedMax = "Please enter Maximum Donated Amount";
  }
  if (Number(values.amountDonatedMin) > Number(values.amountDonatedMax)) {
    errors.amountDonatedMin =
      "Minimum Donated Amount should be less than Maximum Donated Amount";
  }
  return errors;
};

export default validateEmailCampaign;
