const validateAddMediaFile = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Please name the Video";
  }
  if (!values.language) {
    errors.language = "Please select the language option";
  }
  return errors;
};

export default validateAddMediaFile;
