import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { appContext } from "../../App";

import "../Organization/Organization.scss";

import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    heigth: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
    marginTop: "40px",
  },
  deleteBtn: {
    marginTop: "40px",
    float: "right",
  },
  assignContact: {
    width: "100%",
  },
}));

const UploadFileContactsModal = (props) => {
  const {
    selectedUplodingFileContacts,
    setSelectedUplodingFileContacts,
  } = useContext(appContext);
  const classes = useStyles();
  // const [checked, setChecked] = useState(selectedUplodingFileContacts);
  const [upContacts] = useState(props.data);

  const handleToggle = (value) => () => {
    const currentIndex = selectedUplodingFileContacts.indexOf(value);
    const newChecked = [...selectedUplodingFileContacts];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    //setChecked(newChecked);
    setSelectedUplodingFileContacts(newChecked);
  };

  // const submitHandler = async () => {
  //   try {
  //     let data = await addContacts(authUser.unique_id, {
  //       contacts: [...checked],
  //     });
  //     setContacts([...checked, ...contacts]);

  //     getMyContacts(authUser.unique_id)
  //       .then((resp) => {
  //         setContacts(resp);
  //       })
  //       .catch((error) => {
  //         toastr.error(error.message);
  //       });

  //     const groups = await getMyContactGroups(authUser.unique_id);
  //     setContactGroups(groups);
  //     toastr.success(data.message);
  //     props.onContactsSave();
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       toastr.error(error.response.data.message);
  //     } else {
  //       toastr.error(error.message);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (!props.openModal) {
  //     setUpContacts(props.data);
  //     setChecked(props.data);
  //   }
  // }, [props.openModal]);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>{props.name}</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onModalClose} />
              </span>
            </div>
            <div className={classes.plasma}>
              <div className={classes.contactListCntr}>
                {console.log(upContacts, selectedUplodingFileContacts)}
                {upContacts && upContacts.length > 0 ? (
                  <List className={classes.root}>
                    {upContacts.map((contact) => {
                      const labelId = `checkbox-list-label-${contact.email}`;
                      return (
                        <ListItem
                          key={contact.email}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(contact)}
                          style={{
                            borderBottom: "1px solid rgb(246, 247, 251)",
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={
                                selectedUplodingFileContacts.indexOf(
                                  contact
                                ) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>

                          <ListItemText
                            id={labelId}
                            primary={`${contact.firstName} ${contact.lastName}`}
                            secondary={contact.email || contact.phoneNumber}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    No Contacts
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default UploadFileContactsModal;
