import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import CKEditor from "@ckeditor/ckeditor5-react";
import { appContext } from "../../App";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddPhotos from "../common/AddPhotos";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import cuid from "cuid";
import "./DonationRequestUpdate.scss";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  donationRequestUpdate,
  getDonationRequestUpdate,
  deleteDonationRequestUpdate,
  editDonationRequestUpdate,
} from "../../utils/api";
import toastr from "toastr";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 500,
    overflowY: "auto",
  },
}));

const DonationRequestUpdate = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const donationRequestUpdateId = cuid();
  const [canEdit, setCanEdit] = useState(false);
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } =
    useContext(appContext);
  const [titleError, setTitleError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingEditPhotoModal, setShowingEditPhotoModal] = useState(false);
  const [isOpenDonationRequestModal, setIsOpenDonationRequestModal] =
    useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editDes, setEditDes] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [newDefaultImageUrl, setNewDefaultImageUrl] = useState("");
  const [editData, setEditData] = useState(null);
  const userId =
    props &&
    props.donationRequest &&
    props.donationRequest.user_info &&
    props.donationRequest.user_info._id;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      isSuperAdmin ||
      isAdmin ||
      isVolunteer ||
      (authUser &&
        props.donationRequest &&
        props.donationRequest.user_info &&
        authUser.unique_id === props.donationRequest.user_info._id)
    ) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    getDonationRequestUpdate(
      props.donationRequest && props.donationRequest._id
    ).then((resp) => {
      setUpdatedData(resp.data);
    });
  }, [authUser, refresh, props.donationRequest]);

  const updateSavedDonationRequest = (updatedDonationRequest) => {
    setIsOpenDonationRequestModal(false);
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
  };

  const onCloseAddPhoto = (event) => {
    setShowingAddPhotoModal(false);
  };

  const onCloseEditPhoto = (event) => {
    setShowingEditPhotoModal(false);
  };

  const handleTitle = (e) => {
    setTitleError(false);
    setTitle(e.target.value);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescError(false);
    setDescription(editor.getData());
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setDefaultImageUrl("");
  };

  const handleUpdateRequest = () => {
    const obj = {};
    obj.title = title;
    obj.content = description;
    obj.defaultImageUrl = defaultImageUrl;
    obj.requestId = props.donationRequest._id;
    obj.userId = userId;
    obj.uniqueId = donationRequestUpdateId;
    if (title === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    if (description === "") {
      setDescError(true);
    } else {
      setDescError(false);
    }
    if (title !== "" && description !== "") {
      donationRequestUpdate(obj)
        .then((response) => {
          if (response.status === 200) {
            toastr.success("Successfully updated");
            setRefresh(!refresh);
            resetForm();
          }
        })
        .catch((error) => {
          toastr.error(error && error.response && error.response.message);
        });
    }
  };

  const deleteUpdatedRequest = (uniqueId) => {
    if (window.confirm("Are you sure you want to delete the update?")) {
      deleteDonationRequestUpdate(uniqueId, userId)
        .then((response) => {
          setRefresh(!refresh);
          toastr.success("Successfully deleted");
        })
        .catch((error) => {
          toastr.error(error && error.response && error.response.message);
        });
    }
  };

  const handleEditUpdate = (data) => {
    let com = updatedData.data.find((item) => item._id === data._id);
    setEditData(com);
    setOpen(true);
    setEditDes(false);
  };

  const editDescriptionHandler = (e, editor) => {
    setEditDescription(editor.getData());
    setDescError(false);
    setEditDes(true);
  };

  const handleEditTitle = (e) => {
    setEdit(true);
    setTitleError(false);
    setEditedTitle(e.target.value);
  };

  const updateEdit = () => {
    const values = {};
    values.title = editedTitle ? editedTitle : editData.title;
    values.content = editDescription ? editDescription : editData.content;
    values.userId = userId;
    values.requestId =
      props && props.donationRequest && props.donationRequest._id;
    values.defaultImageUrl = newDefaultImageUrl
      ? newDefaultImageUrl
      : editData.defaultImageUrl;

    editDonationRequestUpdate(editData._id, values).then((resp) => {
      if (resp.status === 200) {
        toastr.success("Edited successfully");
        setOpen(false);
        setRefresh(!refresh);
        resetForm();
        setNewDefaultImageUrl("");
        setEditDescription("");
        setEditDes(true);
      }
    });
  };

  return (
    <div className="update-req-container">
      {canEdit && (
        <div className="request-step-two">
          <h1
            style={{
              font: "600 16px Roboto",
              color: "var(--tal_primary)",
            }}
          >
            Add an Update to this Request
          </h1>
          <TextField
            type="text"
            required
            className={`input-field request-title`}
            variant="outlined"
            label="Title"
            name="title"
            value={title}
            onChange={handleTitle}
            inputProps={{
              maxLength: 50,
            }}
          />
          {titleError && <div className="custom-error">Please enter title</div>}
          <CKEditor
            data={description}
            editor={ClassicEditor}
            placeholder="Information about the update"
            onChange={changeDescriptionHandler}
            config={{
              toolbar: [
                "Heading",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "blockQuote",
                "Link",
              ],
            }}
          />
          {descError && (
            <div className="custom-error">Please enter description</div>
          )}
          <div>
            <h3 className="step-two-title" style={{ fontSize: "1.45em" }}>
              <b>Add a cover photo or video</b>
            </h3>
            <p className="image-desc">
              This is the image that will feature right on top of your page.
              Make it impactful!
            </p>

            <div>
              <figure>
                <img
                  src={
                    defaultImageUrl
                      ? defaultImageUrl
                      : "/images/default-req-img.gif"
                  }
                  alt="default"
                  style={{
                    width: "100%",
                    MaxHeight: "364px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setShowingAddPhotoModal(true)}
                />
              </figure>
            </div>
            <div className="parent-notice">
              <h6 style={{ font: "400 12px Roboto" }}>
                A picture is worth a thousand words
              </h6>
              <div className="notice">
                <p
                  style={{
                    margin: 3,
                    font: "400 10px Roboto",
                  }}
                >
                  <span className="tooltip-dots" />
                  Keep it relevant
                </p>
                <p
                  style={{
                    margin: 3,
                    font: "400 10px Roboto",
                  }}
                >
                  <span className="tooltip-dots" />
                  Keep it clear
                </p>
                <p
                  style={{
                    margin: 3,
                    font: "400 10px Roboto",
                  }}
                >
                  <span className="tooltip-dots" />
                  Types: .PNG, .JPG, .BMP
                </p>
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateRequest}
                style={{
                  background: "green",
                  color: "#fff",
                  border: "1px solid green",
                  width: "100%",
                }}
                component="span"
              >
                Add Update
              </Button>
            </div>
          </div>
        </div>
      )}
      <div>
        {updatedData &&
          updatedData.data &&
          updatedData.data.map((data) => (
            <div classname="update-requst-img">
              <div className="updated-ui-content">
                <Typography gutterBottom variant="h3" component="h3">
                  {data.title}
                </Typography>
                <Typography gutterBottom variant="p" component="p">
                  {moment(data.createdAt).format(DATE_TIME_FORMAT)}
                </Typography>
              </div>
              <CardActionArea>
                {data && data.defaultImageUrl && (
                  // <CardMedia
                  //   image={data && data.defaultImageUrl && data.defaultImageUrl}
                  //   style={{
                  //     width: "100%",
                  //     minHeight: "300px",
                  //     maxHeight: "600px",
                  //     margin: " 0 auto",
                  //     border: " 1px solid rgb(241, 241, 241)",
                  //   }}
                  // />
                  <img
                    src={data && data.defaultImageUrl && data.defaultImageUrl}
                    alt="card"
                    style={{
                      // width: "100%",
                      // minHeight: "300px",
                      margin: " 0 auto",
                      // border: " 1px solid rgb(241, 241, 241)",
                      cursor: "auto",
                      minWidth: "auto",
                      display: "flex",
                    }}
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.content && ReactHtmlParser(data.content)}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {canEdit && (
                <CardActions>
                  <Button
                    // variant="contained"
                    // color="secondary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEditUpdate(data)}
                    className="updated-button"
                  >
                    Edit
                  </Button>
                  <Button
                    // variant="contained"
                    // color="secondary"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => deleteUpdatedRequest(data._id)}
                    className="updated-button"
                  >
                    {/* <DeleteIcon /> */}
                    Delete
                  </Button>
                </CardActions>
              )}
            </div>
          ))}
        {updatedData && updatedData.data && updatedData.data.length === 0 && (
          <div className="no-updates">No updates available</div>
        )}
      </div>
      <AddPhotos
        title={canEdit ? "Add Images" : "View Images"}
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        collection="DonationRequest"
        uid={props.donationRequest && props.donationRequest._id}
        subCollection="updates"
        subCollectionUid={donationRequestUpdateId}
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={canEdit}
        userEdit={true}
        open={isOpenDonationRequestModal}
        close={(e) => setIsOpenDonationRequestModal(false)}
        onSave={updateSavedDonationRequest}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TextField
              type="text"
              required
              className={`input-field request-title`}
              variant="outlined"
              label="Title"
              name="title"
              value={edit ? editedTitle : editData && editData.title}
              onChange={handleEditTitle}
              inputProps={{
                maxLength: 50,
              }}
            />
            {titleError && (
              <div className="custom-error">Please enter title</div>
            )}
            <CKEditor
              data={editDes ? editDescription : editData && editData.content}
              editor={ClassicEditor}
              placeholder="Information about the update"
              onChange={editDescriptionHandler}
              config={{
                toolbar: [
                  "Heading",
                  "bold",
                  "italic",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "Link",
                ],
              }}
            />
            {descError && (
              <div className="custom-error">Please enter description</div>
            )}
            <AddPhotos
              title="Default Image Of The Update"
              isOpen={showingEditPhotoModal}
              onClose={onCloseEditPhoto}
              collection="DonationRequest"
              uid={props.donationRequest && props.donationRequest._id}
              subCollection="updates"
              subCollectionUid={editData && editData._id}
              profilephotoURL={editData && editData.defaultImageUrl}
              onProfilephotoURLChange={(url) => setNewDefaultImageUrl(url)}
              canEdit={canEdit}
            />
            <div>
              <h3 className="step-two-title" style={{ fontSize: "1.45em" }}>
                <b>Add a cover photo or video</b>
              </h3>
              <p className="image-desc">
                This is the image that will feature right on top of your page.
                Make it impactful!
              </p>

              <div>
                <figure>
                  <img
                    src={
                      newDefaultImageUrl
                        ? newDefaultImageUrl
                        : editData && editData.defaultImageUrl
                        ? editData.defaultImageUrl
                        : "/images/default-req-img.gif"
                    }
                    alt="default"
                    style={{
                      width: "100%",
                      MaxHeight: "364px",
                      cursor: "pointer",
                      border: "1px solid rgb(241, 241, 241)",
                    }}
                    onClick={(e) => setShowingEditPhotoModal(true)}
                  />
                </figure>
              </div>
              <div className="parent-notice">
                <h6 style={{ font: "400 12px Roboto" }}>
                  A picture is worth a thousand words
                </h6>
                <div className="notice">
                  <p
                    style={{
                      margin: 3,
                      font: "400 10px Roboto",
                    }}
                  >
                    <span className="tooltip-dots" />
                    Keep it relevant
                  </p>
                  <p
                    style={{
                      margin: 3,
                      font: "400 10px Roboto",
                    }}
                  >
                    <span className="tooltip-dots" />
                    Keep it clear
                  </p>
                  <p
                    style={{
                      margin: 3,
                      font: "400 10px Roboto",
                    }}
                  >
                    <span className="tooltip-dots" />
                    Types: .PNG, .JPG, .BMP
                  </p>
                </div>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => updateEdit()}
                className="updated-button"
              >
                Update
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(DonationRequestUpdate);
