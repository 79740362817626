import React, { useContext, useState } from "react";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "react-dates/lib/css/_datepicker.css";
import { withRouter } from "react-router";
import {
  Box,
  Button,
  FormControl,
  Typography,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { fetchAllPlaylist } from "../../utils/api";
import { DATE_FORMAT, TIME_FORMAT_SECONDS } from "../../utils/utils";
import ChannelSelectionBox from "./ChannelSelectBox";
import { appContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  liveplaylists: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#31A93D",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "#ef6c00",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "18px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      border: "#CD2729 2px solid",
      backgroundColor: "white",
      color: "#CD2729",
      borderRadius: "5px",
      padding: "5px",
    },

    mt10: {
      marginTop: "10px",
    },
    br0: {
      border: 0,
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "#CD2729",
      color: "white",
    },
    dBtn: {
      backgroundColor: "#ef6701",
      color: "white",
      border: "none",
      textAlign: "right",
      borderRadius: "5px",
      padding: "8px 15px",
      margin: "10px 0",
    },
  },
}));

const BMImusicsReport = (props) => {
  const { channel } = useContext(appContext);
  const [playlistData, setPlaylistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = React.useState(0);
  const [defaultMessage, setDefaultMessage] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(true);

  const classes = useStyles();

  const applyFilters = () => {
    let newStartDate, newEndDate;
    if (startDate) {
      var date = new Date(
        `${moment.utc(startDate).format("YYYY-MM-DD")} 00:00:00`
      );
      newStartDate = date.getTime();
    }

    if (endDate) {
      var date = new Date(
        `${moment.utc(endDate).format("YYYY-MM-DD")} 23:59:59`
      );
      newEndDate = date.getTime();
    }

    if (newStartDate || newEndDate) {
      setErrorMessage("");
      fetchAllPlaylist(
        {
          startDate: newStartDate,
          endDate: newEndDate,
          channel: channel,
        },
        "bmi"
      )
        .then((response) => {
          setIsLoading(false);
          setIsExportDisabled(false);
          setPlaylistData(response.data.data);
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error(error.message);
        });
    } else {
      setErrorMessage("Please select date filters");
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    //set default message to false when user applies filters
    setDefaultMessage(false);

    if (fromDate != null) {
      setStartDate(fromDate);
      setEndDate(null);
    }
    if (toDate != null) {
      setEndDate(toDate);
    }
  };

  const exportToCSV = () => {
    const JsonFields = [
      "Played Date & Time",
      "Title",
      "Album Title",
      "Artist Name",
      "Writer Names",
      "ISRC",
      "ISWC",
      "Duration",
    ];
    var csvStr = JsonFields.join(",") + "\n";

    playlistData.forEach((element) => {
      let {
        title,
        artistName,
        writers,
        albumTitle,
        isrc,
        iswc,
        duration,
        playedDateTime,
      } = element;

      if (!playedDateTime) {
        playedDateTime = "";
      }

      if (title) {
        title = title.replace(/\,/g, " ");
      }

      if (!albumTitle) {
        albumTitle = "";
      }

      if (!artistName) {
        artistName = "";
      } else {
        artistName = artistName.replace(/\,/g, "|");
      }

      if (!writers) {
        writers = "";
      } else {
        writers = writers.replace(/\,/g, "|");
      }

      if (!isrc) {
        isrc = "";
      }

      if (!iswc) {
        iswc = "";
      }

      if (!duration) {
        duration = "";
      }

      const newDuration =
        duration && new Date(duration * 1000).toISOString().substr(11, 8);

      csvStr +=
        (playedDateTime &&
          moment(playedDateTime, "x").format("DD MMM YYYY hh:mm a")) +
        "," +
        title +
        "," +
        albumTitle +
        "," +
        artistName +
        "," +
        writers +
        "," +
        isrc +
        "," +
        iswc +
        "," +
        newDuration +
        "," +
        "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Songs-Report-${moment().format(
      `${DATE_FORMAT}_${TIME_FORMAT_SECONDS}`
    )}.csv`;
    hiddenElement.click();
  };

  return (
    <Box>
      <Box
        className="drop-box"
        p={1}
        mb={1}
        display="flex"
        gap="5px"
        flexWrap="wrap"
        justifyContent="space-between"
        position="relative"
        zIndex="1"
      >
        <Box display="flex">
          <h4 className="image-title">BMI Music Report</h4>
        </Box>
        <div
          style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
          className="frm_hieght"
        >
          <FormControl>
            <DateRangePicker
              className={useStyles.br0}
              showDefaultInputIcon={true}
              displayFormat={DATE_FORMAT}
              startDate={startDate}
              startDateId="start_date_id"
              endDate={endDate}
              endDateId="end_date_id"
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              onDatesChange={({ startDate, endDate }) =>
                handleDateChange(startDate, endDate)
              }
              isOutsideRange={() => false}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              customClearIcon={true}
            />
            <Box component="h6" mt={1}>
              {errorMessage}
            </Box>
          </FormControl>
          <FormControl variant="outlined" style={{ minWidth: "170px" }}>
            <InputLabel>Channel</InputLabel>
            <ChannelSelectionBox />
          </FormControl>
          <Button
            variant="contained"
            style={{
              borderRadius: "5px",
              backgroundColor: "var(--tal_primary)",
              color: " white",
              height: "55px",
              margin: "0",
              border: "2px solid var(--tal_primary) ",
            }}
            onClick={applyFilters}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={() => exportToCSV()}
            disabled={isExportDisabled}
            style={{
              borderRadius: "5px",
              border: "none",
              color: "var(--tal_primary)",
              height: "55px",
              margin: "0",
              backgroundColor: "white ",
              border: "2px solid var(--tal_primary) ",
            }}
          >
            Export Data
          </Button>
        </div>
      </Box>

      <div style={{ width: "100%", overflowX: "auto" }}>
        <div className="table-fixed drop-box">
          <Box className={classes.table_tab}>
            <TableContainer component={Paper}>
              <Table aria-label="PlaylistStatisticsReport Table">
                <TableHead>
                  <TableRow>
                    <TableCell>Scheduled Date and Time</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Album Title</TableCell>
                    <TableCell>Artist Name</TableCell>
                    <TableCell>writer Names</TableCell>
                    <TableCell>ISRC</TableCell>
                    <TableCell>ISWC</TableCell>
                    <TableCell>Channel</TableCell>
                    <TableCell>Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playlistData &&
                    playlistData.length > 0 &&
                    playlistData.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {moment(row.playedDateTime, "x").format(
                            "DD MMM YYYY hh:mm a"
                          )}
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.albumTitle}</TableCell>
                        <TableCell>{row.artistName}</TableCell>
                        <TableCell>{row.writers}</TableCell>
                        <TableCell>{row.isrc}</TableCell>
                        <TableCell>{row.iswc}</TableCell>
                        <TableCell>{row.channel}</TableCell>
                        <TableCell>
                          {row.duration &&
                            new Date(row.duration * 1000)
                              .toISOString()
                              .substr(11, 8)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px 0",
                          }}
                        >
                          Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {playlistData && playlistData.length === 0 && !isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          {defaultMessage && <h6>Please apply filter(s)</h6>}
                          {!defaultMessage && "No records found"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </Box>
  );
};
export default withRouter(BMImusicsReport);
