import React, { useEffect, useState } from "react";
import toastr from "toastr";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import { deleteCategory } from "../../utils/api";
import AddCategory from "./AddCategory";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
    },
    "& td:last-child Button": {
      marginRight: "10px",
    },
  },
  category: {
    display: "flex",
    justifyContent: "space-between",

     
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      flexDirection: "column",
    },

    "& h2": {
      fontSize: "22px",
      fontWaite: "bold",
      color: "var(--tal_primary)",
    },
  },
}));

function Categories(props) {
  const { categories, setCategories } = props;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  // const [rows, setRows] = useState([]);
  const classes = useStyles();

  // useEffect(() => {
  //   categories.map((details) => {
  //     setRows((prevState) => {
  //       return [
  //         {
  //           cover: details.thumbnailImage
  //             ? details.thumbnailImage
  //             : "/images/radio_thumbnail.png",
  //           id: details._id,
  //           title: details.name,
  //           noOfStreamings: details.noOfStreamings,
  //           createdAt: details.createdAt,
  //         },
  //         ...prevState,
  //       ];
  //     });
  //     return rows;
  //   });
  // }, []);

  const confirmDelete = (recordId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteCategory(recordId)
        .then((delResp) => {
          if (delResp.statusCode === 200) {
            var oldRows = categories.filter((item) => {
              return item._id !== recordId;
            });
            setCategories([...oldRows]);
            toastr.success(delResp.message);
          } else {
            toastr.error("Error while deleting the category");
          }
        })
        .catch((e) => toastr.error("Failed to delete the category"));
    }
  };
  const handleClose = () => {
    setIsEnableEdit(false);
    setOpen(false);
  };

  const handleEdit = (id) => {
    setSelectedId(id);
    setIsEnableEdit(true);
    setOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box className={classes.root}>
        <Box className="drop-box" p={1} mb={1}>
          <Box className={classes.category}>
            <h2>TALMedia Categories </h2>

            <Button
              variant="contained"
            
              style={{ backgroundColor:"var(--tal_primary)", color:"white"}}
              onClick={() => setOpen(true)}
            >
              + Add Category
            </Button>
          </Box>
        </Box>

        <Box className={classes.table_tab}>
          <TableContainer component={Paper}>
            <Table aria-label="all streamings table">
              <TableHead>
                <TableRow>
                  <TableCell>Cover</TableCell>
                  <TableCell>
                    Title&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <img
                        src={
                          row.thumbnailImageUrl
                            ? row.thumbnailImageUrl
                            : "/images/radio_thumbnail.png"
                        }
                        alt="category"
                        width="40px"
                        height="40px"
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("h:mm A, MMM Do YYYY")}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        aria-controls="edit-category"
                        aria-haspopup="true"
                        onClick={() => handleEdit(row)}
                      >
                        <EditIcon style={{ color: "green" }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        aria-controls="delete-category"
                        aria-haspopup="true"
                        onClick={() => confirmDelete(row._id)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <AddCategory
        open={open}
        handleClose={handleClose}
        categories={categories}
        setCategories={setCategories}
        isEnableEdit={isEnableEdit}
        setIsEnableEdit={setIsEnableEdit}
        row={selectedId}
      />
    </div>
  );
}
export default Categories;
