import React, { useState, useEffect, Fragment } from "react";
import toastr from "toastr";
import cuid from "cuid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import firebase from "../../firebase/firebase";
import "./playlists.scss";

import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Switch,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";
import {
  mergeAudioFiles,
  getBlobFromUrl,
  arraysEqual,
} from "../../utils/utils";
import {
  getPlaylistItem,
  getPlaylists,
  savePlaylist,
  updatePlaylist,
} from "../../utils/api";
import { appContext } from "../../App";
import Loader from "../common/Loader";
import ManagePlaylist from "./ManagePlaylist";
import DropzoneInput from "../common/DropzoneInput";
import ChannelSelectionBox from "./ChannelSelectBox";

export default function PlaylistForm(props) {
  const {
    categories,
    setIsDisplayPlaylists,
    isEnableEdit,
    setIsEnableEdit,
    selectedId,
    playlists,
    setPlaylists,
    deleteMediaStorageFile,
  } = props;

  const [editorPick, setEditorPick] = useState(false);
  const handleEditorPick = () => {
    setEditorPick(!editorPick);
  };
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [thumbnailFileError, setThumbnailFileError] = useState("");
  const [dateConflictError, setDateConflictError] = useState("");
  const [duration, setDuration] = useState(0);
  const [sublist, setSublist] = useState([]);
  const [osublist, setOSublist] = useState([]);
  const [playlistItems, setPlaylistItems] = useState([]);
  const [channellistItems, setChannellistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [channel, setChannel] = useState(props.channel);
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ofromDate, setOFromDate] = useState(null);
  const [oendDate, setOEndDate] = useState(null);
  const [category, setCategory] = useState([]);
  const [rjValue, setRJValue] = useState("");
  const [tag, setTag] = useState("");
  const [producer, setProducer] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  const isAllCategories =
    category.length > 0 && category.length === categories.length;
  function handleName(event) {
    setName(event.target.value);
  }

  useEffect(() => {
    setChannellistItems(
      playlistItems.filter((item) => item.channel === channel)
    );
    setSublist([]);
  }, [channel]);

  function handleChangeChannel(event) {
    setChannel(event.target.value);
    setChannellistItems(
      playlistItems.filter((item) => item.channel === event.target.value)
    );
    setSublist([]);
  }

  function handleChangeCategory(event) {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setCategory(category.length === categories.length ? [] : categories);
      return;
    }
    setCategory(value);
  }
  function handleChangeRJValue(event) {
    setRJValue(event.target.value);
  }
  function handleChangeTag(event) {
    setTag(event.target.value);
  }
  function handleChangeProducer(event) {
    setProducer(event.target.value);
  }
  function handleChangeRJValue(event) {
    setRJValue(event.target.value);
  }
  function handleChangeDescription(event) {
    setDescription(event.target.value);
  }

  const handleBack = () => {
    setIsEnableEdit(false);
    setIsDisplayPlaylists(true);
  };

  const saveFileToCloud = (theFile) => {
    return new Promise((resolve, reject) => {
      setIsWaitingForResponse(true);
      let name;
      if (theFile.hasOwnProperty("name")) {
        name = cuid() + theFile.name.substring(theFile.name.lastIndexOf("."));
      } else {
        name = cuid() + ".mp3";
      }
      const fullPath = "talradio/playlist/" + name;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(fullPath);
      pathRef
        .put(theFile)
        .then(() => {
          storageRef
            .child(fullPath)
            .getDownloadURL()
            .then((url) => {
              setIsWaitingForResponse(false);
              resolve(url);
            });
        })
        .catch((error) => {
          setIsWaitingForResponse(false);
          reject(error.message);
        });
    });
  };

  const loadPlaylist = (id) => {
    setIsLoading(true);
    getPlaylists(id)
      .then((response) => {
        if (response) {
          setName(response.data.title);
          setEditorPick(response.data.editorPick);
          setChannel(response.data.channel);
          setFromDate(new Date(response.data.startTime));
          setOFromDate(new Date(response.data.startTime));
          setDuration(response.data.duration);
          setThumbnailUrl(response.data.thumbnailImageUrl);
          setEndDate(new Date(response.data.endTime));
          setOEndDate(new Date(response.data.endTime));
          setDownloadUrl(response.data.downloadUrl);
          setRJValue(response.data.rjUserId);
          setTag(response.data.tags && response.data.tags.join(" "));
          setProducer(response.data.producer);
          setDescription(response.data.description);
          setCategory(
            categories.filter(
              (cat) => response.data.categories.indexOf(cat._id) > -1
            )
          );
          setChannellistItems(
            playlistItems.filter(
              (item) => item.channel === response.data.channel
            )
          );
          response.data.playlistItems.forEach((id) => {
            playlistItems.forEach((item) => {
              if (id === item._id) {
                sublist.push(item);
              }
            });
          });
          setOSublist([...sublist]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Playlist:" + error.message);
      });
  };

  useEffect(() => {
    if (selectedId && isEnableEdit && playlistItems) {
      loadPlaylist(selectedId);
    }
  }, [selectedId, isEnableEdit, playlistItems]);

  const loadPlaylistItems = () => {
    getPlaylistItem()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setPlaylistItems(response.data);
          setChannellistItems(
            response.data.filter((item) => item.channel === channel)
          );
        }
      })
      .catch(() => {
        toastr.error("Problem in fetching Playlist Items");
      });
  };

  useEffect(() => {
    loadPlaylistItems();
  }, []);

  const savePlaylistData = (finalValues) => {
    savePlaylist(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setPlaylists([
            {
              id: resp.data._id,
              category:
                finalValues.categories &&
                finalValues.categories.map((cat) => cat.name).join(","),
              title: finalValues.title,
              rjUserId: rjValue,
              startTime: finalValues.startTime,
              endTime: finalValues.endTime,
              editorPick: editorPick,
              description: finalValues.description,
              duration: finalValues.duration,
              playlistItems: finalValues.playlistItems,
              channel: channel,
              producer: producer,
              thumbnailImageUrl: finalValues.thumbnailImageUrl,
              downloadUrl: finalValues.downloadUrl,
            },
            ...playlists,
          ]);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsDisplayPlaylists(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error);
      });
  };

  const updatePlaylistData = (finalValues) => {
    updatePlaylist(finalValues.selectedId, finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = playlists.map((item) => {
            if (item.id === selectedId) {
              return {
                id: resp.data._id,
                category:
                  finalValues.categories &&
                  finalValues.categories.map((cat) => cat.name).join(","),
                title: finalValues.title,
                rjUserId: rjValue,
                startTime: finalValues.startTime,
                endTime: finalValues.endTime,
                editorPick: editorPick,
                description: finalValues.description,
                duration: finalValues.duration,
                playlistItems: finalValues.playlistItems,
                channel: channel,
                producer: producer,
                thumbnailImageUrl: finalValues.thumbnailImageUrl,
                downloadUrl: finalValues.downloadUrl,
              };
            } else {
              return item;
            }
          });
          setPlaylists(oldRows);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsEnableEdit(false);
        setIsDisplayPlaylists(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.response.data.message);
      });
  };

  const deleteStorageFile = (fileUrl) => {
    const storageRef = firebase.storage;
    const ref = storageRef.refFromURL(fileUrl);
    storageRef
      .ref(ref.fullPath)
      .delete()
      .then(() => {})
      .catch((error) => {
        console.log("Error deleting the storage file: " + error.message);
      });
  };

  const uploadThumbnailImage = (name) => {
    return new Promise((resolve, reject) => {
      const fullPath = "talradio/playlist/thumbnails/" + name;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(fullPath);
      pathRef
        .put(thumbnailFile)
        .then(() => {
          storageRef
            .child(fullPath)
            .getDownloadURL()
            .then((thumbnailUrl) => {
              resolve(thumbnailUrl);
            })
            .catch(function (error) {
              reject("Problem uploading the thumbnail image:" + error.message);
            });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const mergePlaylist = (finalValues, callback) => {
    if (!arraysEqual(sublist, osublist)) {
      const selectedFilesUrl = sublist.map((item) => item.downloadUrl);
      getBlobFromUrl(selectedFilesUrl)
        .then((blobResp) => {
          mergeAudioFiles(blobResp)
            .then((finaloutput) => {
              saveFileToCloud(finaloutput.file)
                .then((response) => {
                  if (downloadUrl) deleteMediaStorageFile(downloadUrl);
                  finalValues.downloadUrl = response;
                  callback(finalValues);
                })
                .catch((error) => {
                  setIsWaitingForResponse(false);
                  toastr.error(error);
                });
            })
            .catch((error) => {
              setIsWaitingForResponse(false);
              toastr.error("Problem merging the audio files:" + error.message);
            });
        })
        .catch((error) => {
          setIsWaitingForResponse(false);
          toastr.error("Blob error:" + error.message);
        });
    } else {
      finalValues.downloadUrl = downloadUrl;
      callback(finalValues);
    }
  };

  const handleNext = async () => {
    const extraErrors = {};
    if (dateConflictError) extraErrors.dateConflictError = dateConflictError;
    if (name === "") {
      extraErrors.name = "Please enter name";
    }

    if (sublist && sublist.length === 0) {
      extraErrors.playlist = "Please select atleast one item";
    }

    if (!isEnableEdit && !thumbnailFile) {
      setThumbnailFileError(
        "Please select a thumbnail image file to set a thumbnail for this Playlist."
      );
      return;
    } else {
      setThumbnailFileError("");
    }
    if (
      endDate &&
      endDate.getTime() - fromDate &&
      fromDate.getTime() < 300000
    ) {
      extraErrors.fDate =
        "Atleast a 5 min difference between start time and end time";
    }
    setExtraErrors(extraErrors);
    if (extraErrors && Object.keys(extraErrors).length !== 0) return;

    const finalValues = {};
    finalValues.channel = channel;
    finalValues.categories = category;
    finalValues.rjUserId = rjValue;
    finalValues.tags = tag && tag.split(" ");
    finalValues.producer = producer;
    finalValues.description = description;
    finalValues.title = name;
    finalValues.editorPick = editorPick;
    finalValues.playlistItems = sublist.map((item) => item._id);
    finalValues.duration = sublist.reduce(
      (sum, item) => sum + item.duration,
      0
    );
    setIsWaitingForResponse(true);
    // Add thumbnail Image if present
    if (thumbnailFile && thumbnailFile.name) {
      uploadThumbnailImage(thumbnailFile.name)
        .then((response) => {
          finalValues.thumbnailImageUrl = response;
          URL.revokeObjectURL(thumbnailFile.preview);
          if (isEnableEdit) {
            finalValues.selectedId = selectedId;

            // Delete existing thumbnail file
            deleteStorageFile(thumbnailUrl);

            mergePlaylist(finalValues, updatePlaylistData);
          } else {
            mergePlaylist(finalValues, savePlaylistData);
          }
        })
        .catch((error) => {
          toastr.error(error);
          setIsWaitingForResponse(false);
        });
    } else if (isEnableEdit) {
      finalValues.thumbnailImageUrl = thumbnailUrl;
      finalValues.selectedId = selectedId;
      mergePlaylist(finalValues, updatePlaylistData);
    }
  };

  return (
    <Box>
      <Box className="drop-box schedule-streaming" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box fontWeight="400" fontSize="20px" color="var(--tal_primary)">
              {isEnableEdit ? "Edit " : "Create "} Playlist
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box component="Typography" lineHeight="36px">
                Editors Pick
              </Box>

              <Switch
                checked={editorPick}
                onChange={handleEditorPick}
                name="editorPick"
                size="medium"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="Title"
              placeholder="Give a title"
              name="name"
              value={name}
              error={
                extraErrors && extraErrors.name && extraErrors.name.length > 0
              }
              helperText={extraErrors.name}
              onChange={handleName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Channel</InputLabel>
              <ChannelSelectionBox
                channel={channel}
                channels={props.channels}
                setChannel={setChannel}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {/* <Typography>Select Categories</Typography> */}

            <FormControl
              variant="outlined"
              fullWidth
              error={
                extraErrors && extraErrors.ctgry && extraErrors.ctgry.length > 0
              }
            >
              <InputLabel
                htmlFor="age-native-simple"
                style={{
                  // marginLeft: "10px",
                  // marginTop: "-7px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  position: "absolute",
                  zIndex: "1",
                  padding: "0 5px",
                }}
              >
                Select Categories
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Categories"
                multiple
                value={category}
                onChange={handleChangeCategory}
                renderValue={(category) => (
                  <>{category.map((cat) => cat.name).join(",")}</>
                )}
              >
                <MenuItem
                  key="all"
                  value="all"
                  classes={{
                    root: isAllCategories ? "rgba(0, 0, 0, 0.08)" : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: "#f50057" }}
                      checked={isAllCategories}
                      indeterminate={
                        category.length > 0 &&
                        category.length < categories.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {categories.map((cat) => (
                  <MenuItem key={cat.name} value={cat}>
                    <ListItemIcon>
                      <Checkbox checked={category.indexOf(cat) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={cat.name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{extraErrors.ctgry}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="rj"
                label="RJ"
                helperText={extraErrors.rjv}
                placeholder="Enter RJ"
                value={rjValue}
                onChange={handleChangeRJValue}
              >
                {rjValue}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="producer"
                label="Producer"
                error={
                  extraErrors &&
                  extraErrors.producer &&
                  extraErrors.producer.length > 0
                }
                helperText={extraErrors.producer}
                placeholder="Producer"
                value={producer}
                onChange={handleChangeProducer}
              >
                {producer}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="Tag"
                label="Tags"
                placeholder="Example:tag1 tag2"
                value={tag}
                onChange={handleChangeTag}
              >
                {tag}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              id="Description"
              label="Description"
              value={description}
              onChange={handleChangeDescription}
            >
              {description}
            </TextField>
          </Grid>
        </Grid>

        <Box className="playlists">
          <Box
            width="100%"
            className="main-2"
            mt={2}
            border="2px dashed #f1f1f1"
          >
            <Box className="select" width="100%">
              <Box component="h4" color="var(--tal_primary)">
                Select Thumbnail Image File
              </Box>
              <Typography>
                (Ideal dimensions of the image : Width - 250 px, Height - 250 px)
              </Typography>
              <DropzoneInput setFile={setThumbnailFile} accept="image/*" />
              {thumbnailFile && (
                <Fragment>
                  <Typography>Thumbnail Image File</Typography>
                  <Typography>{thumbnailFile.name}</Typography>
                </Fragment>
              )}
              {thumbnailFileError && (
                <Typography style={{ color: "red" }}>
                  {thumbnailFileError}
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            style={{
              display: "grid",
              placeContent: "center",
              padding: "0 10px",
              margin: "15px 0 10px 0",
              maxWidth: "250px",
              maxHeight: "330px",
              border: "2px dashed #f1f1f1",
              display: thumbnailFile || thumbnailUrl ? "grid" : "none",
            }}
          >
            {(thumbnailFile || thumbnailUrl) && (
              <img
                width="100%"
                src={
                  thumbnailFile
                    ? URL.createObjectURL(thumbnailFile)
                    : thumbnailUrl
                }
                alt="thumbnail"
              />
            )}
          </Box>
        </Box>

        <Box my={2}>
          <ManagePlaylist
            orows={channellistItems}
            sublist={sublist}
            setSublist={setSublist}
            isLoading={isLoading}
          />
          {extraErrors && extraErrors.playlist && (
            <Typography className="custom-error">
              {extraErrors.playlist}
            </Typography>
          )}
        </Box>
        <Box>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button
            style={{
              backgroundColor: "var(--tal_primary)",
              marginLeft: "5px",
              color: "white",
            }}
            variant="contained"
            onClick={handleNext}
          >
            {isEnableEdit ? "Save" : "Create"}
          </Button>
        </Box>
      </Box>
      <Loader
        isOpen={isWaitingForResponse}
        onClose={() => setIsWaitingForResponse(false)}
      />
    </Box>
  );
}
