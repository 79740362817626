import React, { useState } from "react";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import toastr from "toastr";
import ShareIcon from "@material-ui/icons/Share";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddToCalendar from "react-add-to-calendar";
import "./ReactAddToCalendar.scss";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { appContext } from "../../App";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { deleteChatRooms } from "../../utils/api";
import { KeyboardArrowRight } from "@material-ui/icons";
import { withRouter } from "react-router";
import ShareOnSocialMedia from "../share/ShareOnSocialMedia";
import { getAudioRoomSessionSharedUrl, getShortUrl } from "../../utils/utils";

function Row(props) {
  const { row } = props;
  const { authUser, chats, setChats } = React.useContext(appContext);
  const [isPreviewMode] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAccord, setOpenAccord] = React.useState(true);
  const [isLoading] = React.useState(false);
  const [rowsPerPage] = React.useState(5);
  const [page] = React.useState(0);
  const [chatRoom, setChartRoom] = React.useState({});
  const [shortUrl, setShortUrl] = useState("");

  const handleChatRooms = (id) => {
    props.history.push({
      pathname: "/createAudioRoom",
      state: {
        selectedId: id,
      },
    });
  };

  const confirmDelete = (recordId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteChatRooms(recordId)
        .then((delResp) => {
          if (delResp.statusCode === 200) {
            var oldRows = chats.map((item) => {
              if (item.startTime === row.startTime) {
                return {
                  ...item,
                  chats: item.chats.filter((chat) => chat._id !== recordId),
                };
              } else {
                return item;
              }
            });
            setChats([...oldRows]);
            toastr.success(delResp.message);
          } else {
            toastr.error("Error while deleting the chatRoom");
          }
        })
        .catch((e) => {
          toastr.error("Failed to delete the chatRoom", e.message);
        });
    }
  };

  const handleClickOpen = (chat) => {
    // Get shortUrl from the API for sharing the donation request
    getShortUrl(
      getAudioRoomSessionSharedUrl(chat),
      "audioRoom",
      chat._id
    )
      .then((shortUrl) => {
        setShortUrl(shortUrl);
        setChartRoom(chat);
        setOpen(true);
      })
      .catch((errorMessage) => {
        toastr.error(errorMessage);
      });
  };

  const handleJoin = (row) => {
    if (!authUser || !authUser.unique_id) {
      toastr.error("Please login to access audio rooms");
      return;
    }
    window.open(
      process.env.REACT_APP_CHAT_SERVER_BASE_URL +
        "/join/" +
        row._id +
        "?userId=" +
        authUser.unique_id
    );
  };

  let event = {
    title: props.row.chats[0].title,
    description: props.row.chats[0].description,
    location:
      props.row.chats[0].ownerId.address.city +
      " , " +
      props.row.chats[0].ownerId.address.country,
    startTime: props.row.chats[0].startTime,
    endTime: props.row.chats[0].endTime,
  };

  let icon = { "calendar-plus-o": "left" };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          style={{
            padding: "10px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenAccord(!openAccord)}
          >
            {openAccord ? <KeyboardArrowUpIcon /> : <KeyboardArrowRight />}{" "}
          </IconButton>

          {moment(row.startTime).format("DD  MMM YYYY")}
        </TableCell>
        {/* <TableCell component="th" scope="row" colSpan={6}>
          {moment(row.startTime).format("DD,MMM YYYY")}
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={openAccord}
            timeout="auto"
            unmountOnExit
            style={{ backgroundColor: "white" }}
          >
            <Box
              component="table"
              width="98%"
              margin="0 auto"
              bgcolor="#dfe0e4"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description </TableCell>
                  <TableCell>Room Status</TableCell>
                  <TableCell>Organizer</TableCell>
                  <TableCell> Actions</TableCell>
                </TableRow>
              </TableHead>
              {row.chats &&
                row.chats.length > 0 &&
                row.chats
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((chat) => (
                    <TableRow
                      key={chat._id}
                      style={{ backgroundColor: "#fafafa" }}
                    >
                      <TableCell style={{ width: "100px" }}>
                        {moment(chat.startTime).format("h:mm A")}
                      </TableCell>
                      <TableCell style={{ width: "125px" }}>
                        {moment(chat.endTime).format("DD, MMM YYYY h:mm A")}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "100px",
                          whiteSpace: "normal",
                          wordWrap: "break-all",
                        }}
                      >
                        {chat.title}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "100px",
                          whiteSpace: "normal",
                          wordWrap: "break-all",
                          color: "#f35a57",
                        }}
                      >
                        {chat.description}
                      </TableCell>
                      <TableCell style={{ width: "75px" }}>
                        {new Date().getTime() > chat.startTime &&
                        new Date().getTime() < chat.endTime ? (
                          <span className="ongoing">Ongoing</span>
                        ) : (
                          <span className="upcoming">Upcoming</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{ width: "75px", wordWrap: "break-all" }}
                      >
                        <a
                          style={{
                            color: "red",
                          }}
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "/" +
                            (chat.ownerId && chat.ownerId.username)
                          }
                          target="_blank"
                        >
                          {chat.ownerId &&
                            chat.ownerId.name &&
                            chat.ownerId.name.first_name}{" "}
                          {chat.ownerId &&
                            chat.ownerId.name &&
                            chat.ownerId.name.last_name}
                          {""}
                        </a>
                      </TableCell>

                      <TableCell style={{ width: "320px" }}>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            padding: "6px 15px 0 0",
                            pointerEvents: isPreviewMode ? "none" : "auto",
                          }}
                          className="action-btn"
                        >
                          <Tooltip title="Share Audio Room">
                            <Button
                              color="primary"
                              onClick={() => handleClickOpen(chat)}
                            >
                              <IconButton
                                aria-label="share"
                                className="share-req-icon"
                              >
                                <ShareIcon />
                                <span
                                  style={{
                                    font: "500 11px Roboto",
                                    marginLeft: 4,
                                    color: "#000 !important",
                                    position: "relative",
                                    pointerEvents: isPreviewMode
                                      ? "none"
                                      : "auto",
                                  }}
                                >
                                  Share
                                </span>
                              </IconButton>
                            </Button>
                          </Tooltip>
                          <ShareOnSocialMedia
                            open={open}
                            handleClose={() => setOpen(false)}
                            url={shortUrl}
                            quote={`Hello, Join ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.first_name
                            } ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.last_name
                            } for an Audio conversation on TALGiving Audio Rooms platform. A platform where donors and donee can get together and have realtime conversation to establish transparency in fundraising.
          
What we will discuss: ${chatRoom && chatRoom.title} 
Description: ${chatRoom && chatRoom.description} 
When: ${moment(chatRoom && chatRoom.startTime).format("DD,MMM YYYY h:mm A")} 
Ends At: ${moment(chatRoom && chatRoom.endTime).format("DD,MMM YYYY h:mm A")} 
Where: ${process.env.REACT_APP_CHAT_SERVER_BASE_URL}/join/${
                              chatRoom && chatRoom._id
                            } 
Moderator: ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name.first_name +
                                " " +
                                chatRoom.ownerId.name.last_name
                            } 
                    
Thanks, 
Touch-A-Life Foundation
                              
                    `}
                            subject={`[Touch-A-Life] Join ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.first_name
                            } ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.last_name
                            } for an Audio conversation`}
                            content={`Hello,<br /><br />Join ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.first_name
                            } ${
                              chatRoom &&
                              chatRoom.ownerId &&
                              chatRoom.ownerId.name &&
                              chatRoom.ownerId.name.last_name
                            } for an Audio conversation on TALGiving Audio Rooms platform. A platform where donors and donee can get together and have realtime conversation to establish transparency in fundraising.<br />
         What we will discuss: ${chatRoom && chatRoom.title} <br />
         Description: ${chatRoom && chatRoom.description} <br />
         When: ${moment(chatRoom && chatRoom.startTime).format(
           "DD,MMM YYYY h:mm A"
         )} <br />
         Ends At: ${moment(chatRoom && chatRoom.endTime).format(
           "DD,MMM YYYY h:mm A"
         )} <br />
         Where: ${shortUrl}<br/>
          Moderator:${
            chatRoom &&
            chatRoom.ownerId &&
            chatRoom.ownerId.name.first_name +
              " " +
              chatRoom.ownerId.name.last_name
          } <br /><br /><br /> Thanks, <br />Touch-A-Life Foundation`}
                            redirectPage={`/chatTabs`}
                            shareURL={`${
                              process.env.REACT_APP_CHAT_SERVER_BASE_URL
                            }/join/${chatRoom && chatRoom._id}`}
                          />
                        </div>
                        {new Date().getTime() > chat.startTime &&
                          new Date().getTime() < chat.endTime && (
                            <Button
                              variant="contained"
                              onClick={() => handleJoin(chat)}
                              style={{
                                backgroundColor: "var(--tal_primary)",
                                marginLeft: "5px",
                                color: "white",
                              }}
                            >
                              Join now
                            </Button>
                          )}
                        <IconButton>
                          <AddToCalendar
                            event={event}
                            buttonLabel=""
                            buttonTemplate={icon}
                          />
                        </IconButton>
                        {authUser && authUser.unique_id === chat.ownerId._id && (
                          <>
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleChatRooms(chat._id)}
                            >
                              <EditIcon
                                style={{
                                  color: "green",
                                  padding: "5px",
                                  margin: "5px",
                                  borderRadius: "50px",
                                  fontSize: "30px",
                                  boxShadow: "0 0 9px #e8eaf5",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </IconButton>

                            <IconButton
                              aria-label="delete"
                              onClick={() => confirmDelete(chat._id)}
                            >
                              <DeleteIcon
                                style={{
                                  color: "red",
                                  padding: "5px",
                                  margin: "5px",
                                  borderRadius: "50px",
                                  fontSize: "30px",
                                  boxShadow: "0 0 9px #e8eaf5",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {isLoading && (
        <TableRow align="center">
          <TableCell colSpan="10">
            <Typography
              style={{
                textAlign: "center",
                fontSize: "20px",
                padding: "10px 0",
              }}
            >
              Loading...
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
export default withRouter(Row);
