const validatePlasmaRequest = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = "Please enter title";
  } else if (values.title && values.title.length > 50) {
    errors.title = "Title cannot be more than 50 characters";
  }
  if (!values.blood) {
    errors.blood = "Please select blood group";
  }
  if (!values.fullname) {
    errors.fullname = "Please enter full name";
  }
  if (!values.gender) {
    errors.gender = "Please select gender";
  }
  return errors;
};
export default validatePlasmaRequest;
