import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { appContext } from "../../App";
import { makeStyles } from "@material-ui/core/styles";
import ContactGroupModal from "./ContactGroupModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listContainer: {
    overflowX: "auto",
    height: "auto",
    maxHeight: "200px",
    "& li": {
      borderBottom: "1px solid rgb(246, 247, 251)",
      "&:hover": {
        backgroundColor: "rgb(246, 247, 251)",
      },
    },
  },
}));

const ContactGroups = (props) => {
  const { contactGroups } = useContext(appContext);
  const classes = useStyles();
  const [currentContactGroup, setCurrentContactGroup] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleEditContactGroup = (cg) => {
    setCurrentContactGroup(cg);
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentContactGroup(null);
  };
  return (
    <div className="myOrganizations drop-box contact">
      <div className="side-box">
      <p>My Contact Groups{" "} </p>
        <Button className="add">
          <Tooltip title="Add Contact Group">
            <AddIcon
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </Tooltip>
        </Button>

        </div>
     
      <div className={classes.listContainer}>
        {contactGroups.length ? (
          <List className={classes.root}>
            {contactGroups.map((cg) => {
              const labelId = `checkbox-list-label-${cg._id}`;
              return (
                <ListItem key={cg._id} role={undefined}>
                  <ListItemText
                    id={labelId}
                    primary={`${cg.name} (${
                      cg.contactCount ? cg.contactCount : 0
                    })`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <EditIcon
                        title="Edit Contact"
                        onClick={() => {
                          handleEditContactGroup(cg);
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <div
            style={{
              fontSize: "12px",
              padding: "10px 0",
              textAlign: "center",
            }}
          >
            No Contact Groups
          </div>
        )}
      </div>
      <ContactGroupModal
        currentContactGroup={currentContactGroup}
        openModal={openModal}
        onModalClose={handleModalClose}
        onContactGroupSave={() => {
          setOpenModal(false);
        }}
      ></ContactGroupModal>
    </div>
  );
};

export default ContactGroups;
