import React, { useEffect, useContext } from "react";
import toastr from "toastr";
import { appContext } from "../../App";
import { withRouter } from "react-router-dom";

const BecomeAVolunteer = (props) => {
  const { authUser, setIsVolunteerApplicationFlow } = useContext(appContext);
  useEffect(() => {
    if (authUser && authUser.unique_id) {
      props.history.push(`/volunteerProfile/${authUser.unique_id}`);
    } else {
      const localAuthUser = localStorage.getItem("authUser");
      if (localAuthUser) {
        const parsedAuthUser = JSON.parse(localAuthUser);
        props.history.push(`/volunteerProfile/${parsedAuthUser.unique_id}`);
      } else {
        setIsVolunteerApplicationFlow(true);
        toastr.info(
          "Please complete the signup and then you would be able to fill the TALScout application form."
        );
        props.history.push("/signup");
      }
    }
  }, []);

  return <div></div>;
};

export default withRouter(BecomeAVolunteer);
