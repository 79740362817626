const validateAddPlayListItem = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Please enter the Audio title";
  }
  if (!values.channel) {
    errors.channel = "Please select the channel option";
  }
  if (!values.isrc) {
    errors.isrc = "Please enter the ISRC";
  }
  if (!values.albumTitle) {
    errors.albumTitle = "Please enter the Album Title";
  }
  if (!values.artistName) {
    errors.artistName = "Please enter the Artist Name";
  }
  return errors;
};

export default validateAddPlayListItem;
