import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { Button, Container } from "@material-ui/core";
import "../../components/common/AddMediaFile.scss";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideMenu from "../SideMenu/Sidemenu";
import { withRouter } from "react-router";
import Streamings from "./Streamings";
import Categories from "./Categories";
import TotalRJs from "./TotalRJs";
import Box from "@material-ui/core/Box";
import {
  getChannels,
  getPlaylists,
  getTALRadioCategories,
  getUsers,
  getPlaylistItem,
} from "../../utils/api";
import Playlists from "./Playlists";
import PlaylistStatisticsReport from "./PlaylistStatisticsReport";
import AddPlayListItem from "./AddPlayListItem";
import { appContext } from "../../App";
import FirestoreService from "../common/Firestore/FirestoreService";
import BMImusicsReport from "./BMImusicsReport";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  bgwhite: {
    padding: "10px 0",
    boxShadow: " 0 0 4px #b0b0b0",
    backgroundColor: "white",
    position: " fixed",
    left: "0",
    right: "0",
    width: "100%",
    top: "85px",
    zIndex: "2",

    [theme.breakpoints.down("sm")]: {
      top: "135px",
      zIndex: "10",
    },

    "& Button": {
      color: " var(--tal_primary)",
      minWidth: "50px",
      height: " 50px",
      border: "2px solid var(--tal_primary)",
      borderRadius: " 100%",
      marginRight: "10px",
    },
  },
  button: {
    backgroundColor: "var(--tal_primary)",
    display: "flex",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function TALRadio(props) {
  const classes = useStyles();
  const {
    setPlaylists,
    channel,
    setChannel,
    channels,
    setChannels,
    setPlaylistItems,
    isTalRadioDataLoaded,
    setIsTalRadioDataLoaded,
  } = useContext(appContext);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const [categories, setCategories] = useState([]);
  const [rjvalues, setRJValues] = useState([]);
  const [isPlaylistLoading, setIsPlaylistLoading] = useState(false);
  const [isPlaylistItemsLoading, setIsPlaylistItemsLoading] = useState(false);

  const loadTotalRJs = () => {
    getUsers(null, "radiojockey")
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setRJValues(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching RJs:" + error.message);
      });
  };
  const loadCategories = () => {
    getTALRadioCategories()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setCategories(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Categories:" + error.message);
      });
  };

  const loadPlaylists = () => {
    setIsPlaylistLoading(true);
    getPlaylists("")
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setPlaylists(response.data);
        }
        setIsPlaylistLoading(false);
      })
      .catch((error) => {
        setIsPlaylistLoading(false);
        toastr.error("Problem in fetching Playlists:" + error);
      });
  };

  const onDataChange = (items) => {
    let plitems = [];
    items.docs.forEach((item) => {
      let id = item.id;
      let data = item.data();
      plitems.push({
        _id: id,
        ...data,
      });
    });
    setPlaylistItems(plitems);
    setIsPlaylistItemsLoading(false);
  };

  const loadChannels = () => {
    getChannels(true)
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setChannels(response.data);
          response.data.forEach((channel) => {
            if (channel.isDefault) {
              setChannel(channel.name);
            }
          });
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Channels:" + error.message);
      });
  };

  useEffect(() => {
    setIsPlaylistItemsLoading(true);
    const unsubscribe = FirestoreService.getAll("/playlistitems")
      .orderBy("createdAt", "desc")
      .onSnapshot(onDataChange);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // loadTotalRJs();
    loadCategories();
  }, []);

  useEffect(() => {
    if (!isTalRadioDataLoaded) {
      loadChannels();
      loadPlaylists();
      setIsTalRadioDataLoaded(true);
    }
  }, [isTalRadioDataLoaded]);

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <Box className={classes.bgwhite}>
        <Container maxWidth="lg">
          <Button onClick={handleCloseSideMenu}>
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <div
          className="menu-container manage_podcast_media"
          style={{ top: "55px", marginBottom: "100px" }}
        >
          {closeSideMenu && (
            <Box marginRight="10px">
              <SideMenu />
            </Box>
          )}
          <Box width="100%">
            {props.match.params.id === "liveStreamings" && channel && (
              <Streamings />
            )}
            {props.match.params.id === "managePlaylist" && channel && (
              <Playlists
                categories={categories}
                isLoading={isPlaylistLoading}
                loadPlaylists={loadPlaylists}
                isPlaylistItemsLoading={isPlaylistItemsLoading}
              />
            )}
            {props.match.params.id === "categories" && (
              <Categories
                categories={categories}
                setCategories={setCategories}
              />
            )}
            {/* {props.match.params.id === "totalRJs" && (
              <TotalRJs rjvalues={rjvalues} />
            )} */}
            {props.match.params.id === "managePlaylistItems" && channel && (
              <AddPlayListItem
                isPlaylistItemsLoading={isPlaylistItemsLoading}
              />
            )}
            {props.match.params.id === "statisticsReport" && channel && (
              <PlaylistStatisticsReport channel={channel} channels={channels} />
            )}
            {props.match.params.id === "BMImusicsReport" && channel && (
              <BMImusicsReport />
            )}
          </Box>
        </div>
      </Container>
    </div>
  );
}
export default withRouter(TALRadio);
