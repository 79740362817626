import React, { useState, useEffect, Fragment } from "react";
import toastr from "toastr";
import cuid from "cuid";
import DropzoneInput from "./DropzoneInput";
import firebase from "../../firebase/firebase";
import useFormValidation from "../../hooks/useFormValidation";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import validateAddVideo from "./validateAddVideo";
import Slide from "@material-ui/core/Slide";
import VideoRow from "./VideoRow";
import VideoPlayer from "./VideoPlayer";
import "./AddVideo.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddVideo = (props) => {
  const initialFormState = {
    name: "",
    note: "",
  };
  const [path, setPath] = useState("");
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveOrUploadVideo = () => {
    if (editMode) {
      saveVideo();
    } else {
      uploadVideo();
    }
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialFormState, validateAddVideo, saveOrUploadVideo);

  useEffect(() => {
    const path = `${props.collection}/${props.uid}/videos`;
    setPath(path);
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              setDocuments((prevDocuments) => [
                ...prevDocuments,
                {
                  url: url,
                  name: metadata.name,
                  userGivenName:
                    metadata.customMetadata &&
                    metadata.customMetadata.userGivenName,
                  note: metadata.customMetadata && metadata.customMetadata.note,
                },
              ]);
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        });
      });
    });
  }, [props.collection, props.uid]);

  const saveVideo = () => {
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path + "/" + document.name);
    let metadata = {
      customMetadata: {
        userGivenName: values.name,
        note: values.note,
      },
    };
    pathRef
      .updateMetadata(metadata)
      .then((metadata) => {
        setDocuments(
          documents.map((document) => {
            if (document.name === metadata.name) {
              return {
                ...document,
                userGivenName: metadata.customMetadata.userGivenName,
                note: metadata.customMetadata.note,
              };
            } else {
              return document;
            }
          })
        );
        setValues(initialFormState);
        setEditMode(false);
        toastr.success(" Video is updated successfully");
      })
      .catch(function (error) {
        toastr.error("Problem updating the Video properties:" + error.message);
      });
  };

  async function uploadVideo(name) {
    if (files && files.length > 0) {
      if (!files[0].name) {
        setFileError("Please select a video file to upload");
        return;
      } else {
        setFileError("");
      }
    } else {
      setFileError("Please select a video file to upload");
      return;
    }
    setIsLoading(true);
    if (!name) {
      name = cuid();
    }
    const fullPath = path + "/" + name;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .put(files[0])
      .then(() => {
        storageRef
          .child(fullPath)
          .getDownloadURL()
          .then((url) => {
            URL.revokeObjectURL(files[0].preview);
            setFiles([]);
            let metadata = {
              customMetadata: {
                userGivenName: values.name,
                note: values.note,
              },
            };
            pathRef
              .updateMetadata(metadata)
              .then((metadata) => {
                setDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    userGivenName: metadata.customMetadata.userGivenName,
                    note: metadata.customMetadata.note,
                  },
                ]);
                setValues(initialFormState);
                props.onSavingDocument(
                  "Video uploaded successfully",
                  url,
                  metadata.name,
                  metadata.customMetadata.userGivenName,
                  metadata.customMetadata.note
                );
                setIsLoading(false);
              })
              .catch(function (error) {
                toastr.error(
                  "Problem updating the video properties:" + error.message
                );
                setIsLoading(false);
              });
          });
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error.message);
      });
  }

  const deleteDocument = (name) => {
    const fullPath = path + "/" + name;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .delete()
      .then(() => {
        setDocuments(documents.filter((document) => document.name !== name));
        toastr.success("Video is deleted successfully ");
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const editingDocument = (document) => {
    setDocument(document);
    setEditMode(true);
    setValues({
      name: document.userGivenName,
      note: document.note,
    });
  };

  const onPlayVideo = (document) => {
    setDocument(document);
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      className="upload-cntnr"
    >
      <div className="AddVideo">
        <div className="dialog-box drop-box">
          <h2 className="image-title">{props.title}</h2>
          <div className="close-image-popup">
            <IconButton
              // edge="start"
              // color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="add-image-container">
          <Grid container spacing={2}>
            {props.canEdit && (
              <Fragment>
                <Grid item xs={12} md={6}>
                  <div className="part-1">
                    <div className="image child-container">
                      <Typography variant="h4">Step 1 - Select File</Typography>
                      <DropzoneInput
                        setFiles={setFiles}
                        accept={
                          props &&
                          props.path &&
                          props.path.includes("/transformers/SubmitIdea")
                            ? "video/mp4"
                            : "video/*,.mp4,.mkv,.dat,.avi"
                        }
                        multiple={false}
                      />
                      {fileError && (
                        <Typography style={{ color: "red" }}>
                          {fileError}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="part-2">
                    <div className="cropper child-container">
                      <Typography variant="h4">Step 2 - Upload</Typography>
                      {files[0] && (
                        <Fragment>
                          <Typography
                            style={{
                              padding: " 5px 0px",
                              background: "#e5e5e5",
                              marginTop: "5px",
                            }}
                          >
                            Selected File: {files[0].name}
                          </Typography>
                        </Fragment>
                      )}
                    </div>
                    {props &&
                      props.path &&
                      props.path.includes("/transformers/SubmitIdea") && (
                        <p style={{ color: "red" }}>
                          Please upload .mp4 files only
                        </p>
                      )}
                    <div>
                      <TextField
                        required
                        variant="filled"
                        className="input-field"
                        placeholder="Video Name"
                        name="name"
                        value={values.name}
                        onChange={changeHandler}
                      />
                      {errors.name && (
                        <Typography style={{ color: "red" }}>
                          {errors.name}
                        </Typography>
                      )}
                    </div>

                    <div>
                      <TextField
                        type="text"
                        name="note"
                        className="input-field"
                        placeholder="Add A Note"
                        value={values.note}
                        onChange={changeHandler}
                      />
                      {errors.note && (
                        <Typography style={{ color: "red" }}>
                          {errors.note}
                        </Typography>
                      )}
                    </div>

                    <Button
                      className="upload-btn"
                      onClick={submitHandler}
                      disabled={isLoading}
                    >
                      {editMode ? "Save" : "Upload"}
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                    </Button>
                  </div>
                </Grid>
              </Fragment>
            )}
            {documents && documents.length > 0 && (
              <Grid item xs={12} md={12}>
                <div style={{ background: "rgb(248 248 248)", margin: "10px" }}>
                  <Table celled striped>
                    <TableHead>
                      <TableRow>
                        <TableCell>Video Name</TableCell>
                        <TableCell>Note</TableCell>
                        {props.canEdit && <TableCell>Edit/Delete</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documents.map((document, index) => {
                        return (
                          <VideoRow
                            key={index}
                            document={document}
                            onPlayHandler={() => onPlayVideo(document)}
                            onDeleteHandler={() =>
                              deleteDocument(document.name)
                            }
                            onEditHandler={() => editingDocument(document)}
                            canEdit={props.canEdit}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                  <VideoPlayer
                    isOpen={isPlayingVideo}
                    onClose={onClosePlayVideo}
                    name={document && document.userGivenName}
                    url={document && document.url}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};

export default AddVideo;
