import React, { useState } from "react";
import { Avatar, Button, TextField } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import toastr from "toastr";
import FormHelperText from "@material-ui/core/FormHelperText";
import "./Comment.scss";
export default function AddComment({
  path,
  senderProfileImageUrl,
  userId,
  senderName,
  setCommentId,
  promptText,
  loadComments,
}) {
  const [message, setMessage] = useState("");
  const [disableButtonStatus, setDisableButtonStatus] = useState(true);

  const submitMessage = () => {
    if (message && message.trim()) {
      const ref = firebase.realtimeDb.ref(path);
      const values = {};
      values.text = message;
      values.userId = userId;
      values.senderName = senderName;
      values.senderProfileImageUrl = senderProfileImageUrl || "";
      values.whenSent = new Date().getTime();
      setMessage("");
      setCommentId("");
      ref.push(values);
      if (loadComments) loadComments();
    }
  };
  const textChangeHandler = (event) => {
    if (!event.target.value) {
      setDisableButtonStatus(true);
    } else {
      setDisableButtonStatus(false);
    }
    setMessage(event.target.value);
  };

  const keyPressHandler = (event) => {
    if (!message) {
      setDisableButtonStatus(true);
    } else {
      setDisableButtonStatus(false);
      if (event.key === "Enter") {
        submitMessage();
      }
      setDisableButtonStatus(true);
    }
  };
  return (
    <div>
      <div className="comment-textfield-container">
        <div className="comment-ui-req">
        <Avatar
          src={senderProfileImageUrl}
          alt="Avatar"
          className="comment-avatar"
        />
        <TextField
          type="text"
          name="message"
          value={message}
          className="comments-field"
          onChange={textChangeHandler}
          onKeyPress={keyPressHandler}
          placeholder={promptText}
          width={16}
          style={{ marginRight: "15px" }}
        />
        </div>
        <div>
          <Button
            name="submit"
            size="big"
            variant="contained"
            onClick={submitMessage}
            disabled={disableButtonStatus}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
