import validator from "validator";
const validateContact = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "Please enter first name";
  }
  if (values.email || values.phoneNumber) {
    if(values.email && !validator.isEmail(values.email)){
      errors.email = "Please enter valid email"
    } 
    if(!values.email && values.phoneNumber && !validator.isMobilePhone(values.phoneNumber)){
      errors.phoneNumber = "Please enter valid mobile number"
    } 
  } else {
    errors.email = "Please enter email or phone number";
  }
 if(!validator.isMobilePhone(values.phoneNumber)){
  errors.phoneNumber = "Please enter valid mobile number";
 }
  return errors;
};
export default validateContact;