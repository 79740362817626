import React, { useState, useEffect, Fragment, useContext } from "react";
import toastr from "toastr";
import cuid from "cuid";
import firebase from "../../firebase/firebase";
import useFormValidation from "../../hooks/useFormValidation";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextareaAutosize,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  InputBase,
  MenuItem,
  Select,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ValidateLearningLabsMedia from "./ValidateLearningLabsMedia";
import VideoRows from "./VideoRows";
import { appContext } from "../../App";
import "./ManageLearningLabs.scss";
import SideMenu from "../SideMenu/Sidemenu";
import DropzoneInput from "../common/DropzoneInput";
//import FirestoreService from "../common/Firestore/FirestoreService";
import cssstyle from"./ManageLearningLabs.module.scss";
import {
  getLearningLabs,
  saveLearningLabs,
  updateLearningLabs,
  deleteLearningLabs,
} from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
}));

const LearningLabsForm = (props) => {
  const initialFormState = {
    title: "",
    mediaUrl: "",
    type: "",
    description: "",
  };
  const path = `learninglabs/`;
  const collection = "/learninglabs";
  const [files, setFiles] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [thumbnailFileError, setThumbnailFileError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useContext(appContext);
  const [page, setPage] = useState(0);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [learningLabsType, setLearningLabsType] = useState(" ");

  const saveOrUploadDocument = () => {
    if (editMode) {
      saveDocument();
    } else {
      uploadFile();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setOffset(0);
    setDocuments([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(
      initialFormState,
      ValidateLearningLabsMedia,
      saveOrUploadDocument
    );

  useEffect(() => {
    if (!!files) {
      return URL.revokeObjectURL(files.preview);
    }
  }, [files]);

  useEffect(() => {
    if (!!thumbnailFile) {
      return URL.revokeObjectURL(thumbnailFile.preview);
    }
  }, [thumbnailFile]);

  const loadMediaDocumentsKeyword = (keyword) => {
    setIsLoading(true);
    getLearningLabs(
      keyword && keyword.length > 0 ? "" : learningLabsType,
      rowsPerPage,
      offset,
      keyword
    )
      .then((response) => {
        if (response) {
          setDocuments([...response.data]);
          setTotalCountOfRecords(response.totalCountOfRecords);
          setOffset(offset + rowsPerPage);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Podcast:" + error);
      });
  };

  const changeHandlerLearningLabsType = (e) => {
    setPage(0);
    setDocuments([]);
    setOffset(0);
    setLearningLabsType(e && e.target.value);
  };

  useEffect(() => {
    loadMediaDocumentsKeyword(keyword);
  }, [learningLabsType, rowsPerPage, page]);

  async function uploadFile() {
    if (!editMode && thumbnailFile && thumbnailFile.length === 0) {
      setThumbnailFileError(
        "Please select a thumbnail image file to set a thumbnail"
      );
      return;
    } else {
      setThumbnailFileError("");
    }
    setIsLoading(true);
    saveFileToCloud();
  }

  const saveDocument = () => {
    let metadata = {
      title: values.title,
      mediaUrl: values.mediaUrl,
      type: values.type,
      description: values.description,
    };
    updateLearningLabs(document._id, metadata)
      .then(() => {
        setDocuments(
          documents.map((doc) => {
            if (doc._id === document._id) {
              return { ...doc, ...metadata };
            } else {
              return doc;
            }
          })
        );
        setValues(initialFormState);
        setEditMode(false);
        toastr.success("Video properties are updated successfully.");
      })
      .catch(function (error) {
        toastr.error("Problem updating the Video properties:" + error.message);
      });
  };

  const saveFileToCloud = () => {
    const bucket2 = path + "/thumbnails/" + cuid() + "-" + values.title;
    Promise.all([getUrl(thumbnailFile[0], bucket2)])
      .then((response) => {
        let newDocument = {
          thumbnailImageUrl: response[0],
          title: values.title,
          mediaUrl: values.mediaUrl,
          type: values.type,
          description: values.description,
          time: new Date().getTime(),
        };

        saveLearningLabs(newDocument)
          .then((response) => {
            setDocuments([response.data, ...documents]);
            setThumbnailFile([]);
            setFiles([]);
            setValues(initialFormState);

            let type = values.type;
            if (type === "none") {
              type = "other";
            }

            toastr.success("The file is uploaded succesfully");
            setIsLoading(false);
          })
          .catch(function (error) {
            toastr.error(error);
            setIsLoading(false);
          });
      })
      .catch(function (error) {
        toastr.error("Problem in uploading the Files:" + error);
        setIsLoading(false);
      });
  };
  const getUrl = (file, bucket) => {
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(bucket);
    return new Promise((resolve, reject) => {
      pathRef
        .put(file)
        .then(() => {
          storageRef
            .child(bucket)
            .getDownloadURL()
            .then((mediaUrl) => {
              URL.revokeObjectURL(file.preview);
              resolve(mediaUrl);
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const deleteDocument = (document) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      deleteLearningLabs(document._id)
        .then((status) => {
          setDocuments(documents.filter((doc) => doc._id !== document._id));
          setEditMode(false);
          setValues(initialFormState);
          toastr.success("The file is successfully deleted from the database");
        })
        .catch((error) => {
          toastr.error("Error deleting the file:" + error.message);
        });
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const editingDocument = (document) => {
    setDocument(document);
    setEditMode(true);
    setValues({
      title: document.title,
      mediaUrl: document.mediaUrl,
      type: document.type,
      description: document.description,
    });
    scrollToTop();
  };

  const songsSearch = (e) => {
    setKeyword(e && e.target.value);
    setOffset(0);
    setPage(0);
    setIsSearchMode(true);
  };

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const onSubmit = (e) => {
    if (e.keyCode === 13) {
      loadMediaDocumentsKeyword(keyword);
    }
  };

  const cancelEditHandler = () => {
    setEditMode(false);
    setValues(initialFormState);
  };

  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <div className="manage_podcast_media">
        <div className="barnav">
          <Container MaxWidth="lg">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4 className={cssstyle.image_title}>Manage LearningLabs Media</h4>
            </div>
          </Container>
        </div>

        <Container maxWidth="lg">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}

            <div className={cssstyle.add_image_container}>
              <Fragment>
                <div className="top-box">
                  <Grid container spacing={2}>
                    {!editMode && (
                      <Grid item xs={12} md={4} sm={12}>
                        <div className="main-2 drop-box">
                          <div className="select">
                            <Typography variant="h4">
                              Select Thumbnail Image File
                            </Typography>
                            <h5>
                              (Ensure width and height are equal. For eg. 300 x
                              300)
                            </h5>
                            <DropzoneInput
                              setFiles={setThumbnailFile}
                              accept="image/*"
                              multiple={false}
                            />
                            {thumbnailFileError && (
                              <Typography style={{ color: "red" }}>
                                {thumbnailFileError}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                    )}

                    <Grid item xs={12} md={4} sm={12}>
                      <div className="main-3 drop-box">
                        <div className="cropper child-container">
                          {files && files.length > 0 && (
                            <Fragment>
                              <Typography>Selected Files:</Typography>
                              {files.map((eachFile, index) => {
                                return (
                                  <Typography key={index}>
                                    {eachFile.name}
                                  </Typography>
                                );
                              })}
                            </Fragment>
                          )}

                          <div>
                            <TextField
                              required
                              variant="filled"
                              className="input-field"
                              placeholder="Give a title"
                              name="title"
                              value={values.title}
                              onChange={changeHandler}
                            />
                            {errors && errors.title && (
                              <div className="custom-error">{errors.title}</div>
                            )}
                          </div>
                          <div>
                            <TextField
                              required
                              variant="filled"
                              className="input-field"
                              placeholder="Give a MediaUrl"
                              name="mediaUrl"
                              value={values.mediaUrl}
                              onChange={changeHandler}
                            />
                            {errors && errors.mediaUrl && (
                              <Typography className="custom-error">
                                {errors.mediaUrl}
                              </Typography>
                            )}
                          </div>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel
                              style={{ paddingLeft: "10px", marginTop: "-3px" }}
                            >
                              Select Type
                            </InputLabel>
                            <Select
                              variant="outlined"
                              name="type"
                              value={values.type}
                              label="Select Type"
                              onChange={changeHandler}
                            >
                              <MenuItem value="1">SDGS</MenuItem>
                              <MenuItem value="2">TRANSFORMER TALKS</MenuItem>
                              <MenuItem value="3">E-BOOKS</MenuItem>
                              <MenuItem value="4">BLOGS </MenuItem>
                            </Select>{" "}
                            {errors && errors.type && (
                              <div className="custom-error">{errors.type}</div>
                            )}
                          </FormControl>
                          <div>
                            <TextareaAutosize
                              name="description"
                              multiline
                              rows="4"
                              className="input-field"
                              placeholder="Enter a description"
                              value={values.description}
                              onChange={changeHandler}
                            />
                            {errors.description && (
                              <Typography style={{ color: "red" }}>
                                {" "}
                                {errors.description}
                              </Typography>
                            )}
                          </div>
                          {thumbnailFile && thumbnailFile[0] && (
                            <Fragment>
                              <Typography style={{wordBreak:"break-all"}}>
                                Thumbnail Image File :
                              {thumbnailFile[0].name}
                              </Typography>
                            </Fragment>
                          )}
                          <div className="save-btn">
                            <Button
                              className="upload-btn"
                              onClick={submitHandler}
                              disabled={isLoading}
                            >
                              {editMode ? "Save" : "Upload"}
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  className="buttonProgress"
                                />
                              )}
                            </Button>
                            {editMode && (
                              <Button
                                className="upload-btn"
                                onClick={cancelEditHandler}
                              >
                                Cancel Edit
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Fragment>

              <FormControl
                className="drop-box"
                style={{ width: "100%", margin: "10px 0", padding: "5px" }}
              >
                <InputLabel style={{ marginLeft: "18px" }}>
                  Select Type
                </InputLabel>
                <Select
                  variant="outlined"
                  name="type"
                  value={learningLabsType}
                  label="Select Type"
                  onChange={changeHandlerLearningLabsType}
                  style={{ width: "100%" }}
                >
                  <MenuItem value=" ">All </MenuItem>
                  <MenuItem value="1">SDGS</MenuItem>
                  <MenuItem value="2">TRANSFORMERS TALKS</MenuItem>
                  <MenuItem value="3">E-BOOKS</MenuItem>
                  <MenuItem value="4"> BLOGS</MenuItem>
                </Select>
              </FormControl>
              <Box
                className="searchbar-dr boder4"
                whiteSpace="noWrap"
                style={{ marginLeft: "10px" }}
              >
                <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
                <InputBase
                    placeholder="Enter search keyword"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={keyword}
                  inputProps={{ "aria-label": "search" }}
                  onChange={songsSearch}
                  onKeyDown={(e) => onSubmit(e)}
                />

                {keyword && (
                  <p className="result" style={{ marginLeft: "20px" }}>
                    Search Results for
                    <span
                      style={{
                        // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                        // borderRadius: "5px",
                        padding: "3px 10px 5px 10px",
                        marginLeft: "10px",
                        fontWeight: " bold",
                        color: "#686868",
                        background: "#f8f8f8",
                      }}
                    >
                      {keyword}
                    </span>
                    <IconButton
                      edge="start"
                      aria-label="close"
                      style={{
                        padding: "3px",
                        color: "rgb(205, 38, 39)",
                        position: "relative",
                        top: "-2px",
                        margin: "0 0 0 10px",
                        border: "1px solid #ededed",
                      }}
                    >
                      <Tooltip title="Clear Search Results">
                        <CloseIcon
                          onClick={() => {
                            setKeyword("");
                            loadMediaDocumentsKeyword("");
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </p>
                )}
              </Box>

              <Box className="table-responsive drop-box " mt={1}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="podcast-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Edit/Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading &&
                        documents &&
                        documents.length > 0 &&
                        documents
                          // .slice(
                          //   page * rowsPerPage,
                          //   page * rowsPerPage + rowsPerPage
                          // )
                          .map((document, index) => {
                            return (
                              <VideoRows
                                key={index}
                                document={document}
                                onDeleteHandler={() => deleteDocument(document)}
                                onEditHandler={() => editingDocument(document)}
                                canEdit={true}
                              />
                            );
                          })}
                      {!isLoading && documents && documents.length === 0 && (
                        <TableRow>
                          <TableCell>No Records Found</TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell>
                            <Typography> Loading...</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCountOfRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LearningLabsForm;
