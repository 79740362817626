const ValidateWithdrawalForm = (values) => {
  let errors = {};
  if (!values.payeeName) {
    errors.payeeName = "Please enter Payee Name";
  }
  if (values.requestType === "online") {
    if (!values.accountNumber) {
      errors.accountNumber = "Please enter account number";
    }
    if (!values.routingNumber) {
      errors.routingNumber = "please enter IFSC or Routing Number";
    }
  }

  return errors;
};

export default ValidateWithdrawalForm;
