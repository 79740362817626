import React, { Fragment, useState, useRef, useEffect } from "react";
import { Tooltip, Typography, Button, Grid, Box } from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import MailIcon from "@material-ui/icons/Mail";
import { podcastShareUrl } from "../../utils/utils";
import ShareThroughEmail from "../common/ShareThroughEmail";
import Divider from "@material-ui/core/Divider";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { saveShortUrl } from "../../utils/api";
const ShareTalRadioFile = (props) => {
  const [copySuccess, setCopySuccess] = useState("");
  const [showElement, setShowElement] = React.useState(true);
  const textAreaRef = useRef(null);
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const shareThroughMail = () => {
    setOpenShareEmail(!openShareEmail);
  };
  const { url, quote, subject, content, shareURL, redirectPage, representsWhat , referenceId} = props;

  const [shortUrls, setShortUrls] = useState("");
  const loadShortUrl = (url) => {
    console.log("url", url);
    const payload = { url : `${podcastShareUrl(props)}` , representsWhat , referenceId};

    if (payload) {
      console.log("url 1 :-", url);
      saveShortUrl(payload)
        .then((response) => {
          console.log("response", response);
          console.log("shortUrl", response.data.data.shortUrl);
          setShortUrls(response.data.data.shortUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    loadShortUrl(url);
  }, [url]);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const copyCodeToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setShowElement(true);
    setCopySuccess("URL Copied!");
    setTimeout(function () {
      setShowElement(false);
      setCopySuccess("");
    }, 2000);
  };

  return (
    <Fragment>
      <Grid container className="share-pop">
        <Grid>
          <Grid item>
            <Tooltip title="Share on Facebook">
              <div>
                <FacebookShareButton
                  url={shortUrls}
                  quote={props.name}
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" display="block" gutterBottom>
              Facebook
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <Tooltip title="Share on Twitter">
              <div>
                <TwitterShareButton
                  url={shortUrls}
                  title={props.name}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" display="block" gutterBottom>
              Twitter
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <Tooltip title="Share on LinkedIn">
              <div>
                <LinkedinShareButton
                  url={shortUrls}
                  quote={props.name}
                >
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" display="block" gutterBottom>
              LinkedIn
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <Tooltip title="Share on WhatsApp">
              <div>
                <WhatsappShareButton
                  url={shortUrls}
                  title={props.name}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" display="block" gutterBottom>
              WhatsApp
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <Tooltip title="Share by Email">
              <a
                href={`mailto:?subject=[TALRadio] Have you seen the TALRadio content - "${props.name}"?&body=Hello. %0D%0A I thought you might be interested in this TALRadio content:%0D%0A ${process.env.REACT_APP_BASE_URL}/talmedia/${props.file} %0D%0A Thanks, %0D%0A Touch-A-Life Foundation`}
                target="_blank"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <MailIcon
                  onClick={(e) => shareThroughMail(e)}
                  style={{
                    color: "#fff",
                    background: "#7777ff",
                    borderRadius: "50%",
                    padding: 5,
                    fontSize: 40,
                    cursor: "pointer",
                  }}
                />
              </a>
            </Tooltip>
            {openShareEmail &&
              openShareEmail === true &&
              subject &&
              redirectPage && (
                <ShareThroughEmail
                  isOpen={openShareEmail}
                  subject={subject}
                  content={content}
                  onClose={(e) => setOpenShareEmail(false)}
                  redirectPage={redirectPage}
                />
              )}
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" display="block" gutterBottom>
              Email
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%" }} />
        <Grid item xs={12} md={9} sm={9} className="copy-url">
          <input
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            readOnly
            ref={textAreaRef}
            value={shortUrls}
          />
          <div className="url-copy">{copySuccess}</div>
        </Grid>
        <Grid item xs={12} md={3} sm={3} className="copy-url">
          {document.queryCommandSupported("copy") && (
            <Button
              color="primary"
              style={{ float: "right" }}
              onClick={copyCodeToClipboard}
            >
              Copy Link
            </Button>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box className="fund-share-link">
            <p>
              <strong>Tip : </strong>Paste this podcast link anywhere.
            </p>
            <Box>
              <InstagramIcon size={40} round={true} />
              <YouTubeIcon size={40} round={true} />
              <img
                src="../images/slack.png"
                style={{ width: "20px", height: "20px" }}
              />
              <img
                src="../images/snapchat.png"
                style={{ width: "20px", height: "20px" }}
              />
              <img
                src="../images/tumblr.png"
                style={{ width: "20px", height: "20px" }}
              />
              <img
                src="../images/more.png"
                style={{ width: "20px", height: "20px" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ShareTalRadioFile;