const ValidateVolunteerProfile = (values) => {
  let errors = {};
  if (!values.hoursAvailablePerWeek) {
    errors.hoursAvailablePerWeek =
      "Please enter the hours that you would like to spare per week";
  }
  if (!values.whyWorkWithUs) {
    errors.whyWorkWithUs = "Please tell us why do you want to work with us";
  }
  return errors;
};

export default ValidateVolunteerProfile;
