import React, { useState, useEffect, useContext, Fragment } from "react";
import toastr from "toastr";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Link,
  Typography,
  TablePagination,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import {
  DATE_TIME_FORMAT,
  HOME_PAGE,
  checkUrlWithHttp,
} from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import Organization from "../Organization/Organization";
import cssstyles from "./MyOrganization.module.scss";

import {
  //   getVolunteers,
  //   updateVolunteerApplicationStatus,
  getAllOrganizations,
  updateOrganizationStatus,
  deleteOrganization,
  getOrgdetails,
  getOrganizationList,
} from "../../utils/api";
import Loader from "../common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "defaultImageUrl",
    numeric: false,
    disablePadding: true,
    label: "Organization Logo",
  },
  {
    id: "orgName",
    numeric: false,
    disablePadding: true,
    label: "Organization Name",
  },
  {
    id: "websiteUrl",
    numeric: true,
    disablePadding: false,
    label: "Web Site",
  },
  {
    id: "orgType",
    numeric: false,
    disablePadding: false,
    label: "Organization Type",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    "& td": {
      padding: "4px 16px",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
    "& td:nth-child(6)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
    "& td:nth-child(7)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const MyOrganizations = (props) => {
  const { authUser, isAdmin } = useContext(appContext);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [orgDetails, setOrgDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOrgDetails = (orgId) => {
    setIsLoading(true);
    getOrgdetails(orgId)
      .then((resp) => {
        setIsLoading(false);
        setOrgDetails(resp[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error.message);
      });
  };

  const handleOrganization = (rowData) => {
    setOpenOrgModal(true);
    setEdit(true);
    handleOrgDetails(rowData && rowData._id);
  };
  const handleOrganizationToAdd = () => {
    setOpenOrgModal(true);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    props.history.push(`/organization/${orgId}`);
  };

  const handleCloseEditOrg = () => {
    setOpenOrgModal(false);
    setEdit(false);
    setOrgDetails({});
    window.location.reload();
  };

  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Approved";
    }
  };

  const deleteOrg = (row) => {
    if (window.confirm("Are you sure you want to delete the organization?")) {
      deleteOrganization(
        row._id,
        row.user_info ? row.user_info._id : row.userId
      )
        .then((response) => {
          toastr.success(response.data.message);
          setOrgList(orgList.filter((org) => org.orgId._id !== row._id));
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setIsLoading(true);
      getOrganizationList(authUser.unique_id)
        .then((resp) => {
          setOrgList(resp);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error(error.message);
        });
    }
  }, [authUser]);

  return (
    <div className="myDonations-container">
      <div className={cssstyles.bar_nav}>
        <Container MaxWidth="lg">
          <Box className={cssstyles.org_head}>
            <Grid className={cssstyles.top_header}>
              {authUser && (
                <Button
                  onClick={handleCloseSideMenu}
                  className={cssstyles.org_btn}
                >
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4>My Organizations</h4>
            </Grid>
            <Grid>
              <Button
                onClick={handleOrganizationToAdd}
                className="add"
                style={{
                  minWidth: "152px",
                  background: authUser ? "#f35a57 " : "transparent",
                  color: "#fff",
                  // position: "absolute",
                  // left: "70%",
                }}
              >
                Add Organization
                <Tooltip title="Add An Organization">
                  <AddIcon />
                </Tooltip>
              </Button>
            </Grid>
          </Box>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )}

          <div style={{ position: "sticky", top: "0", width: "100%" }}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(orgList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            <span>
                              <img
                                src={
                                  row.orgId.defaultImageUrl
                                    ? row.orgId.defaultImageUrl
                                    : "/images/orgLogo.png"
                                }
                                style={{
                                  borderRadius: "100px",
                                  width: "40px",
                                  height: "40px",
                                }}
                                className="profile-pic"
                              />
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <a
                              style={{ color: "#007bff" }}
                              href={`/organization/${row.orgId._id}`}
                              target="_blank"
                            >
                              {row.orgId.orgName}
                            </a>
                          </TableCell>
                          <TableCell align="left">
                            <a
                              style={{ color: "#007bff" }}
                              href={checkUrlWithHttp(row.orgId.websiteUrl)}
                              target="_blank"
                            >
                              {checkUrlWithHttp(row.orgId.websiteUrl)}
                            </a>
                          </TableCell>
                          <TableCell align="left">
                            {row.orgId.orgType}
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(
                              row.organizationDetails
                                ? row.organizationDetails.status
                                : row.orgId.status
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {((row && row.userId == authUser.unique_id) ||
                              (row &&
                                row.userId &&
                                row.userId._id === authUser.unique_id &&
                                (row.role === "owner" ||
                                  row.role === "admin" ||
                                  isAdmin))) && (
                              <div>
                                <Tooltip title="Edit">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() =>
                                      handleOrganization(row.orgId)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>{" "}
                                <Tooltip title="Delete">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() => deleteOrg(row && row.orgId)}
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {!isLoading && orgList && orgList.length === 0 && (
                <Typography className="no-req-text">
                  No Organizations Found
                </Typography>
              )}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={orgList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  background: "white",
                  bottom: "0",
                  borderTop: "1px solid #eaeaea",
                }}
              />
            </TableContainer>

            <Organization
              openOrgModal={openOrgModal}
              onCloseModal={handleCloseEditOrg}
              onSavingOrg={onSavingOrg}
              editOrg={edit}
              orgDetails={edit ? orgDetails : ""}
            />
          </div>
        </div>
      </Container>
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)}></Loader>
    </div>
  );
};

export default MyOrganizations;
