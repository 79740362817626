import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Tooltip } from "@material-ui/core";
import { changePassword } from "../../../utils/api";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [password] = useState({});
  const [passwordView, setViewPassword] = useState(false);
  const [smallLetter, setSmallLetterCondition] = useState(false);
  const [capitalLetter, setCapitalLetterCondition] = useState(false);
  const [length, setPasswordLength] = useState(false);
  const [numberCondition, setNumberCondition] = useState(false);
  const [specialLetter, setSpecialLetter] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeHandler = (event) => {
    const pswd = event.target.value;
    if (pswd.match(/[a-z]/)) {
      setSmallLetterCondition(true);
    } else {
      setSmallLetterCondition(false);
    }
    if (pswd.match(/[A-Z]+/)) {
      setCapitalLetterCondition(true);
    } else {
      setCapitalLetterCondition(false);
    }
    if (pswd.match(/\d/)) {
      setNumberCondition(true);
    } else {
      setNumberCondition(false);
    }
    if (pswd.match(/[^a-zA-Z0-9\-/]/)) {
      setSpecialLetter(true);
    } else {
      setSpecialLetter(false);
    }
    if (pswd.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
  };

  const changePasswordHandler = () => {
    changePassword(password)
      .then((response) => {
        if (response) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showPasswordRules = () => {
    setViewPassword(true);
  };

  const viewPasswordRules = () => {
    if (
      smallLetter === true &&
      capitalLetter === true &&
      length === true &&
      specialLetter === true &&
      numberCondition === true
    ) {
      setViewPassword(false);
    }
    return (
      <div className="password-strength">
        <h4>Password must be requirements</h4>
        <ul>
          <li
            className={smallLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one letter</strong>
          </li>
          <li
            className={capitalLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one capital letter</strong>
          </li>
          <li
            className={numberCondition === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one digit</strong>
          </li>
          <li
            className={specialLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one special character</strong>
          </li>
          <li
            className={length === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>8 characters</strong>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Typography component="div" className="change-password-container">
      <Typography component="div" className="reset-password">
        <Typography component="div" className="headername">
          <b>Change Password</b>
        </Typography>
        {/* <Typography component="div">
					<TextField
						className="tooltiptext login-input"
						id="outlined-adornment-confirm-password"
						name="currentPassword"
						style={{ width: '100%' }}
						placeholder="Current Password *"
						type='password'
						// value={values.password}
						onChange={changeHandler}
					/>
				</Typography> */}
        {/* { errors && <span>{errors.userName}</span> } */}
        <FormControl
          className="full-width border-radius"
          style={{ width: "100%" }}
        >
          <TextField
            className="tooltiptext login-input"
            id="outlined-adornment-confirm-password"
            name="confirmPassword"
            style={{ width: "100%" }}
            placeholder="Confirm Password *"
            type={showPassword ? "password" : "text"}
            value={password.newPassword}
            onChange={changeHandler}
            onClick={showPasswordRules}
          />
          <InputAdornment position="end" className="showpassword">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              style={{ marginTop: "10px" }}
            >
              {showPassword ? (
                <Tooltip title="Click to Show the Password">
                  <VisibilityOff />
                </Tooltip>
              ) : (
                <Tooltip title="Click to hide the Password">
                  <Visibility />
                </Tooltip>
              )}
            </IconButton>
          </InputAdornment>
          {passwordView === true ? viewPasswordRules() : ""}
        </FormControl>
        <br />
        <Button
          style={{
            background: "#f44336",
            width: "100%",
            margin: "15px 0",
          }}
          className="submit-button"
          variant="contained"
          color="primary"
          type="submit"
          onClick={changePasswordHandler}
        >
          confirm
        </Button>
        <br />
      </Typography>
    </Typography>
  );
};
export default ChangePassword;
