import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Typography,
  Link,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { appContext } from "../../App";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import { getWithdrawRequests } from "../../utils/api";
import SideMenu from "../SideMenu/Sidemenu";
import "../donationDetails/myDonations.scss";
import EditIcon from "@material-ui/icons/Edit";
import DonationWithdrawalForm from "./DonationWithdrawalForm";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/styles";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Updated Date",
  },
  {
    id: "donee",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Donation Request",
  },
  {
    id: "request_type",
    numeric: true,
    disablePadding: false,
    label: "Request Type",
  },
  { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "600px",
    overflowX: "auto",
    left: "0",
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const MyWithdrawals = () => {
  const { authUser } = useContext(appContext);
  const [donations, setDonations] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("state");
  const [withdrawalRequest, setWithdrawalRequest] = useState({});
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenWithdrawalRequestModal, setIsOpenWithdrawalRequestModal] =
    useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Completed";
    } else if (status === -2) {
      return "Incomplete";
    } else if (status === -3) {
      return "On Hold";
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadWithdrawals = (userId) => {
    setIsLoading(true);
    getWithdrawRequests(userId)
      .then((response) => {
        setIsLoading(false);
        if (response && response instanceof Array && response.length >= 0) {
          const responseArray = [];
          response.map((data) => {
            const obj = data;
            if (data.userId && data.userId.name) {
              obj.donee = `${data.userId.name.first_name} ${data.userId.name.last_name}`;
            }
            responseArray.push(obj);
            return null;
          });
          setWithdrawalRequests([...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };
  useEffect(() => {
    if (authUser) {
      loadWithdrawals(authUser.unique_id);
    }
  }, [authUser]);

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const updateSavedWithdrawalRequest = (updatedWithdrawalRequest) => {
    setWithdrawalRequests((withdrawalRequests) => {
      return withdrawalRequests.map((withdrawalRequest) => {
        if (withdrawalRequest._id === updatedWithdrawalRequest._id) {
          withdrawalRequest.status = updatedWithdrawalRequest.status;
          withdrawalRequest.amount = updatedWithdrawalRequest.amount;
          withdrawalRequest.requestType = updatedWithdrawalRequest.requestType;
          withdrawalRequest.payeeName = updatedWithdrawalRequest.payeeName;
          withdrawalRequest.notes = updatedWithdrawalRequest.notes;
          withdrawalRequest.updatedAt = updatedWithdrawalRequest.updatedAt;
        }
        return withdrawalRequest;
      });
    });
    setIsOpenWithdrawalRequestModal(false);
  };

  const editWithdrawalRequest = (withdrawalRequest) => {
    setWithdrawalRequest(withdrawalRequest);
    setIsOpenWithdrawalRequestModal(true);
  };

  return (
    <div className="myDonations-container">
      <div className="barnav">
        <Container MaxWidth="lg">
          <div style={{ display: "flex" }}>
            <Button onClick={handleCloseSideMenu}>
              {!closeSideMenu && <MenuIcon />}
              {closeSideMenu && <ArrowBackIcon />}
            </Button>

            <h2
              className="title"
              style={{
                margin: "0",
                lineHeight: "30px",
                color: "var(--tal_primary) ",
              }}
            >
              My Withdrawals
            </h2>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )}

          <div className="table-container " style={{ width: "100%" }}>
            <TableContainer className={`${classes.container}`}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(withdrawalRequests, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={event => handleClick(event, row.name)}
                          // role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">
                            {moment(row.createdAt).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell align="left">
                            {moment(row.updatedAt).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              className="name-hovr"
                              href={`/newUserProfile/${
                                row.userId && row.userId._id
                              }`}
                              target="blank"
                            >
                              {row.donee}
                            </Link>
                          </TableCell>

                          <TableCell
                            align="left"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Link
                              className="name-hovr"
                              href={
                                row.requestId &&
                                `/donationRequest/${row.requestId._id}`
                              }
                              target="blank"
                            >
                              {row.requestId && row.requestId.title}
                            </Link>
                          </TableCell>
                          <TableCell align="left">{row.requestType}</TableCell>
                          <TableCell align="left">
                            {row.requestId && row.requestId.units + " "}{" "}
                            {row.amount}
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(row.status)}
                          </TableCell>

                          <TableCell align="center">
                            {row.status === 0 && (
                              <Tooltip title="Edit">
                                <IconButton
                                  className="del-btn"
                                  onClick={() => editWithdrawalRequest(row)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

              {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
              {!isLoading &&
                withdrawalRequests &&
                withdrawalRequests.length === 0 && (
                  <Typography className="no-req-text">
                    No Withdrawals Found
                  </Typography>
                )}
              <TableFooter style={{ display: "table", width: "100%" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={withdrawalRequests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </TableContainer>
          </div>
        </div>
      </Container>
      <DonationWithdrawalForm
        withdrawalRequest={withdrawalRequest}
        open={isOpenWithdrawalRequestModal}
        close={() => setIsOpenWithdrawalRequestModal(false)}
        onSave={updateSavedWithdrawalRequest}
      />
    </div>
  );
};

export default MyWithdrawals;
