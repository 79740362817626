import React from "react";
import ReactPlayer from "react-player";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./styles.scss";

const VideoPlayer = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="lg">
      <div
        className="dialog-box drop-box"
        style={{
          zIndex: "1",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "0",
          position: "sticky",
          top: "0",
          padding: "8px 10px 0px 15px",
        }}
      >
        <h2
          className="image-title"
          style={{ color: "rgb(205, 38, 39)", fontSize: "22px" }}
        >
          {props.name}
        </h2>
        <div className="close-image-popup">
          <IconButton onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <ReactPlayer
        url={props.url}
        playing
        controls={true}
        style={{ margin: "0 auto", width: "auto" }}
      />

      <Typography
        style={{
          marginTop: "10px",
          padding: "10px",
          backgroundColor: "#fafafa",
          borderTop: "1px solid #e7e7e7",
        }}
      >
        {props.note}
      </Typography>
    </Dialog>
  );
};

export default VideoPlayer;
