const validateRequest = (values) => {
  let errors = {};
  if (!values.cashQuantity) {
    errors.cashQuantity = "Please enter amount";
  }
  if (values.cashUnit === "USD" && values.cashQuantity < 1) {
    errors.cashQuantity = "Please enter minimum $1";
  } else if (values.cashUnit === "INR" && values.cashQuantity < 50) {
    errors.cashQuantity = "Please enter minimum INR 50";
  }
  if (!values.title) {
    errors.title = "Please enter title";
  } else if (values.title && values.title.length > 50) {
    errors.title = "Title cannot be more than 50 characters";
  }
  if (!values.fundsRecipient) {
    errors.fundsRecipient = "Please select whom you are raising funds for.";
  }
  return errors;
};

export default validateRequest;
